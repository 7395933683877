import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaHeart } from 'react-icons/fa'
import { FiHeart } from 'react-icons/fi'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ToolTipChild from '../../components/ToolTipChild'
import { Tooltip } from '@mui/material'
import { compare } from '../../assets/images'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import { addWishList, removeWishList } from '../wishlist'

const HeaderSelected = ({ compareDataItem, profileType, selectedIds, subProfileType }) => {
  const { inst_name, area_nm, ct_nm, ph_url, id } = compareDataItem
  const [show, setShow] = useState(false)
  const { wishListEdu, wishListInst, userData } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const wish =
    wishListEdu?.find((x) => x.entity_id === id) || wishListInst?.find((x) => x.entity_id === id)

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {ph_url ? (
        <img className="imgdiv" src={ph_url} />
      ) : (
        <div
          className="imgdiv"
          style={{
            background: `#EBEBF2 url(${
              profileType === "coaching"
                ? compare.default_photo_coaching
                : compare.default_photo_inst
            }) no-repeat no-repeat center center`,
          }}
        />
      )}

      <Tooltip title={inst_name} arrow>
        <ToolTipChild>
          <h4
            className="schoolName"
            onClick={() =>
              history.push(
                `/details/${subProfileType}/${inst_name
                  .replace(/ /g, "-")
                  .replace(/\./g, "")
                  .toLowerCase()}?id=${id}`
              )
            }
          >
            {inst_name}
          </h4>
        </ToolTipChild>
      </Tooltip>
      {show && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 5,
            right: 10,
            backgroundColor: "white",
            padding: 10,
            borderRadius: "100%",
            border: "1px solid grey",
            cursor: "pointer",
          }}
          onClick={() => {
            selectedIds.length > 1 && dispatch({ type: 'UPDATE_SELECTED_COMPARE', data:{id} })
          }}
        >
          <AiOutlineClose />
        </div>
      )}
      <div className="flex-row">
        <img style={{ width: "10px", height: "13px", marginRight: "6px" }} src={compare.location} />
        <p>{[area_nm, ct_nm].filter((a) => a).join(", ") || "-"}</p>
      </div>
      <div className="flex-row compare-apply">
        <Link to="/apply/">
          <button>{t("apply_now")}</button>
        </Link>
        <div
          className="shortlist-div flex-row"
          onClick={() => {
            wish
              ? removeWishList(
                  { wishListEdu, wishListInst, userData },
                  profileType,
                  dispatch,
                  wish
                )
              : addWishList({ wishListEdu, wishListInst, userData }, profileType, dispatch, id)
          }}
        >
          {wish ? <FaHeart color="#FD6C73" size={16} /> : <FiHeart color="#FD6C73" size={16} />}
        </div>
      </div>
    </div>
  );
}

export default HeaderSelected
