import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LangDropDown = () => {
  const initialLang = localStorage.getItem("lang")
  const [language, setLanguage] = useState(initialLang ?? 'eng')
  const { i18n } = useTranslation()


  const handleLangChange = (val) => {
  setLanguage(val)
  i18n.changeLanguage(val)
  localStorage.setItem("lang", val)
  }

    return (
        <select
            className='home-header-select'
            value={language}
            onChange={(e) => handleLangChange(e.target.value)}
          >
            <option value={'eng'}>English</option>
            <option value={'marathi'}>मराठी</option>
            <option value={'hindi'}>हिंदी</option>
          </select>
    );
};

export default LangDropDown;