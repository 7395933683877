import { Component } from 'react'
// import Loader from '../../components/loader'
import Banner from './banner'
// import SkillList from './skillList'
import School from './school'
import Coaching from './coaching'
import Educator from './educators'
import Category from './category'
import Blog from './blog'
import BannerImg from './bannerImg'
// import Whyus from './whyus'
import Testimonials from './testimonials'
import Location from '../../components/Location/index'
import ScrollIndicator from '../../components/ScrollIndicator'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import Offer from '../../components/Offer/index'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import './home.css'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state={
    }
  }
  

  postAllProfileTileDetails = () => {
    this.props.dispatch({type:'SET_LOADING', isLoading:true})
    api
      .postCustom({
        url: APIURL.allProfileTiles,
        // headers: {
        //   Authorization: `Bearer ${this.props.authToken ? this.props.authToken : ''}`,
        // },
        data: {
          std_crs_type: this.props.std_crs_type || 'School',
          mst_std_nm: this.props.mst_std_nm || '1st',
          location_det: this.props.location_det
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.props.dispatch({ type: `SET_TILE_DETAILS`, allTileDetails: response.data.result })
        }
      })
      .catch((err) => {
        toast.error(errorMsg(err))
      })
      .finally(()=>{this.props.dispatch({type:'SET_LOADING', isLoading:false})})
  }
   setShowsignin = (bool)=>{
    const { dispatch } = this.props;
    dispatch({type:'SHOW_SIGN_IN', value:bool})
  }
  componentDidMount() {
    window.scrollTo(0,0)
    this.postAllProfileTileDetails()
    // setTimeout(() => { this.setState({ showOffer: true })}, 2000)
    setTimeout(() => { this.setState({showOffer:false }) 
    this.props.dispatch({type: 'TOGGLE_POPUP', showLocPopup: true}) }, 5000)
    window.addEventListener('beforeunload', this.props.dispatch({type: 'TOGGLE_POPUP', showLocPopup: false}));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.dispatch({type: 'TOGGLE_POPUP', showLocPopup: false})); // remove the event handler for normal unmounting
}
  // shouldComponentUpdate(prevProps, prevState) {
  //   if (prevProps.showSignIn !== this.props.showSignIn) {
  //     return true
  //   }
  //   return false
  // }
  
  componentDidUpdate(prevProps, prevState){
    if (prevProps.location_det !== this.props.location_det) 
      this.postAllProfileTileDetails()
  }

  // setShowsignin = () => {
  //   this.setState({ showsignin: !this.state.showsignin })
  //   console.log('set show sign in')
  // }


  showOffer = () => this.setState({ showOffer: !this.state.showOffer })

  render() {
    const { showOffer } = this.state
    const { schools, coachings, educators, categories, latest_reads } = this.props.allTileDetails ?? {}
  
   
    return (
      <div>
        {this.props.showLocPopup && <Location />}
        {showOffer && <Offer showOffer={() => this.showOffer()} />}
        <Banner
          showSignIn={this.showSignIn}
          setShowsignin={this.setShowsignin}
        />
        
        {/* <SkillList /> */}
        <School schools={schools} />
        <Coaching coachings={coachings} />
        <Educator educators={educators} />
        <Category categories={categories || []} />
        <Blog latest_reads={latest_reads || []} />
        <BannerImg />
        <Testimonials />
        {/* <Whyus />   */}
        <ScrollIndicator />
        <ToastContainer theme="colored" closeButton={false} />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({ 
  showSignIn: state.auth.showSignIn, 
  allTileDetails: state.auth.allTileDetails, 
  location_det: state.location.location_det, 
  mst_std_nm: state.location.mst_std_nm,
  std_crs_type: state.location.std_crs_type,
  showLocPopup: state.location.showLocPopup
})
export default connect(mapStateToProps)(Home)
