import Home from '../modules/home'
import Listing from '../modules/listing'
import Details from '../modules/details'
import Compare from '../modules/compare'
import Apply from '../modules/apply'
import Contact from '../modules/contact'
import Student from '../modules/student'
import AllReviews from '../modules/reviews'
import StudyAbroad from '../modules/studyAbroad'

const websiteRoute = [
    {path: '/', exact: true, strict:true, component:  Home},
    {path: '/home/', exact: true, strict:true, component:  Home},
    {path: '/listing/', exact: true, strict:true, component:  Listing},
    {path: '/student/', exact: true, strict:true, component:  Student},
    {path: '/listing/:subProfileType',exact: true, strict:true,component: Listing},
    {path: '/details/', exact: true, strict:true, component:  Details},
    {path: '/details/:subProfileType/:name', exact: true, strict:true, component:  Details},
    {path: '/details/:subProfileType/allReviews/:id', exact: true, strict:true, component:  AllReviews},
    {path: '/compare/', exact: true, strict:true, component:  Compare},
    {path: '/apply/:profileType/:id', exact: true, strict:true, component:  Apply},
    {path: '/contact/', exact: true, strict:true, component:  Contact},
    {path: '/studyAbroad', exact: true, strict:true, component:  StudyAbroad},
    
]

const dashboardRoutes = [
    {path: '/student/enquirey', exact: true, strict:true, component:  Student},
    {path: '/student/applications', exact: true, strict:true, component:  Student},
    {path: '/student/wishlist', exact: true, strict:true, component:  Student},
    {path: '/student/review', exact: true, strict:true, component:  Student},
    {path: '/student/profile', exact: true, strict:true, component:  Student},
    {path: '/student/settings', exact: true, strict:true, component:  Student},
]

export  {websiteRoute, dashboardRoutes};