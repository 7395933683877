const initialState = {
  userData: {},
  multiLogin: [],
  isLoading: false,
  wishListEdu:[],
  wishListInst:[],
  allTileDetails: {},
  allProfileTypes: [],
  footerCities: [],
  form_id:"",
  applicationId:"",
  showSignIn:false
}

function auth(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_SIGN_IN':{
      return {...state, showSignIn: action.value}
    }
    case 'SET_LOADING': {
      return { ...state, isLoading: action.isLoading }
    }
    case 'SET_TILE_DETAILS': {
      // const { profile_types, footer_cities } = action.allTileDetails
      // if (
      //   state?.allProfileTypes?.toString() !== profile_types?.toString() ||
      //   state.footerCities?.toString() !== footer_cities?.toString()
      // ) {
      //   return {
      //     ...state,
      //     allTileDetails: action.allTileDetails,
      //     allProfileTypes: profile_types,
      //     footerCities: footer_cities,
      //   }
      // } else {
        return {
          ...state,
          allTileDetails: action.allTileDetails,
        }
      // }
    }
    
    case 'UPDATE_WISHLIST': {
      const {type, ...wishList} = action
        return {
          ...state,
          ...wishList,
        }
    }

    case 'SET_PROFILE_TYPES': {
      return {
        ...state,
        allProfileTypes: action.allProfileTypes,
      }
    }
    case 'SET_FOOTER_CITIES': {
      return {
        ...state,
        footerCities: action.footerCities,
      }
    }
    case 'SET_USER_DETAILS': {
      return { ...state, ...action.data }
    }
    case 'ADD_FORM': {
      return { ...state, form_id:action.form_id  ,applicationId:action.applicationId}
    }
    case 'LOGOUT': {
      return { userData: {}, multiLogin: [], p_type: '', up_id: '', token: '', wishListEdu:[], wishListInst:[]}
    }
    default: {
      return state
    }
  }
}

export default auth
