import React from "react";
import { student } from "../../assets/images";
import './nabBar.css'
import { useTranslation } from 'react-i18next';

export default function NavBar(props) {
    const { t } = useTranslation()
    return (
        <>
            <div className="navBar-out">
                {
                    props.modules == 'applications' &&
                    <div >{t("my_apps")}  ( {props.count} Applications )</div>
                }
                {
                    props.modules == 'enquirey' &&
                    <div >{t("my_enq")}  ( {props.count} Enquries)</div>
                }
                {
                    props.modules == 'review' &&
                    <div >{t("my_reviews")}  ( {props.count} Reviews)</div>
                }
                {
                    props.modules == 'wishlist' &&
                    <div >{t("my_wish")}  ( {props.count} Wishlist)</div>
                }
                {
                    props.modules == 'profile' &&
                    <div >{t("my_profile")}  </div>
                }

{
                    props.modules == 'settings' &&
                    <div style={{ marginLeft: '30px' }}>Settings  </div>
                }


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {/* {
                        props.modules == 'applications' &&
                        <div style={{ border: '1px solid #D7D5EA', padding: '2px', borderRadius: '5px',marginRight:'10px', display: 'flex', flexDirection: 'row' }}>
                            <img src={student.fil} style={{ margin: '5px' }} />
                            <input type='date' className="inputStudent" style={{ width: '100px' }}></input>
                            <lable style={{ color: '#1954E7', marginLeft: '5px', marginRight: '5px' }}>{t("to")}</lable>
                            <input type="date" className="inputStudent" style={{ width: '100px' }}></input>
                        </div>
                     } */}

                     {
                        (props.modules == 'applications' || props.modules == 'wishlist' || props.modules == 'review' || props.modules == 'enquirey') &&
                        <div style={{ border: '1px solid #D7D5EA', padding: '2px', borderRadius: '5px', display: 'flex', marginRight:'10px',flexDirection: 'row' }}>
                        <input placeholder={t("search")} style={{ border: 0, outline: 0, margin: '3px' }} onChange={(e)=>props.setSearch(e.target.value)} />
                        <img src={student.search} style={{ height: '20px', width: '20px', margin: '3px' }} />
                    </div>
                     }

                  

                </div>


            </div>
        </>
    )
}