import React, { useState, useEffect } from "react"
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Form1 = (props) => {
  const { t } = useTranslation()
  const userData = useSelector(state => state.auth.userData)
  const { token, c_id, up_id ,p_type} = userData
  const [BloodGroup, setBloodGroup] = useState([])
  const [category, setCategory] = useState([])
  const [motherTounge, setMotherTounge] = useState([])
  const [religion, setRelegion] = useState([])
  const [gender, setGender] = useState([])
  const app_id = useSelector(state => state.auth.applicationId)


  const Type = () => {
    if (p_type == 1) {
      return 'coaching'
    } else if (p_type == 2) {
      return 'school'
    } else if (p_type == 3) {
      return 'educator'
    } else if (p_type == 4) {
      return 'student'
    } else {
      return 'staff'
    }
  }



  const [studentData, setStudentData] = useState({
    aadhar_no: "",
    dob: "",
    email_id: "",
    student_name: "",
    religion_id: "",
    mobile_no: "",
    category_id: "",
    mother_tongue_id: "",
    blood_group_id: "",
    country_id: "",
    address: "",
    gender_id: "",
    application_id:app_id,
    applier_type:Type()
  })



  useEffect(() => {
    profiletype()
  }, [])

  useEffect(() => {
    if (props.FormData) {
      setStudentData({
        ...studentData,
        aadhar_no: props.FormData?.aadhar_no??'',
        dob: props.FormData?.dob??'',
        email_id: props.FormData?.email_id??'',
        student_name: props.FormData?.student_name??'',
        religion_id: props.FormData?.religion_id??'',
        mobile_no: props.FormData?.mobile_no??'',
        category_id: props.FormData?.category_id??'',
        mother_tongue_id: props.FormData?.mother_tongue_id??'',
        blood_group_id: props.FormData?.blood_group_id??'',
        country_id: props.FormData?.country_id??'',
        address: props.FormData?.address??'',
        gender_id: props.FormData.gender_id,
      })
    }
  }, [props.FormData])





  const savedetails = () => {
    if (studentData.student_name == "") {
      toast.error("Please enter Student Name.")
    } else if (studentData.mobile_no == "") {
      toast.error("Please enter Mobile Number")
    } else if (studentData.email_id == "") {
      toast.error("Please enter Email")
    } else if (studentData.aadhar_no == "") {
      toast.error("Please enter Adhhar Number")
    } else {
      props.setLoading(true)
      let request = {
        url: `website/institute/form/${props.addId}/student/add`,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: studentData
      }
      api.postCustom(request).then(data => {
        props.setLoading(false)
        toast.success("Student details Added successfully");
        props.setFormno(2)

      }).catch((err) => {
        props.setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }


  const profiletype = () => {
    let request = {
      url: `master/all/sub-master/?mast_id_list=86de75c1-746b-429f-9430-11107e21139a,5eb46979-06b8-4c2e-8bc3-1704f5393b9f,f690a45f-97a4-4733-83ab-bb7ad76d5210,7743d3db-f57e-4f51-a11a-95c9f673eda7,d1765f9f-a998-4ebf-a3ad-2a5a02b6a4a2`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
    api.getCustom(request).then(data => {
      setBloodGroup(data.data.result['86de75c1-746b-429f-9430-11107e21139a'])
      setCategory(data.data.result['5eb46979-06b8-4c2e-8bc3-1704f5393b9f'])
      setRelegion(data.data.result['f690a45f-97a4-4733-83ab-bb7ad76d5210'])
      setMotherTounge(data.data.result['7743d3db-f57e-4f51-a11a-95c9f673eda7'])
      setGender(data.data.result["d1765f9f-a998-4ebf-a3ad-2a5a02b6a4a2"])

    }).catch((err) => {
      if (err.response.status == 401) {
        props.dispatch({ type: 'LOGOUT' })
      } else {
        // toast.error(errorMsg(err))
      };
    })
  }
  const validateEmail = (email) => {
    var reg =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!reg.test(email))
      toast.error("Please enter a valid email address");

  }

  const validateText = (name) => {
    var reg = /^[A-Z a-z]*$/
    if (!reg.test(name)) {
      return false;
    }
    return true;
  }
  const onlyNumberKey = (evt) => {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which || evt.keyCode || 0
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
    return true;
  }


  return (
    <>
      <div className="form-group"><input type="text" value={studentData.student_name} placeholder="Student Name *" onChange={(e) => { if (validateText(e.target.value)) { setStudentData({ ...studentData, student_name: e.target.value }) } }} /></div>
      <div className="form-group">
        <input placeholder={`${t('mob_no')} *`}
          type="text"
          pattern="[0-9]*"
          value={studentData.mobile_no}
          onKeyPress={onlyNumberKey}
          maxLength={10}
          onChange={(e) => setStudentData({ ...studentData, mobile_no: e.target.validity.valid ? e.target.value : studentData.mobile_no })} />
        <input type="text" placeholder={`${t('email_add')} *`} value={studentData.email_id} onBlur={(e) => validateEmail(e.target.value)} onChange={(e) => setStudentData({ ...studentData, email_id: e.target.value })} />
      </div>
      <div className="form-group"><input type="text" maxLength='12' value={studentData.aadhar_no} placeholder={`${t('aadhaar')} *`} onChange={(e) => setStudentData({ ...studentData, aadhar_no: e.target.value })} /></div>
      <div className="form-group">
        <select required value={studentData.country_id} onChange={(e) => setStudentData({ ...studentData, country_id: e.target.value })}><option value={""} className="placeholder" selected disabled>{t('nation')}</option><option value="f3c25bf7-34b1-41a0-81c9-24a2978a7c57">India</option></select>
        <select required value={studentData.religion_id} onChange={(e) => setStudentData({ ...studentData, religion_id: e.target.value })}><option value={""} className="placeholder" selected disabled>{t('religion')}</option>{religion.map((item) => <option value={item.entity_id}>{item.name}</option>)}</select>
        <select required value={studentData.category_id} onChange={(e) => setStudentData({ ...studentData, category_id: e.target.value })}><option value={""} className="placeholder" selected disabled>{t('category')}</option>{category.map((item) => <option value={item.entity_id}>{item.name}</option>)}</select>
      </div>
      <div className="form-group">
        <input type="text" onFocus={() => { document.getElementById('dob').type = 'date' }} id="dob" placeholder={t('dob')} value={studentData.dob} onChange={(e) => setStudentData({ ...studentData, dob: e.target.value })} />
        <select required value={studentData.gender_id} onChange={(e) => setStudentData({ ...studentData, gender_id: e.target.value })}><option value={""} className="placeholder" selected disabled>{t('gender')}</option>{gender
          .map((item) => <option value={item.entity_id}>{item.name}</option>)}</select>
      </div>
      <div className="flex-row">
        <select required value={studentData.mother_tongue_id} onChange={(e) => setStudentData({ ...studentData, mother_tongue_id: e.target.value })}><option value={""} className="placeholder" selected disabled >{t('mother_tongue')}</option>{motherTounge.map((item) => <option value={item.entity_id}>{item.name}</option>)}</select>
        <select required value={studentData.blood_group_id} onChange={(e) => setStudentData({ ...studentData, blood_group_id: e.target.value })}><option value={""} className="placeholder" selected disabled >{t('blood_grp')}</option>{BloodGroup.map((item) => <option value={item.entity_id}>{item.name}</option>)}</select>
      </div>
      <div className="form-group"><input type="text" placeholder={t('address')} value={studentData.address} onChange={(e) => setStudentData({ ...studentData, address: e.target.value })} /></div>
      <div className="submit-div">
        <button onClick={() => savedetails()}>Save and Next</button>
      </div>
    </>
  )
}



export default Form1