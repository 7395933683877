import { Link, useParams } from 'react-router-dom'
import { AiFillRightCircle } from 'react-icons/ai'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { details } from '../../assets/images';
import { toast, ToastContainer } from "react-toastify";
import WriteReview from './writeReview';
import RatingCard from './ratingCard';
import { skeletonRatingCard } from '../Skeleton/skeleton';
import { useDispatch } from 'react-redux';

const Reviews = ({loading, id, profileType, ratingData, isAllReviews, userData, setRatingData, subProfileType}) => {
    const [isReview, setisReview] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const afterOpenReviewModal = () => { }
    const afterCloseReviewModal = () => { }
   

    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = isReview ? 'hidden' : 'auto';
    }, [isReview])


    const customStyles = {

        overlay: {
            position: 'fixed',
            top: 0, left: 0,
            right: 0, bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)'
        },
        content: {
            width: "54vw",
            height: "47vh",
            position: 'absolute',
            top: '15%', left: '20%',
            right: '20%', bottom: '20%',
            overflow: 'auto',
            backgroundColor: "#FFF",
            borderRadius: "6px",
            boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",
        }
    };
    
    const {avg_ratings, ratings, reviews, page_offset, page_size, sort_by } = ratingData?.result?.map_response ||{}
    const {total_elements} = ratingData?.result ||{}
    return (
      <>
        <ToastContainer />
        <div className="detail-container ">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>{t("all_reviews")} ({total_elements ?? 0})</h2>
          {!isAllReviews && <Link to={`/details/${subProfileType}/allReviews/${id}`}>
          {t('view_all')}{' '}
          <AiFillRightCircle color="#4274B0" style={{ marginBottom: '-7px' }} size={24} />
        </Link>}
          </div>
          <div className="details-reviews">
            <div className="rating-div flex-row">
              <div className="left-div">
                <div className="flex-row" style={{ marginBottom: "40px" }}>
                  <div
                    className="rating flex-row"
                    style={{
                      background: `url(${details.star_big})`,
                      backgroundSize: "cover",
                    }}
                  >
                    {avg_ratings ? (Math.round(avg_ratings * 10) / 10) : "N/A"}
                  </div>
                  <p className="basedon">
                    {t("based_on")}
                    <br />
                    {total_elements ?? 0} {t("reviews")}
                  </p>
                </div>
                <div className="all-ratings">
                  {ratingElement(5,ratings)}
                  {ratingElement(4,ratings)}
                  {ratingElement(3,ratings)}
                  {ratingElement(2,ratings)}
                  {ratingElement(1,ratings)}
                </div>
              </div>
              <div className="right-div">
                <p>{t("review_title")}</p>
                <button onClick={() => {
                  !(Object.keys(userData ?? {}).length) ? dispatch({ type:'SHOW_SIGN_IN', value:true }) :
                  (userData.p_type !== 4) ? toast.error("You need to login as student.") : setisReview(true)}}>
                  {t("write_review")}
                </button>
              </div>
            </div>
            <div className="review-div">
              {loading && <>{skeletonRatingCard()} {skeletonRatingCard()}</>  }
              {!loading && (reviews ? (reviews || []).map((obj, index) => {
                return (
                <RatingCard data={obj} userData={userData} profileType={profileType} key={index} setRatingData={setRatingData} ratingData={ratingData} index={index}/>
              )}) : 
              (<div><h1>Be the first to post review</h1></div>))
              }
            </div>
          </div>
        </div>

        <Modal
          isOpen={isReview}
          onAfterOpen={() => afterOpenReviewModal()}
          onRequestClose={() => afterCloseReviewModal()}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          closeTimeoutMS={200}
        >
          <WriteReview setisReview={setisReview} userData={userData} profileType={profileType} id={id}/>
        </Modal>
      </>
    );
}
export default Reviews


const ratingElement = (key, ratings)=>{
  const max = Object.keys(ratings ?? {}).length && Object.values(ratings).reduce((prev,curr)=>curr > prev ? curr : prev,0) 
return(
  <div className="ratings flex-row">
    <p>{key}</p>
    <p>
      <img src={details.star_grey} />
    </p>
    <div className="rating-percent">
      <div style={{ width: `${(max && ratings?.[key]) ? ratings?.[key]*100/max : 0}%` }}></div>
    </div>
    <p>{ratings?.[key] ?? 0}</p>
  </div>
)
}