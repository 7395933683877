import { useTranslation } from 'react-i18next';

export default function ApplyHeader(props) {
  const { t } = useTranslation()
  let complete = (props?.formno ?? 1) * 1;

  let state = [
    { id: 1, name: t("stud_details") },
    { id: 2, name: t("parent_details") },
    { id: 3, name: t("edu_details") },
    { id: 4, name: t("docs") },
  ];

  console.log(props.addId,"props.addId")


  return (
    <div className="apply-header flex-row">
      {state.map((data, key) => (
        <div className={complete >= data.id ? "inner active" : "inner"}>
          <div className="flex-row" onClick={() => {props.fetchFormDetails();  props.addId  && props.setFormno(data.id)}}>
            <p></p>
            <div className="step">{data.id}</div>
            <p className={data.id == 4 ? "hide" : ""}></p>
          </div>
          <h4>{data.name}</h4>
        </div>
      ))}
    </div>
  );
}
