import {useEffect} from 'react'
import { components } from '../../assets/images'
import { useTranslation } from 'react-i18next';

export default function School() {
    const { t } = useTranslation()
    var margin = 0;
    useEffect(() => {
        var timer = setInterval(() => {
            let nItemm = document.querySelector("#signin-slider-dots div:nth-child(" + (margin+1) + ")");
            nItemm.classList.remove("active");
            margin = ((margin+1)%4)
            document.getElementById('signin-slider-div').style.marginLeft = '-'+(margin*100)+'%';
            
            let nItem = document.querySelector("#signin-slider-dots div:nth-child(" + (margin+1) + ")");
            nItem.classList.add("active");
            //Add class 'active' to nth child
        }, 2500)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return (
        <>
            <div id="signin-slider-div" className="signin-slider-div" style={{marginLeft: '0%'}}>
                <div className="signin-slider">
                    <img src={components.signin_slider1} />
                    <div className="sigin-slider-info">
                        <h3>{t('search')}</h3>
                        <p>{t('slider1_text')}</p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={components.signin_slider2} />
                    <div className="sigin-slider-info">
                        <h3>{t('connect')}</h3>
                        <p>{t('slider2_text')}</p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={components.signin_slider3} />
                    <div className="sigin-slider-info">
                        <h3>{t('learn')}</h3>
                        <p>{t('slider3_text')}</p>
                    </div>
                </div>
                <div className="signin-slider">
                    <img src={components.signin_slider4} />
                    <div className="sigin-slider-info">
                        <h3>{t('discover_int')}</h3>
                        <p>{t('slider4_text')}</p>
                    </div>
                </div>
            </div>
            <div className="signin-slider-dots" id="signin-slider-dots">
                <div></div>
                <div className="active"></div>
                <div></div>
                <div></div>
            </div>
        </>
    )
}
