import React, { useState, useEffect } from "react";
import '../nabBar.css'
import { MdOutlineLocationOn } from 'react-icons/md'
import { home, details } from "../../../assets/images";
import { APIURL } from '../../../api/url'
import { toast } from 'react-toastify';
import Moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { useTranslation } from 'react-i18next';
import { getDistance,short } from "../../../utils/utils";



export default function Wishlist(props) {
    const [rdata, setRdata] = useState([])
    const {lat: myLat, long: myLong} = useSelector(state=>state.location.location_det)
    const { t } = useTranslation()
    const history = useHistory()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData

    useEffect(() => {
        getWishlist()
    }, [props.filter,props.type,props.search])


    const getWishlist = (active) => {
        props.setLoading(true)
        let request = {
            url: `website/wishlist/all?stu_id=${c_id}&sortOrder=${props.filter}&profile_type=${props.type}&search_text=${props.search}&pageSize=&pageOffset&isAll`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setRdata(data.data.result)
            props.setCount(data.data.result.length??0)
        }).catch((err) => {
            console.log(err, "errrr")
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }

    const Remove = (id) => {
        props.setLoading(true)
        let request = {
          url: `website/wishlist/${id}/delete`,
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
        api.delete(request).then(data => {
          props.setLoading(false)
          toast.success("Removed successfully")
          setRdata(rdata.filter((item3) => item3.id != id))
        }).catch((err) => {
          props.setLoading(false)
          if (err.response.status == 401) {
            props.dispatch({ type: 'LOGOUT' })
          } else {
            toast.error(errorMsg(err))
          }
        })
      }
    

    return (
        <>
            {rdata.map((item, key) => {
                return (

                    <div className="maindiv" key={key}>
                        <div>
                            <div className="fl-v">
                                <div style={{ backgroundImage: `url(${item.ph_url ?? home.school})`,  borderRadius: '8px' }} className='bg-p'>
                                    <div className="tippp" style={{ left: 0, position: 'relative' }}>
                                        <img src={home.person_km} style={{ width: '10px', height: '10px', marginRight: '5px' }} />
                                        <h6 className="loctext" >{getDistance(myLat, myLong, item.lat, item.long, 'K')}km {t('away')}</h6>
                                    </div>
                                    <div className="estab" >
                                        &nbsp;&nbsp;
                                        <MdOutlineLocationOn color="white" size={16} style={{ marginBottom: '-4px' }} />
                                        &nbsp;{item.address}
                                        {/* {[area_nm, ct_nm].filter((a) => a).join(', ')} */}
                                    </div>
                                </div>

                                <div className="bg-65">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}><div className="instiHeading" style={{ marginTop: '10px' }}>{item.inst_name}</div><img src={details.badge} style={{ width: 18, height: 20, marginLeft: 10, marginTop: "10px" }} /></div>
                                    <div className="intiiiSmall">{t("established_in")} {item.estb_in}</div>
                                    <div className="laticHeading">{item.tagline??'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy...'}</div>
                                    <div className="b-div">
                                        <div >
                                            <p>{t("ownership")}</p>
                                            <p className="newheading">{item.own_type}</p>

                                        </div>
                                        <div >
                                            <p>{t("standard")}</p>
                                            <p className="newheading">{item.std_range}</p>
                                        </div>
                                        <div >
                                            <p>{t("board")}</p>
                                            <p className="newheading">{item.edu_boards}</p>
                                        </div>
                                        <div >
                                            <p>{t("medium")}</p>
                                            <p className="newheading">{item.m_of_instruct}</p>

                                        </div>
                                        <div >
                                            <p>{t("stud_fac")}</p>
                                            <p className="newheading">{item.s_to_f_ratio}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-but">
                                    <button className="removeButton" onClick={()=>Remove(item.id)}>{t("remove")}</button>
                                    <button className="ViewButton" onClick={()=>history.push(`/details/${item.sub_prof_types}/${(item.e_name || item.inst_name)
                          .replace(/ /g, '-').replace(/\./g,'')
                          .toLowerCase()}?id=${item.id}`)}>{t("view_profile")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="bottomdivcard" >{t("avg_fees")} : {item.average_fee}</div>
                    </div>
                )
            })}

        </>
    )


}