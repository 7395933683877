import React, { useRef, useState } from "react";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import { getYTVideoThumbnail } from "../../utils/utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { details, home } from "../../assets/images";

const SliderGallery = ({ list, selectedItem, setSelectedItem, isImage }) => {
  const [galleryInd, setGalleryInd] = useState(selectedItem)
  const slider = useRef(null);
  const PrevBtn = ({ className }) => {
    return (
      <button id="prev" className={className} onClick={() => slidePrev()}>
        <img src={home.left_icon} style={{ width: "6px", height: "10px" }} />
      </button>
    );
  };

  const NextBtn = ({ className }) => {
    return (
      <button id="next" className={className} onClick={() => slideNext()}>
        <img src={home.right_icon} style={{ width: "6px", height: "10px" }} />
      </button>
    );
  };

  const slideNext = () => {
    if (Number(selectedItem) < list?.length - 1) {
      slider?.current?.slickGoTo(Number(selectedItem) + 1);
      setSelectedItem((prev) => (Number(prev) + 1).toString());
    }
  };

  const slidePrev = () => {
    if (Number(selectedItem) > 0) {
      slider?.current?.slickGoTo(Number(selectedItem) - 1);
      setSelectedItem((prev) => (Number(prev) - 1).toString());
    }
  };

  const handleItemClick = (index) => {
    slider?.current?.slickGoTo(index);
    setSelectedItem(index.toString());
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: Number(selectedItem),
    className: "slick-list1",
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
    afterChange: () => setGalleryInd(selectedItem)
    // beforeChange: (curr, next) => {
    //   handleChange(curr, next);
    // },
  };

  return (
    <section className="preview-section">
      <div className="carousel-container">
        <Slider ref={slider} {...settings}>
          {list?.map((item, index) => (
            <div key={index}>
              {isImage ? (
                <img src={item} className="preview-img" />
              ) : (
                <ReactPlayer
                  url={item}
                  playIcon={
                    <button className="play-icon">
                      <img className="play-button" src={details.play_button} />
                    </button>
                  }
                  className="preview-img"
                  light={getYTVideoThumbnail(item)}
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
      <div className="img-gallery">
        {list?.map((item, index) => (
          <div key={index}>
            <img
              src={isImage ? item : getYTVideoThumbnail(item)}
              className={`small-img${index.toString() === galleryInd ? "__active" : ""}`}
              onClick={() => handleItemClick(index)}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default SliderGallery;
