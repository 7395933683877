import { details } from '../../assets/images'
import { short } from '../../utils/utils'
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

export default function TitleBottomInfo({ profileDetails, profileType }) {
  const { t } = useTranslation()
  const { std_range, edu_board, average_fee, m_of_instruct, s_to_f_ratio,inst_courses } =
    profileDetails
  const isLoading = useSelector(state=>state.details?.isLoading)
    
    if(isLoading)
    {
      return(
        <div style={{boxSizing:'border-box',display:'flex', width:'100', height:'auto', justifyContent:'space-between', paddingLeft:'5%', paddingRight:'5%'}}>
          {[1,2,3,4,5].map((i)=>{
            return(<Skeleton key={i} variant='rectangular' style={{height:'5em', width:'14%', margin:'1%', marginLeft:'1%', marginRight:'1%', borderRadius:5}} /> )
          })}
        </div>
      )
    }
  const school =(std_range, edu_board)=> (
    <>
      <div className="info-tr">
        <div>
          <img src={details.standard} />
        </div>
        <div>
          <p>{t("standard")}</p>
          <h4>{std_range || '-'}</h4>
        </div>
      </div>
      <div className="separator"></div>
      <div className="info-tr">
        <div>
          <img src={details.board} />
        </div>
        <div>
          <p>{t("board")}</p>
          <h4>{edu_board || '-'}</h4>
        </div>
      </div>
      <div className="separator"></div>
    </>
  )

  const coaching =(inst_courses)=> (
    <>
      <div className="info-tr">
        <div>
          <img src={details.board} />
        </div>
        <div>
          <p>{t("courses")}</p>
          <h4>{short(inst_courses,3)}</h4>
        </div>
      </div>
      <div className="separator"></div>
    </>
  )
  
  return (
    <div className="detail-info-table">
      <div className="flex-row detail-div">

        {profileType === 'school'
          ? school(std_range, edu_board)
          : coaching(inst_courses)}

        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.avgfee} />
          </div>
          <div>
            <p>{t("avg_fees")}</p>
            <h4>
              <span></span>
              {average_fee}/month
            </h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.medium} />
          </div>
          <div>
            <p>{t("medium")}</p>
            <h4>
                {short(m_of_instruct,3)}
            </h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.ratio} />
          </div>
          <div>
            <p>{t('stud_fac')}</p>
            <h4>{s_to_f_ratio || '-'}</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
