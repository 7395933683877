import { useSelector } from 'react-redux'
import { components } from '../../assets/images'
import './loader.css'
function Loader({isLoading}) {
  // const isLoading = useSelector((state) => state.listing.isLoading)

  return (
    <>
      <div className='loader_container' style={ !isLoading ?{display:'none'}:{}}>
        <div className='loader'>
          <center>
            <img src={components.loader} />
          </center>
        </div>
      </div>
    </>
  )
}
export default Loader
