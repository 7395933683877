import Filter from "../../../components/Filter/index"
import { useTranslation } from 'react-i18next';

function FilterItem({keyName, data, selectedFilters, onChecked, onClickMore}) {
  const { t } = useTranslation()

  let titles = {
    std_ids: t('standard'),
    crs_ids: t('courses'),
    edu_brd_ids: t('board'),
    moi_ids: t('medium_instruct'),
    mot_ids: t('mode_of_teaching'),
    fac_ids: t('facilities'),
    ext_cur_ids: t('extra_cirr'),
    own_type_ids: t('ownership'),
    edu_type_ids: t('edu_type'),
  }

  return (
    <div className="filter-box">
      <Filter id={keyName} title={titles[keyName]}>
        <div className={'filter-section-maxheight'}>
          {data?.slice(0,3).map((item, index) => {
            const { id, name, occurence } = item
            return (
              <div className="option" key={index}>
                <div className="flex-row">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        onChecked(keyName, id)
                      }}
                      checked={selectedFilters[keyName].includes(id)}
                    />
                    {name}
                  </label>
                </div>
                <p>({occurence})</p>
              </div>
            )
          })}
        </div>
        {data.length > 3 && (
          <p
            className="more"
            onClick={() => {
                onClickMore(keyName,data)
            }}
          >{`+${data.length - 3} more`}</p>
        )}
      </Filter>
    </div>
  )
}
export default FilterItem