import About from './about'
import Details from './details'
import Imagess from '../images'
import Director from './director'
import { useSelector } from 'react-redux'

export default function Index({profileDetails, profileType}) {
    const {overview, dir_li, g_img_urls, vdo_link} = profileDetails
    const isLoading = useSelector(state=>state.details?.isLoading)

    return (
        <>
            <About overview={overview} isLoading={isLoading}/>
            <Details profileDetails={profileDetails} profileType={profileType} isLoading={isLoading}/>
            <Imagess images={g_img_urls} videos={vdo_link} isLoading={isLoading}/>
            <Director dir_li={dir_li} isLoading={isLoading}/>
        </>
    )
}
