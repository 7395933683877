import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from "react-router-dom";
import { details, home } from "../../assets/images";
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux'
import EnquiryPopup from "../../components/EnquiryPopup";

const responsive = {
    all: {
        breakpoint: { max: 5000, min: 0 },
        items: 1
    }
};


const Slider = ({isEducator, profileDetails,profileType}) => {
    const userData = useSelector(state => state.auth.userData)
    const { t } = useTranslation()
    const {estb_in,c_img_urls, id } = profileDetails || {}
    const [enquiry, setEnquiry] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <div className="detail-slider">
            {isEducator 
                ? 
                <div className="slides" style={{ background: `url(${(details.educatorCover2)}) center center`, backgroundSize: 'cover' }}/>
                :
                <Carousel responsive={responsive} focusOnSelect={true} showDots={false} arrows={true} >
                    {
                       ( Array.isArray(c_img_urls) && c_img_urls.length) ? c_img_urls.map((item, key) =>
                            <div key={key} className="slides" style={{ background: `url(${encodeURI(c_img_urls).replace(/\(/g, '%28').replace(/\)/g, '%29')}) center center`, backgroundSize: 'cover' }}>
                                <div className="overlay"></div>
                            </div>
                        ) 
                        : 
                        <div className="slides" style={{ background: `url(${details.educatorCover}) center center`, backgroundSize: 'cover' }}>
                            <div className="overlay"></div>
                          </div>
                    }
                </Carousel>}
            {!isEducator && <div className="detail-slider-info">
                <div>
                    <p className="established">{estb_in && `Established in ${estb_in}`}</p>
                    <div className="flex-row">
                        {/* changed from a to div of enquiry */}
                        <div className="flex-row enquire-now-v" onClick={()=>{ 
                            Object.keys(userData ?? {}).length ? id && setEnquiry(id) : dispatch({ type:'SHOW_SIGN_IN', value:true })                            }}>
                                <img src={details.phone} style={{ width: 16, height: 16, marginRight: 8 }} />{t("enquire now")}
                        </div>
{/*------------------------------ CHANGE LINK TO DIV -------------------------------------------*/}

                        <div className="ApplyNow"
                        onClick={()=>{ 
                            if(Object.keys(userData ?? {}).length){
                                if(id){
                                    history.push(`/apply/${profileType}/${id}`); 
                                    dispatch({ type: 'ADD_FORM', form_id: "",applicationId:"" })
                                }
                            }
                            else{
                                dispatch({ type:'SHOW_SIGN_IN', value:true })
                            }
                        }}
                        >{t("apply_now")}</div>

                    </div>
                </div>
            </div>}
            {enquiry && <EnquiryPopup inst_id={enquiry} close={()=>setEnquiry('')}/>}
        </div>
    )
}

export default Slider