const initialState = {
  isLoading: false,
  lStdCrssFacilitiesNews : false,
  lFeeTchrAdmn : false,
  lMngtAlumniSchl : false,
  lEventAchvPlacFaq : false,
  stdCrssFacilitiesNews: {},
  feeTchrAdmn: {},
  mngtAlumniSchl: {},
  eventAchvPlacFaq: {},
}

function details(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING_ITEM': {
      return { ...state, ...action.value }
    }
    case 'SET_LOADING': {
      return { ...state, isLoading: action.value  }
    }
    case 'UPDATE_DETAILSPAGE_STATE': {
      const { data } = action
      return { ...state, ...data }
    }
    default: {
      return state
    }
  }
}

export default details
