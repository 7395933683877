const initialState = {
  selectedFilters: {
    prof_id: null,
    loc_det: {},
    std_ids:[],
    crs_ids: [],
    edu_brd_ids: [],
    moi_ids: [],
    mot_ids: [],
    fac_ids: [],
    ext_cur_ids: [],
    own_type_ids: [],
    edu_type_ids: [],
    dist_frm: 0,
    dist_to: 0,
    fee_frm: 0,
    fee_to: 0,
    expr_frm: 0,
    expr_to: 0,
  },
  isLoading: false,
  filterLoading:false,
  cardsLoading:false,
  listDetails:{},
  pageOffset:1
}

function listing(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SELECTED_FILTERS': {
      const {keyName, value} = action.updatedFilter;

      if(Array.isArray(state.selectedFilters[keyName])) {

        const valOfKey =[...state.selectedFilters[keyName]]

        if(action.reset){
          return {...state, selectedFilters:{ ...initialState.selectedFilters, [keyName]:[value]}}
        }
        if(state.selectedFilters[keyName].includes(value))
        {
          valOfKey.splice(valOfKey.indexOf(value), 1)
          return {...state, selectedFilters: { ...state.selectedFilters, [keyName]:[...valOfKey]}}
        }

        return { ...state, selectedFilters: { ...state.selectedFilters, [keyName]:[...state.selectedFilters[keyName], value]}, pageOffset:1}

      } 
      return { ...state, selectedFilters: { ...state.selectedFilters, [keyName]: value}, pageOffset:1}
      }

    case 'SET_LIST_DETAILS': {
      return {
        ...state,
        listDetails: action.listDetails,
        // isLoading:false
      }
    }
    case 'SET_LOADING':{
      return {
        ...state,
        ...action.value
      }
    }
    case 'UPDATE_PAGE_OFFSET': {
      return {
        ...state,
        pageOffset: action.pageOffset,
      }
    }
    case 'RESET_FILTERS':{
      return {...state, selectedFilters:initialState.selectedFilters }
    }

    default: {
      return { ...state }
    }
  }
}

export default listing
