import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from './locales/en/translationEn.json'
import translationHi from './locales/hi/translationHi.json'
import translationMt from './locales/mt/translationMt.json'
import { rootstore } from "./redux/store";

let state = rootstore.getState()
const lang = localStorage.getItem('lang')
const resources = {
  eng: {
    translation: translationEn
  },
  hindi: {
    translation: translationHi
  },
  marathi: {
    translation: translationMt
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang || 'eng', 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;