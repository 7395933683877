import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { CgCloseR } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import './enquiryPopup.css'

const EnquiryPopup = (props) => {
  const { id, close, masterProfileType} = props
  const { c_id, up_id, token, p_type } = useSelector((state) => state.auth.userData)
  const { t } = useTranslation()
  const fromKey={
    '1' : 'enq_inst_id',
    '2' : 'enq_inst_id',
    '3' : 'enq_edu_id',
    '4' : 'stu_id',
    '5' : 'INVALID',
    '6' : 'INVALID',
  }
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields: touched },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      msg: '',
      name: '',
      mob_no: '',
      eml_id: '',
    },

    criteriaMode: 'all',
  })
  const validationSchema = {
    name: {
      required: t('name_req'),
      minLength: {
        value: 3,
        message: t('min_three_letters'), // JS only: <p>error message</p> TS only support string
      },
      maxLength: {
        value: 20,
        message: t('max_twenty_letters'), // JS only: <p>error message</p> TS only support string
      },
      pattern: {
        value: /^[a-zA-Z ]*$/,
        message: t('special_chars'),
      },
    },
    mob_no: {
      required: t('mob_no_req'),
      minLength: { value: 10, message: t('valid_mob_no') },
      pattern: { value: /^[0-9]+$/, message: t('no_allowed') },
    },
    eml_id: {
      required: t('email_req'),
      pattern: {
        value: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
        message: t('invalid_email'),
      },
    },
    msg: { required: t('msg_req'), minLength: { value: 5, message: t('min_five_chars') } },
  }
  const onSubmit = (data) => {
    const { msg, name, mob_no, eml_id } = data
    api
      .postAuth({
        url: APIURL.addEnquiry,
        token: `Bearer ${token}`,
        data: {
          [fromKey[p_type]]: c_id,
          [masterProfileType==='educator' ? 'edu_id' : 'inst_id']: id ,
          msg,
          name,
          mob_no,
          eml_id,
        },
      })
      .then((res) => {
        console.log(res.data)
      })
      .catch((e) => errorMsg(e))
      .finally((e) => close())
  }

  return (
    <div className="enquiry-popup-container">
      <div className="enquiry-div">
        <div className="enquiry-heder-div flex-row">
          <div>
            <h2>{t('send_enquiry')}</h2>
            <p className="horline"></p>
          </div>

          <div className="enquiry-close" onClick={() => close()}>
            <CgCloseR color="#9B9B9B" size={26} />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="enquiry-form">
            <div>
              <h4>
              {t('name')}<p style={{ color: 'red' }}>&nbsp;*</p>
              </h4>
              <input type={'text'} placeholder="Enter your name" {...register('name', validationSchema.name)} />
              {touched.name && errors.name?.message ? <div className="err-msg">*{errors?.name?.message}</div> : null}
            </div>

            <div>
              <h4>
              {t('mob_no')}<p style={{ color: 'red' }}>&nbsp;*</p>
              </h4>
              <input
                type={'text'}
                placeholder="99000 99000"
                {...register('mob_no', validationSchema.mob_no)}
                maxLength={10}
              />
              {touched.mob_no && errors.mob_no?.message ? (
                <div className="err-msg">*{errors?.mob_no?.message}</div>
              ) : null}
            </div>

            <div>
              <h4>
              {t('email_add')}<p style={{ color: 'red' }}>&nbsp;*</p>
              </h4>
              <input type={'text'} placeholder="yourname@domain.com" {...register('eml_id', validationSchema.eml_id)} />
              {touched.eml_id && errors.eml_id?.message ? (
                <div className="err-msg">*{errors?.eml_id?.message}</div>
              ) : null}
            </div>

            <div>
              <h4>{t('messages')}</h4>
              <input type={'text'} placeholder={`${t('write_msg')}...`} {...register('msg', validationSchema.msg)} />
              {touched.msg && errors.msg?.message ? <div className="err-msg">*{errors?.msg?.message}</div> : null}
            </div>

            <button className="enquiry-btn" type="submit">
              <p>{t('send_enquiry')}</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EnquiryPopup
