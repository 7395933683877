import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import ChangePopup from "./changePopup";
import Autocomplete from "react-google-autocomplete";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { APIURL } from "../../../api/url";
import { connect } from 'react-redux'
import config from '../../../config'
import Loader from '../../../components/Loader'
import AddChild from "./addChild";
import { isphnValid } from "../../../utils/utils";
import { CiEdit } from 'react-icons/ci'
import { AiOutlineDelete } from 'react-icons/ai'


export default function Profile(props) {
    const [change, setChange] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [showAddChild, setAddChild] = useState(false)
    const [children, setChildren] = useState([])
    const [activeChild, setActiveChild] = useState({})
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData

    const [address, setaddress] = useState({
        "address": "",
        "p_code": "",
        "lat": 0,
        "long": 0,
        "c_nm": "India",
        "s_nm": "",
        "ct_nm": "",
        "ar_nm": "",
    })

    const [profile, setProfile] = useState(
        {
            "nm": "",
            "is_parent": false,
            "u_id": up_id,
            "m_no": "",
            "em_id": "",
            "loc_det": {},
            "edu_lvl_id": "",
            "edu_lvl_desc": ""
        }
    )

    useEffect(() => {
        fetchFormDetails()
    }, [])

    const fetchFormDetails = () => {
        setLoading(true)
        let request = {
            url: `stud/${c_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            let rdata = data.data.result
            setProfile({ ...profile, nm: rdata.nm, u_id: up_id, m_no: rdata.m_no, em_id: rdata.em_id, edu_lvl_desc: rdata.edu_lvl_desc, edu_lvl_id: rdata.edu_lvl_id, is_parent:rdata.is_parent})
            setChildren(rdata.childs)
            setaddress({
                ...address, address: rdata.loc_det.address, pin: rdata.loc_det.p_code, lat: rdata.loc_det.lat, long: rdata.loc_det.long, c_nm: rdata.loc_det.c_nm,
                s_nm: rdata.loc_det.s_nm,
                ct_nm: rdata.loc_det.ct_nm,
                ar_nm: rdata.loc_det.ar_nm,

            })
            props.setName(rdata.nm)
            props.setProfilePhoto(rdata.p_url)
            props.setImage(rdata.p_url)


        }).catch((err) => {
            toast.error(errorMsg(err));
        }).finally(()=>setLoading(false))
    }

    function isObject(val) {
        return val instanceof Object;
      }

    const savedetails = () => {
        if(profile.nm==""){
            toast.error("Please enter Name.")
        }else if(profile.m_no==""){
            toast.error("Please enter Mobile No.")
        }else if(!isphnValid(profile.m_no)){
          toast.error("Please enter a valid Mobile No.")
        }else if(profile.em_id==""){
            toast.error("Please enter Mail Id.")
        }else if(address.address==""){
            toast.error("Please enter Address.")
        }else if(profile.is_parent && !children?.length) {
            toast.error("Please add a child")
        } else{
        setLoading(true)
        var fd = new FormData();
        let temp = {
            "nm": profile.nm,
            "u_id": up_id,
            "m_no": profile.m_no,
            "em_id": profile.em_id,
            "is_parent": profile.is_parent,
            "p_url": isObject(props.profilePhoto ) ? null : props.profilePhoto ,
            "loc_det": {
                "address": address.address,
                "p_code": address.pin,
                "lat": address.lat,
                "long": address.long,
                "c_nm": address.c_nm,
                "s_nm": address.s_nm,
                "ct_nm": address.ct_nm,
                "ar_nm": address.ar_nm,
            },
            "edu_lvl_id": profile.edu_lvl_id,
            "edu_lvl_desc": profile.edu_lvl_desc
        }
        fd.append("u_data", JSON.stringify(temp))
        fd.append("file", props.profilePhoto)
        let request = {
            url: `stud/${c_id}/update`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: fd
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Profile Updated successfully");
            fetchFormDetails()
           

        }).catch((err) => {
              setLoading(false)
            if (err.response.status == 401) {
                dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    }
    }

    const deleteItem = (ind) => {
      const prevChildren = [...children]
      const deletedChild = prevChildren[ind]
      setLoading(true)
      api.deleteAuth({ url: `${APIURL.child}/${deletedChild.id}/delete`, token: `Bearer ${token}`, params: {uid: up_id}}).then((data) => {
        toast.success(data.data.result)
          prevChildren?.splice(ind, 1)
          setChildren(prevChildren)
      }).catch((err) => toast.error(errorMsg(err))).finally(()=>setLoading(false));
    }

    console.log({children})

    const editItem = (ind) => {
      const prevChildren = [...children]
      setActiveChild(prevChildren[ind])
      setAddChild(true)
    }

    return (
      <>
        <Loader isLoading={loading} />

        <div className="basic-det">
          <div className="notification">{t("basic_det")}</div>
          <p class="line_purple"></p>
          <div className="parent-row">
            <div className="chk-container">
              <input
                type={"checkbox"}
                id={"par-chkbox"}
                checked={profile.is_parent}
                onChange={(e) => {
                  if (!children.length) {
                    setProfile({ ...profile, is_parent: e.target.checked });
                  }
                }}
              />
              <div className="parent-label">
                <label htmlFor="par-chkbox" className="main-label">
                  {t("are_you_parent")}
                </label>
                <label className="hint">{`(${t("par_warning")})`}</label>
              </div>
            </div>
            {profile.is_parent && (
              <div className="add-btn-sec">
                <button className="add-child-btn" onClick={() => setAddChild(true)}>
                  {t("add_child")}
                </button>
              </div>
            )}
          </div>
          <div className="parent-row">
            {children?.map((item, index) => {
              return (
                <div className="child-info-box" key={item.id}>
                  <div className="child-name">{item.name}</div>
                  <div className="icons-sec">
                    <CiEdit
                      color="#4274B0"
                      size={18}
                      style={{ marginRight: "1rem" }}
                      onClick={() => editItem(index)}
                    />
                    <AiOutlineDelete color="#EC1212" size={18} onClick={() => deleteItem(index)} />
                  </div>
                </div>
              );
            })}
          </div>
          <input
            className="expandable"
            placeholder={`${t("full_name")} *`}
            value={profile.nm}
            onChange={(e) => setProfile({ ...profile, nm: e.target.value })}
          ></input>

          <div className="dis-row">
            <div class="flexv-p1 w-70">
              <div class="select-wrappers">
                <select class="expandale-input w-200 india">
                  <option value="ind">IND +91</option>
                  <option>+1</option>
                </select>
              </div>
              <input
                class="expandale-input"
                contenteditable="true"
                type={"number"}
                maxlength={10}
                placeholder="Enter"
                value={profile.m_no}
                onChange={(e) => setProfile({ ...profile, m_no: e.target.value })}
              ></input>
              <a onClick={() => setChange(true)}>{t("change")}</a>
            </div>

            <input
              type={"email"}
              className="expandable"
              style={{ marginLeft: "10px" }}
              value={profile.em_id}
              onChange={(e) => setProfile({ ...profile, em_id: e.target.value })}
              contenteditable="true"
              placeholder={t("email_hint")}
            ></input>
          </div>
          <Autocomplete
            apiKey={"AIzaSyBgjbTWg8b3WbMXvdykC5zn28RdmNKil6k"}
            class="expandableComp"
            placeholder={`${t("address")}*`}
            value={address.address}
            onChange={(e) => setaddress({ ...address, address: e.target.value })}
            onPlaceSelected={(place) => {
              let zip =
                place.address_components.find((item) => item.types[0] === "postal_code")
                  ?.long_name ?? "-";
              let state =
                place.address_components.find(
                  (item) => item.types[0] === "administrative_area_level_1"
                )?.long_name ?? "-";
              let city =
                place.address_components.find(
                  (item) => item.types[0] === "administrative_area_level_2"
                )?.long_name ?? "-";
              let area =
                place.address_components.find((item) => item.types[0] === "sublocality_level_1")
                  ?.long_name ?? "-";
              setaddress({
                ...address,
                address: place.formatted_address,
                pin: zip,
                s_nm: state,
                ct_nm: city,
                ar_nm: area,
                lat: place.geometry.location.lat(),
                long: place.geometry.location.lng(),
              });
            }}
            options={{
              types: ["geocode", "establishment"],
              componentRestrictions: { country: "in" },
            }}
          />
          {/* <input className="expandableComp" placeholder={`${t("address")}*`}></input> */}
          <div className="dis-row">
            <input class="expandableShort" placeholder={t("pin")} value={address.pin}></input>
            <input class="expandableShort" placeholder={t("countru")} value="India"></input>
            <input class="expandableShort" placeholder={t("state")} value={address.s_nm}></input>
            <input class="expandableShort" placeholder={t("city")} value={address.ct_nm}></input>
            {/* <input class="expandableShort" placeholder={t("state")} value={address.s_nm}> </input> */}
            {/* <input class="expandableShort" placeholder={t("city")}  value={address.ct_nm}></input> */}
          </div>

          <div className="notification" style={{ marginTop: "20px" }}>
            {t("acad_details")}
          </div>
          <p class="line_purple mar-btm-20"></p>
          <div className="dis-row">
            <select
              className="expandable"
              style={{ fontSize: "16px" }}
              value={profile.edu_lvl_id}
              onChange={(e) => setProfile({ ...profile, edu_lvl_id: e.target.value })}
            >
              <option>{t("select_edu")}</option>
              <option
                value={
                  config.env == "sit"
                    ? "8efc3ab0-2952-4adc-94f8-df6d4f8380a4"
                    : "013c22ec-dcff-4ad4-b9f9-419f11fa4e47"
                }
              >
                Play School
              </option>
              <option
                value={
                  config.env == "sit"
                    ? "86409b84-59a7-40be-b398-5d12ec01cc53"
                    : "38fad9f5-60cc-4da9-aba7-27df7f79b708"
                }
              >
                School
              </option>
              <option
                value={
                  config.env == "sit"
                    ? "aca78425-dda8-412f-8d86-e0fd163d0982"
                    : "20821264-e6cf-4464-9132-1ac451d6824e"
                }
              >
                Under Graduate
              </option>
              <option
                value={
                  config.env == "sit"
                    ? "5ba08e7e-333c-4c58-a64a-8cc07c4dcc72"
                    : "d79a1210-fd4c-484f-b3be-e51788ec4746"
                }
              >
                Post Graduate
              </option>
              <option
                value={
                  config.env == "sit"
                    ? "9d406798-4e67-49d3-851c-ec6a701fa480"
                    : "bbc9934c-d3b2-4b94-a737-6f0ad1b775b0"
                }
              >
                PHD
              </option>
            </select>
          </div>
          <input
            className="expandableComp"
            contenteditable="true"
            placeholder={t("tell_us")}
            value={profile.edu_lvl_desc}
            onChange={(e) => setProfile({ ...profile, edu_lvl_desc: e.target.value })}
          ></input>

          <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "20px" }}>
            <button className="removeButton">{t("cancel")}</button>
            <button
              className="ViewButton"
              onClick={() => savedetails()}
              style={{ marginRight: "20px" }}
            >
              {t("update")}
            </button>
          </div>
        </div>

        {change && <ChangePopup setChange={setChange} />}
        {showAddChild && (
          <AddChild
            setAddChild={setAddChild}
            showAddChild={showAddChild}
            children={children}
            setChildren={setChildren}
            activeChild={activeChild}
            setActiveChild={setActiveChild}
            userData={userData}
            setLoading={setLoading}
          />
        )}
      </>
    );
}