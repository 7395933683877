import { BsArrowLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import {  useHistory } from 'react-router-dom'

const NavBar = (props) => {
    const { t } = useTranslation();
    let history = useHistory();
    const { shareLinks, subProfileType, profileDetails, id } = props
    const {e_name, inst_name} = profileDetails

    return (
        <div className="nav-section">
        <div className="back-container">
          <BsArrowLeft
            color="#6B6B6B"
            size={18}
            style={{ marginTop: 2, marginRight: 10, cursor: "pointer" }}
            onClick={() => history.push(
              `/details/${subProfileType}/${(e_name || inst_name).replace(/ /g, '-').replace(/\./g, '').toLowerCase()}?id=${id}`
            )}
          />
          <p className="back">{t("back_to_profile")}</p>
        </div>
        {/* TO DO: Make links clickable */}
        <nav className="nav-links">
          {shareLinks.map((item, index) => (
            <a key={index}>
              <img src={item} />
            </a>
          ))}
        </nav>
      </div>
    );
};

export default NavBar;