import { put, takeLatest, call } from 'redux-saga/effects';
import api, { errorMsg } from '../../../api/api'
import { APIURL } from '../../../api/url'
export const watchLogin = function* watchLogin() {
    yield takeLatest('BEGIN_LOGIN', beginLogin);
    
    
}

function* beginLogin(action) {
    yield put({ type: 'LOGIN_LOADING' });
    
}

export const beginFetchList = function* beginFetchList(){
    yield takeLatest('BEGIN_FETCHING_LIST',fetchingList)
}

function* fetchingList({type,payload}) {
    const {filter, loc_det, prof_id, pageOffset, listDetails} = payload
    const noOfPages = listDetails && Object.keys(listDetails).length > 0 && listDetails?.total_elements !== 0 ? Math.ceil(listDetails?.total_elements / 20.0) : 1
    if (pageOffset <= noOfPages) {
      yield put({ type: 'SET_LOADING', value: {cardsLoading:true} })
      let res = yield call(api.postCustom,{
        url: APIURL.filteredList,
        data: { ...{...filter, loc_det }, prof_id, pageOffset },
        params: {
          pageOffset,
          pageSize: 20,
          isAll: false,
          sortBy: 'LATEST',
        },
      })
      
  if (res.status === 200 ) {
          // if(listDetails?.toString()!==res.data.result)
          let newCards = (listDetails.response && pageOffset!==1) ? [...listDetails.response , ...res.data.result?.response] : res.data.result?.response
         
          let updatedCards = {...res?.data.result}
          updatedCards.response = newCards
            yield put({
              type: 'SET_LIST_DETAILS',
              listDetails: res?.data.result?.pageOffset === 1 ? res.data.result : updatedCards,
            })
            yield put({ type: 'SET_LOADING', value: {cardsLoading:false} })
        }
    }
    }        
    

