export const staticText = {
  t_and_c: "Terms and Conditions",
  address: "Address",
  address_part1: "Head Office: Sr No 2/1/5, Archway,",
  address_part2: "Office 101-102, Balewadi, Pune 411045",
  company: "Company",
  about_us: "About Us",
  continue: "Continue",
  blogs: "Blogs",
  privacy: "Privacy Policy",
  help: "Help",
  contact_us: "Contact Us",
  site_map: "Site map",
};
