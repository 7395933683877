import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { home } from '../../assets/images'
import { AiFillRightCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { skeletonSchoolCoach } from '../../components/Skeleton/skeleton'
import SchoolCoachCard from './schoolCoachCard'

const Coaching = ({ coachings }) => {
  const skeletonArray = [1, 2, 3, 4, 5, 6, 7]
  const { lat: myLat, long: myLong } = useSelector((state) => state.location.location_det)
  const isLoading = useSelector((state) => state.auth.isLoading)
  const { t } = useTranslation()

  const PrevBtn = (props) => {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <img src={home.left_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  const NextBtn = (props) => {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        <img src={home.right_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  //OR to be replaced by nullish when data is removed from persistor
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    appendArrows: coachings?.length >= 4,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (coachings?.length || skeletonArray.length) <= 5 ? 3 : 4.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (coachings?.length || skeletonArray.length) <= 5 ? 3 : 3.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },

      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (coachings?.length || skeletonArray.length) <= 5 ? 2.5 : 2.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (coachings?.length || skeletonArray.length) <= 5 ? 1.5 : 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
  }

  const textObj = {
    "away": t('away'),
    "visit": t('visit')
  }

  return (
    <div className="m-v-60-30">
      <div className="carousel-header-row">
        <div>
          <h3>{t('coaching_title')}</h3>
          <div className="carousel-dash"></div>
        </div>

        <Link to="/listing/competitive-exam">
          {t('view_all')}{' '}
          <AiFillRightCircle color="#4274B0" style={{ marginBottom: '-7px' }} size={24} />
        </Link>
      </div>
      <Slider {...settings}>
        {!coachings || isLoading
          ? skeletonSchoolCoach(skeletonArray)
          : coachingList(coachings, myLat, myLong, t("no_data_available"), textObj)}
      </Slider>
    </div>
  )
}
const coachingList = (coachings, myLat, myLong, emptyArrayText, textObj) =>
  coachings.length ? (
    coachings.map((obj, index) => SchoolCoachCard(obj, myLat, myLong, false, textObj))
  ) : (
    <h1>{emptyArrayText}</h1>
  )

export default Coaching
