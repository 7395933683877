import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Search from './search'
import { home } from '../../assets/images'
import { useHistory } from 'react-router-dom'
import { skeletonProfileHome } from '../../components/Skeleton/skeleton'
import { useSelector } from 'react-redux';

const BannerMenu = (props) => {
  const [activeMenu, setActiveMenu] = useState(null)
  const  allProfileTypes = useSelector((state)=> state.auth.allProfileTypes)
  const { t } = useTranslation()
  const history = useHistory()
  const skeletonArray=[1, 2, 3, 4, 5, 6]
  useEffect(() => {}, [activeMenu])

  return (
    <div className="home-banner-div">
      <div className="home-search-container home-logo-spaceBetween">
        <div className="home-search-container-inner ">
          <h1>{t('heading_part1')}</h1>
          <div className="home-logo-content">
            <h2>{t('heading_part2')}&nbsp;</h2>
            <p className="banner-text">{t('heading_part3')}</p>
          </div>

          <Search />
        </div>

        <div className="banner-girl">
          <img src={home.banner_girl} alt="Banner" />
        </div>
      </div>

      <div className="home-header-menu">
        {allProfileTypes?.length ? (
            allProfileTypes.map((obj, index) => {
              const { id, name, ph_url, example } = obj
              return (
                <div
                  key={index}
                  className={activeMenu === id ? 'header-menu active' : 'header-menu'}
                  onClick={() => {
                    setActiveMenu(id)
                    if (name === 'Study Abroad') {
                      history.push('/studyAbroad')
                    } else {
                      history.push(`/listing/${name.toLowerCase().replace(/ /g, '-')}`)
                    }
                  }}
                >
                  <img src={ph_url} alt={index} />
                  <p>{name}</p>
                  <p className='examples'>{example?.join(', ')}</p>
                </div>
              )
            })
          ) : (
            skeletonProfileHome(skeletonArray)
        )}
      </div>
    </div>
  )
}

export default BannerMenu
