import { useTranslation } from 'react-i18next';
import { details } from '../../../assets/images'
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton'

const Qualification = ( props ) => {
  const {qualifications} = props
  const { isLoading } = props
  const { t } = useTranslation()
  // const isLoading = true
  // const qualifications= [
  //   {
  //       "id": "3a21d816-a9fc-4004-a5ab-c4f9c9fba3f1",
  //       "educator": null,
  //       "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
  //       "inst_name": "Pune university",
  //       "d_name": "Engineering",
  //       "e_type": "Full Time",
  //       "f_date": "2022-04-13",
  //       "t_date": "2022-09-13",
  //       "is_ongoing": false,
  //       "desc": "<p>dctrfyt fgtiyi yi;uiu tgyh'o8uo 8y98u0'9ou0u.</p>",
  //       "c_date": "2022-08-12 23:40:32.0",
  //       "u_date": "2022-08-12 23:41:19.0",
  //       "ht_qual": true,
  //       "uid": null
  //   }
  // ]
 if (isLoading) {
   return (
       <>
       {skeletonDetailsEdu()}
       </>
   )
 }
  return (
    <div className="detail-container">
      <h2>{t("edu_qualif")}</h2>
      <div className="details-qualification">
        {Array.isArray(qualifications) && qualifications.length !== 0 ? qualifications.map((data, index) => {
          const { inst_name, e_type, d_name, desc, f_date, t_date } = data
         
          return (
            <div key={index}>
              <div className="flex-row details-dmsg">
                <div>
                  <div className="detail-qualification-coaching">
                    <img src={details.coaching_logo} />
                  </div>
                </div>
                <div>
                  <h4 style={{ color: '#4274B0', textTransform: 'capitalize' }}>
                    {inst_name}
                  </h4>
                  <div className="flex-row detdiv">
                    <h6>
                      {[
                        f_date &&
                          new Date(f_date).toLocaleString('default', {
                            month: 'long',
                            year: 'numeric',
                          }),
                        t_date ?
                          new Date(t_date).toLocaleString('default', {
                            month: 'long',
                            year: 'numeric',
                          }): 'present'
                      ]
                        .join(' - ')}{' '}
                    </h6>
                    <div className="separator"></div>
                    <h6>{e_type}</h6>
                  </div>
                  <p
                    style={{
                      color: '#283B4C',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {d_name}
                  </p>
                  <p className="descr">{desc?.replace(/<[^>]*>?/gm, '')}</p>
                </div>
              </div>
            </div>
          )
        }) : <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>}
      </div>
    </div>
  )
}

export default Qualification
