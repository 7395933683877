import { useState } from 'react'
import { Link } from "react-scroll";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import ToolTipChild from '../../components/ToolTipChild';
import { skeletonDetailsInstSidebar } from '../../components/Skeleton/skeleton';

const Sidebar = ({profileType}) => {
    const activeClass = "dsidebar active"
    const inActiveClass = "dsidebar"
    const [activeSideBar, setActiveSideBar] = useState(0)
    const isLoading = useSelector(state=>state.details?.isLoading)
    const isLoadingContent = useSelector(state=>state.details?.lLtdCrssFacilitiesNews) 
    const stdCrssFacilitiesNews = useSelector(state=>state.details?.stdCrssFacilitiesNews) ?? {}

    const { t } = useTranslation()
    const {news} = stdCrssFacilitiesNews
    // const news= [
    //     {
    //         "id": "2d4d6402-94a5-4700-ba12-73733ba2ac76",
    //         "title": "election",
    //         "links": [
    //             "https://discovery-admin.proctur.com/coaching/news/",
    //             "https://app.slack.com/client/T02LZUK77MZ"
    //         ],
    //         "desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi fermentum lorem non nisl fringilla aliquet. Morbi interdum commodo pulvinar. Curabitur nec congue ipsum. Fusce nulla ante, maximus in dui et, tempus egestas elit. Aenean facilisis dignissim consequat. Suspendisse nibh dolor, vestibulum in lorem eget, tempor facilisis nisi.",
    //         "i_p_id": "b611b682-d8dd-41d7-b431-15296c0517d7",
    //         "c_date": "2022-08-19 15:49:12.0",
    //         "u_date": "2022-08-19 15:49:12.0",
    //         "file_li": [
    //             {
    //                 "id": "356cb7a9-2da9-431c-85a4-598ef527d426",
    //                 "i_prof_id": "b611b682-d8dd-41d7-b431-15296c0517d7",
    //                 "u_from": "NEWS",
    //                 "m_id": "2d4d6402-94a5-4700-ba12-73733ba2ac76",
    //                 "f_disp": "vector_magic.jpg",
    //                 "f_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile_dfndfn/news_election/vector_magic.jpg",
    //                 "f_size": 0,
    //                 "f_type": null,
    //                 "c_by": null,
    //                 "c_date": "2022-08-19 15:49:18.0"
    //             },
    //             {
    //                 "id": "fff00fda-fe52-46a7-a922-cebef91af1d4",
    //                 "i_prof_id": "b611b682-d8dd-41d7-b431-15296c0517d7",
    //                 "u_from": "NEWS",
    //                 "m_id": "2d4d6402-94a5-4700-ba12-73733ba2ac76",
    //                 "f_disp": "img1.jpg",
    //                 "f_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile_dfndfn/news_election/img1.jpg",
    //                 "f_size": 0,
    //                 "f_type": null,
    //                 "c_by": null,
    //                 "c_date": "2022-08-19 15:49:19.0"
    //             }
    //         ],
    //         "uid": null
    //     }
    // ]
    const setActiveSideBar1 = (event) => {

        // event.currentTarget.classList.toggle('dsidebar active');
        console.log("setActiveSideBar1 : ", event)
        // event.relatedTarget.classList.remove('active')
        event.currentTarget.classList.add('active');
        event.currentTarget.classList.remove('active');
    }
    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsInstSidebar()}
          </>
      )
    }
    
    return (
      <>
        <div className="details-sidebar">
         <div>
         <div>
            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_overview"
              className={activeSideBar === 0 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(0)}
              href="#overview"
            >
              {t("overview")}
            </Link>

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_standard"
              className={activeSideBar === 1 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(1)}
              href="#standard"
            >
              {profileType === "school"
                ? t("standard")
                : t("courses")}
            </Link>
            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_structure"
              className={activeSideBar === 2 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(2)}
              href="#fee"
            >
              {t('fee_struct')}
            </Link>
            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_facilities"
              className={activeSideBar === 3 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(3)}
              href="#facilities"
            >
              {t("facilities")}
            </Link>
            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_teachers"
              className={activeSideBar === 4 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(4)}
              href="#teachers"
            >
              {t("teacher")}
            </Link>
            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_admission"
              className={activeSideBar === 5 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(5)}
              href="#admission"
            >
              {t("admission")}
            </Link>

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_management"
              className={activeSideBar === 6 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(6)}
              href="#management"
            >
              {t("mdesk")}
            </Link>

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_alumni"
              className={activeSideBar === 7 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(7)}
              href="#alumni"
            >
              {t("alumni")}
            </Link>

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_scholarship"
              className={activeSideBar === 8 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(8)}
              href="#alumni"
            >
              {t("scholarship")}
            </Link>

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_events"
              className={activeSideBar === 9 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(9)}
              href="#event"
            >
              {t("events")}
            </Link>

            {profileType !== "school" && (
              <Link
                activeClass="active"
                smooth
                spy
                to="sidebar_placement"
                className={activeSideBar === 10 ? activeClass : inActiveClass}
                onClick={() => setActiveSideBar(10)}
                href="#placement"
              >
                {t("placements")}
              </Link>
            )}

            {/* <Link activeClass="active" smooth spy to="sidebar_rating">  */}

            <Link
              activeClass="active"
              smooth
              spy
              to="sidebar_faq"
              className={activeSideBar === 11 ? activeClass : inActiveClass}
              onClick={() => setActiveSideBar(11)}
              href="#faq"
            >
              {t("faq")}
            </Link>
          </div>
         </div>

          <div className="latest-news">
            <h4 className="blue-bg">{t('latest_news')}</h4>
            {Array.isArray(news) && news.length !== 0 ? (
              news.map((item, index) => {
                const { title, desc, file_li } = item;
                return (
                  <div key={index} className="news">
                    <p style={{ fontWeight: "bolder", color: "black" }}>{title}</p>
                    <Tooltip title={desc} arrow>
                      <ToolTipChild>
                        <p className="lat-news">{desc?.replace(/<[^>]*>?/gm, "") || "N/A"}</p>
                      </ToolTipChild>
                    </Tooltip>
                    <a href="#">{t("download")}</a>
                  </div>
                );
              })
            ) : (
              <h5 style={{ fontSize: "16px" }}>{t('no news available')}</h5>
            )}
          </div>
        </div>
      </>
    );
}

export default Sidebar;