import Form1 from './form1'
import Form2 from './form2'
import Form3 from './form3'
import Form4 from './form4'
import Form5 from './Form5'
import { ToastContainer } from 'react-toastify';
import '../apply.css'

const index = (props) => {
    return (
        <div className="form-container" style={{width:'auto'}}>
            {
                props.formno == 1 && <Form1 {...props} />
            }
            {
                props.formno == 2 && <Form2 {...props} />
            }
            {
                props.formno == 3 && <Form3 {...props} />
            }
            {
                props.formno == 4 && <Form4 {...props} />
            }
            {
                props.formno == 0 && <Form5 {...props} />
            }
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}

export default index