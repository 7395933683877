import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiShareAlt } from 'react-icons/bi'
import { HiThumbUp, HiThumbDown } from 'react-icons/hi'
import moment from 'moment'
import { details } from '../../assets/images'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../../api/api'
import { useDispatch } from 'react-redux'

const RatingCard = ({ data, userData, profileType, ratingData, setRatingData, index }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    id,
    ph_url,
    stud_nm,
    overall,
    c_date,
    faculty,
    academics,
    infra,
    placements,
    std_mat,
    desc,
    helpful_count,
    is_review_helpful,
    review_helpful_id,
  } = data
  const { token, up_id } = userData

  const onTapThumb = (review_id, review_helpful_id, is_helpful) => {
    if(Object.keys(userData ?? {}).length === 0){
      dispatch({ type:'SHOW_SIGN_IN', value:true })
    }else if (userData.p_type !== 4) {
      toast.error('You need to login as student.')
    } else {
      let is_educator = profileType === 'educator'
      if (ratingData.result.map_response.reviews[index].is_review_helpful !== is_helpful) {
        api
          .postCustom({
            url: `review-helpful/${review_id}/add`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              uid_of_ticker: up_id,
              review_helpful_id,
              is_helpful,
              is_educator,
            },
          })
          .then((res) => {
            if (res.data.status_code === 200) {
              const temp = { ...ratingData }
              temp.result.map_response.reviews[index].is_review_helpful = is_helpful
              temp.result.map_response.reviews[index].review_helpful_id =
                res.data.result.review_helpful_id
              if (is_helpful) {
                temp.result.map_response.reviews[index].helpful_count++
              } else if (
                ratingData.result.map_response.reviews[index].review_helpful_id &&
                is_helpful === false
              ) {
                temp.result.map_response.reviews[index].helpful_count--
              }
              setRatingData(temp)
            }
          })
          .catch((err) => {
            toast.error(errorMsg(err))
          })
      }
    }
  }

  return (
    <div key={id} className="review flex-row">
      <div className="avatar-div">
        <img src={ph_url} />
      </div>
      <div className="content-div">
        <div className="flex-row heading-div">
          <div>
            <h4 className="flex-row">
              {stud_nm}
              <div className="flex-row">
                {overall ?? '-'} <img src={details.star_white} />
              </div>
            </h4>
            <p>{moment(c_date).fromNow()}</p>
          </div>
          <div className="share">
            <BiShareAlt color={'#0A72B0'} size={16} />
            {/* <img src={share} /> */}
          </div>
        </div>
        <div className="review-tags">
          {faculty ? (
            <div>
              {t('faculty')}
              <span>
                {faculty} <img src={details.star_grey} />
              </span>
            </div>
          ) : null}

          {academics ? (
            <div>
              {t('academics')}
              <span>
                {academics} <img src={details.star_grey} />
              </span>
            </div>
          ) : null}
          {infra ? (
            <div>
              {t('infra')}
              <span>
                {infra} <img src={details.star_grey} />
              </span>
            </div>
          ) : null}
          {placements ? (
            <div>
              {t('placements')}
              <span>
                {placements} <img src={details.star_grey} />
              </span>
            </div>
          ) : null}
          {std_mat ? (
            <div>
              {t('stud_material')}
              <span>
                {std_mat} <img src={details.star_grey} />
              </span>
            </div>
          ) : null}
        </div>
        <p>{desc}</p>
        <br />
        <p>
          {helpful_count} {t('people_found')}
        </p>
        <div className="flex-row helpful-div">
          <p>{t('is _this_helpful')}</p>
          <div
            className="flex-row helpful-img"
            style={{ color: is_review_helpful ? '#5ad092' : '#707070' }}
            onClick={() => onTapThumb(id, review_helpful_id, true)}
          >
            <HiThumbUp size={20} color={is_review_helpful ? '#5ad092' : '#707070'} /> {t('yes')}
          </div>{' '}
          {/*<img src={yes}/> */}
          <div
            className="flex-row helpful-img"
            style={{ color: is_review_helpful === false ? '#095A8B' : '#707070' }}
            onClick={() => onTapThumb(id, review_helpful_id, false)}
          >
            <HiThumbDown size={20} color={is_review_helpful === false ? '#095A8B' : '#707070'} />{' '}
            {t('no')}
          </div>
          {/*<img src={no}/> */}
        </div>
      </div>
    </div>
  )
}

export default RatingCard
