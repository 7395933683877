
import { useEffect, useState } from 'react'
import HeadList from '../../components/Headlist/index'
import ApplySchool from './apply-school'
import ApplyForm from './apply-form'
import ScrollIndicator from '../../components/ScrollIndicator'
import './apply.css'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import api, { errorMsg } from '../../api/api'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';



export default function Index(props) {
    const userData = useSelector(state=>state.auth.userData)
    const [loading,setLoading]=useState(false) 
    const {token, c_id, up_id} = userData
    const { profileType, id } = useParams()
    const [rdata, setRdata] = useState({})
    const [FormData,setFormData]=useState({})
    const history = useHistory()
    const [formno, setFormno] = useState(0)
    const add_id = useSelector(state=>state.auth.form_id)
    const inc_sec = history.location.state?.inc ?? ''
    const [applicationId,setApplicationId] =useState(0)


   


    useEffect(() => {
        fetchData();
        if (add_id) {
            setFormno(inc_sec)
            fetchFormDetails(add_id)
        }

    }, [])




    const fetchFormDetails = (id) => {
        setLoading(true)
        let request = {
            url: `website/institute/form/${id?id:add_id}/0/get`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            setFormData(data.data.result)
        }).catch((err) => {
            toast.error(errorMsg(err));
        }).finally(()=>setLoading(false))
    }



    const fetchData = () => {
        setLoading(true)
        let request = {
            url: `website/institute/admission-setting/application-form-fee/${id}/get`, //793b91be-6a6b-49d0-972a-58a9b05b840c
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            setRdata(data.data.result)
        }).catch((err) => {
            toast.error(errorMsg(err));
        }).finally(()=>setLoading(false))
    }


    return (
        <div>

            <Loader isLoading={loading}/>
            <HeadList />
            <div className="listing-heading-title apply-p">
                <span>Home / </span> <span>{profileType} / </span><span>{rdata.inst_name} /</span> <span>Apply</span>
            </div>

            <div className="apply-div">

                  
                <div className="apply-school-div">
                    <ApplySchool rdata={rdata} formno={formno} setFormno={setFormno} addId={add_id} FormData={FormData} setLoading={setLoading}/>
                </div>

                <div className="apply-form-div">
                    <ApplyForm rdata={rdata} formno={formno} setFormno={setFormno} addId={add_id}  FormData={FormData} fetchFormDetails={fetchFormDetails} setLoading={setLoading}/>
                </div>
            </div>

            <ScrollIndicator />
        </div>
    )
}
