const initialState = {
  selectedIds: [],
  profileType: null,
  subProfileType: null,
}

function compare(state = initialState, action) {
  const { data, type } = action
  const { id, profileType, subProfileType} = data || {}
  switch (type) {
    case 'UPDATE_SELECTED_COMPARE': {
      if (state.selectedIds.includes(id)) {
        return { ...state, selectedIds: state.selectedIds.filter((a) => a !== id) }
      }
      if (subProfileType === state.subProfileType) {
        return { ...state, selectedIds: [...state.selectedIds, id] }
      } else {
        return { selectedIds: [id], profileType, subProfileType }
      }
    }

    case 'RESET_COMPARE': {
      return initialState
    }

    default: {
      return { ...state }
    }
  }
}

export default compare
