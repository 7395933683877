import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import './headList.css';

const Headlist = () => {
  const allProfileTypes = useSelector((state) => state.auth.allProfileTypes)

  if (allProfileTypes) {
    return (
      <div className="listing-headlist-container">
        <div className="listing-headlist">
          {Array.isArray(allProfileTypes) &&
            allProfileTypes.map((obj, index) => (
              <NavLink
                key={index}
                to={`/listing/${obj.name.toLowerCase().replace(/ /g, '-')}` || '/listing/'}
                
                className={(isActive) => 'hlist' + (isActive ? ' active' : '')}
              >
                {obj.name}
              </NavLink>
            ))}
        </div>
      </div>
    )}
    return (
      <div className="listing-headlist-container">
        <div className="listing-headlist"></div>
      </div>
    )
  }


export default Headlist
