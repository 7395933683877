import { useState } from 'react'
import { details } from '../../../assets/images'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';


const Alumni = () => {
  const [viewMore, setViewMore] = useState(false)
  const [descrIndex, setDescrIndex] = useState(0)
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=>{return state.details?.lMngtAlumniSchl})
  const mngtAlumniSchl = useSelector((state) => state.details?.mngtAlumniSchl) ?? {}
  const { t } = useTranslation()
  const { alumnies } = mngtAlumniSchl
  const onTapViewMore = (index) => {
    setViewMore(!viewMore)
    setDescrIndex(index)
  }

  // const alumnies= [
  //     {
  //         "id": "02fa6ec4-0951-46d6-a8de-5f14c986177e",
  //         "inst_prof": null,
  //         "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
  //         "title": "Mr",
  //         "name": "nnfmmffm",
  //         "p_ph": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/alumni/profilePhoto/Group_402428_(1).png",
  //         "achv_det": "nmndmfnmf",
  //         "vdo_link": null,
  //         "mfng": "nmdnmf",
  //         "is_active": true,
  //         "s_on_prof": false,
  //         "c_date": "2022-07-05 14:41:06.0",
  //         "u_date": "2022-07-04 12:5838.0",
  //         "uid": null
  //     },
  //     {
  //         "id": "12a9d57d-3050-4a6a-a1d9-a5f04ad0fe62",
  //         "inst_prof": null,
  //         "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
  //         "title": "Mr",
  //         "name": "jdjdjhdhjjhdhj",
  //         "p_ph": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/alumni/profilePhoto/Group_402427_(1).png",
  //         "achv_det": "dbfndfnnfbnf",
  //         "vdo_link": [
  //             "https://youtu.be/YwEKIl3qQzA"
  //         ],
  //         "mfng": "hhg",
  //         "is_active": true,
  //         "s_on_prof": false,
  //         "c_date": "2022-07-04 12:51:15.0",
  //         "u_date": "2022-07-04 12:57:55.0",
  //         "uid": null
  //     },
  //     {
  //         "id": "13b4ba62-98cd-4dc8-a649-5f8b8d378bd0",
  //         "inst_prof": null,
  //         "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
  //         "title": "Ms",
  //         "name": "nnf",
  //         "p_ph": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/alumni/profilePhoto/Group_402427_(1).png",
  //         "achv_det": "nfdnbndf",
  //         "vdo_link": null,
  //         "mfng": "",
  //         "is_active": true,
  //         "s_on_prof": false,
  //         "c_date": "2022-07-05 14:41:06.0",
  //         "u_date": "2022-07-04 14:45:07.0",
  //         "uid": null
  //     }
  // ]
  const viewMoreClass = 'descr'
  const viewLessClass = 'descrless'

  if (isLoading || isLoadingContent) {
    return (
        <>
        {skeletonDetailsEdu()}
        </>
    )
  }

  return (
    <div className="detail-container">
      <h2>{t("alumni")}</h2>
      <div className="details-alumni">
        {Array.isArray(alumnies) && alumnies.length !== 0 ? (
          alumnies.map((obj, index) => {
            const { name, p_ph, achv_det, vdo_link, mfng, u_date } = obj
            return (
              <div key={index}>
                <div className="flex-row">
                  <img className="alimg" src={details.alumini} />
                  <div>
                    <h4>{achv_det?.replace(/<[^>]*>?/gm, "") || "N/A"}</h4>
                  </div>
                </div>
                <div className="flex-row details-dmsg">
                  <img src={p_ph} />
                  <div>
                    <h4>{name}</h4>
                    <p>{new Date(u_date).toLocaleDateString()}</p>
                  </div>
                </div>
                <p className={viewMore && index === descrIndex ? viewLessClass : viewMoreClass}>
                  {mfng?.replace(/<[^>]*>?/gm, "") || "No Message"}
                </p>
                <div className="flex-row alumni-links">
                  <a className="first" href="#">
                    {t("watch_video")}
                  </a>
                  <p className="second flex-row" onClick={() => onTapViewMore(index)}>
                    {t("view")}{" "}
                    {viewMore && index === descrIndex
                      ? t("less")
                      : t("more")}{" "}
                    {viewMore && index === descrIndex ? (
                      <img src={details.blue_arrow_up} />
                    ) : (
                      <img src={details.blue_arrow_down} />
                    )}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>
        )}
      </div>
    </div>
  )
}

export default Alumni
