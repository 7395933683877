import { useState, useEffect, memo } from 'react'
import Slider from 'react-rangeslider'
import { FaRegEdit } from 'react-icons/fa'
import Filter from '../../../components/Filter/index'
import { APIURL } from '../../../api/url'
import api, { errorMsg } from '../../../api/api'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import FilterModalMUI from './filterModalMUI'
import { toast } from 'react-toastify'
import SelectedFiltersView from './SelectedFiltersView'
import FilterItem from './FilterItem'
import { skeletonFilters } from '../../../components/Skeleton/skeleton'
import { useQuery } from '../../../utils/utils'
import { useHistory } from 'react-router-dom'

const Filters = ({ profileid }) => {
  const [filterOptions, setFilterOptions] = useState({})
  const selectedFilters = useSelector((state) => state.listing?.selectedFilters, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const filterLoading = useSelector((state) => state.listing?.filterLoading, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const cardsLoading = useSelector((state) => state.listing?.cardsLoading, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const { ct_nm } = useSelector((state) => state.location?.location_det, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [keyName, setKeyName] = useState() 
  const [filterDataPopup, setFilterDataPopup] = useState([])

  const query = useQuery()
  const courseFilter = query.get('course')
  const crs_id = query.get('crs_id')

  const history = useHistory()
  const { mots, mst_stds, mst_crss, edu_brds, own_types, mois, edu_types, facilities, extr_cur_acts } = filterOptions

  const [distance, setDistance] = useState(1000)
  const [fees, setFees] = useState(1000)
  useEffect(() => {
    let courseId= mst_crss?.find((x)=>x.name===courseFilter)?.id
    if(courseId && !selectedFilters.crs_ids.includes(courseId)){
      dispatch({type: 'UPDATE_SELECTED_FILTERS', updatedFilter: { keyName: 'crs_ids', value: courseId }, reset:true })
    }
  }, [ mst_crss])

  useEffect(() => {
    getFiltersOptions(profileid)
    !crs_id && dispatch({ type: 'RESET_FILTERS' })
  }, [profileid])

  const getFiltersOptions = (profileid) => {
    dispatch({type:'SET_LOADING', value:{filterLoading:true}})
    api
      .get(
        APIURL.filterOptionsAPI + profileid
          // (category === 'school'
          //   ? 'SCHOOL'
          //   : category === 'educator'
          //   ? 'EDUCATOR'
          //   : 'COACHING')
      )
      .then((response) => {
        if (response.status === 200) {
          setFilterOptions(response.data.result)
        }
      })
      .catch((err) => {
        toast.error(errorMsg(err))
      })
      .finally(()=>dispatch({type:'SET_LOADING', value:{filterLoading:false}}))
  }

  const onChecked = (keyName, value) => {
    if(!cardsLoading){
      dispatch({
        type: 'UPDATE_SELECTED_FILTERS', 
        updatedFilter: { keyName, value },
      })
      if(value==crs_id){
        history.push(history.location.pathname)
      }
    }
  }

  const onClickMore = (keyName, data, open) => {
    setKeyName(keyName)
    setFilterDataPopup(data)
    setOpen(true)
  }

if(filterLoading){
  return(
    skeletonFilters(t('filters'))
  )
}
  return (
    <div>
      <div className="listing-filter-div" id="listing-filter-div">
        <div className="header">
          <h3>{t('filters')}</h3>
          <p onClick={() => {
            if(!cardsLoading){
              dispatch({ type: 'RESET_FILTERS' })
              history.push(history.location.pathname)
            }
          }}>{t('clear_all')}</p>
        </div>
        <SelectedFiltersView selectedFilters={selectedFilters} filterOptions={filterOptions} onChecked={onChecked} />
        {mots && (
          <div className="filter-box">
            <Filter id={1} title={t('mode_of_teaching')}>
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                {mots.map((item, index) => {
                  const { id, name, occurence } = item
                  return (
                    <div className="option" key={index}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            onChecked('mot_ids', id)
                          }}
                          checked={selectedFilters.mot_ids.includes(id)}
                        />
                        {name}
                      </label>
                      <p>({occurence})</p>
                    </div>
                  )
                })}
              </div>
            </Filter>
          </div>
        )}
        <div className="filter-box">
          <Filter id={2} title={t('loc_distance')} isDisabled={!ct_nm} tooltipText={t('sel_acc_loc')}>
            <div className="location-filter">
              <p>10/100, Ballewadi…</p>
              <p style={{ justifyContent: 'flex-end', color: '#1f5ca2' }}>
                <FaRegEdit color="#1F5CA2" size={16} />
                {t('apply_filter')}
              </p>
            </div>
            <Slider
              min={1000}
              max={15000}
              step={100}
              value={distance}
              tooltip={true}
              onChange={(value) => setDistance(value)}
            />
            <div className="markers">
              <span>1km</span>
              <span>5km</span>
              <span>10km</span>
              <span>15km</span>
            </div>
          </Filter>
        </div>

        <div className="filter-box">
          <Filter id={3} title={t('mon_fee_range')} isDisabled={!ct_nm} tooltipText={t('sel_acc_loc')}>
            <Slider
              min={1000}
              max={40000}
              step={500}
              value={fees}
              tooltip={true}
              onChange={(value) => {
                setFees(value)
              }}
            />
            <div className="markers">
              <span>1k</span>
              <span>10k</span>
              <span>20k</span>
              <span>30k</span>
              <span>40k</span>
            </div>
          </Filter>
        </div>

        {mst_stds && (
          <div className="filter-box">
            <Filter id={4} title={t('standard')}>
              <div className={'option-div'}>
                {mst_stds.map((item, index) => {
                  const { id, name, occurence } = item
                  return (
                    <div className="option onethird" key={index}>
                        <label>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              onChecked('std_ids', id)
                            }}
                            checked={selectedFilters.std_ids.includes(id)}
                          />
                          {name}
                        </label>
                      </div>
                  )
                })}
              </div>
            </Filter>
          </div>
        )}
        {edu_brds && (
          <FilterItem
            onChecked={onChecked}
            selectedFilters={selectedFilters}
            onClickMore={onClickMore}
            data={edu_brds}
            keyName={'edu_brd_ids'}
          />
        )}
        {mst_crss && (
          <FilterItem
            onChecked={onChecked}
            selectedFilters={selectedFilters}
            onClickMore={onClickMore}
            data={mst_crss}
            keyName={'crs_ids'}
          />
        )}
        {mois && (
          <FilterItem
            onChecked={onChecked}
            selectedFilters={selectedFilters}
            onClickMore={onClickMore}
            data={mois}
            keyName={'moi_ids'}
          />
        )}
        {extr_cur_acts && (
          <div className="filter-box">
            <Filter id={8} title={t('extra_cirr')}>
              <div className={'option-div-maxheight'}>
                {extr_cur_acts?.slice(0,6).map((item, index) => {
                  const { id, name, occurence } = item
                  return (
                    <div key={index} className="option half" style={{ width: '50%' }}>
                      <div>
                        <label
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            inlineSize: '90%',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => onChecked('ext_cur_ids', id)}
                            checked={selectedFilters.ext_cur_ids.includes(id)}
                          />
                          {name}
                        </label>
                      </div>
                    </div>
                  )
                })}
              </div>
              {extr_cur_acts.length > 6 && (
                <p
                  className="more"
                  onClick={() => {
                    onClickMore('ext_cur_ids', extr_cur_acts)
                  }}
                >{`+${extr_cur_acts.length - 6} more`}</p>
              )}
            </Filter>
          </div>
        )}
        {facilities && (
          <div className="filter-box">
            <Filter id={9} title={t('facilities')}>
              <div className={'option-div-maxheight'}>
                {facilities?.slice(0,6).map((item, index) => {
                  const { id, name, occurence } = item
                  return (
                    <div key={index} className="option half" style={{ width: '50%' }}>
                      <div>
                        <label style={{
                          width:'100%',
                          "overflow": "hidden",
                          "inlineSize": "90%",
                          "whiteSpace": "nowrap",
                          "textOverflow": "ellipsis"
                        }}>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              onChecked('fac_ids', id)
                            }}
                            checked={selectedFilters.fac_ids.includes(id)}
                          />
                          {name}
                        </label>
                      </div>
                    </div>
                  )
                })}
              </div>
              {facilities.length > 6 && (
                <p className="more" onClick={() => onClickMore('fac_ids', facilities)}>
                  {/* {`+${Math.round((facilities.length - 1) / 2 - 1)} more`} */}
                  {`+${facilities.length - 6} more`}
                </p>
              )}
            </Filter>
          </div>
        )}
      </div>
      <FilterModalMUI showModal={open} setShowModal={setOpen} keyName={keyName} filterDataPopup={filterDataPopup} />
    </div>
  )
}


export default memo(Filters)
