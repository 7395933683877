import HeadList from '../../components/Headlist/index'
import Contactus from './contactus'
import GetInTouch from './getInTouch'
import ScrollIndicator from '../../components/ScrollIndicator'
import { ToastContainer } from 'react-toastify'
import './contact.css'

const index = () => {
    return (
        <div>
            <HeadList />
            <div className="listing-heading-title" style={{ paddingLeft: "35px" }}>
                <span>Home /  </span> Contact us
            </div>

            <div className="contact-div">
                <div className="contact-form-div">
                    <Contactus />
                </div>
                <div className="contact-mail-div">
                    <GetInTouch />
                </div>
            </div>

            <ScrollIndicator />
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}

export default index