import React, { useState } from "react";
import NavBar from "./navBar";
import ApplicationSideBar from "./application/applicationSideBar";
import Settings from "./settings";
import { ToastContainer,toast } from "react-toastify";


export default function Student(props) {
const [count,setCount] =useState(0)
const [search,setSearch] = useState("")
    let module = window.location.href.split("/")[4]


    return (
        <>
            
            <NavBar  modules={module}  setCount={setCount} count={count} setSearch={setSearch} search={search}/>

            {
                module=='settings'?
                <Settings/>:
                <ApplicationSideBar  modules={module} setCount={setCount} count={count} setSearch={setSearch} search={search}/>
            }
           <ToastContainer/>
        </>
    )
} 