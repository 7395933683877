import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../../../api/api'
import About from './about'
import Event from './event'
import Experience from './experience'
import Images from '../images'
import Qualification from './qualification'
import { useDispatch, useSelector } from 'react-redux'


const Content = ({profileDetails, id}) => {
  const {p_headline, vdo_link} = profileDetails
  const [contentData, setContentData] = useState({})
  const [contentLoading, setContentLoading] = useState(false)
  const {achievements, experiences, qualifications} = contentData
  const isLoading = useSelector(state=>state.details?.isLoading)

  useEffect(() => {
    const getContentData = () => {
      setContentLoading(true)
      api
        .get(`website/view/edu/${id}/comb-qual-expr-achv`)
        .then((response) => {
          if (response.status === 200) {
            setContentData(response.data.result)
            setContentLoading(false)
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        })
        .finally(() => setContentLoading(false) )
    }
    id && getContentData()

  }, [id])
  
  return (
    <div className="detail-content-div">
      <About p_headline={p_headline?.replace(/<[^>]*>?/gm, '')}/>
      <Images images={null} videos={vdo_link} isLoading={isLoading || contentLoading}/>
      <Qualification qualifications={qualifications} isLoading={isLoading || contentLoading}/>
      <Experience experiences={experiences} isLoading={isLoading || contentLoading}/>
      <Event events={achievements} isLoading={isLoading || contentLoading}/>
    </div>

  )
}

export default Content