
import Sheading from './sheading'
import { details, student, listing } from '../../../assets/images'
import { IoArrowBackSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Achievements from './achievements';
 
import dummy from '../../../assets/pdf/dummy.pdf'
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

const Events = () => {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=>{return state.details?.lEventAchvPlacFaq})
    const eventAchvPlacFaq = useSelector(state=>state.details?.eventAchvPlacFaq) ?? {}
    const { t } = useTranslation()
    const {events} = eventAchvPlacFaq 

    // const events =[
    //     {
    //         "id": "f34a017b-d1ea-4fbc-ba8f-e2b20ef1b2ce",
    //         "inst_prof": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "date": "2022-01-14",
    //         "name": "test_event",
    //         "mode": "Online",
    //         "c_by": "teacher",
    //         "desc": "reference",
    //         "c_date": "2022-01-15 15:34:41.0",
    //         "u_date": "2022-01-15 15:34:41.0",
    //         "uid": null
    //     },
    //     {
    //         "id": "f34a017b-d1ea-4fbc-ba8f-e2b20ef1b2ce",
    //         "inst_prof": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "date": "2022-01-14",
    //         "name": "test_event",
    //         "mode": "Online",
    //         "c_by": "teacher",
    //         "desc": "reference",
    //         "c_date": "2022-01-15 15:34:41.0",
    //         "u_date": "2022-01-15 15:34:41.0",
    //         "uid": null
    //     }
    // ]

    const achievements= [
        {
            "id": "3a3e9d2f-c395-4664-9a92-119abb54908d",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "name": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            "year": "2022-01-19",
            "awarded_by": "lknbn",
            "desc": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi fermentum lorem non nisl fringilla aliquet. Morbi interdum commodo pulvinar. Curabitur nec congue ipsum. Fusce nulla ante, maximus in dui et, tempus egestas elit. Aenean facilisis dignissim consequat. Suspendisse nibh dolor, vestibulum in lorem eget, tempor facilisis nisi. Aliquam elementum, turpis in placerat interdum, nisl nulla vulputate nibh, nec vehicula nulla ipsum sed ligula. Morbi lacinia dapibus dolor vitae varius.</p>",
            "c_date": "2022-01-14 17:29:10.0",
            "u_date": "2022-05-24 12:41:06.0",
            "uid": null,
            "files": [{
                file: details.flip,
                text: "Lorem ipsum dolor sit amet, ash"
            },{
                file: details.flip,
                text: "Lorem ipsum dolor sit amet, ash"
            },{
                file: details.flip,
                text: "Lorem ipsum dolor sit amet, ash"
            },{
                file: dummy,
                text: "Lorem ipsum dolor sit amet, ash"
            },{
                file: dummy,
                text: "Lorem ipsum dolor sit amet, ash"
            }]
        },
        {
            "id": "72a6388f-0500-49e3-8e75-0588a0dfb9a9",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "name": "Acvjfjkdfjk",
            "year": "2022-01-28",
            "awarded_by": "lkjhgfcdx",
            "desc": "",
            "c_date": "2022-01-14 17:23:29.0",
            "u_date": "2022-01-14 17:23:29.0",
            "uid": null,
            "files": null
        },
        {
            "id": "855e823e-7c33-4e22-9cec-4a4f4e742efe",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "name": "named",
            "year": "2015-01-27",
            "awarded_by": "head",
            "desc": "<p>vmcvmnvcmvcv</p>",
            "c_date": "2022-01-14 17:37:58.0",
            "u_date": "2022-01-14 17:37:58.0",
            "uid": null,
            "files": null
        },
        {
            "id": "f42d7a6e-7a1a-4334-b6eb-bc56ea128aba",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "name": "Certification",
            "year": "2022-05-24",
            "awarded_by": "Free Code Map",
            "desc": "<p>Coding Camp</p>",
            "c_date": "2022-05-24 12:36:02.0",
            "u_date": "2022-05-24 12:36:02.0",
            "uid": null,
            "files": null
        }
    ]
    
    

    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsEdu()}
          </>
      )
    }

    return (
        <div className="detail-container">
            <h2>{t("events")}</h2>
            <Sheading title="Events" />
            <div className="details-events">
            {Array.isArray(events) && events.length!==0 ? events.map((item, index) => {
                const { date, name, mode, c_by, desc } = item
                let dateObj = new Date(date)
                return (
                <div key={index} className="flex-row">
                    <div className="blue-bg leftdiv">
                        <h4>{
                        `${dateObj.getDate()} ${dateObj.toLocaleDateString('default', 
                        { month: 'short'
                       })}`}</h4>
                        <p>{mode}</p>
                    </div>
                    <div className="rightdiv">
                        <h4>{name}</h4>
                        <h6>{t("conducted_by")} : {c_by}</h6>
                        <p>{desc?.replace(/<[^>]*>?/gm, '')}</p>
                    </div>
                </div>)}
                ):<h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>}
            </div>
            <Sheading title="Achivements" />
            <Achievements achievements={achievements} />
            

        </div>
    )
}

export default Events
