import { useState } from "react";
import { getYTVideoThumbnail } from "../../utils/utils";
import GalleryPopup from "./GalleryPopup/GalleryPopup.js";
import { useTranslation } from 'react-i18next';
import { details } from "../../assets/images";
import { skeletonDetailsImages } from "../../components/Skeleton/skeleton";
const Images = (props) => {
  const MAX = 4;
  const {images, videos} = props
  const { isLoading }=props
  const [view, setView] = useState(false);
  const [itemId, setItemId] = useState('')
  const [isImage, setIsImage] = useState(true);
  const { t } = useTranslation()
  /*--------------only videos for educator-----------------*/
  // const videos = [
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  //   "https://www.youtube.com/embed/lxebT0ok87I",
  // ];
  // const images = [
  //   /* 'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile/TestingCoachingInstitute/gallery/img1.jpg',
  //   'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile/TestingCoachingInstitute/gallery/vector_magic.jpg', */
  //   details.galleryImg1,
  //   components.signin_slider1,
  //   details.galleryImg1,
  //   components.signin_slider2,
  //   details.galleryImg1,
  //   components.signin_slider3,
  //   details.galleryImg1,
  //   components.signin_slider4,
  //   details.galleryImg1,
  //   components.signin_slider1,
  // ];
  const handleCls = () => {
    setView(false);
  };

  const handleImgClick = (id) => {
    setIsImage(true)
    setItemId(id)
    setView(true)
  }

  const handleVideoClick = (id) => {
    setIsImage(false)
    setItemId(id)
    setView(true)
  }
  if (isLoading) {
    return (
        <>
        {skeletonDetailsImages()}
        </>
    )
  }
  
  return (
    <div className="detail-container">
      <div
        className="flex-row img-space"
        style={{ justifyContent: "space-between", marginBottom: "20px" }}
      >
        <h2 style={{ marginBottom: 0 }}>{t("images_videos")}</h2>
        <p className="flex-row viewall" onClick={() => setView(true)}>
          {t("view more")}{" "}
          <img src={details.bluearrow} style={{ width: 24, height: 24, marginLeft: "10px" }} />
        </p>
        <GalleryPopup
          open={view}
          handleClose={handleCls}
          image={images}
          video={videos}
          itemIndex={itemId ?? ""}
          setItemId={setItemId}
          setIsImage={setIsImage}
          isImage={isImage}
        />
      </div>
      <div className="flex-row details-school-img">
        <>
          {(Array.isArray(images) && images.length !== 0) ||
          (Array.isArray(videos) && videos.length !== 0) ? (
            videos?.length === 0 || !videos ? (
              <>
                {images?.slice(0, 4).map((item, index) => (
                  <img
                    key={index}
                    src={`${item}`}
                    onClick={() => handleImgClick(index.toString())}
                  />
                ))}
              </>
            ) : images?.length >= 3 ? (
              <>
                <>
                  {images.slice(0, 3).map((item, index) => (
                    <img
                      key={index}
                      src={`${item}`}
                      onClick={() => handleImgClick(index.toString())}
                    />
                  ))}
                </>
                <>
                  {videos.slice(0, 1).map((item, index) => (
                    <div key={index}>
                      <img
                        className="vid-thumbnail"
                        src={getYTVideoThumbnail(item) || details.img4}
                      />
                      <img className="play-button" src={details.play_button} onClick={() => handleVideoClick(index.toString())} />
                    </div>
                  ))}
                </>
              </>
            ) : (
              <>
                <>
                  {images?.map((item, index) => (
                    <img
                      key={index}
                      src={`${item}`}
                      onClick={() => handleImgClick(index.toString())}
                    />
                  ))}
                </>
                <>
                  {videos.slice(0, MAX - (images?.length ?? 0)).map((item, index) => (
                    <div key={index}>
                      <img
                        className="vid-thumbnail"
                        src={getYTVideoThumbnail(item) || details.img4}
                      />
                      <img className="play-button" src={details.play_button} />
                    </div>
                  ))}
                </>
              </>
            )
          ) : (
            <h3 style={{ fontSize: 16 }}>{t("no_data_available")}</h3>
          )}
        </>
      </div>
    </div>
  );
};

export default Images;
