import React, { Component } from "react";
import { BiChevronUp } from 'react-icons/bi'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Tooltip } from "@mui/material";
import './filter.css'
import ToolTipChild from "../ToolTipChild";

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true
        };
    }

    handleClick = () => {
        var bodyContElem = document.getElementById(
            this.props.id + "_collapsible__body__hidden"
        );

        if (this.state.isCollapsed) {
            bodyContElem.style.maxHeight = bodyContElem.scrollHeight + "px";
            bodyContElem.style.opacity = 1;
            this.setState({ isCollapsed: false });
        } else {
            bodyContElem.style.maxHeight = 0;
            bodyContElem.style.opacity = 0;
            this.setState({ isCollapsed: true });
        }

    }

    renderInfoIcon = () => {
        return (
          <Tooltip title={this.props.tooltipText} arrow>
            <ToolTipChild>
                <AiOutlineInfoCircle size={18} color={"#686666"} style={{marginRight: '4px'}} />
            </ToolTipChild>
          </Tooltip>
        );
    }

    render() {
        const { isCollapsed } = this.state
        return (
            <div className="filter__container" >
                <div className="filter_title_container" >
                    <div className={"collapsible__title_text"} >
                        {this.props.title}
                    </div>

                    <div className="collapsible__title-button__container">
                    {this.props.isDisabled ? this.renderInfoIcon() : (
                        <div className={ isCollapsed? "button_icon__open" :"button_icon" } onClick={this.handleClick}>
                       <BiChevronUp size={26} color={"#686666"} />
                    </div>
                    )}
                    </div>
                </div>

                <div // PURPLE
                    id={this.props.id + "_collapsible__body__hidden"}
                    style={{

                        transition: "opacity 0.4s ease, max-height 0.4s ease",
                        maxHeight: "0px",
                        opacity: "0",
                        overflow: "hidden"
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Filter;
