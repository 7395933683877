import { Component } from "react";
import { withTranslation } from "react-i18next";

import Slider from "react-slick";
import { home } from "../../assets/images";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

class testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: 0,
      activeImageClass1: "img-fluid0-active",
      activeImageClass2: "img-fluid1",
      activeImageClass3: "img-fluid2",
    };
  }

  shouldComponentUpdate(prev, next) {
    if (
      prev.sliderIndex !== next.sliderIndex ||
      prev.activeImageClass1 !== next.activeImageClass1 ||
      prev.activeImageClass2 !== next.activeImageClass2 ||
      prev.activeImageClass3 !== next.activeImageClass3
    ) {
      return true;
    }
    return false;
  }

  slickPrev = (item) => {
    this.sliderRef.slickPrev();
  };

  slickNext = (item) => {
    this.sliderRef.slickNext();
  };

  setSlider = (ind) => {
    this.sliderRef.slickGoTo(ind);
    this.setImgClass(ind);
  };

  setImgClass = (ind) => {
    const active = "img-fluid" + ind + "-active";
    ind === 0 && this.setState({ activeImageClass1: active });
    ind === 1 && this.setState({ activeImageClass2: active });
    ind === 2 && this.setState({ activeImageClass3: active });
  };

  removeImgClass = (ind) => {
    const active = "img-fluid" + ind + "-active";
    ind === 0 && this.setState({ activeImageClass1: active.substring(0, 10) });
    ind === 1 && this.setState({ activeImageClass2: active.substring(0, 10) });
    ind === 2 && this.setState({ activeImageClass3: active.substring(0, 10) });
  };

  render() {
    const { sliderIndex, activeImageClass1, activeImageClass2, activeImageClass3 } = this.state;
    const { t } = this.props;
    const settings = {
      customPaging: function (i) {
        return <div className="dot"></div>;
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",

      infinite: true,
      initialSlide: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,

      beforeChange: (current, next) => {
        this.setState({ sliderIndex: next });
        this.setImgClass(next);
        this.removeImgClass(current);
      },
      // afterChange: current => this.setImgClass(current)
    };

    return (
      <div className="testimonial-container">
        <div className="figure-container">
          <div className="figure-row-div">
            <figure className="figure-content1" onClick={() => this.setSlider(0)}>
              <img
                src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-01-179x179.png"
                className={activeImageClass1}
                alt="1"
              />
            </figure>
          </div>

          <div className="figure-row-div2">
            <figure className="figure-content" onClick={() => this.setSlider(1)}>
              <img
                src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-02-306x306.png"
                className={activeImageClass2}
                alt="2"
              />
            </figure>
          </div>

          <div className="figure-row-div">
            <figure className="figure-content1" onClick={() => this.setSlider(2)}>
              <img
                src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-03-179x179.png"
                className={activeImageClass3}
                alt="3"
              />
            </figure>
          </div>
        </div>

        <div className="indicator">
          {sliderIndex === 0 && (
            <div className="indicator1">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}

          {sliderIndex === 1 && (
            <div className="indicator2">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}

          {sliderIndex === 2 && (
            <div className="indicator3">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>

        <div className="testimonial-slider">
          <div className="testimonial-slider-content">
            <div className="testimonial-title-content">
              <p className="testimonial-title">{t("testimonials")}</p>
            </div>
            <div className="testimonial-btn-container">
              <div className="testimonial-btn" onClick={() => this.slickPrev()}>
                <img src={home.left_icon} className="left-right-icon" />
              </div>
              <div className="testimonial-btn" onClick={() => this.slickNext()}>
                <img src={home.right_icon} className="left-right-icon" />
              </div>
            </div>
          </div>

          <Slider
            ref={(ref) => {
              this.sliderRef = ref;
            }}
            {...settings}
          >
            <div className="testimonial-card">
              <p className="testimonial-card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna tempor adipiscing
                convallis urna diam sit. Pellentesque tristique at sit neque luctus. Et id nec
                maecenas orci. Malesuada scelerisq sed ultricies aliquam tempor, faucibus risus ut
                elit. Et ut risus massa tellus egestas ut leo mauris. Nulla tempor amet, lectus
                neque dui. In vitae molestie a, sed tellus, aenean. as orci. Malesuada scelerisq sed
                ultricies aliquam{" "}
              </p>
              <p className="testimonial-card-name">Lorem ipsum began 1</p>
            </div>

            <div className="testimonial-card">
              <p className="testimonial-card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna tempor adipiscing
                convallis urna diam sit. Pellentesque tristique at sit neque luctus. Et id nec
                maecenas orci. Malesuada scelerisq sed ultricies aliquam tempor, faucibus risus ut
                elit. Et ut risus massa tellus egestas ut leo mauris. Nulla tempor amet, lectus
                neque dui. In vitae molestie a, sed tellus, aenean. as orci. Malesuada scelerisq sed
                ultricies aliquam{" "}
              </p>
              <p className="testimonial-card-name">Lorem ipsum began 2</p>
            </div>

            <div className="testimonial-card">
              <p className="testimonial-card-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna tempor adipiscing
                convallis urna diam sit. Pellentesque tristique at sit neque luctus. Et id nec
                maecenas orci. Malesuada scelerisq sed ultricies aliquam tempor, faucibus risus ut
                elit. Et ut risus massa tellus egestas ut leo mauris. Nulla tempor amet, lectus
                neque dui. In vitae molestie a, sed tellus, aenean. as orci. Malesuada scelerisq sed
                ultricies aliquam{" "}
              </p>
              <p className="testimonial-card-name">Lorem ipsum began 3</p>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default withTranslation()(testimonials);
