import { useRef, useState } from "react"
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from "react-icons/ri"
import { useSelector } from "react-redux"
import ProfileMenu from "./profileMenu"
import { components } from '../../assets/images'
import './signinButton.css'


export default function SigninButton({ setShowsignin }) {
    const userData = useSelector(state => state.auth.userData)
    // const userData = true
    const [showMenus, setShowMenus] = useState(false)
    const ref = useRef(null);
    const { t } = useTranslation()

    if (Object.keys(userData ?? {}).length)
        return (
            <div className="flex-row">
                <img className="avatar" src={components.signin_davatar} />
                <h4>{userData.name}</h4>
                <RiArrowDownSLine size={25} className='svg-y' onClick={() => setShowMenus(!showMenus)} />
                {showMenus == true &&
                    <div className="dropdown" ref={ref}>
                        <ProfileMenu />
                    </div>
                }
            </div>
        )
    return (
        <div className="home-login-button-div" onClick={() =>setShowsignin(true)}>
            {t('sign_in')}
        </div>


    )

}