import { Skeleton } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { details } from '../../assets/images'
import { short } from '../../utils/utils'

const ProfileBottomInfo = ({ profileDetails }) => {
  const { std_nms, crs_nms, f_pr_hr, tot_expr_yr, tot_expr_mn, n_of_s } = profileDetails
  const isLoading = useSelector(state=>state.details?.isLoading)
  const { t } = useTranslation()
  
  if(isLoading)
  {
    return(
      <div style={{boxSizing:'border-box',display:'flex', width:'100', height:'auto', justifyContent:'space-between', paddingLeft:'5%', paddingRight:'5%'}}>
        {[1,2,3,4,5].map((i)=>{
          return(<Skeleton key={i} variant='rectangular' style={{height:'5em', width:'14%', margin:'1%', marginLeft:'1%', marginRight:'1%', borderRadius:5}} /> )
        })}
      </div>
    )
  }
  return (
    <div className="detail-info-table">
      <div className="flex-row detail-div">
        <div className="info-tr">
          <div>
            <img src={details.standard} />
          </div>
          <div>
            <p>{t("standard")}</p>
            <h4 style={{ color: '#283B4C' }}>{short(std_nms, 3)}</h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.courses} />
          </div>
          <div>
            <p>{t("courses")}</p>
            <h4 style={{ color: '#283B4C' }}>{short(crs_nms, 3)}</h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.avgfee} />
          </div>
          <div>
            <p>{t("avg_fees")}</p>
            <h4 style={{ color: '#283B4C' }}>
              {' '}
              {f_pr_hr ? (f_pr_hr > 1000 ? Math.ceil((f_pr_hr / 1000) * 100) / 100 + 'k' : f_pr_hr) : '-'}
              /Hr
            </h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.experience} />
          </div>
          <div>
            <p>{t("experience")}</p>
            <h4 style={{ color: '#283B4C' }}>
              {tot_expr_yr !== 0 && `${tot_expr_yr} year`}
              {tot_expr_mn !== 0 && ` ${tot_expr_mn} months`}
              {tot_expr_mn === 0 && tot_expr_yr === 0 && '-'}
            </h4>
          </div>
        </div>
        <div className="separator"></div>
        <div className="info-tr">
          <div>
            <img src={details.no_of_student} />
          </div>
          <div>
            <p>{t("no_of_studs")}</p>
            <h4 style={{ color: '#283B4C' }}>{n_of_s ?? 0}+</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileBottomInfo
