import { useState } from "react";
import { GoSearch } from "react-icons/go";
import { home } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

export default function Search(props) {
  const [showCollege, setshowCollege] = useState(false);
  const { t } = useTranslation();
  const { address } = useSelector((state) => state.location.location_det);
  const { mst_std_nm } = useSelector(state => state.location)
  const dispatch = useDispatch()

  return (
    <>
      <div className="home-search-div">
        <div className="home-search-div-input2">
          <p>{t("class_search")}</p>
          <div className="loc-stand-div" onClick={() => dispatch({ type: 'TOGGLE_POPUP', showLocPopup: true })}>
            <div className="home-search-input-div no-margin-right">
              {mst_std_nm ? <div style={{color: '#283B4C'}}>{mst_std_nm}</div> : <div className="placeholder-text">4th</div>}
              <img src={home.class_search} style={{ width: 16, height: 20, marginLeft: 20 }} />
              <div className="input-seperator"></div>
            </div>
            <div className="home-search-input-div no-margin-left">
              <input
                readOnly={true}
                placeholder="Enter a location"
                className="read-only-inp"
                value={address ?? ""}
              />
              <img src={home.location_icon} style={{ width: 16, height: 24, marginLeft: 20 }} />
            </div>
          </div>
        </div>
        <div className="home-search-div-input3">
          <p>{t("start_search")}</p>
          <div
            className="home-search-input-div margin-vertical"
            onClick={() => {
              setshowCollege(!showCollege);
            }}
          >
            <input className="home-search-input" type="text" placeholder={t("school_hint")} />
            {/* <img src={search} style={{ width: 20, height: 20, marginLeft: 20 }} /> */}
            <GoSearch size={20} color={"#283B4Cr"} style={{ marginLeft: 0 }} />
          </div>
          {showCollege && (
            <div className="dropdown collegedd" style={{ width: "300px" }}>
              <div className="arrow"></div>
              <ul>
                {[1, 2, 3, 4, 5, 6, 7].map((data) => (
                  <li key={data}>
                    <div
                      className="flex-row"
                      onClick={() => {
                        setshowCollege(!showCollege);
                      }}
                    >
                      <img src={home.school} />
                      <div>
                        <h4>Dhanyashree College</h4>
                        <p>College in Mumbai</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="hide-cross-div"></div>
      </div>
      <div className="home-search-result-div">
        {false && <div className="home-search-result-div-inner"></div>}
      </div>
    </>
  );
}
