import { useTranslation } from 'react-i18next';

export default function CardHeading({category}) {
    const { t } = useTranslation()

    return (
        <div className="listing-card-heading">
            <div className="lheading">
                <h3><span style={{textTransform:'capitalize'}}>{category}</span> In Baner, Pune</h3>
            </div>
            <div className="heading-relevance">
                <div>
                    {/* <img src={sort} style={{width: 24, height: 24}} /> */}
                    <p>{t('sort_by')}</p>
                </div>
                <div className="listing-select">
                    <select>
                        <option>{t('relevant')}</option>
                    </select>
                </div>
            </div>
        </div>
    )
}


