import { details as detail } from '../../../assets/images'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { skeletonDetailsImages } from '../../../components/Skeleton/skeleton';

export default function Details({ profileDetails, profileType, isLoading}) {
  const { t } = useTranslation()
  const { own_type, e_type, n_o_fac, sch_type, camp_size, n_of_stud } =
    profileDetails

  const headingText = () => i18n.language === 'eng' ? (
    <h2>{t("details_of")} {profileType==='school' ? t("school") : t("institute")}</h2>
  ) : (
    <h2>{profileType==='school' ? t("school") : t("institute")} {t("details_of")}</h2>
  )
  if (isLoading) {
    return (
        <>
        {skeletonDetailsImages()}
        </>
    )
  }
  return (
    <div className="detail-container">
      {headingText()}
      <div className="flex-row details-school-det">
        {profileType === 'school' && (
          <div onClick={() => {}}>
            <img src={detail.ownership} style={{ width: 34, height: 34 }} />
            <p>{t("owner_type")}</p>
            <h4>{own_type || '-'}</h4>
          </div>
        )}

        <div onClick={() => {}}>
          <img src={detail.type} style={{ width: 34, height: 34 }} />
          <p>{t("edu_type")}</p>
          <h4>{e_type || '-'} </h4>
        </div>

        <div onClick={() => {}}>
          <img src={detail.faculty} style={{ width: 34, height: 34 }} />
          <p>{t("no_of_faculties")}</p>
          <h4>{n_o_fac}+</h4>
        </div>

        {profileType === 'school' && (
          <div onClick={() => {}}>
            <img src={detail.school_type} style={{ width: 34, height: 34 }} />
            <p>{t("school_type")}</p>
            <h4>{sch_type || '-'}</h4>
          </div>
        )}

        <div onClick={() => {}}>
          <img src={detail.campus} style={{ width: 34, height: 34 }} />
          <p>{t("campus_size")}</p>
          <h4>{camp_size || '-'}</h4>
        </div>

        <div onClick={() => {}}>
          <img src={detail.students} style={{ width: 34, height: 34 }} />
          <p>{t("no_of_studs")}</p>
          <h4>{n_of_stud}+</h4>
        </div>
      </div>
    </div>
  )
}
