import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../reducers'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'

import rootSaga from '../saga/'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  blacklist: ['details', 'listing']
}

const composeEnhancers = [applyMiddleware(sagaMiddleware),
    process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null]

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const rootstore = createStore(
  persistedReducer,
  compose(...composeEnhancers.filter(a=>a))
)
sagaMiddleware.run(rootSaga)
export const persistor = persistStore(rootstore)
