import React, { useState, useEffect } from "react"
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';



const Form3 = (props) => {
    const { t } = useTranslation()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData
    const [educationDetail, setEducationDetail] = useState(
        {
            current_education_level: "",
            msg_for_inst: ""
        }
    )

    useEffect(() => {
        if (props.FormData) {
            setEducationDetail({
                ...educationDetail,
                current_education_level: props.FormData?.current_education_level??"",
                msg_for_inst: props.FormData?.msg_for_inst??'',
            })
        }
    }, [props.FormData])

    

    const savedetails = () => {
        if (educationDetail.current_education_level == "") {
            toast.error("Please select Current Education Level.")
        } else {
            props.setLoading(true)
            let request = {
                url: `website/institute/form/${props.addId}/education/add`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: educationDetail
            }
            api.postCustom(request).then(data => {
                props.setLoading(false)
                toast.success("Parent details Added successfully");
                props.setFormno(4)

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }

    return (
        <>
            <div className="form-group">
                <select required value={props.FormData.current_education_level} onChange={(e) => setEducationDetail({ ...educationDetail, current_education_level: e.target.value })}>
                    <option value="">{`${t('curr_edu_level')} *`}</option>
                    <option value="013c22ec-dcff-4ad4-b9f9-419f11fa4e47">Play School</option>
                    <option value="38fad9f5-60cc-4da9-aba7-27df7f79b708">School</option>
                    <option value="20821264-e6cf-4464-9132-1ac451d6824e">Under Graduate</option>
                    <option value="d79a1210-fd4c-484f-b3be-e51788ec4746">Post Graduate</option>
                    <option value="bbc9934c-d3b2-4b94-a737-6f0ad1b775b0">PHD</option>
                </select>

            </div>
            <div className="form-group"><input type="text" value={props.FormData.msg_for_inst} placeholder={t('msg_for_school')} onChange={(e) => setEducationDetail({ ...educationDetail, msg_for_inst: e.target.value })} /></div>
            <div className="submit-div">
                <p onClick={() => (props.fetchFormDetails(),props.setFormno(2))}>{t('back')}</p>
                <button onClick={() => savedetails(4)}>{t('save_next')}</button>
            </div>
        </>
    )
}

export default Form3