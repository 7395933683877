const URL = {
    sit: {
        BASE_URL                : 'https://discov-dev-api.proctur.com/proc-dsc/api/v1/',
     },
    prod: {
        BASE_URL                : 'https://discprodapi.proctur.com/proc-dsc/api/v1/',
    },
    uat: {
        BASE_URL                : 'https://discov-dev-api.proctur.com/proc-dsc/api/v1/',
    },
}

export const APIURL = {
    allProfileTiles : 'website/home/all',
    allProfileTypes : 'website/home/all-prof-type',
    allList : 'website/listing/', // + school-list/ educator-list
    filterOptionsAPI : 'website/listing/filter/all-criterias/', // + EDUCATOR/SCHOOL/etc
    filteredList: 'website/listing/filter/', // + EDUCATOR/SCHOOL/etc +<optional>pageOption+pageSize+sortBy+etc
    detailsPageInst: 'website/view/inst/',
    compare: 'website/listing/compare',
    footerCities:'website/home/footer-cities-list',
    contactUs:'website/home/contact-us',
    multilang: 'inst/lang',
    otpValidate: 'user/otp/validate',
    userLogin:'user/login',
    addEnquiry:'enquiry/add',
    multiLogin: 'user/login-multi-user',
    reviewsInst: 'institute/review',
    reviewsEdu: 'institute/educator',
    adminRegister : 'user/register',
    getWishlistInst :'website/wishlist/get-wished-ids/institute',
    getWishlistEdu :'website/wishlist/get-wished-ids/educator',
    wishlistAdd : 'website/wishlist/add',
    educatorUnderInst: 'website/view/inst/educator',
    claim: 'website/claim/add',
    child: 'stud/child',

}

export default URL;