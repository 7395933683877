import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function Placement() {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=>{return state.details?.lEventAchvPlacFaq})
    const eventAchvPlacFaq = useSelector(state=>state.details?.eventAchvPlacFaq) ?? {}
    const { t } = useTranslation()
    const {placements} = eventAchvPlacFaq 
  /*   const placements= [
        {
            "id": "a6569fab-39f7-4440-b413-b19d46188e57",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "c_name": "jgajaj",
            "c_l_url": "https://testeduimspro.s3.ap-southeast-1.amazonaws.com/placement/companyLogo/hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh.png",
            "c_date": "2022-01-31 18:30:49.0",
            "u_date": "2022-01-31 18:30:49.0",
            "uid": null
        },
        {
            "id": "fc07ad87-a90a-4ab8-a7fe-9bd66e05bf9b",
            "inst_prof": null,
            "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
            "c_name": "D-company",
            "c_l_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/placement/companyLogo/cbse-board-education-school-logo-1BDB17DD5B-seeklogo.com.png",
            "c_date": "2022-02-03 10:45:03.0",
            "u_date": "2022-02-03 10:45:03.0",
            "uid": null
        },
        {
          "id": "a6569fab-39f7-4440-b413-b19d46188e57",
          "inst_prof": null,
          "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
          "c_name": "jgajaj",
          "c_l_url": "https://testeduimspro.s3.ap-southeast-1.amazonaws.com/placement/companyLogo/hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh.png",
          "c_date": "2022-01-31 18:30:49.0",
          "u_date": "2022-01-31 18:30:49.0",
          "uid": null
      },
      {
          "id": "fc07ad87-a90a-4ab8-a7fe-9bd66e05bf9b",
          "inst_prof": null,
          "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
          "c_name": "D-company",
          "c_l_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/placement/companyLogo/cbse-board-education-school-logo-1BDB17DD5B-seeklogo.com.png",
          "c_date": "2022-02-03 10:45:03.0",
          "u_date": "2022-02-03 10:45:03.0",
          "uid": null
      }
    ] */

    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsEdu()}
          </>
      )
    }

    return (
      <div className="detail-container">
        <h2>{t("placements")}</h2>

        <div className="flex-row details-facilities">
          {Array.isArray(placements) && placements.length !== 0 ? (
            placements.map((obj, index) => {
              const { id, c_name, c_l_url } = obj;
              return (
                <div key={index}>
                  <div
                    style={{ height: "150px" }}
                    className="img-container flex-row"
                  >
                    <img src={c_l_url} />
                  </div>
                  <h4 style={{ marginTop: "10px", marginBottom: "5px" }}>
                    {c_name}
                  </h4>
                  <p>
                    <a href="#">{t("link")}</a>
                  </p>
                </div>
              );
            })
          ) : (
            <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
          )}
        </div>
      </div>
    );
}
