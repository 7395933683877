import React, { useState,useEffect } from "react";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Loader from '../../components/Loader'
import { useTranslation } from 'react-i18next';
import { useSelector ,useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import api, { errorMsg } from '../../api/api'
import { connect } from 'react-redux'


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177DDC' : '#1890FF',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


export default function Settings(props) {
    const [tab, setTab] = useState("Notifications")
    const [rdata, setRdata] = useState([])
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState()
    const [letter, setLetter] = useState()
    const [setting, setSetting] = useState('')
    const [active, setActive] = useState()
    const { t } = useTranslation()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData


    useEffect(() => {
        getSettings();
    }, [])
    
    const getSettings = () => {
            setLoading(true)
            let request = {
                url: `setting/STUDENT/${c_id}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            }
            api.getCustom(request).then(data => {
                setLoading(false)
                setLetter(data.data.result.enb_proc_news_letters)
                setNotification(data.data.result.enb_notification)
                setSetting(data.data.result.id)
                setActive(data.data.result.is_profile_active)
                setRdata(data.data.result)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    // props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
    }
    
    const updateSettings = () => {
        setLoading(true)
        let request = {
            url: `setting/${setting}/update`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                // i_p_id: "d94de3e6-d5f7-4515-80fe-58c4a59331f3",
                enb_notification: notification,
                enb_proc_news_letters: letter
            }
        }
        api.putOther(request).then(data => {
            setLoading(false)
            toast.success("Settings Updated sucessfully")
            getSettings()
        }).catch((err) => {
            setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })
    
    }
    

    return (
        <>
         <Loader isLoading={loading}/>
            <div className="settingsMaindiv">
                <div className="notification">{t("notification")}</div>
                <p class="line_purple"></p>
                <div>

                    <div>
                        <div className="mt-20">{t("notification_update")}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <label className="intiiiSmall">{t("notifications_receive")}</label>
                            <AntSwitch  inputProps={{ 'aria-label': 'ant design' }} checked={notification}
                                        onClick={() => setNotification(!notification)} />
                        </div>

                        <div className="mt-20">{t("promo_and_new")}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <label className="intiiiSmall">{t("offer_emails")}</label>
                            <AntSwitch  inputProps={{ 'aria-label': 'ant design' }} checked={letter}
                                        onClick={() => setLetter(!letter)} />
                        </div>
                    </div>
                    <div style={{ borderBottom: '0.5px solid #E7E7E7', marginTop: '20px' }}></div>

                    <div style={{ marginTop: '20px',display:'flex',justifyContent:'flex-end' }}>
                        {/* <div className="notification">Account</div>
                        <p class="line_purple"></p>

                        <div></div> */}
                        <button className="settingButton" onClick={()=>updateSettings()} >Update Settings</button>
                        {/* <div className="w-f-wrap">
                            <label className="intiiiSmall">All Emails you receive for New Offers and Updates from Our side.</label>
                            <div style={{display:'flex'}}>
                           
                            <button className="settingButton">{t("del_my_acc")}</button>
                            </div>
                           
                        </div> */}
                    </div>
                </div>

            </div>
        </>
    )
}