export const CODE = {
  school: "inst",
  coaching: "inst",
  educator: "edu",
};

export const profileTypeTransform = (profileType) =>
  profileType && ["school", "educator"].includes(profileType?.toLowerCase())
    ? profileType?.toLowerCase()
    : "coaching";
