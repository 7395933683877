import { home } from '../../assets/images';
const SigninGradient = ({ setShowsigninGrad }) => {

    const handleClick = () => {
        setShowsigninGrad(false)
    }

    return (
        <div className="grad-container" onClick={() => handleClick()}>
            <img className='grad-img' src={home.signin_grad} />
            <img className='signin-arrow' src={home.signin_arrow} />
        </div>
    );
};

export default SigninGradient;