
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom'
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';






const Form5 = (props) => {
  const dispatch = useDispatch()
  const { profileType, id } = useParams()
  const { t } = useTranslation()
  const [check, setCheck] = useState(false)
  const [course, setCourse] = useState([])
  const [standard, setStandard] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const userData = useSelector(state => state.auth.userData)
  const { token, c_id, up_id, p_type } = userData





  useEffect(() => {
    getStandard();
    Category()
  }, [])



  let RAZORPAY_TEST_KEY = 'rzp_test_6LsjO5j4RhDFnk'

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => { resolve(true); };
      script.onerror = () => { resolve(false); };
      document.body.appendChild(script);
    });
  }

  const openCheckout = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (res) {
      let options = {
        "key": RAZORPAY_TEST_KEY,
        "amount": (Number(50) * 100).toFixed(2), // 2000 paise = INR 20, amount in paisa
        "currency": 'INR',
        "name": "Student Form",
        "description": "PP",
        "handler": (response) => {
          addDetails();
        },
        "theme": {
          "color": getComputedStyle(document.documentElement).getPropertyValue("--theme-color")
        },
        notes: "jhhfhdjhjfh"
      };
      let rzp = new window.Razorpay(options);
      rzp.on('payment.failed', (response) => {
        console.log("failed :", response);
        addDetails();
        // this.updateOrder(false, order_data, '');
      });
      rzp.open();
    }
  }

  const Type = () => {
    if (p_type == 1) {
      return 'coaching'
    } else if (p_type == 2) {
      return 'school'
    } else if (p_type == 3) {
      return 'educator'
    } else if (p_type == 4) {
      return 'student'
    } else {
      return 'staff'
    }
  }



  const addDetails = () => {
    props.setLoading(true)
    if (profileType == "school") {
      let request = {
        url: `website/student/applications/add`,
        headers: { 'Authorization': `Bearer ${token}` },
        data: {
          applier_id: c_id,
          inst_id: id,
          standard_id: selectedId,
          is_form_fee_paid: true,
          applier_type: Type()

        }
      }
      api.postCustom(request).then(data => {
        toast.success("Fees Paid succesfully. ")
        console.log(data.data.result.admission_form_id, "data.data.result.admission_form_id")
        dispatch({ type: 'ADD_FORM', form_id: data.data.result.admission_form_id, applicationId: data.data.result.application_id })
        props.fetchFormDetails(data.data.result.admission_form_id)
        props.setFormno(1);
      }).catch((err) => {
        props.setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    } else {
      let request = {
        url: `website/student/applications/add`,
        headers: { 'Authorization': `Bearer ${token}` },
        data: {
          applier_id: c_id,
          inst_id: id,
          course_id: selectedId,
          is_form_fee_paid: true,
          applier_type: Type()

        }
      }
      api.postCustom(request).then(data => {
        toast.success("Fees Piad Successfully")
        dispatch({ type: 'ADD_FORM', form_id: data.data.result.admission_form_id })
        props.setFormno(1);
        props.fetchFormDetails(data.data.result.admission_form_id)


      }).catch((err) => {
        props.setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }


  }



  const getStandard = () => {
    // setLoading(true)
    let request = {
      url: `master-stand/all`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
    api.getCustom(request).then(data => {
      // setLoading(false)
      setStandard(data.data.result)


    }).catch((err) => {
      // setLoading(false)

    })
  }



  const Category = () => {
    // setLoading(true)
    let request = {
      url: `course/all`,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
    api.getCustom(request).then(data => {
      // setLoading(false)
      setCourse(data.data.result)


    }).catch((err) => {
      // setLoading(false)
      // if (err.response.status == 401) {
      //   props.dispatch({ type: 'LOGOUT' })
      // } else {
      //   toast.error(errorMsg(err))
      // };
    })
  }


  return (
    <><div className='submit-content'>
      <div className="flex-row">
        <h3>{t("start_app")}</h3>
      </div>
      <div className='submit-note'>
        <h6>{t("pay_info_title")}</h6>
        <ul>
          <li>{t("pay_info1")}</li>
          <li>{t("pay_info2")}</li>
          <li>{t("pay_info3")}</li>
        </ul>
      </div>
      <div className="form-group">
        <select required onChange={(e) => setSelectedId(e.target.value)}><option value={""} className="placeholder" selected disabled >{t("std_course")}</option>
          {
            profileType == 'school' ?
              standard.map((item) => <option value={item.entity_id}>{item.name}</option>) :
              course.map((item) => <option value={item.entity_id}>{item.name}</option>)
          }

        </select>
      </div>
      {/* <div className="form-group">
        <select required><option value={""} className="placeholder" selected disabled>Academic Year</option><option value={1}>2012</option></select>
    </div> */}
      <div className='agree-content'>
        <input className='agree-checkbox' type="checkbox" onClick={() => setCheck(!check)} /><p className='agree-text'>{t("t_and_c")}</p>
      </div>
      <div className="submit-div" style={{ justifyContent: "space-between" }}>
        <div className='fee-content'><h4>{t("form_fee")}:</h4><h3 style={{ color: "#FD6C73", marginLeft: "10px", marginTop: "-5px" }}>₹{props.rdata.application_fee}</h3></div>
        <button onClick={() => {
          if (selectedId == "") {
            toast.error("Please select Standard/Course.")
          } else if (check == false) {
            toast.error("Please agree to the terms and conditions.")
          }
          else {
            openCheckout();
          }
        }}> {t("proceed")}</button>
      </div>
    </div></>

  );
};

export default Form5;
