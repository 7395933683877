import { apply } from '../../assets/images'
import { useTranslation } from 'react-i18next';

const ApplySchool = (props) => {
  const { t } = useTranslation()


  return (
    <div>
            <h1>{t('apply_to')} <span style={{color: '#4274B0'}}>{props.rdata?.inst_name??"-"}</span></h1>
            <img src={apply.banner} />
            {
              props.addId &&
              <div className="school-info">
                <div className="flex-row blue-bg"><h4>{t('select_course')}</h4><p  style={{marginRight: "20px"}}>{props.FormData.seleted_course}</p></div>
                {/* <div className="flex-row blue-bg"><h4>{t('acd_year')}</h4><p style={{marginRight: "20px"}}>2022</p></div> */}
                <div className="flex-row blue-bg"><h4>{t('form_fee')}</h4><p className="paid">Paid</p></div>
            </div>
            }
            
        </div>
  )
}

export default ApplySchool 