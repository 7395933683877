import { useState } from 'react'
import { ToastContainer } from 'react-toastify';
import '../../components/Signin/signin.css'
import { apply } from '../../assets/images'
import './completepopup.css'



export default function SubmitPopup(props) {

    return (
        <div className="modal-container">
            <div style={{ background: ' #FFFFFF', borderRadius: '8px', width: '400px' }}>
                <div className="changedivv" style={{ padding: 0 }} >
                    <div style={{ display: 'flex', justifyContent: 'center', background: '#EFF6FF', borderRadius: '5px' }}>
                        <img src={apply.submit} style={{ padding: '10px', marginBottom: '20px' }}></img>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', alignItems: 'center' }} >
                        <div className='ThankYou' style={{ marginBottom: '10px' }}>
                            Submit Form?
                        </div>
                        <div className='Congo' style={{ marginBottom: '10px' }}>
                            Are you sure you want to Submit form ?
                            You will not be able to make changes later on.
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                            <button className='cancelButton' onClick={()=>props.setSubmitPopup(false)}>Cancel</button>
                            <button className='submitButton' onClick={()=>props.submit()}>Submit</button>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    )
}
