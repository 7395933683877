import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { short } from "../../../utils/utils";
import { skeletonDetailsEdu } from "../../../components/Skeleton/skeleton";
import TeacherModal from "../TeacherModal/teacherModal";
import { APIURL } from "../../../api/url";
import api, { errorMsg } from "../../../api/api";
import { toast } from 'react-toastify';

export default function Teachers() {
  const isLoading = useSelector((state) => state.details?.isLoading);
  const isLoadingContent = useSelector((state) => state.details?.lFeeTchrAdmn);
  const feeTchrAdmn = useSelector((state) => state.details?.feeTchrAdmn) ?? {};
  const [open, setOpen] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState({});
  const [ detailedInfo, setDetailedInfo ] = useState({})
  const { t } = useTranslation();
  const { teachers } = feeTchrAdmn;
  // const teachers = [
  //   {
  //     social_media_link_map: null,
  //     id: '5fd1edac-4d33-4b6e-a281-4d534a2d07de',
  //     title: 'Mr',
  //     e_name: 'fg',
  //     n_of_s: 0,
  //     p_headline: 'nm',
  //     address: null,
  //     onl_only: false,
  //     c_id: null,
  //     p_code: null,
  //     s_id: null,
  //     ct_id: null,
  //     email: null,
  //     phone: null,
  //     w_number: null,
  //     ph_url:
  //       'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/airlearn-whiteboard.png',
  //     mot: null,
  //     p_tuitions: false,
  //     f_pr_hr: 0,
  //     vdo_link: null,
  //     s_m_link: null,
  //     s_c_names: null,
  //     is_act: true,
  //     c_date: null,
  //     u_date: null,
  //     uid: null,
  //     i_p_id: null,
  //     is_internal: false,
  //     ss_det: null,
  //     cs_det: null,
  //     s_on_prof: false,
  //     ss_map_li: null,
  //     cs_map_li: null,
  //     added_by: null,
  //     lat: 0,
  //     long: 0,
  //     is_by_proc: false,
  //     view_cnt: 0,
  //     is_archived: false,
  //     is_blk_upld: false,
  //     excp_rem: null,
  //     rec_cnt: 0,
  //     tel_no: null,
  //     m_of_instruct: null,
  //     edu_type: null,
  //     loc_det: null,
  //     edu_qual_li: null,
  //     edu_expr_li: null,
  //     edu_achv_li: null,
  //     high_qual: null,
  //     tot_expr_yr: 0,
  //     tot_expr_mn: 0,
  //     std_nms: ['Standard Test'],
  //     crs_nms: ['mst crs 3'],
  //     sub_names: ['mst c sub 3', 'Subject Test 1'],
  //     crs_sub_li: null,
  //     std_sub_li: null,
  //     review_cnt: 0,
  //     is_verified: false,
  //     disp_id: null,
  //     ct_nm: null,
  //     ar_nm: null,
  //     status: null,
  //     kyc_status: null,
  //     upd_by: null,
  //     qual_names: [],
  //   },
  //   {
  //     social_media_link_map: null,
  //     id: '51a81de1-ff93-4af6-a30d-b0fa1ff96f62',
  //     title: 'Mr',
  //     e_name: 'Amit',
  //     n_of_s: 0,
  //     p_headline: 'Amazing TEacher',
  //     address: null,
  //     onl_only: false,
  //     c_id: null,
  //     p_code: null,
  //     s_id: null,
  //     ct_id: null,
  //     email: null,
  //     phone: null,
  //     w_number: null,
  //     ph_url:
  //       'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/WhatsApp_Image_2022-06-21_at_10.37.34_AM.jpeg',
  //     mot: null,
  //     p_tuitions: false,
  //     f_pr_hr: 0,
  //     vdo_link: null,
  //     s_m_link: null,
  //     s_c_names: null,
  //     is_act: true,
  //     c_date: null,
  //     u_date: null,
  //     uid: null,
  //     i_p_id: null,
  //     is_internal: false,
  //     ss_det: null,
  //     cs_det: null,
  //     s_on_prof: false,
  //     ss_map_li: null,
  //     cs_map_li: null,
  //     added_by: null,
  //     lat: 0,
  //     long: 0,
  //     is_by_proc: false,
  //     view_cnt: 0,
  //     is_archived: false,
  //     is_blk_upld: false,
  //     excp_rem: null,
  //     rec_cnt: 0,
  //     tel_no: null,
  //     m_of_instruct: null,
  //     edu_type: null,
  //     loc_det: null,
  //     edu_qual_li: null,
  //     edu_expr_li: null,
  //     edu_achv_li: null,
  //     high_qual: null,
  //     tot_expr_yr: 0,
  //     tot_expr_mn: 1,
  //     std_nms: ['Proctur Collage'],
  //     crs_nms: ['Civil Services', 'Singing', 'Master Course 2'],
  //     sub_names: ['General Knowledge', 'Songs', 'Schooll New', 'Sub-2'],
  //     crs_sub_li: null,
  //     std_sub_li: null,
  //     review_cnt: 0,
  //     is_verified: false,
  //     disp_id: null,
  //     ct_nm: null,
  //     ar_nm: null,
  //     status: null,
  //     kyc_status: null,
  //     upd_by: null,
  //     qual_names: ['BTECH', 'B TEch'],
  //   },
  //   {
  //     social_media_link_map: null,
  //     id: 'a746335d-8613-4b40-bbb9-56b97da90ee0',
  //     title: 'Ms',
  //     e_name: 'gdfs',
  //     n_of_s: 0,
  //     p_headline: 'gdrsfd',
  //     address: null,
  //     onl_only: false,
  //     c_id: null,
  //     p_code: null,
  //     s_id: null,
  //     ct_id: null,
  //     email: null,
  //     phone: null,
  //     w_number: null,
  //     ph_url: 'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/calender123.png',
  //     mot: null,
  //     p_tuitions: false,
  //     f_pr_hr: 0,
  //     vdo_link: null,
  //     s_m_link: null,
  //     s_c_names: null,
  //     is_act: true,
  //     c_date: null,
  //     u_date: null,
  //     uid: null,
  //     i_p_id: null,
  //     is_internal: false,
  //     ss_det: null,
  //     cs_det: null,
  //     s_on_prof: false,
  //     ss_map_li: null,
  //     cs_map_li: null,
  //     added_by: null,
  //     lat: 0,
  //     long: 0,
  //     is_by_proc: false,
  //     view_cnt: 0,
  //     is_archived: false,
  //     is_blk_upld: false,
  //     excp_rem: null,
  //     rec_cnt: 0,
  //     tel_no: null,
  //     m_of_instruct: null,
  //     edu_type: null,
  //     loc_det: null,
  //     edu_qual_li: null,
  //     edu_expr_li: null,
  //     edu_achv_li: null,
  //     high_qual: null,
  //     tot_expr_yr: 0,
  //     tot_expr_mn: 0,
  //     std_nms: ['Standard Test'],
  //     crs_nms: ['mst crs 3'],
  //     sub_names: ['mst c sub 3', 'Subject Test 1'],
  //     crs_sub_li: null,
  //     std_sub_li: null,
  //     review_cnt: 0,
  //     is_verified: false,
  //     disp_id: null,
  //     ct_nm: null,
  //     ar_nm: null,
  //     status: null,
  //     kyc_status: null,
  //     upd_by: null,
  //     qual_names: [],
  //   },
  // ]

  if (isLoading || isLoadingContent) {
    return <>{skeletonDetailsEdu()}</>;
  }

  const handleSeeMore = (obj) => {
    const { e_name, p_headline, ph_url, high_qual, tot_expr_yr, tot_expr_mn, sub_names, id } = obj;
    let request = {
      url: `${APIURL.educatorUnderInst}/${id}`,
  }
  api.getCustom(request).then(data => {
      setDetailedInfo({edu_achv: data?.data.result?.edu_achv, edu_exp: data?.data.result?.edu_exp, edu_qual: data?.data.result?.edu_qual})
  }).catch((err) => {
     toast.error(errorMsg(err));
  })
    const exp =
      tot_expr_mn === 0 && tot_expr_yr === 0
        ? "N/A"
        : `${tot_expr_yr !== 0 && tot_expr_yr} Year ${tot_expr_mn !== 0 && tot_expr_mn} Months`;
    const infoObj = {
      exp,
      e_name,
      ph_url,
      high_qual,
      sub_names,
    };
    setTeacherInfo(infoObj)
    setOpen(true)
  };

  return (
    <div className="detail-container">
      <div className="flex-row" style={{ justifyContent: "space-between" }}>
        <h2 style={{ marginBottom: 20 }}>{t("teacher")}</h2>
        <p className="flex-row">
          {t("sort_by")}{" "}
          <select>
            <option>{t("standard")}</option>
          </select>{" "}
        </p>
      </div>
      {/* <Sheading title="1st" /> */}
      {Array.isArray(teachers) && teachers.length !== 0 ? (
        teachers.map((obj, index) => {
          const { e_name, p_headline, ph_url, high_qual, tot_expr_yr, tot_expr_mn, sub_names } =
            obj;

          return (
            <div key={index} className="flex-row details-teacher" style={{ alignItems: "stretch" }}>
              {/* <div
              className="imgavatar"
              style={{ background: `url(${ph_url}) center center`, backgroundSize: 'cover' }}
            ></div> */}
              <img className="imgavatar" src={ph_url} />
              <div style={{ flex: 1 }}>
                <div className="flex-row tinfo">
                  <div>
                    <p>{t("name")}</p>
                    <h4>{e_name}</h4>
                  </div>
                  <div>
                    <p>{t("subject")}</p>
                    <h4>{short(sub_names, 1)}</h4>
                  </div>
                  <div>
                    <p>{t("experience")}</p>
                    <h4>
                      {tot_expr_yr !== 0 && `${tot_expr_yr} Year`}
                      {tot_expr_mn !== 0 && ` ${tot_expr_mn} Month`}
                      {tot_expr_mn === 0 && tot_expr_yr === 0 && "N/A"}
                    </h4>
                  </div>
                  <div>
                    <p>{t("qualification")}</p>
                    <h4>{high_qual || "-"}</h4>
                  </div>
                  <div>
                    <a onClick={() => handleSeeMore(obj)}>{t("see_more")}</a>
                  </div>
                </div>
                <div style={{ backgroundColor: "#F4F9FF" }}>
                  <p className="teacher-det">{p_headline}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
      )}
      <TeacherModal open={open} handleCloseClick={() => setOpen(false)} info={teacherInfo} detailedInfo={detailedInfo}/>
    </div>
  );
}
