import { details } from '../../../assets/images'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

const Management = () => {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=>{return state.details?.lMngtAlumniSchl})
  const mngtAlumniSchl = useSelector((state) => state.details?.mngtAlumniSchl) ?? {}
  const { t } = useTranslation()
  const { mngt_desks } = mngtAlumniSchl
  // const mngt_desks = [
  //   {
  //       "id": "c7d3325e-473c-4392-912e-cae44cc25398",
  //       "inst_prof": null,
  //       "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
  //       "title": "Mr",
  //       "name": "ptitoitio",
  //       "designation": "gjgghjgj",
  //       "ph": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/managementMessage/photo/Group_402428_(1).png",
  //       "vdo_link": [
  //           "\"https://youtu.be/YwEKIl3qQzA\""
  //       ],
  //       "m_msg": "mndnmfnnm",
  //       "is_active": true,
  //       "s_on_prof": false,
  //       "c_date": "2022-07-04 11:44:33.0",
  //       "u_date": "2022-07-04 11:44:33.0",
  //       "uid": null
  //   }
  // ]

  if (isLoading || isLoadingContent) {
    return (
        <>
        {skeletonDetailsEdu()}
        </>
    )
  }

  return (
    <div className="detail-container">
      <h2>{t("mdesk")}</h2>
      <div className="details-management">
        {Array.isArray(mngt_desks) && mngt_desks.length !== 0 ? (
          mngt_desks.map((obj, index) => {
            const { name, ph, designation, m_msg } = obj
            return (
              <div key={index}>
                <div className="flex-row" style={{ alignItems: 'flex-start' }}>
                  <div
                    className="details-dmsg flex-row"
                    style={{ marginTop: 0, alignItems: 'flex-start' }}
                  >
                    <img src={ph} style={{ marginTop: '5px' }} />
                    <div style={{ flex: 1 }}>
                      <h4>{name}</h4>
                      <p style={{ marginBottom: '8px' }}>{designation}</p>
                      <a href="#">{t("watch_video")}</a> {/*To be filled */}
                    </div>
                  </div>
                  <div
                    className="flex-row"
                    style={{ alignItems: 'flex-start', flex: 3 }}
                  >
                    <img src={details.quotes} className="comma" />
                    <div>
                      <p>{m_msg?.replace(/<[^>]*>?/gm, '') || 'N/A'} </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        ) : (
            <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>
        )}
      </div>
    </div>
  )
}

export default Management
