import Sheading from './sheading'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function Facilities({ profileType }) {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state => state.details?.lStdCrssFacilitiesNews)
  const stdCrssFacilitiesNews = useSelector((state) => state.details?.stdCrssFacilitiesNews) ?? {}
  const { facilities, extra_curr_act } = stdCrssFacilitiesNews
  const { t } = useTranslation()
  // const facilities = [
  //   {
  //       "id": "2abd7d30-19d6-4f66-9d5f-637424a7b46d",
  //       "name": "ac",
  //       "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/masterCategory/icon/image_(10).png",
  //       "sub_mst_li": null,
  //       "oth_det_map": null
  //   },
  //   {
  //       "id": "853a0dad-c8e8-4470-874c-1375954c0b34",
  //       "name": "Facility Test",
  //       "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/master/Facilities/submaster/Facility Test/icon/teacher.jpg",
  //       "sub_mst_li": null,
  //       "oth_det_map": null
  //   }
  // ]
  // const extra_curr_act = [
  //   {
  //       "id": "3c11b584-a4a8-4d00-adad-099145163fd9",
  //       "name": "Horse Riding",
  //       "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/masterCategory/icon/airlearn-whiteboard.png",
  //       "sub_mst_li": null,
  //       "oth_det_map": null
  //   },
  //   {
  //       "id": "3f06ac83-570a-4f77-9d42-49503e048ed5",
  //       "name": "Test",
  //       "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/master/Extra Curricular Activities/submaster/Test/icon/icons8-clock-24.png",
  //       "sub_mst_li": null,
  //       "oth_det_map": null
  //   }
  // ]
  if (isLoading || isLoadingContent) {
    return (
        <>
        {skeletonDetailsEdu()}
        </>
    )
  }
  return (
    <div className="detail-container">
      <div className="flex-row" style={{ justifyContent: 'space-between' }}>
        <h2 style={{ marginBottom: 0 }}>{t("facilities")}</h2>
      </div>
      {Array.isArray(facilities) && facilities.length !== 0 && <Sheading title={t("facilities")} />}
      <div className="flex-row details-facilities">
        {Array.isArray(facilities) && facilities.length !== 0 ? (
          facilities.map((obj, index) => (
            <div key={index}>
              <div className="img-container blue-bg flex-row">
                <img src={obj.ph_url} />
              </div>
              <h4>{obj.name}</h4>
            </div>
          ))
        ) : (
          <h5 style={{ fontSize: '16px', marginTop:20}}>{t("no_data_available")}</h5>
        )}
      </div>

      {Array.isArray(extra_curr_act) && extra_curr_act.length !== 0 && (
        <>
          <Sheading title={t("extra_cirr")} />
          <div className="flex-row details-facilities">
            {extra_curr_act.map((obj, index) => (
              <div key={index}>
                <div className="img-container blue-bg flex-row">
                  <img src={obj.ph_url} />
                </div>
                <h4>{obj.name}</h4>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
