import { details } from "../../../assets/images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { skeletonDetailsEdu } from "../../../components/Skeleton/skeleton";

export default function Standard({ profileType }) {
  const isLoading = useSelector((state) => state.details?.isLoading);
  const isLoadingContent = useSelector((state) => {
    return state.details?.lStdCrssFacilitiesNews;
  });
  const stdCrssFacilitiesNews = useSelector((state) => state.details?.stdCrssFacilitiesNews) ?? {};
  const { t } = useTranslation();
  const stdCrss = stdCrssFacilitiesNews.courses ?? stdCrssFacilitiesNews.standards;

  // const stdCrss= [
  //     {
  //         "entity_id": "4201c64e-4f90-4731-8e0f-27d82060e702",
  //         "created_date": "2022-07-13T09:24:05.099+0000",
  //         "updated_date": "2022-07-13T09:24:05.099+0000",
  //         "active": true,
  //         "total_seats": 2000,
  //         "eligibility": "<p>Xth</p>",
  //         "key_points": "<p>Xth</p>",
  //         "description": "<p>\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth\t\tXth</p>",
  //         "syllabus": "<p>Xth</p>",
  //         "show_on_profile": false,
  //         "archived": false,
  //         "mast_std_name": "Mst Std 1",
  //         "inst_name": null,
  //         "mast_std_id": null,
  //         "inst_id": null,
  //         "mof_teaching_id": null,
  //         "mof_teaching": "Online",
  //         "fee_li": null,
  //         "schedule_li": [
  //             {
  //                 "entity_id": "ee0be4ef-a2cd-4b53-9a40-7de7f168a789",
  //                 "created_date": "2022-07-13T09:24:05.794+0000",
  //                 "updated_date": "2022-07-13T09:24:05.794+0000",
  //                 "schedule_days": "Monday",
  //                 "from_time": "08:00",
  //                 "to_time": "14:00",
  //                 "archived": false,
  //                 "std_name": "Mst Std 1",
  //                 "course_name": null,
  //                 "active": true
  //             }
  //         ],
  //         "file_li": null
  //     }
  // ]
  if (isLoading || isLoadingContent) {
    return <>{skeletonDetailsEdu()}</>;
  }
  return (
    <div className="detail-container">
      <h2>{profileType === "school" ? t("standard") : t("courses")}</h2>
      {Array.isArray(stdCrss) && stdCrss.length !== 0 ? (
        stdCrss.map((obj, index) => {
          const { mof_teaching, total_seats, mast_std_name, course_name, schedule_li } = obj;
          return (
            <div key={index} className="flex-row details-standard">
              <div className="stan-heading blue-bg flex-row">
                <h4>{mast_std_name || course_name}</h4>
              </div>
              <div style={{ flex: 1 }}>
                <div className="flex-row tinfo">
                  {schedule_li?.map((item,index) => {
                    return (
                        <div className="flex-row" key={index}>
                      <div className="imgdiv flex-row">
                        <img src={details.clock} />
                      </div>
                      <div>
                        <p>
                          {profileType === "school" && "School "}
                          {t("timing")}
                        </p>
                        <h4>
                          {item.from_time} to {item.to_time} &nbsp;
                          <span>
                            {item.from_day?.substr(0, 3)}-{item.to_day?.substr(0, 3)}
                          </span>
                        </h4>
                      </div>
                    </div>
                    )
                  })}
                  <div className="flex-row">
                    <div className="imgdiv flex-row">
                      <img src={details.mode} />
                    </div>
                    <div>
                      <p>{t("mode_of_teaching")}</p>
                      <h4>{mof_teaching}</h4>
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="imgdiv flex-row">
                      <img src={details.seat} />
                    </div>
                    <div>
                      <p>{t("total_seats")}</p>
                      <h4>{total_seats}+</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
      )}
    </div>
  );
}
