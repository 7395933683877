import { details } from '../../../assets/images'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton'

const Event = (props) => {
  const {events} = props
  const { isLoading } = props
  const { t } = useTranslation();

  // const isLoading = true
  // const events =  [
  //     {
  //         "id": "f66c6718-dbdd-4c6c-b11c-99b333236f98",
  //         "educator": null,
  //         "e_id": null,
  //         "title": "Award @2019",
  //         "yr": "2021-12-01",
  //         "awarded_by": "Proctur",
  //         "desc": "<p>adrfutu 78y89'o 9y98u09 97y08u.</p>",
  //         "c_date": "2022-08-12 23:55:14.0",
  //         "u_date": "2022-08-12 23:55:14.0",
  //         "uid": null,
  //         "files": null
  //     }
  // ]
 if (isLoading) {
   return (
       <>
       {skeletonDetailsEdu()}
       </>
   )
 }
  return (
    <div className="detail-container">
      <h2>{t("achievements")}</h2>
      {/* <Sheading title="Achivements" /> */}
      <div className="details-events-sec">
        {Array.isArray(events) && events.length !== 0 ? events?.map((obj, index) => {
          const { yr, title, awarded_by, desc } = obj
          return (
            <div key={index}>
              <h3>{yr && new Date(yr).toLocaleDateString('en-UK')}</h3>
              <div>
                <h4>{title}</h4>
                <h6>{t('awarded_by')} : {awarded_by}</h6>
                <p>{desc?.replace(/<[^>]*>?/gm, '')}</p>
                <div className="click flex-row">
                  <a href="#">{t('click_here')}</a> <img src={details.blue_arrow} />
                </div>
              </div>
            </div>
          )
        }) : <h5 style={{fontSize:'16px'}}>{t('no_fee_data')}</h5>}
      </div>
    </div>
  )
}

export default Event
