import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function About({overview, isLoading}) {
    const { t } = useTranslation()

    if (isLoading) {
        return (
            <>
            {skeletonDetailsEdu()}
            </>
        )
      }
    return (
        <div className="detail-container">
            <h2>{t("about")}</h2>
            <p>{overview?.replace(/<[^>]*>?/gm, '') || '-'}</p>
        </div>
    )
}
