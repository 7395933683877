import { useEffect, useState, useRef } from 'react'
import { MdOutlineLocationOn } from 'react-icons/md'
import { GoPrimitiveDot } from 'react-icons/go'
import { FaHeart } from 'react-icons/fa'
import { FiHeart } from 'react-icons/fi'
import { IoShareSocialSharp } from 'react-icons/io5'

import { details, listing, home } from '../../assets/images'

import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { getDistance, short, useQuery } from '../../utils/utils'
import CompareButton from '../../components/CompareButton/index'
import EnquiryPopup from '../../components/EnquiryPopup'
import { skeletonListCards } from '../../components/Skeleton/skeleton'
import { APIURL } from '../../api/url'
import api, { errorMsg } from '../../api/api'
import { addWishList, removeWishList } from '../wishlist'

const Cards = ({ profileData, subProfileType }) => {
  const [enquiry, setEnquiry] = useState('')
  const [tooltipData, setTooltipData] = useState(undefined)
  const selectedFilters = useSelector((state) => state.listing?.selectedFilters, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const listDetails = useSelector((state) => state.listing?.listDetails, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const pageOffset = useSelector((state) => state.listing?.pageOffset, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const cardsLoading = useSelector((state) => state.listing?.cardsLoading, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const selectedIds = useSelector((state) => state.compare.selectedIds, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const wishListEdu = useSelector((state) => state.auth.wishListEdu, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const wishListInst = useSelector((state) => state.auth.wishListInst, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const compareSubProfileType = useSelector((state) => state.compare.subProfileType, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const location_det = useSelector((state) => state.location.location_det, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const userData = useSelector((state) => state.auth.userData, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const { lat: myLat, long: myLong } = useSelector((state) => state.location.location_det, (a,b)=>JSON.stringify(a)===JSON.stringify(b))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const myRef = useRef()
  const skeltonRef = useRef()
  const { mst_name } = profileData
  const mstNm = mst_name?.toLowerCase()

  const query = useQuery()
  const courseFilter = query.get('course')
  const ref= useRef(0)

  useEffect(() => {
    dispatch({ type: 'UPDATE_PAGE_OFFSET', pageOffset: 1 })
  }, [subProfileType])

  useEffect(() => {
    const postFilteredList = (prof_id, filter) => {
      if (!courseFilter) {
        dispatch({
          type: 'BEGIN_FETCHING_LIST',
          payload: { filter, loc_det: location_det, prof_id, pageOffset, listDetails },
        })
      } else {
        ref.current>0 &&
          dispatch({
            type: 'BEGIN_FETCHING_LIST',
            payload: { filter, loc_det: location_det, prof_id, pageOffset, listDetails },
          })
        ref.current++
      }
    }
    profileData.id && postFilteredList(profileData.id, selectedFilters)
  }, [profileData.id, selectedFilters, pageOffset])

  // useEffect(() => {
  //   console.log('profileData',profileData)
  // }, [profileData])
  // useEffect(() => {
  //   console.log('selectedFilters',selectedFilters)
  // }, [selectedFilters])
  // useEffect(() => {
  //   console.log('pageOffset',pageOffset)
  // }, [pageOffset])
  

  useEffect(() => {
    if (cardsLoading) {
      skeltonRef.current?.scrollTo({
        y: 0,
        animated: true,
      })
    }
  }, [cardsLoading])

  if (cardsLoading && pageOffset <= 1) {
    const skeletonArray = [1, 2, 3, 4, 5]
    return (
      <div ref={skeltonRef} style={{ display: 'flex', flexDirection: 'column' }}>
        {skeletonListCards(skeletonArray)}
      </div>
    )
  }
  if (listDetails?.response?.length === 0) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '30px',
            height: '45vh',
            color: 'rgba(0,0,0,0.5)',
          }}
        >
          {t('no_data')}
        </div>
        <CompareButton />
      </>
    )
  }

  return (
    <div className="listing-card-div">
      {listDetails?.response?.map((item, index) => {
        const {
          ph_url,
          area_nm,
          ct_nm,
          inst_name,
          id,
          is_verified,
          estb_in,
          e_name,
          mot,
          view_cnt,
          p_headline,
          tagline,
          own_type,
          std_range,
          edu_board,
          inst_courses,
          m_of_instruct,
          s_to_f_ratio,
          std_nms,
          crs_nms,
          tot_expr_yr,
          tot_expr_mn,
          average_fee,
          f_pr_hr,
          lat,
          long,
        } = item
        const wish = wishListEdu?.find((x) => x.entity_id === id) || wishListInst?.find((x) => x.entity_id === id)
        return (
          <div key={index} className="card">
            <div className="info-div">
              <div
                className="school-img"
                style={{
                  backgroundImage: `url(${encodeURI(ph_url)
                    .replace(/\(/g, '%28')
                    .replace(
                      /\)/g,
                      '%29'
                    )}), linear-gradient(180deg, rgba(236, 243, 251, 1) -9.27%, rgba(236, 243, 251, 1) 66.67%, rgba(0, 0, 0, 0.53) 109.27%)`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',

                  backgroundPosition: 'center center',
                  backgroundBlendMode: 'multiply',
                }}
              >
                <div className="tip">
                  <img src={home.person_km} style={{ width: '10px', height: '10px' }} />
                  <h6 className="loc-km-text">
                    {getDistance(myLat, myLong, lat, long, 'K')}km {t('away')}
                  </h6>
                </div>
                <div className="established">
                  &nbsp;&nbsp;
                  <MdOutlineLocationOn color="white" size={16} style={{ marginBottom: '-4px' }} />
                  &nbsp;
                  {[area_nm, ct_nm].filter((a) => a).join(', ')}
                </div>
              </div>

              <div className="info-content">
                <div className="title">
                  <div className="title-div">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Link
                        to={`/details/${subProfileType}/${(e_name || inst_name)
                          .replace(/ /g, '-')
                          .replace(/\./g, '')
                          .toLowerCase()}?id=${id}`}
                      >
                        <h2>{e_name || inst_name} </h2>
                      </Link>
                      {is_verified && (
                        <img
                          src={details.badge}
                          alt={index}
                          style={{ width: 18, height: 22, marginLeft: 10, marginTop: -6 }}
                        />
                      )}
                    </div>
                    <div className="flex-row">
                      {mstNm === 'educator' ? (
                        <>
                          <GoPrimitiveDot color="#354857" />
                          <p>{mot && mot.join(', ')}</p>
                        </>
                      ) : (
                        <p>
                          {t('established_in')} {estb_in || 'N/A'}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="view-share">
                    <div className="view">
                      <img src={details.eye} style={{ width: 20, height: 15, marginRight: 4 }} />
                      {view_cnt}
                    </div>
                    <div
                      className="view"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        wish
                          ? removeWishList(
                              { wishListEdu, wishListInst, userData },
                              mstNm,
                              dispatch,
                              wish
                            )
                          : addWishList({ wishListEdu, wishListInst, userData }, mstNm, dispatch, id)
                      }}
                    >
                      {wish ? (
                        <FaHeart color="#FD6C73" style={{ marginRight: 4 }} size={16} />
                      ) : (
                        <FiHeart color="#FD6C73" style={{ marginRight: 4 }} size={16} />
                      )}
                    </div>

                    <span data-tip data-for="facility_tooltip" event="click">
                      <div className="share" onMouseEnter={() => setTooltipData(item)}>
                        <IoShareSocialSharp size={16} color="#4274B0" />
                        &nbsp;{t('share')}
                      </div>
                    </span>
                  </div>
                </div>

                <div className="details">
                  {mstNm === 'educator'
                    ? p_headline
                      ? p_headline.replace(/<[^>]*>?/gm, '')
                      : '-'
                    : tagline
                    ? tagline.replace(/<[^>]*>?/gm, '')
                    : '-'}
                </div>
                {mstNm !== 'educator' && (
                  <div className="info-table">
                    {mstNm === 'school' ? (
                      <>
                        <div className="info-tr">
                          <p>{t('ownership')}</p>
                          <h4>{own_type || 'N/A'}</h4>
                        </div>
                        <div className="separator"></div>
                        <div className="info-tr">
                          <p>{t('standard')}</p>
                          <h4>{std_range || 'N/A'}</h4>
                        </div>
                        <div className="separator"></div>
                        <div className="info-tr">
                          <p>{t('board')}</p>
                          <h4>{edu_board || 'N/A'}</h4>
                        </div>
                        <div className="separator"></div>
                      </>
                    ) : (
                      <div className="info-tr">
                        <p>{t('courses')}</p>
                        <h4>{short(inst_courses, 3)}</h4>
                      </div>
                    )}
                    <div className="separator"></div>
                    <div className="info-tr">
                      <p>{t('medium')}</p>
                      <h4>{short(m_of_instruct, 3)}</h4>
                    </div>
                    <div className="separator"></div>
                    <div className="info-tr">
                      <p>{t('stud_fac')}</p>
                      <h4>{s_to_f_ratio || 'N/A'}</h4>
                    </div>
                  </div>
                )}
                {mstNm === 'educator' && (
                  <div className="info-table">
                    <div className="info-tr">
                      <p>{t('standard')}</p>
                      <h4>{short(std_nms, 3)}</h4>
                    </div>
                    <div className="separator"></div>

                    <div className="info-tr">
                      <p>{t('courses')}</p>
                      <h4>{short(crs_nms, 3)}</h4>
                    </div>

                    <div className="separator"></div>

                    <div className="info-tr">
                      <p>{t('experience')}</p>

                      <h4>
                        {tot_expr_yr !== 0 && `${tot_expr_yr} year`}
                        {tot_expr_mn !== 0 && ` ${tot_expr_mn} months`}
                        {tot_expr_mn === 0 && tot_expr_yr === 0 && 'N/A'}
                      </h4>
                    </div>
                    <div className="separator"></div>
                    <div className="info-tr">
                      <p>{t('medium')}</p>
                      <h4>{short(m_of_instruct, 3)}</h4>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="card-footer">
              <div className="fee-div">
                {t('avg_fee')} : <span style={{ color: '#707070', fontSize: '120%' }}>₹</span>
                &nbsp;
                {mstNm === 'educator' ? f_pr_hr + '/hour' : average_fee + '/month'}
              </div>

              <div className="enquire-div">
                {mstNm !== 'educator' && (
                  <div className="compare">
                    <input
                      id={`compare-${id}`}
                      type="checkbox"
                      checked={selectedIds.includes(id)}
                      onChange={() => {
                        if (
                          selectedIds.length < 3 ||
                          selectedIds.includes(id) ||
                          compareSubProfileType !== subProfileType
                        ) {
                          dispatch({
                            type: 'UPDATE_SELECTED_COMPARE',
                            data: { id, subProfileType, profileType: mstNm },
                          })
                        } else {
                          toast.warning(t('comp_limit'))
                        }
                      }}
                    />
                    <label htmlFor={`compare-${id}`}>{t('compare')}</label>
                  </div>
                )}
                <div
                  className="enquire"
                  onClick={() => {
                    Object.keys(userData ?? {}).length
                      ? setEnquiry(id)
                      : dispatch({ type: 'SHOW_SIGN_IN', value: true })
                  }}
                >
                  <img src={details.phone} />
                  {t('enquire')}
                </div>
              </div>
            </div>
          </div>
        )
      })}

      <ReactTooltip
        id="facility_tooltip"
        className="share-tooltip-container"
        clickable={true}
        place="bottom"
        effect="solid"
        arrowColor="#FFF"
        offset={{ top: 0, left: -30 }}
      >
        <div className="share-tooltip-content">
          <div className="icon-content">
            <p>{t('share_edu_profile')}</p>
            <div className="flex-row">
              <img src={listing.fb} />
              <img src={listing.ln} />
              <img src={listing.gm} />
              <img src={listing.ig} />
              <img src={listing.tw} />
            </div>
          </div>
          <div className="input-content">
            <p>{t('share_link')}</p>
            <div className="flex-row btn-content">
              <input
                ref={myRef}
                type={'text'}
                value={`${window.location.origin}/details/${subProfileType}/${(
                  tooltipData?.e_name || tooltipData?.inst_name
                )
                  ?.replace(/ /g, '-')
                  .replace(/\./g, '')
                  .toLowerCase()}?id=${tooltipData?.id}`}
                readOnly
              />

              <div
                className="copyurl-btn"
                onClick={(e) => {
                  navigator.clipboard.writeText(myRef.current.value)
                  e.target.innerHTML = 'URL copied'
                  setTimeout(() => (e.target.innerHTML = 'Copy URL'), 1000)
                }}
              >
                {t('copy_url')}
              </div>
            </div>
          </div>
        </div>
      </ReactTooltip>
      <CompareButton />
      {enquiry && (
        <EnquiryPopup id={enquiry} masterProfileType={mstNm} close={() => setEnquiry('')} />
      )}
    </div>
  )
}

export default Cards
