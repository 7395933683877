import Sidebar from './sidebar'
import Content from './content'

export default function main({profileDetails, profileType}) {
    return (
        <div className="details-main-content">
            <Sidebar profileType={profileType}/>
            <Content profileDetails={profileDetails} profileType={profileType}/>
        </div>
    )
}
