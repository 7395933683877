import React, { useState, useEffect } from "react";
import '../nabBar.css'
import { student } from "../../../assets/images";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import { ListItem } from "@mui/material";

export default function MyReviews(props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [rdata, setRdata] = useState([])
    const history = useHistory()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData



    useEffect(() => {
        getReview()
      }, [props.filter,props.search,props.flag])


    const getReview = (active) => {
        props.setLoading(true)
        let request = {
            url: `stud/${up_id}/review/all?is_educator=${props.flag}&search_text=${props.search}&pageOffset=1&pageSize=4&sort_order=${props.filter}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setRdata(data.data.result.response??[])
            props.setCount(data.data.result.response.length ??0)
        }).catch((err) => {
            console.log(err, "errrr")
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }



    return (
        <>
            {rdata.map((data,key) => {
                return (
                    <div className="maindiv" key={key}>
                        <div style={{ width: '5px', background: '#F4F9FF', borderRadius: '8px' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div  className="h-flex">
                                <div className="d-logo">
                                    <img src={student.logo_inst}/>
                                    <div style={{ marginLeft: '20px' }}>
                                        <div className="instiHeading">
                                           {data.i_p_nm}
                                        </div>
                                        <div className="intiiiSmall">
                                            Rajouri Garden, Delhi
                                        </div>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="dis-bottomdiv"  >
                                        {data.c_date}
                                        {/* 15 Nov 2021 | 7.30 PM */}
                                        </div>
                                </div>
                            </div>
                            <div style={{borderBottom: '0.5px solid #E7E7E7',marginLeft:'30px',marginRight:'30px'}}></div>
                    
                    <div className="ml-40"> 
                   <div className="dis-Reviewdiv2">
                       <div className="dis-overall" >
                           <div className="dis-rating">
                               <div className="headingOver" >{t("overall")}</div>
                               <div className="dis-OvLable">{data.overall}<img src={student.star} style={{ marginLeft: "5px" }} /></div>
                           </div>
                           <div className="dis-rating">
                               <label className="headingOver">{t("faculty")}</label>
                               <div className="dis-OvLable">{data.faculty}<img src={student.star} style={{ marginLeft: "5px" }} /></div>
                           </div>
                           <div className="dis-rating">
                               <label className="headingOver">{t("academics")}</label>
                               <div className="dis-OvLable">{data.academics}<img src={student.star} style={{ marginLeft: "5px" }} /></div>
                           </div>
                       </div>
                       <div className="dis-overall" >
                           <div className="dis-rating">
                               <div className="headingOver">{t("infra")}</div>
                               <div className="dis-OvLable">{data.infra}<img src={student.star} style={{ marginLeft: "5px"}} /></div>
                           </div>
                           <div className="dis-rating">
                               <label className="headingOver">{t("placements")}</label>
                               <div className="dis-OvLable">{data.placements}<img src={student.star} style={{ marginLeft: "5px" }} /></div>
                           </div>
                           <div className="dis-rating">
                               <label className="headingOver">{t("stud_material")}</label>
                               <div className="dis-OvLable">{data.std_mat}<img src={student.star} style={{ marginLeft: "5px" }} /></div>
                           </div>
                       </div>
                   </div>
                   <div className="intiiiSmall" >
                  {data.desc}
                       </div>
                   </div>
               </div>

           </div>
                )
            })}

        </>
    )
}


