import 'react-circular-progressbar/dist/styles.css'
import ReactTooltip from 'react-tooltip'
import CompareItem, { CompareItemEmpty } from './contentCompareItem'
import { useTranslation } from 'react-i18next';


const ContentCompare = ({ compareData, profileType }) => {
  const { t } = useTranslation()
  const isSchool= profileType==='school'
  // const isSchool=false
  return (
    <div className="flex-row compare-content">
      <div>
        <p>{t("avg_fees")}</p>
        <p>{t("rating")}</p>
        <p>{t("distance")}</p>
        <p>{isSchool ? t("standards") : t("distance")}</p>
        <p>{t("instructions")}</p>
        <p>{t("no_of_views")}</p>
        {isSchool && <p>{t("owner_type")}</p>}
        {isSchool && <p>{t("board")}</p>}
        <p>{t("stud_fac")}</p>
        <p>{t("no_of_fac")}</p>
        <p>{t("campus_size")}</p>
        <p>{t("no_of_studs")}</p>
        <p>{t("facility")}</p>
      </div>

      {compareData[0] ? (
        <CompareItem
          compareDataItem={compareData[0]}
          profileType={profileType}
          isSchool={isSchool}
        />
      ) : (
        <CompareItemEmpty isSchool={isSchool} />
      )}
      {compareData[1] ? (
        <CompareItem
          compareDataItem={compareData[1]}
          profileType={profileType}
          isSchool={isSchool}
        />
      ) : (
        <CompareItemEmpty isSchool={isSchool} />
      )}
      {compareData[2] ? (
        <CompareItem
          compareDataItem={compareData[2]}
          profileType={profileType}
          isSchool={isSchool}
        />
      ) : (
        <CompareItemEmpty isSchool={isSchool} />
      )}

      <ReactTooltip
        id="facility_tooltip"
        type="light"
        place="right"
        effect="solid"
      >
        <div>
          {[1, 2, 3, 4, 5, 6].map((item, index) => {
            return (
              <div>
                <ul>
                  <li>Canteen</li>
                </ul>
              </div>
            );
          })}
        </div>
      </ReactTooltip>
    </div>
  );
}

export default ContentCompare
