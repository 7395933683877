import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { components } from '../../assets/images'
import LangDropDown from '../LangDropdown'
import Signin from '../Signin'
import SigninButton from '../SigninButton/index'
import './header.css'

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showError)
  } else {
    alert('Geolocation is not supported by this browser.')
  }
}

const showPosition = (position) => {
  alert('Latitude: ' + position.coords.latitude + ', Longitude: ' + position.coords.longitude)
}

const showError = (error) => {
  let response = ''
  switch (error.code) {
    case error.PERMISSION_DENIED:
      response = 'User denied the request for Geolocation.'
      break
    case error.POSITION_UNAVAILABLE:
      response = 'Location information is unavailable.'
      break
    case error.TIMEOUT:
      response = 'The request to get user location timed out.'
      break
    case error.UNKNOWN_ERROR:
      response = 'An unknown error occurred.'
      break
    default:
      response = 'An unknown error occurred.'
      break
  }
  alert(response)
}

const Header = () => {
  const showSignIn = useSelector((state) => state.auth.showSignIn)
  const dispatch = useDispatch()
  const setShowsignin = (bool) => {
    dispatch({ type: 'SHOW_SIGN_IN', value: bool })
  }
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setShowMenus(false)
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [showMenus]);

  return (
    <>
      <div className="home-header-div">
        <div className="home-logo-spaceBetween">
          <div className="home-logo-div">
            <Link to={'/'}>
              <img src={components.logo} className="listing-logo" />
            </Link>
          </div>
          <LangDropDown />
        </div>
        <SigninButton setShowsignin={setShowsignin} />
      </div>
      {showSignIn && <Signin setShowsignin={setShowsignin} />}
    </>
  )
}

export default Header
