import { useEffect } from 'react'
import Overview from './overview'
import Structure from './other/structure'
import Teachers from './other/teachers'
import Standard from './other/standard'
import Facilities from './other/facilities'
import Placement from './other/placement'
import Scholarship from './other/scholarship'
import Alumni from './other/alumni'
import Management from './other/management'
import Admission from './other/admission'
import Events from './other/events'
import Rating from './other/rating'
import Faqs from './other/faq.js'
import { toast } from 'react-toastify';

import ScrollIndicator from '../../components/ScrollIndicator'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import { useDispatch } from 'react-redux'

export default function Content({profileDetails, profileType}) {

    const dispatch = useDispatch()
    const { id } = profileDetails
    const getStdCrssFacilitiesNews = (id) => {
      dispatch({type:'SET_LOADING_ITEM', value:{ lStdCrssFacilitiesNews:true}})
      api
        .get(APIURL.detailsPageInst + id + '/comb-std-crs-fac-news')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: 'UPDATE_DETAILSPAGE_STATE',
              data: { stdCrssFacilitiesNews: response.data.result },
            })
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        }).finally(()=>{
          dispatch({type:'SET_LOADING_ITEM', value:{ lStdCrssFacilitiesNews:false}})
        })
    }
    const getFeeTchrAdmn = (id) => {
      dispatch({type:'SET_LOADING_ITEM', value:{ lFeeTchrAdmn: true }})
      api
        .get(APIURL.detailsPageInst + id + '/comb-fee-teacher-admn')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: 'UPDATE_DETAILSPAGE_STATE',
              data: { feeTchrAdmn: response.data.result },
            })
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        }).finally(()=>{
          dispatch({type:'SET_LOADING_ITEM', value:{ lFeeTchrAdmn: false}})
        })
    }
    const getMngtAlumniSchl = (id) => {
      dispatch({type:'SET_LOADING_ITEM', value:{ lMngtAlumniSchl:true}})
      api
        .get(APIURL.detailsPageInst + id + '/comb-mngt-alumni-schl')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: 'UPDATE_DETAILSPAGE_STATE',
              data: { mngtAlumniSchl: response.data.result },
            })
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        }).finally(()=>{
          dispatch({type:'SET_LOADING_ITEM', value:{ lMngtAlumniSchl:false}})
        })
    }
    const getEventAchvPlacFaq = (id) => {
      dispatch({type:'SET_LOADING_ITEM', value:{ lEventAchvPlacFaq:true}})
      api
        .get(APIURL.detailsPageInst + id + '/comb-event-achv-plac-faq')
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: 'UPDATE_DETAILSPAGE_STATE',
              data: { eventAchvPlacFaq: response.data.result },
            })
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        }).finally(()=>{
          dispatch({type:'SET_LOADING_ITEM', value:{ lEventAchvPlacFaq:false}})
        })
    }

    useEffect(() => {
      const getData = async (id) => {
        getStdCrssFacilitiesNews(id)
        getFeeTchrAdmn(id)
        getMngtAlumniSchl(id)
        getEventAchvPlacFaq(id)
      }
      id && getData(id)
    }, [id])

    useEffect(() => {
      return () => {
        dispatch({
          type: 'UPDATE_DETAILSPAGE_STATE',
          data: {
            stdCrssFacilitiesNews: {},
            feeTchrAdmn: {},
            mngtAlumniSchl: {},
            eventAchvPlacFaq: {},
          },
        })
      }
    }, [])
    
    return (
        <div className="detail-content-div ">
            <section id="sidebar_overview"><Overview profileDetails={profileDetails} profileType={profileType}/></section>
            <section id="sidebar_standard"><Standard profileType={profileType}/></section>
            <section id="sidebar_structure"><Structure /></section> 

            <section id="sidebar_facilities"><Facilities /></section>
            <section id="sidebar_teachers"><Teachers /></section>   
            <section id="sidebar_admission"><Admission /></section> 

            <section id="sidebar_management"><Management /></section>
            <section id="sidebar_alumni"><Alumni /></section>
            <section id="sidebar_scholarship"><Scholarship /></section>

            <section id="sidebar_events"><Events /></section>
            {profileType!=='school' && <section id="sidebar_placement"><Placement /></section>}
            <section id="sidebar_rating"><Rating id={id} profileType={profileType}/></section>
            <section id="sidebar_faq"><Faqs id={id} type="inst"/></section>
            <ScrollIndicator />
        </div>
    )
}
