import { listing } from '../../assets/images'
import { useTranslation } from 'react-i18next';

export default function Filterbutton() {
    const { t } = useTranslation()
    const toggle = () => {
        let sel = document.querySelector('.listing-filter-div')
        if(sel.style.display === '' || sel.style.display === 'none'){
            sel.style.display = 'block'
        } else {
            sel.style.display = 'none'
        }
    }
    return (
        <div className="filterButton" onClick={toggle}>
            <p>{t('apply_filter')}</p>
            <img src={listing.filter} style={{width: 20, height: 20}} />
        </div>
    )
}
