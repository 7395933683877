import { useState, useEffect, useRef } from "react";
import { APIURL } from "../../api/url";
import api from "../../api/api";
import { CODE } from "../../utils/profileUtils";
const useLoadReviews = (userData, profileType, id) => {
  const [ratingData, setRatingData] = useState([]);
  const { token, c_id } = userData;
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [prevY, setPrevY] = useState(0);
  let ratingDataRef = useRef([]);

  let loadingRef = useRef(null);
  let prevYRef = useRef({});
  let pageRef = useRef({});
  ratingDataRef.current = ratingData;
  pageRef.current = page;

  prevYRef.current = prevY;

  const getReviews = () => {
    if (userData.p_type === 4) {
      api
        .getCustom({
          url: `${APIURL.reviewsInst}/${id}/all`,
          headers: { Authorization: `Bearer ${token}` },
          params: {
            student_uid: c_id,
            pageOffset: page,
            pageSize: 2,
          },
        })
        .then((res) => {
          if (page === 1) {
            setRatingData(res.data);
          } else {
            const tempRatingData = loadMoreReviews(res);
            setRatingData(tempRatingData);
          }
        })
        .catch((res) => console.log(res));
    } else {
      api
        .getCustom({
          url: `website/view/${CODE[profileType]}/${id}/review/all`,
          params: {
            pageOffset: page,
            pageSize: 2,
          },
        })
        .then((res) => {
          if (page === 1) {
            setRatingData(res.data);
          } else {
            const tempRatingData = loadMoreReviews(res);
            setRatingData(tempRatingData);
          }
        })
        .catch((res) => console.log(res));
    }
  };

  const loadMoreReviews = (res) => {
    if (res) {
      setHasMore(false)
    }
    let moreRatings = res.data.result.map_response.reviews;
    let tempRatingData = { ...ratingData };
    let reviewsData = [...tempRatingData.result.map_response?.reviews, ...moreRatings];
    tempRatingData.result.map_response.reviews = reviewsData;
    return tempRatingData;
  };

  useEffect(() => {
    getReviews();
  }, [page]);

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    console.log(loadingRef.current)
    observer.observe(loadingRef.current);
  }, []);

  const handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;

    if (prevYRef.current > y) {
      console.log("actually getting photos.");
      setPage(pageRef.current + 1);
    } else {
      console.log("conditional is false");
    }
    setPrevY(y);
  };

  return { ratingData, loadingRef, hasMore };
};

export default useLoadReviews;
