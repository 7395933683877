import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { components } from '../../assets/images'
import HeadList from '../../components/Headlist/index'
import ScrollIndicator from '../../components/ScrollIndicator'
import ScrollButton from '../../components/ScrollToTop'
import CardHeading from './cardHeading'
import Cards from './cards'
import Filterbutton from './filterbutton'
import Filters from './filters/index'
import InfiniteScroll from './infiniteScroll'
import './listing.css'

const Index = () => {
  const { subProfileType } = useParams()
  const subProfileTypeTrans = subProfileType?.toLowerCase()
  const MASTER_PROFILE_TYPES = ['school','coaching','educators']
  const allProfileTypes = useSelector((state) => state.auth.allProfileTypes, (a,b)=>JSON.stringify(a)===JSON.stringify(b) )
  const history = useHistory()
  const dispatch = useDispatch()
  
  // useEffect(() => {
  //   if(crs_id){
  //     dispatch({type: 'UPDATE_SELECTED_FILTERS', updatedFilter: { keyName: 'crs_ids', value: crs_id }, reset:true })
  //   }
  // }, [crs_id])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const profileData =
    allProfileTypes?.length &&
    (allProfileTypes.filter(
      ({ name }) => name.toLowerCase() === subProfileTypeTrans.replace(/-/g, ' ')
    )[0] ||
      allProfileTypes[0])

  const MainContent = () => {
    if (allProfileTypes?.length === 0 || profileData === undefined) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <img
            src={components.loader}
            style={{ justifySelf: 'center', height: '30vh' }}
            alt="loading"
          />
        </div>
      )
    } else if (!MASTER_PROFILE_TYPES.some((i) => i.includes(profileData.mst_name?.toLowerCase()))) {
      return <h1>Some Error Occurred...</h1>
    }
    return (
      <>
        <HeadList />
        <div className="listing-content">
          <div className="listing-filters">
            <Filterbutton />
            <Filters profileid={profileData.id}/>
          </div>
          <div className="listing-content-div">
            <div className="listing-heading-title">
              <span onClick={()=>{history.push('/')}}>Home /</span> <span style={{ textTransform: 'capitalize' }}> {profileData.name}</span>
            </div>
            <CardHeading category={profileData.name?.toLowerCase()} />
            <Cards profileData={profileData} subProfileType={subProfileTypeTrans}/>
            <InfiniteScroll />
            {/* <Loader/> */}
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <MainContent />
      <ScrollIndicator />
      <ScrollButton />
      <ToastContainer theme="colored" closeButton={false} />
    </div>
  )
}

export default Index
