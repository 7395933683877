import ratingJSON from './rating.json'
import { useEffect, useState } from 'react'
import api from '../../../api/api';
import { APIURL } from '../../../api/url';
import { useSelector } from 'react-redux';
import Reviews from '../../../components/Reviews';
import { CODE } from '../../../utils/profileUtils';
import { useParams } from 'react-router-dom';

const Rating = ({id, profileType}) => {
    const userData = useSelector(state=>state.auth.userData)
    const {subProfileType} = useParams()
    const [ratingData, setRatingData] = useState({})
    const [loading, setLoading] = useState(false)
    const {token, up_id} = userData

    useEffect(() => {
      const getReviews = ()=>{
        setLoading(true)
        if (userData.p_type===4)
        {
        api.getCustom({
              url: `${profileType==='educator' ? APIURL.reviewsEdu : APIURL.reviewsInst}/${id}/all`,
              headers: { Authorization: `Bearer ${token}`},
              params: {
                student_uid:  up_id,
                pageOffset: 1,
                pageSize:3
              },
            }).then((res)=>setRatingData(res.data)).catch((res)=>console.log(res)).finally(()=>setLoading(false))
      }else {
          api.getCustom({
            url: `website/view/${CODE[profileType]}/${id}/review/all`,
            params: {
              pageOffset: 1,
              pageSize:3
            },
          }).then((res)=>setRatingData(res.data)).catch((res)=>console.log(res)).finally(()=>setLoading(false))
          
  
      }}
      id && getReviews()
    }, [id])

 
    return <Reviews loading={loading} profileType={profileType} subProfileType={subProfileType} id={id} ratingData={ratingData} isAllReviews={false} userData={userData} setRatingData={setRatingData}/>
}

export default Rating
