import { IoLocationSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { home } from '../../assets/images'
import { getDistance, short } from '../../utils/utils'
export default function SchoolCoachCard(obj, myLat, myLong, isSchool, textObj) {
  const { i_p_id, prof_ph, area_nm, city_nm, inst_nm, lat, long, edu_brd, mst_crs_arr } = obj
  return (
    <div key={i_p_id} className="slider-container">
      <div className="carousel-img-container">
        <div className="carousel-km-text-content">
          <img src={home.person_km} style={{ width: '10px', height: '10px' }} />
          <p className="carousel-km-text">&nbsp; {getDistance(myLat, myLong, lat, long, 'K')}km {textObj['away']}&nbsp;</p>
        </div>
        <img src={prof_ph || home.default_photo_inst} className="carousel-img" />
        <p className="carousel-address-text flex-row">
          &nbsp;
          <span style={{ marginBottom: '0px' }}>
            <IoLocationSharp style={{ color: '#FFFFFF' }} size={13} />
          </span>
          &nbsp;{[area_nm, city_nm].filter((a) => a).join(', ')}&nbsp;
        </p>
        <div className="overlay-img" />
        <div className="visit-btn-container">
          <div className="visit-btn">
            <Link to={`/details/${isSchool ? 'school' : 'competitive-exam'}/${inst_nm.replace(/ /g, '-').replace(/\./g,'').toLowerCase()}?id=${i_p_id}`}> {textObj['visit']} </Link>
          </div>
        </div>
      </div>
      <div className="carousel-school-content">
        <p className="carousel-school-text">{[inst_nm, area_nm].filter((a) => a).join(', ')}&nbsp;</p>
        <div className="certificate-container">
          <img src={home.certificate} style={{ width: '10.74px', height: '10px' }} />
          <p className="certificate-container-text">&nbsp;&nbsp;{isSchool ? edu_brd : short(mst_crs_arr, 2)}</p>
        </div>
      </div>
    </div>
  )
}