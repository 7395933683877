import { memo } from 'react'
// import ReactCSSTransitionGroup from 'react-transition-group';
import React, { useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { components } from '../../assets/images'
import LangDropDown from '../LangDropdown'
import Signin from '../Signin'
import SigninButton from '../SigninButton/index'
import './headerSearch.css'
import HomeSearch from './homeSearch'

const Header2 = () => {
    const showSignIn = useSelector(state=>state.auth.showSignIn)
    const dispatch= useDispatch()
    const setShowsignin = (bool)=>{
      dispatch({type:'SHOW_SIGN_IN', value:bool})
    }
    const [show, setShow] = useState(false)

    const handleCollapse = () => {
      let bodyContElem = document.getElementsByClassName('home-search-div2-ext')

      if (bodyContElem.length && !show) {
        bodyContElem[0].style.maxHeight = '100vh'
        bodyContElem[0].style.opacity = 1
      } else {
        bodyContElem[0].style.maxHeight = 0
        bodyContElem[0].style.opacity = 0
      }
      setShow(!show)
    } 
    return (
<>
        <div>
            <div className="home-header-div list-header-div" style={{ border: '1px solid #EEE', position: 'fixed' }}>
                <div className="home-logo-div2">
                    <Link to={'/'}><img src={components.logo} className="listing-logo" /></Link>
                </div>
                <LangDropDown />
                <div className="home-header-right" style={{ width: '80%' }}>
                    {HomeSearch()}
                    <SigninButton setShowsignin={setShowsignin} />
                    <FaBars color={'#283B4Cr'} className="search-collapse-button" onClick={handleCollapse}/>
                    {/* <div className="home-login-button-div listing-login" style={{ marginLeft: 50, marginRight: 90 }}>Sign In</div> */} 
                </div>
            </div>
            {HomeSearch('-ext', show)}
        </div>
        {
        showSignIn &&
        <Signin setShowsignin={setShowsignin} />
      }
</>

    )


}

export default memo(Header2)