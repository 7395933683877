import React, { useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { Rating } from "react-simple-star-rating";
import { useTranslation } from "react-i18next";
import { details } from "../../assets/images";
import api from "../../api/api";
import { APIURL } from "../../api/url";
import { toast } from "react-toastify";

const WriteReview = ({ setisReview, userData, profileType, id }) => {
  const {token, up_id} = userData
  const [ratingObj, setRatingObj] = useState({
    overall: 0,
    faculty: 0,
    academics: 0,
    std_mat: 0,
    infra: 0,
    placements: 0,
    campus: 0,
    val_money: 0,
    desc:''

  });
  const { t } = useTranslation();
  const ratingArr = [
    { text: t("overall"), state: ratingObj.overall },
    { text: t("faculty"), state: ratingObj.faculty },
    { text: t("academics"), state: ratingObj.academics },
    { text: t("infra"), state: ratingObj.infra },
    { text: t("placements"), state: ratingObj.placements },
    { text: t("stud_material"), state: ratingObj.std_mat },
  ];
  const onSubmit = ()=>{
    api.postCustom({
      url: (profileType === 'educator' ? APIURL.reviewsEdu : APIURL.reviewsInst) + '/add',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        g_b_uid: up_id,
        i_p_id: id,
        ...ratingObj,
      },
    }).then((res)=>{
      if(res.data.status_code===200)
        toast.success('Review Successfully posted')
      else{
       toast.error('Some Error Occurred')
      }
    }).catch(
      (err)=> toast.error('Some Error Occurred')
    ).finally(()=>setisReview(false))
  }

  return (
    <div className="popup-container">
      <div className="review-header-content">
        <div>
          <h2 className="review-header">{t("give_review")}</h2>
          <p>{t("share_exp")}</p>
        </div>
        <div onClick={() => setisReview(false)}>
          <FaRegWindowClose color="#9B9B9B" size={24} />
        </div>
      </div>
      <div className="modal-professor-content">
        <img src={details.avatar2} />
        <div>
          <h3>{userData.name}</h3>
          {/* <p>Rajouri Garden, New Delhi</p> */}
        </div>
      </div>

      <div className="review-header-content">
        <div className="review-column-45">
          {ratingArr.map((item) => {
            return (
              <div className="review-column-45-content">
                <p style={{ width: "40%" }}>{item.text}</p>
                <Rating
                  onClick={(value) =>
                    setRatingObj((prev) => ({
                      ...prev,
                      [item.text.replace(/\s/g, "").toLowerCase()]: value,
                    }))
                  }
                  iconsCount={5}
                  initialValue={item.state}
                  ratingValue={0}
                  size={20}
                  readonly={false}
                  allowHalfIcon={true}
                  allowHover={true}
                  style={{ marginTop: 3 }}
                />
                <p style={{ width: "10%", color: "#707070" }}>{`(${item.state})`}</p>
              </div>
            );
          })}
        </div>
        <div className="modal-vertical-line" />
        <div className="review-column-45">
          <p>{t("write_review")}</p>
          <textarea 
          rows={7} 
          placeholder={"Min 50 charectors or 250 charactors..."} 
          value={ratingObj.desc} 
          onChange={(e)=>
            setRatingObj({...ratingObj, desc:e.target.value})}
          />
          <button onClick={()=>onSubmit()}>
            <p>{t("submit")}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WriteReview;
