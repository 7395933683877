import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { details, listing } from "../../assets/images";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import api, { errorMsg } from '../../api/api'
import { short } from "../../utils/utils";
import { CODE, profileTypeTransform } from "../../utils/profileUtils";
import { toast } from 'react-toastify'
import "./reviews.css";
import Reviews from "../../components/Reviews";
import { skeletonRatingCard } from "../../components/Skeleton/skeleton";
import Organisation from "./organisation";
import NavBar from "./navBar";
import useLoadReviews from "./useLoadReviews";

const AllReviews = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.auth.userData);
  const [profileDetails, setProfileDetails] = useState({})
  const { subProfileType, id } = useParams();
  const subProfileTypeTrans = subProfileType?.toLowerCase()
  const profileType = profileTypeTransform(subProfileTypeTrans)

  useEffect(() => {
    const postFilteredList = (profileType) => {
      let type = CODE[profileType];
      api
        .get(`website/view/${type}/${id}`)
        .then((response) => {
          if (response.status === 200) {
            setProfileDetails(response.data.result);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(errorMsg(err));
        });
    };
    postFilteredList(profileType);
  }, [id, profileType]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { ratingData, loadingRef, hasMore } = useLoadReviews(userData, profileType, id);

  const organizationSchoolInfo = [
    { text: t("standard"), icon: details.standard, val: profileDetails.std_range ?? '-' },
    { text: t("board"), icon: details.board, val: profileDetails.edu_board ?? '-' },
    { text: t("avg_fees"), icon: details.avgfee, val: profileDetails ? `${profileDetails.average_fee}/month` : '-' },
    { text: t("medium"), icon: details.medium, val: short(profileDetails.m_of_instruct, 3)},
    { text: t("stud_fac"), icon: details.ratio, val: profileDetails.s_to_f_ratio ?? '-' },
  ];

  const organizationCoachingInfo = [
    { text: t("courses"), icon: details.avgfee, val: short(profileDetails.inst_courses, 3) },
    { text: t("avg_fees"), icon: details.avgfee, val: profileDetails ? `${profileDetails.average_fee}/month` : '-'},
    { text: t("medium"), icon: details.medium, val: short(profileDetails.m_of_instruct, 3)  },
    { text: t("stud_fac"), icon: details.ratio, val: profileDetails.s_to_f_ratio ?? '-' },
  ];

  const shareLinks = [listing.web, listing.fb, listing.ln, listing.tw, listing.yt, listing.ig];

  const organizationInfo =
    profileType === "school" ? organizationSchoolInfo : organizationCoachingInfo;

  return (
    <>
      <NavBar 
        shareLinks={shareLinks}
        subProfileType={subProfileTypeTrans} 
        profileDetails={profileDetails} 
        id={id}
        />
      <div className="content-section">
        <Organisation organizationInfo={organizationInfo} profileDetails={profileDetails} />
        <div className="wid-75">
          <Reviews
            profileType={profileType}
            id={id}
            subProfileType={subProfileTypeTrans}
            ratingData={ratingData}
            isAllReviews
            userData={userData}
          />
          {hasMore && <div ref={loadingRef}>{skeletonRatingCard()}</div>}
        </div>
      </div>
    </>
  );
};

export default AllReviews;
