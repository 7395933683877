export const components = {
    /*Images*/
    logo: require('./img/logo.svg').default,
    footer_fb: require('./img/footer/fb.png').default,
    footer_lin: require('./img/footer/in.svg').default,
    footer_tw: require('./img/footer/tw.svg').default,
    footer_yt: require('./img/footer/yt.png').default,
    footer_play: require('./img/footer/play.png').default,
    footer_apps: require('./img/footer/appstore.svg').default,
    // loader: require('./loader/loader.gif').default,
    loader: require('./loader/loader3.svg').default,
    loader2: require('./loader/loader2.gif').default,
    signin_slider1: require('./signin/slider1.jpg').default,
    signin_slider2: require('./signin/slider2.jpg').default,
    signin_slider3: require('./signin/slider3.jpg').default,
    signin_slider4: require('./signin/slider4.jpg').default,
    signin_davatar: require('./img/home/davatar.svg').default,

    /*Icons*/
    offer: require('./icon/profile/offer.png').default,
    signin_arrow: require('./icon/signin/arrow.png').default,
    signin_india: require('./icon/india.png').default,
    signin_help: require('./icon/signin/help.png').default,
    profile_interest: require('./icon/profile/intrest.png').default,
    profile_wish: require('./icon/profile/wish.png').default,
    profile_enq: require('./icon/profile/enq.png').default,
    profile_rate: require('./icon/profile/rate.png').default,
    profile_notepad: require('./icon/profile/notepad.png').default,
    profile_setting: require('./icon/profile/setting.png').default,
}

export const home = {
    /*Images*/
    banner_girl: require('./img/home/bannerGirl.svg').default,
    signin_arrow: require('./img/home/signin_arrow.svg').default,
    signin_grad: require('./img/home/signin_grad.svg').default,
    banner: require('./img/banner/banner.png').default,
    blog1: require('./img/blog/1.png').default,
    blog2: require('./img/blog/2.png').default,
    blog3: require('./img/blog/3.png').default,
    blog4: require('./img/blog/4.png').default,
    blog5: require('./img/blog/5.png').default,
    category1: require('./img/category/1.png').default,
    category2: require('./img/category/2.png').default,
    category3: require('./img/category/3.png').default,
    category4: require('./img/category/4.png').default,
    category5: require('./img/category/5.png').default,
    category6: require('./img/category/6.png').default,
    category_bg: require('./img/home/categorybg.svg').default,
    person_km: require('./img/school/person_km.png').default,
    certificate: require('./img/school/certificate.png').default,
    educator_cert: require('./img/educator/certificate.png').default,
    briefcase: require('./img/educator/briefcase.png').default,
    teaching: require('./img/educator/teaching.png').default,
    school: require('./img/school.png').default,
    default_photo_edu: require('./img/defaultPhotoEdu.svg').default,
    default_photo_inst: require('./img/defaultPhotoInst.svg').default,
    default_photo_coach: require('./img/defaultPhotoCoaching.svg').default,
    avatar2: require('./img/home/avatar2.png').default,
    whybg: require('./img/home/whybg.png').default,

    /*Icons*/
    location_icon: require('./icon/home/location-search.png').default,
    class_search: require('./icon/home/class-search.png').default,
    left_icon: require('./icon/home/homeIcon/left.png').default,
    right_icon: require('./icon/home/homeIcon/right.png').default,
    grey_academic: require('./icon/home/grey/academic.png').default,
    grey_competitive: require('./icon/home/grey/competitive.png').default,
    grey_develop: require('./icon/home/grey/develop.png').default,
    grey_educator: require('./icon/home/grey/educator.png').default,
    grey_persue: require('./icon/home/grey/pursue.png').default,
    grey_school: require('./icon/home/grey/school.png').default,
    red_academic: require('./icon/home/red/academic.png').default,
    red_competitive: require('./icon/home/red/competitive.png').default,
    red_develop: require('./icon/home/red/develop.png').default,
    red_educator: require('./icon/home/red/educator.png').default,
    red_persue: require('./icon/home/red/pursue.png').default,
    red_school: require('./icon/home/red/school.png').default,
}

export const apply = {
    banner: require('./img/apply-banner.jpg').default,
    upload: require('./icon/apply/upload.png').default,
    close: require('./icon/apply/close.png').default,
    png: require('./icon/apply/png.png').default,
    pdf: require('./icon/apply/pdf.png').default,
    jpeg: require('./icon/apply/jpeg.png').default,
    img: require('./icon/apply/img.png').default,
    check:require('./icon/apply/check.png').default,
    mainImg:require('./icon/apply/mainimg.png').default,
    submit:require('./icon/apply/submit.png').default
}

export const details = {
    /*Images*/
    img4: require('./img/details/img4.png').default,
    slider_banner: require('./img/detail-banner.png').default,
    avatar2: require('./img/details/avatar2.png').default,
    educatorCover : require('./img/details/educatorCover.png').default,
    educatorCover2 : require('./img/details/educatorCover2.png').default,
    galleryImg1 : require('./img/gallery-img1.png').default,

    /*Icons*/
    claimFlagFill: require('./icon/details/claimFlagFill.svg').default,
    star: require('./icon/details/star-white.png').default,
    bluearrow: require('./icon/bluearrow.png').default,
    play_button: require('./icon/playButton.svg').default,
    avgfee: require('./icon/details/avgfee.png').default,
    standard: require('./icon/details/standard.png').default,
    courses: require('./icon/details/courses.png').default,
    experience: require('./icon/details/experience.png').default,
    no_of_student: require('./icon/details/no_of_student.png').default,
    eye: require('./icon/eye.png').default,
    location2: require('./icon/location2.png').default,
    badge: require('./icon/badge.png').default,
    phone: require('./icon/phone.png').default,
    km_away: require('./icon/kmaway.png').default,
    board: require('./icon/details/board.png').default,
    medium: require('./icon/details/medium.png').default,
    ratio: require('./icon/details/ratio.png').default,
    default_profile_edu: require('./icon/details/defaultProfileEdu.svg').default,
    default_profile_inst: require('./icon/details/defaultProfileInst.svg').default,
    gallery_close: require('./icon/galleryCloseButton.svg').default,
    blue_arrow: require('./icon/details/arrow.png').default,
    red_Circle: require('./icon/details/redcircle.png').default,
    coaching_logo: require('./icon/details/coaching-logo.png').default,
    alumini: require('./icon/details/alumni.png').default,
    blue_arrow_down: require('./icon/details/bluearrowDown.png').default,
    blue_arrow_up: require('./icon/details/bluearrowUp.png').default,
    flip: require('./icon/details/flipimg.png').default,
    quotes: require('./icon/details/comma.png').default,
    close: require('./icon/signin/close.png').default,
    teacher: require('./icon/details/teacher.png').default,
    start_grey: require('./icon/details/stargrey.png').default,
    star_white: require('./icon/details/star-white.png').default,
    star_gold: require('./icon/details/stargold.png').default,
    star_big: require('./icon/details/starbig.png').default,
    scholarship: require('./icon/details/scholarship.png').default,
    clock: require('./icon/details/clock.png').default,
    mode: require('./icon/details/mode.png').default,
    seat: require('./icon/details/seat.png').default,
    ownership: require('./icon/details/ownership.png').default,
    type: require('./icon/details/type.png').default,
    faculty: require('./icon/details/faculty.png').default,
    school_type: require('./icon/details/schooltype.png').default,
    campus: require('./icon/details/campus.png').default,
    students: require('./icon/details/students.png').default,
    jpegIcon: require('./icon/details/jpegIcon.svg').default,
    pdfIcon: require('./icon/details/pdfIcon.svg').default,
    pngIcon: require('./icon/details/pngIcon.svg').default,
    
    //cover
    CoverDevelopSkill: require('./img/details/CoverDevelopSkill.png').default,
    CoverDevelopSkill1: require('./img/details/CoverDevelopSkill1.png').default
}

export const compare = {
    location: require('./icon/location3.png').default,
    default_photo_inst: require('./img/defaultPhotoInst.svg').default,
    default_photo_coaching: require('./img/defaultPhotoCoaching.svg').default
}

export const contact = {
    contact: require('./img/contact/contact.png').default,
    next_arrow: require('./img/contact/next_arrow.png').default,
    mail_call: require('./img/contact/mailCall.png').default,
    call: require('./img/contact/call.png').default,
    mail: require('./img/contact/mail.png').default,
    pune: require('./img/contact/pune.png').default,
    noida: require('./img/contact/noida.png').default,
}

export const listing = {
    fb: require('./icon/share/fb.png').default,
    ln: require('./icon/share/in.png').default,
    ig: require('./icon/share/ig.png').default,
    tw: require('./icon/share/tw.png').default,
    gm: require('./icon/share/gm.png').default,
    filter: require('./icon/filter.png').default,
    pdf: require('./icon/pdf.png').default,
    web: require('./icon/share/rev_web.png').default,
    yt: require('./icon/share/rev_yt.png').default,
}

export const student = {
    search: require('./icon/search.png').default,
    fil: require('./icon/fil.png').default,
    down: require('./icon/down.png').default,
    cup: require('./icon/cup.png').default,
    cross: require('./img/cross.png').default,
    rec: require('./img/rec.png').default,
    logo_inst: require('./icon/logoinst.png').default,
    star: require('./icon/star.png').default,
    download:require('./img/download.png').default,
    cr:require('./img/c.png').default,
    logo:require('./img/logo.png').default
}

export const studyAbroad = {
    bckgrnd_map: require('./img/studyAbroad/backgrnd-map.svg').default,
    world_map: require('./img/studyAbroad/world-map.png').default,
    counsel: require('./img/studyAbroad/counsel.svg').default,
    ielts: require('./img/studyAbroad/ielts.svg').default,
    per_sta: require('./img/studyAbroad/per_sta.svg').default,
    pre_depart: require('./img/studyAbroad/pre_depart.svg').default,
    uni_select: require('./img/studyAbroad/uni_select.svg').default,
    app_assist: require('./img/studyAbroad/app_assist.svg').default,
    visa_adv: require('./img/studyAbroad/visa_adv.svg').default,
    ftr_review: require('./img/studyAbroad/ftr_review.svg').default,
}
