import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { components } from '../../assets/images'
import api from './../../api/api'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIURL } from '../../api/url';


export default function Login(props) {
    let [loading, setLoading] = useState(false)
    let [number, setNumber] = useState("");
    const { t } = useTranslation()


    const onSubmit = () => {
        if (number.length !== 10) {
            toast.error(t('valid_no'));
        } else {
            setLoading(true);
            api.post({ url: APIURL.userLogin, data: { phone: number, user_type: 4 } }).then((res) => {
                const {is_multi_login, is_new_user} = res.data.result
                let adata = res.data.result
                adata.number = number
                adata.user_type = 4
                localStorage.setItem("login", JSON.stringify(adata))
                props.setSelected('ValidateOtp')
            }).catch((err) => {
                toast.error("Some error occured while logging in.");
            }).finally(()=>setLoading(false))
        }
    }

    const onlyNumberKey = (evt) => {
        // Only ASCII character in that range allowed
        var ASCIICode = evt.which || evt.keyCode || 0
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }

    return (
        <div className="signin">
            <h2>{t('sign_in')}</h2>
            <h4>{t('to_your_acc')}</h4>
            <p className="loginp">{t('take_first_step')} </p>
            <div className="flex-row">
                <img className="country" src={components.signin_india} />
                <select className="ccode"><option>+91</option></select>
                <input
                    type="text"
                    pattern="[0-9]*"
                    value={number}
                    onKeyPress={onlyNumberKey}
                    maxLength={10}
                    onChange={(e) => setNumber(e.target.validity.valid ? e.target.value : number)} placeholder={t('phone_hint')} />
            </div>
            {
                loading ?
                    <div className="loginProgress"><img src={components.loader2} /></div>
                    :
                    <button onClick={onSubmit} className="flex-row">{t('continue')} <img src={components.signin_arrow} /></button>
            }


            <h4 className="inst"><a onClick={() => props.setSelected('AdminSignin')}>{t('are_you_institute')} <span>{t('click_here')}</span></a></h4>
        </div>
    )
}
