import Routes from "./routes";
import "react-rangeslider/lib/index.css";
import "./css/website.css";
import "./css/media.css";
import { getTokenFlag, onMessageListener } from "./firebase";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api, { errorMsg } from "./api/api";
import { toast } from "react-toastify";
import { APIURL } from "./api/url";
import SigninGradient from "./modules/home/signinGradient";

function App() {
  const [showSignInGrad, setShowsigninGrad] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.userData || {});
  const { up_id, c_id, p_type, token } = userData
  // const userData = useSelector(state=>state.auth.userData)
  useEffect(() => {
    setTimeout(() => setShowsigninGrad(true), 20000);
  }, []);

  useEffect(() => {
    dispatch({ type: "SHOW_SIGN_IN", value: false });
    let token = getTokenFlag();
    if (token == false) {
      requestPermission();
    }

    const getProfileType = () => {
      api
        .get(APIURL.allProfileTypes)
        .then((response) =>
          dispatch({
            type: `SET_PROFILE_TYPES`,
            allProfileTypes: response.data.result,
          })
        )
        .catch((err) => {
          toast.error(errorMsg(err));
        });
    };
    getProfileType();
  }, []);

  useEffect(() => {
    const getWishlist = (val) => {
      api
        .getCustom({
          url: `${val === "edu" ? APIURL.getWishlistEdu : APIURL.getWishlistInst}/` + c_id,
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        })
        .then((res) => {
          if (val === "edu") {
            let wishListEdu = res.data.result;
            dispatch({ type: "UPDATE_WISHLIST", wishListEdu });
          } else {
            let wishListInst = res.data.result;
            dispatch({ type: "UPDATE_WISHLIST", wishListInst });
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err));
        });
    };
    if (up_id && p_type === 4) {
      getWishlist("edu");
      getWishlist("inst");
    }
  }, [up_id]);

  onMessageListener()
    .then((payload) => {
      const { title, body } = payload.notification;
      // toast.success(`${title} \n ${body}`)
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  const requestPermission = () => {
    let token;
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        token = getTokenFlag();
      } else {
        console.log("Unable to get permission to notify.");
        return false;
      }
    });
  };

  return (
    <>
      <Routes />
      {showSignInGrad && !(Object.keys(userData ?? {}).length) && (
        <SigninGradient setShowsigninGrad={setShowsigninGrad} />
      )}
    </>
  );
}

export default App;
