import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { home } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { AiFillRightCircle } from 'react-icons/ai'
import { skeletonEdu } from '../../components/Skeleton/skeleton'
import EducatorCard from './educatorCard'

const Educators = ({ educators }) => {
  const { t } = useTranslation()
  const skeletonArray = [1, 2, 3, 4, 5, 6, 7]
  const isLoading = useSelector((state) => state.auth.isLoading)
  const PrevBtn = ({ className, onClick }) => {
    return (
      <div className={className} style={{ marginTop: '-135px' }} onClick={onClick}>
        <img src={home.left_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  const NextBtn = ({ className, onClick }) => {
    return (
      <div className={className} style={{ marginTop: '-135px' }} onClick={onClick}>
        <img src={home.right_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  //OR to be replaced by nullish when data is removed from persistor
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    appendArrows: educators?.length >= 4,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (educators?.length || skeletonArray.length) <= 5 ? 3 : 4.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (educators?.length || skeletonArray.length) <= 5 ? 3 : 3.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },

      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (educators?.length || skeletonArray.length) <= 5 ? 2.5 : 2.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: (educators?.length || skeletonArray.length) <= 5 ? 1.5 : 1.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
  }

  return (
    <div className="m-v-60-30">
      <div className="carousel-header-row">
        <div>
          <h3>{t('edu_title')}</h3>
          <div className="carousel-dash"></div>
        </div>

        <Link to="/listing/educator">
          {t('view_all')}{' '}
          <AiFillRightCircle color="#4274B0" style={{ marginBottom: '-7px' }} size={24} />
        </Link>
      </div>
      <Slider {...settings}>
        {!educators || isLoading ? skeletonEdu(skeletonArray) : educatorList(educators, t('no_data'), t('visit'))}
      </Slider>
    </div>
  )
}

const educatorList = (educators, noDataText, visitText) =>
  educators.length ? educators.map((obj, index) => EducatorCard(obj, visitText)) : <h1>{noDataText}</h1>

export default Educators
