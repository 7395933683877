import { Modal, Box } from "@mui/material";
import { AiOutlineClose } from 'react-icons/ai'
import { details } from "../../../assets/images";
import Qualification from "../mainEdu/qualification";
import Experience from "../mainEdu/experience";
import Achievements from "../other/achievements";
import './teachermodal.scss'
import { short } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "87%",
  width: "100%",
  maxWidth: "1050px",
  maxHeight: "670px",
  bgcolor: "background.paper",
  boxShadow: 8,
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  fontFamily: "Poppins,Lato",
  overflow: "hidden",
};

const qualifications= [
      {
          "id": "3a21d816-a9fc-4004-a5ab-c4f9c9fba3f1",
          "educator": null,
          "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
          "inst_name": "Pune university",
          "d_name": "Engineering",
          "e_type": "Full Time",
          "f_date": "2022-04-13",
          "t_date": "2022-09-13",
          "is_ongoing": false,
          "desc": "<p>dctrfyt fgtiyi yi;uiu tgyh'o8uo 8y98u0'9ou0u.</p>",
          "c_date": "2022-08-12 23:40:32.0",
          "u_date": "2022-08-12 23:41:19.0",
          "ht_qual": true,
          "uid": null
      },
      {
        "id": "3a21d816-a9fc-4004-a5ab-c4f9c9fba3f1",
        "educator": null,
        "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
        "inst_name": "Pune university",
        "d_name": "Engineering",
        "e_type": "Full Time",
        "f_date": "2022-04-13",
        "t_date": "2022-09-13",
        "is_ongoing": false,
        "desc": "<p>dctrfyt fgtiyi yi;uiu tgyh'o8uo 8y98u0'9ou0u.</p>",
        "c_date": "2022-08-12 23:40:32.0",
        "u_date": "2022-08-12 23:41:19.0",
        "ht_qual": true,
        "uid": null
    }
    ]

    const experiences = [
    {
      "id": "0473e74b-40b2-44bf-91c3-e68823e71137",
      "educator": null,
      "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
      "inst_name": "Pune university.",
      "designation": "Teacher",
      "e_type": "Full Time",
      "f_date": "2019-07-20",
      "t_date": "2022-07-29",
      "is_current": false,
      "desc": "<p>yfyug 7y98u 8uu9i y98u0u09.</p>",
      "c_date": "2022-08-12 23:43:17.0",
      "u_date": "2022-08-12 23:43:17.0",
      "uid": null
    },
    {
        "id": "0473e74b-40b2-44bf-91c3-e68823e71137",
        "educator": null,
        "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
        "inst_name": "Pune university.",
        "designation": "Teacher",
        "e_type": "Full Time",
        "f_date": "2019-07-20",
        "t_date": "2022-07-29",
        "is_current": false,
        "desc": "<p>yfyug 7y98u 8uu9i y98u0u09.</p>",
        "c_date": "2022-08-12 23:43:17.0",
        "u_date": "2022-08-12 23:43:17.0",
        "uid": null
      }
  ]

  const achievements= [
    {
        "id": "3a3e9d2f-c395-4664-9a92-119abb54908d",
        "inst_prof": null,
        "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
        "name": "njkkllkkl;l",
        "year": "2022-01-19",
        "awarded_by": "lknbn",
        "desc": "<p>Details</p>",
        "c_date": "2022-01-14 17:29:10.0",
        "u_date": "2022-05-24 12:41:06.0",
        "uid": null,
        "files": null
    },
    {
        "id": "72a6388f-0500-49e3-8e75-0588a0dfb9a9",
        "inst_prof": null,
        "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
        "name": "Acvjfjkdfjk",
        "year": "2022-01-28",
        "awarded_by": "lkjhgfcdx",
        "desc": "",
        "c_date": "2022-01-14 17:23:29.0",
        "u_date": "2022-01-14 17:23:29.0",
        "uid": null,
        "files": null
    },
    {
        "id": "855e823e-7c33-4e22-9cec-4a4f4e742efe",
        "inst_prof": null,
        "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
        "name": "named",
        "year": "2015-01-27",
        "awarded_by": "head",
        "desc": "<p>vmcvmnvcmvcv</p>",
        "c_date": "2022-01-14 17:37:58.0",
        "u_date": "2022-01-14 17:37:58.0",
        "uid": null,
        "files": null
    },
    {
        "id": "f42d7a6e-7a1a-4334-b6eb-bc56ea128aba",
        "inst_prof": null,
        "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
        "name": "Certification",
        "year": "2022-05-24",
        "awarded_by": "Free Code Map",
        "desc": "<p>Coding Camp</p>",
        "c_date": "2022-05-24 12:36:02.0",
        "u_date": "2022-05-24 12:36:02.0",
        "uid": null,
        "files": null
    }
]

const TeacherModal = ({open, handleCloseClick, info, detailedInfo}) => {
  const { t } = useTranslation()
  const {edu_achv, edu_qual, edu_exp} = detailedInfo

  const transformAchievements = (ach) => {
   let achievements = []
   ach?.forEach((item) => achievements.push({file: item.file, name: item.title, year: item.c_date, awarded_by: item.awarded_by}))
    return achievements
  } 

  const renderTeacherInfo = (info) => {
    const { e_name, ph_url, high_qual, exp, sub_names } = info;
    return (
      <div className="info-section">
        <div className="seperator"></div>
        <div className="img-container"><img src={ph_url} className='profile-img' /></div>
        <div className="teacher-info">
          <div className="item"><p className="title">{t('name')}</p><p className="value">{e_name}</p></div>
          <div className="item"><p className="title">{t('experience')}</p><p className="value">{exp}</p></div>
          <div className="item"><p className="title">{t('subject')}</p><p className="value">{short(sub_names, 1)}</p></div>
          <div className="item"><p className="title">{t('qualification')}</p><p className="value">{high_qual}</p></div>
        </div>
      </div>
    )
  }

  return (
    <Modal open={open}>
      <Box sx={style}>
      <header className="teacher-header"></header>
      <AiOutlineClose className="close" onClick={() => handleCloseClick()} />
      <section className="career-sec">
        {renderTeacherInfo(info)}
        <Qualification qualifications={edu_qual} />
        <Experience experiences={edu_exp} />
        <div className="padding-20">
        <h2 className="ach-heading">{t('achievements')}</h2>
         <Achievements achievements={transformAchievements(edu_achv)} insideModal={true} />
        </div>
      </section>
      </Box>
    </Modal>
  );
};

export default TeacherModal;
