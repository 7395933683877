import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu, skeletonDetailsImages } from '../../../components/Skeleton/skeleton';

export default function Director(props) {
    const { t } = useTranslation()
    const { dir_li, isLoading } = props
    // const dir_li = [
    //   {
    //     c_date: '2022-08-10 05:52:52.0',
    //     d_msg: 'newndn',
    //     d_nm: 'nbndbdbn',
    //     i_p_id: '8041daeb-a313-4e42-9939-690fd1b108de',
    //     id: 'a9ff13b1-3626-444e-b0c6-8c81a9550ab7',
    //     ph_url:
    //       'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile/Final_Testing/director/nbndbdbn/photo/icons8-clock-24.png',
    //     u_date: '2022-08-10 05:52:52.0',
    //     uid: null,
    //   },
    //   {
    //     c_date: '2022-08-10 05:52:52.0',
    //     d_msg: 'newndn',
    //     d_nm: 'nbndbdbn',
    //     i_p_id: '8041daeb-a313-4e42-9939-690fd1b108de',
    //     id: 'a9ff13b1-3626-444e-b0c6-8c81a9550ab7',
    //     ph_url:
    //       'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/instituteProfile/Final_Testing/director/nbndbdbn/photo/icons8-clock-24.png',
    //     u_date: '2022-08-10 05:52:52.0',
    //     uid: null,
    //   },
    // ]
    if (isLoading) {
        return (
            <>
            {skeletonDetailsEdu()}
            </>
        )
      }
    return (
        <div className="detail-container">
            <h2>{t("director_msg")}</h2>
            {Array.isArray(dir_li) && dir_li.length !== 0 ? (
                dir_li.map((obj, index) => {
                    const { d_msg, d_nm, ph_url } = obj
                    return (
                        <Fragment key={index}>
                            <p>{d_msg}</p>

                            <div className="flex-row details-dmsg">
                                <img src={ph_url}/>
                                <div>
                                    <h4>{d_nm}</h4>
                                    <p>{`CEO & Director`}</p>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px', marginBottom: '10px', height: '2px', borderBottom: 'solid #4d4d55 0.5px'}}/>
                        </Fragment>
                    )
                })
            ) : (
                <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>
            )}
        </div>
    )
}
