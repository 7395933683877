import { useTranslation } from 'react-i18next';
import { details } from '../../../assets/images'
import { skeletonDetailsEdu } from "../../../components/Skeleton/skeleton"

const Experience = (props) => {
  // const { experiences } = props
  const { isLoading, experiences } = props
  const { t } = useTranslation()
  //   const isLoading = useSelector((state) => state.details?.isLoadingContentEdu)
  // const isLoading = true

  // const experiences = [
  //   {
  //     "id": "0473e74b-40b2-44bf-91c3-e68823e71137",
  //     "educator": null,
  //     "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
  //     "inst_name": "Pune university.",
  //     "designation": "Teacher",
  //     "e_type": "Full Time",
  //     "f_date": "2019-07-20",
  //     "t_date": "2022-07-29",
  //     "is_current": false,
  //     "desc": "<p>yfyug 7y98u 8uu9i y98u0u09.</p>",
  //     "c_date": "2022-08-12 23:43:17.0",
  //     "u_date": "2022-08-12 23:43:17.0",
  //     "uid": null
  //   },
  //   {
  //     "id": "0473e74b-40b2-44bf-91c3-e68823e71137",
  //     "educator": null,
  //     "e_id": "4f34570e-8ffd-4c89-9a72-3874f91662fc",
  //     "inst_name": "Pune university.",
  //     "designation": "Teacher",
  //     "e_type": "Full Time",
  //     "f_date": "2019-07-20",
  //     "t_date": "2022-07-29",
  //     "is_current": false,
  //     "desc": "<p>yfyug 7y98u 8uu9i y98u0u09.</p>",
  //     "c_date": "2022-08-12 23:43:17.0",
  //     "u_date": "2022-08-12 23:43:17.0",
  //     "uid": null
  //   }
  // ]
   if (isLoading) {
     return (
         <>
         {skeletonDetailsEdu()}
         </>
     )
   }
  return (
    <div className="detail-container">
      <h2>{t('experience')}</h2>

      {Array.isArray(experiences) && experiences.length !== 0 ? experiences.map((obj, index) => {
        const { f_date, t_date, desc, e_type, inst_name, designation } = obj

        const calcDateDiff = (a, b) => {
          let d1 = new Date(a)
          let d2 = b ? new Date(b) : new Date()
          let m = Math.round((d2 - d1) / (1000 * 60 * 60 * 24 * 30))

          let mDec =
            Math.round(((d2 - d1) / (1000 * 60 * 60 * 24 * 30)) * 10) / 10

          if (mDec <= 0.6) return '0.5 Months'

          if (m < 12) return `${m} Months`

          if (m % 12 === 0) return `${m / 12} Years`

          return `${Math.floor(m / 12)} Years & ${m % 12} Months`
        }

        return (
          <div className="details-experience" key={index}>
            <div className="left-div">
              <h4>
                {[
                  f_date &&
                    new Date(f_date).toLocaleString('default', {
                      month: 'short',
                      year: '2-digit',
                    }),
                  t_date
                    ? new Date(t_date).toLocaleString('default', {
                        month: 'short',
                        year: '2-digit',
                      })
                    : 'present',
                ].join(' - ')}
              </h4>
              <p>{calcDateDiff(f_date, t_date)}</p>
            </div>
            <div className="middle-div">
              <img src={details.red_Circle} />
              {index!==experiences.length-1 && <div className="sline"></div>}
            </div>
            <div className="right-div">
              <h4>{designation}</h4>
              <div className="flex-row detdiv">
                <h6>{inst_name}</h6>
                <div className="separator"></div>
                <h6>{e_type}</h6>
              </div>
              <p className="descr">{desc?.replace(/<[^>]*>?/gm, '')}</p>
            </div>
          </div>
        )
      }) : <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>}
    </div>
  )
}

export default Experience
