import { useFormik } from 'formik'
import api, { errorMsg } from '../../api/api'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import './contact.css'
import { contact } from '../../assets/images'
import { APIURL } from '../../api/url'
import { useTranslation } from 'react-i18next';

const Form = (props) => {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      nm: '',
      ph_no: '',
      em_id: '',
      msg: '',
    },
    validationSchema: Yup.object({
      nm: Yup.string().max(20, t('char_limit')).required(t('name_req')),
      ph_no: Yup.string()
        .required(t('phn_req'))
        .matches(/^[0-9]+$/, t('only_digits'))
        .min(10, t('ten_digits'))
        .max(10, t('ten_digits')),
      em_id: Yup.string()
        .email(t('invalid_email'))
        .matches(
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
          t('invalid_email')
        ),
      msg: Yup.string().required(t('msg_req')),
    }),
    onSubmit: (values, { resetForm }) => {
      api
        .post({ url: APIURL.contactUs, data: values })
        .then((res) => toast.success(res.data.message))
        .catch((err) => toast.error(errorMsg(err)))
        .finally(() => resetForm(formik.initialValues))
    },
  })
  const { getFieldProps, handleSubmit, errors, touched } = formik
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-container">
        <div className="form-group">
          <input name="nm" type="text" placeholder={`${t('name_hint')} *`}  {...getFieldProps('nm')} />
        </div>
        {touched.nm && errors.nm ? <div className="err-msg">*{errors.nm}</div> : null}

        <div className="form-group">
          <input name="ph_no" type={'tel'} placeholder={`${t('phone_hint')} *`} {...getFieldProps('ph_no')} maxLength={10}/>
        </div>
        {touched.ph_no && errors.ph_no ? <div className="err-msg">*{errors.ph_no}</div> : null}

        <div className="form-group">
          <input name="em_id" type={'email'} placeholder={`${t('email_hint')} *`} {...getFieldProps('em_id')} />
        </div>
        {touched.em_id && errors.em_id ? <div className="err-msg">*{errors.em_id}</div> : null}

        <div className="form-group">
          <textarea name="msg" type="text" placeholder={t("write_msg")} {...getFieldProps('msg')} />
        </div>
        {touched.msg && errors.msg ? <div className="err-msg" style={{margin:1}}>*{errors.msg}</div> : null}

        <div className="send-div">
          <button type={'submit'}>
          {t("send")}&nbsp;&nbsp;
            <img src={contact.next_arrow} />
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
