import { memo } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

const data = [
  {
    id: '034b0c6b-633a-457e-889f-f7517002c3d0',
    crs_nm: '.NET',
    file_url:
      'https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/masterCourse/icon/.NET/img1.jpg',
    sub_prof_id: '132aed08-56f0-493b-b97a-40a80babb6c8',
    sub_prof_nm: 'Persue Hobby',
  },
]
const Card = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { obj, index } = props
    const {id, crs_nm, file_url, sub_prof_id, sub_prof_nm} = obj
    return (
      <div
        key={index}
        className="slider-container"
        style={{ marginTop: index % 2 === 0 ? 0 : 60 }}
      >
        <div className="category-card-container" onClick={async()=>{
          history.push(`/listing/${sub_prof_nm.toLowerCase().replace(/ /g, '-')}?course=${crs_nm}`)
          
        }}>
          {/* <div key={id} className="category-img-container" style={{ backgroundImage: `url(${ph_url})`, backgroundSize: 'cover', backgroundPosition: 'center center', }}/> */}
          <img
            src={file_url}
            className="category-img-container"
            style={{
              backgroundImage: `url(${encodeURI(file_url)
                .replace(/\(/g, "%28")
                .replace(/\)/g, "%29")})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              cursor:'pointer'
            }}
          />
          <div className="category-content">
            <p className="category-text">{crs_nm}&nbsp;</p>
          </div>
        </div>
      </div>
    );
}

export default memo(Card)