import { contact } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import Form from './form'

const ContactUs = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className="contact-container">
                <div className='contact-header'>
                    <img src={contact.contact} />
                    <div className="contact-title-content">
                        <h4>{t("contact_us")}</h4>
                        <h5>{t("feel_free_to_reach_us_for_feedback_or_enquire")}</h5>
                    </div>

                </div>
                <div className='contact-parent-container'>
                    <h3>{t("for_parents")}</h3>
                </div>
                <Form />


            </div>
        </>
    )
}

export default ContactUs