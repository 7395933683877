import React from "react";

const ToolTipChild = React.forwardRef((props, ref) => {
    //  Spread the props to the underlying DOM element.
    return (
      <div {...props} ref={ref} style={{cursor: "pointer"}}>
            {props.children}
      </div>
    );
  });

  export default ToolTipChild