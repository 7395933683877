import React from "react";
import { components } from '../../assets/images'
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

export default function ProfileMenu(props) {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    return (
        <div className="profile-menu">
            <div className="flex-row">
                <img src={components.profile_interest} />
                <Link to="/student/profile" style={{marginLeft:'20px',color: '#283B4C'}}>{t('my_profile')}</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row" >
                <img src={components.profile_wish}/>
                <Link to="/student/wishlist" style={{marginLeft:'20px',color: '#283B4C'}}>{t('my_wish')}</Link>
                {/* <p>My Wishlist</p> */}
            </div>

            <div className="hline"/>

            <div className="flex-row" >
                <img src={components.profile_enq}/>
                <Link to="/student/enquirey" style={{marginLeft:'20px',color: '#283B4C'}}>{t('my_enq')}</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row" >
                <img src={components.profile_rate}/>
                <Link to="/student/review" style={{marginLeft:'20px',color: '#283B4C'}}>{t('my_rating')}</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row" >
                <img src={components.profile_notepad}/>
                <Link to="/student/applications" style={{marginLeft:'20px',color: '#283B4C'}}>{t('my_apps')}</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row" >
                <img src={components.profile_setting}/>
                <Link to="/student/settings" style={{marginLeft:'20px',color: '#283B4C'}}>{t('setting')}</Link>
            </div>
            <div className="hline"/>

            <div className="flex-row" style={{cursor:"pointer"}} onClick={()=>{
                dispatch({type:"LOGOUT"})
                toast.success('You have successfully Logged out')
                history.push('/')
                }} >
                <img src={components.profile_setting}/>
                <div style={{marginLeft:'20px',color: '#283B4C',}}>Logout</div>
            </div>
{/*             
            <div className="hline"/>

            <div className="flex-row">
                <img src={Rate}/>
                <Link to="/student/settings" style={{marginLeft:'20px',color: '#283B4C'}}>Rate Us</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row">
                <img src={Refer}/>
                <Link to="/student/settings" style={{marginLeft:'20px',color: '#283B4C'}}>Refer a friend</Link>
            </div>

            <div className="hline"/>

            <div className="flex-row">
                <img src={Feedback}/>
                <Link to="/student/settings" style={{marginLeft:'20px',color: '#283B4C'}}>Feedback</Link>
            </div>

            <div className="hline"/>
            
            <div className="flex-row">
                <img src={Contus}/>
                <Link to="/student/settings" style={{marginLeft:'20px',color: '#283B4C'}}>Contact us</Link>
            </div> */}
        </div>
    );
}



