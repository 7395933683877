import { Box, Modal } from '@mui/material'
import { CgCloseR } from 'react-icons/cg'
import { FiSearch } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

const FilterModalMUI = ({
  
  showModal,
  setShowModal,
  keyName,
  filterDataPopup,
  onChange,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedFilters = useSelector((state) => state.listing?.selectedFilters)
  return (
    <Modal
      open={showModal}
      // onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          width: '60%',
          maxHeight: '65vh',
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          boxShadow: 24,
          // p: 4,
        }}
      >
        <div className='fmodal-input-header' >
          <div  className='fmodal-input-content' >
            <input
              type={'text'}
              placeholder={t('search')}
              style={{ border: '0px' }}
            />
            <FiSearch size={18} color={'#6B6B6B'} style={{margin:10}} />
          </div>
          <div style={{ marginRight: 10 }}  >
            <CgCloseR
              color="#6B6B6B"
              size={25}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
        <div style={{ border: '0.5px solid rgba(0, 0, 0, 0.38)' }} />
        <div  className='fmodal-data-group'
        >
          {filterDataPopup &&
            filterDataPopup.map((item, index) => {
              return (
                // inline CSS Used here
                <div className="filter-option flex-row" key={item.id}>
                  <div className="flex-row" style={{width:'100%'}}>
                    <label style={{ display: 'flex', alignItems: 'baseline', width:'100%'  }}>
                      <input
                        type="checkbox"
                        style={{
                          marginInlineEnd: '10px',
                          alignSelf: 'center',
                        }}
                        onChange={(e) => {
                          dispatch({
                            type: 'UPDATE_SELECTED_FILTERS',
                            updatedFilter: {
                              keyName,
                              value: item.id,
                            },
                          })
                        }}
                        checked={selectedFilters[keyName].includes(item.id)}
                      />
                      <p
                        style={{
                          // background:'blue',
                          width:'100%',
                          "overflow": "hidden",
                          "inlineSize": "90%",
                          "whiteSpace": "nowrap",
                          "textOverflow": "ellipsis"
                        }}
                      >
                        {item.name}
                      </p>
                    </label>
                  </div>
                </div>
              )
            })}
        </div>
      </Box>
    </Modal>
  )
}
export default FilterModalMUI