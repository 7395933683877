import React, { useRef } from "react";
import { student } from '../../../assets/images'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";





export default function OnlineForm(props) {
    // const receiptTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4",);
        const data = await html2canvas(document.querySelector("#receiptTemplateRef"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("onlineAdmission.pdf");
    };

    return (
        <>
            <div className="modal-container" >
                <div style={{ background: ' #FFFFFF', borderRadius: '8px', marginTop: "400px", marginBottom: "50px" }}>
                    <div style={{ background: 'linear-gradient(90deg, #04498A 19.01%, #52C8E6 94.66%)', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <label className="formHeading">Ackowledgement Receipt</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={student.download} style={{ width: '15px', height: "15px" }} onClick={() => handleGeneratePdf()}></img>
                            <img src={student.cr} style={{ width: '15px', height: "15px", marginLeft: '10px' }} onClick={()=>props.setForm(false)}></img>
                        </div>
                    </div>

                    <div id="receiptTemplateRef">
                        <div style={{ display: 'flex', flexDirection: 'row', background: ' #E7F2FF', justifyContent: 'space-between', padding: '10px' }}>
                            <img src={student.logo} style={{ width: '120px', height: '120px' }}></img>
                            <div>
                                <div className="StudeeHeading">Studde</div>
                                <div className="addressHeading">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</div>
                                <div className="addressHeading">Email : info@yourschoolname.com</div>
                                <div className="addressHeading">Ph: +91 9123456780</div>
                            </div>
                            <div className="Reciept" style={{ display: 'inline-block', alignSelf: 'flex-end' }}>Acknowledgement Receipt</div>

                        </div>

                        <div style={{ padding: '10px' }}>
                            <div className="Appliedto" style={{ marginBottom: '8px' }}><div style={{ background: '#4274B0', width: '3px', height: '20px', marginRight: '10px' }}></div>Applied To</div>
                            <div className="InstName" > School/Institute Name<label className="SchoolName">KCS School</label></div>
                            <div className="InstName"> Form Fee<label className="SchoolName">700(Rs) (Paid)</label></div>
                        </div>

                        <div style={{ padding: "10px" }}>
                            <div style={{ border: "1px solid #C4C4C4", borderRadius: "5px", marginBottom: '10px' }}>
                                <div className="Details" style={{ background: ' #4274B0', padding: '5px' }}>Student Details</div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px"}}>
                                    <div className="InstName" style={{width:"30%"}}>Student Name </div>
                                    <div className="SchoolName" style={{width:"50%"}}>Suresh Raina</div>
                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px", justifyContent: 'space-between' }}>
                                    <div className="InstName" style={{width:"30%"}} >Mobile Number </div>
                                    <div className="SchoolName" style={{width:"30%"}}>9876543210</div>

                                    <div className="InstName"  style={{width:"20%"}}>Email </div>
                                    <div className="SchoolName" style={{width:"20%"}}>Suresh@Raina.com</div>
                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>Adhaar Number</div>
                                    <div className="SchoolName" style={{width:"50%"}}>11222121212</div></div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between' }}>
                                    <div className="InstName" style={{width:"30%"}}>Nation </div>
                                    <div className="SchoolName" style={{width:"30%"}}>India</div>

                                    <div className="InstName" style={{width:"20%"}}>Relegion </div>
                                    <div className="SchoolName" style={{width:"20%"}}>Indian</div>
                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>Course</div>
                                    <div className="SchoolName" style={{width:"50%"}}>Suresh Raina</div></div>


                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>DofB </div>
                                    <div className="SchoolName"style={{width:"30%"}}>27-Nov-9878</div>

                                    <div className="InstName" style={{width:"20%"}}>Gender </div>
                                    <div className="SchoolName" style={{width:"20%"}}>Male</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>Mother Tounge</div>
                                    <div className="SchoolName" style={{width:"30%"}}>Hindi</div>


                                    <div className="InstName" style={{width:"20%"}}>Blood Group </div>
                                    <div className="SchoolName" style={{width:"20%"}}>o+</div>
                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>Address</div>
                                    <div className="SchoolName" style={{width:"50%"}}>Suresh Raina</div></div>

                            </div>

                            <div style={{ border: "1px solid #C4C4C4", borderRadius: "5px", marginBottom: '10px' }}>
                                <div className="Details" style={{ background: ' #4274B0', padding: '5px' }}>Parent Details</div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName"  style={{width:"30%"}}>Parent Name</div>
                                    <div className="SchoolName"  style={{width:"50%"}}>Suresh Raina</div></div>

                                <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between', padding: "5px" }}>
                                    <div className="InstName"  style={{width:"30%"}}>Mobile Number </div>
                                    <div className="SchoolName"  style={{width:"30%"}}>9876543210</div>
                                    <div className="InstName"  style={{width:"20%"}}>Email </div>
                                    <div className="SchoolName"  style={{width:"20%"}}>Suresh@Raina.com</div>
                                </div>
                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row',padding: "5px" }}>
                                    <div className="InstName"  style={{width:"30%"}}>Relationship with Applicant</div>
                                    <div className="SchoolName"  style={{width:"50%"}}>11222121212</div></div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName"  style={{width:"30%"}}>Occupation</div>
                                    <div className="SchoolName" style={{width:"50%"}}>Suresh Raina</div></div>
                            </div>

                            <div style={{ border: "1px solid #C4C4C4", borderRadius: "5px", marginBottom: '10px' }}>
                                <div className="Details" style={{ background: ' #4274B0', padding: '5px' }}>Educational Details</div>
                                <div style={{ borderBottom: "1px solid #C4C4C4", padding: "5px", display: 'flex', flexDirection: 'row' }}>
                                    <div className="InstName" style={{width:"30%"}}>Current Educational Level </div>
                                    <div className="SchoolName" style={{width:"50%"}}>Bachlore in mech</div>
                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", padding: "5px", display: 'flex', flexDirection: 'row', padding: "5px" }}>
                                    <div className="InstName" style={{width:"30%"}}>Message for School</div>
                                    <div className="SchoolName" style={{width:"50%"}}>Please accept my request</div>
                                </div>
                            </div>

                            <div style={{ border: "1px solid #C4C4C4", borderRadius: "5px", marginBottom: '10px' }}>
                                <div className="Details" style={{ background: ' #4274B0', padding: '5px' }}>Educational Details</div>
                                <div >
                                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between' }}>
                                        <div className="InstName" style={{width:"30%"}}>Aadhaar Card</div>
                                        <div className="SchoolName" style={{width:"30%"}}>Yes</div>
                                        <div className="InstName" style={{width:"20%"}}>Address Proof </div>
                                        <div className="SchoolName" style={{width:"20%"}}>Yes</div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #C4C4C4", padding: "5px", justifyContent: 'space-between' }}>
                                        <div className="InstName" style={{width:"30%"}}>Birth Certificate </div>
                                        <div className="SchoolName" style={{width:"30%"}}>Yes</div>
                                        <div className="InstName" style={{width:"20%"}}>Education Proof</div>
                                        <div className="SchoolName" style={{width:"20%"}}>Yes</div>
                                    </div>

                                  

                                </div>
                            </div>
                        </div>




                        <div className="InstName" style={{ background: ' #E7F2FF', display: 'flex', justifyContent: 'center', padding: '5px' }}>Note:This is fee form admission application only. We do not assure you any guarantee of admission.</div>
                        <div className="Powered">Powered by Proctur</div>
                    </div>


                </div>
            </div>
        </>
    )
}