import { useState } from 'react'
import { ToastContainer } from 'react-toastify';
import '../../components/Signin/signin.css'
import { apply } from '../../assets/images'
import './completepopup.css'
import { useHistory } from 'react-router-dom';



export default function CompletePopup() {
    const history = useHistory()

    return (
        <div className="modal-container">
            <div style={{ background: ' #FFFFFF', borderRadius: '8px', width: '400px' }}>
                <div className="changedivv" >
                    <div style={{display: 'flex', justifyContent: 'center',left: '40%',position: 'absolute' ,top:'-25px'}}>
                        <img src={apply.check} style={{ width: '70px', height: '70px' }}></img>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop: '40px' }}>
                        <img src={apply.mainImg} style={{ width: '180px', height: '150px' }}></img>
                    </div>
                    <div className='ThankYou' style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:'20px' }}>
                        Thank You !
                    </div>
                    <div className='Congo' style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:'20px' }}>
                        Congrats, your application has been
                        submitted successfully.
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:'20px' }}>
                        <button className='buttonView' onClick={()=>history.push(`/student/applications`)}>View My Application</button>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:'20px',marginBottom:'20px' }}>
                        <button className='buttonHomePage' onClick={()=>history.push(`/home/`)}>Go to Home Page</button>
                    </div>

                </div>


            </div>
        </div>
    )
}
