import { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { FaHeart } from 'react-icons/fa'
import { details } from '../../assets/images'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getDistance } from '../../utils/utils'
import EnquiryPopup from '../../components/EnquiryPopup'
import { Skeleton } from '@mui/material'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import { addWishList, removeWishList } from '../wishlist'

const ProfileSection = ({ profileDetails, profileType }) => {
  const { lat: myLat, long: myLong } = useSelector((state) => state.location.location_det)
  const { wishListEdu, wishListInst, userData } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    e_name,
    isVerified,
    view_cnt,
    avg_rating,
    review_cnt,
    ph_url,
    area_nm,
    ct_nm,
    lat,
    long,
    id,
  } = profileDetails
  const [enquiry, setEnquiry] = useState('')
  const isLoading = useSelector((state) => state.details?.isLoading)
  // const isLoading=true
  const wish = wishListEdu?.find((x) => x.entity_id === id) || wishListInst?.find((x) => x.entity_id === id)

  return (
    <div className="details-title-section flex-row">
      <div className="profile-main">
        {isLoading ? (
          <Skeleton variant="circular" style={{ width: '150px', height: 150 }} />
        ) : (
          <img src={ph_url || details.default_profile_edu} />
        )}
      </div>
      <div className="profile-main-content">
        <div className="flex-row">
          {isLoading ? (
            <Skeleton variant="text" style={{ width: '15em', height: '3em', maxWidth: '90vw' }} />
          ) : (
            <h3 style={{ textTransform: 'capitalize' }}>{e_name?.toLowerCase()}</h3>
          )}
          {isVerified && (
            <img
              src={details.badge}
              style={{
                width: 24,
                height: 30,
                marginLeft: 20,
                objectFit: 'contain',
              }}
            />
          )}
        </div>
        <div className="flex-row location">
          <img src={details.location2} style={{ width: 16, marginRight: 8 }} />
          {isLoading ? (
            <Skeleton variant={'text'} style={{ width: '8em', maxWidth: '35vw' }} />
          ) : (
            [area_nm, ct_nm].filter((a) => a).join(', ') || '-'
          )}
          <div className="separator" />
          <div className="flex-row">
            <img src={details.km_away} style={{ width: 18, height: 18, marginRight: 8 }} />
            {isLoading ? (
              <Skeleton variant={'text'} style={{ width: '8em', maxWidth: '35vw' }} />
            ) : (
              <p>{getDistance(myLat, myLong, lat, long, 'K')}Km Away</p>
            )}
          </div>
        </div>
      </div>
      <div className="title-compare">
        <div className="flex-row view-rating">
          <div className="flex-row view">
            <img src={details.eye} style={{ width: 17.5, height: 12.5, marginRight: 10 }} />
            {view_cnt}
          </div>
          <div className="detail-rating flex-row">
            <Rating
              // onClick={handleRating}
              iconsCount={5}
              initialValue={avg_rating}
              ratingValue={0}
              size={20}
              readonly={true}
              style={{ marginTop: 3 }}
            />
            <div className="flex-row">
              &nbsp;<p style={{ color: '#707070' }}>|&nbsp;</p> {review_cnt} {t('reviews')}
            </div>
          </div>
        </div>

        <div className="flex-row">
          <div
            className="flex-row enquire-now"
            onClick={() => {
              Object.keys(userData ?? {}).length
                ? setEnquiry(id)
                : dispatch({ type: 'SHOW_SIGN_IN', value: true })
            }}
          >
            <img src={details.phone} style={{ width: 16, height: 16, marginRight: 10 }} />
            {t('enquire now')}
          </div>
          <div
            className="shortlist-div 
            -row"
            onClick={() => {
              wish
                ? removeWishList(
                    { wishListEdu, wishListInst, userData },
                    profileType,
                    dispatch,
                    wish
                  )
                : addWishList({ wishListEdu, wishListInst, userData }, profileType, dispatch, id)
            }}
          >
            <FaHeart size={15} color={wish ? '#FD6C73' : '#C8C8C8'} />
            {/* <img src={heart} style={{ width: 20, height: 15 }} /> */}
          </div>
        </div>
      </div>
      {enquiry && <EnquiryPopup inst_id={enquiry} close={() => setEnquiry('')} />}
    </div>
  )
}

export default ProfileSection
