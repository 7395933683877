import { Box, Modal } from "@mui/material";
import { useState, useEffect } from "react";
import SliderGallery from "../../../components/SliderGalley";
import "./GalleryPopup.scss";
import { details } from "../../../assets/images";
import { BsArrowLeft } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { getYTVideoThumbnail } from "../../../utils/utils";

export default function GalleryPopup({
  open,
  handleClose,
  image,
  video,
  itemIndex,
  setItemId,
  isImage,
  setIsImage,
}) {
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "87%",
    width: "100%",
    maxWidth: "1171px",
    bgcolor: "background.paper",
    boxShadow: 8,
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    fontFamily: "Poppins,Lato",
    overflow: "hidden",
  };

  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    if (itemIndex) {
      setSelectedItem(itemIndex);
    }
  }, [itemIndex]);

  const handleImgClick = (id) => {
    setSelectedItem(id);
  };

  const handleCloseClick = () => {
    setSelectedItem("");
    setItemId("");
    handleClose();
  };

  const handleBack = () => {
    setSelectedItem("");
    setItemId("");
  };

  const renderGalleryView = () => {
    return (
      <>
        <div className="image-vid-selector">
          <div className={isImage ? "selected-category" : ""} onClick={() => setIsImage(true)}>
            <span className="popup-label">{t("images")}</span>
          </div>
          <div className={!isImage ? "selected-category" : ""} onClick={() => setIsImage(false)}>
            <span className="popup-label">{t("videos")}</span>
          </div>
        </div>
        <div className={"gallery-wrapper"}>
          {isImage
            ? image?.map((item, index) => (
                <div className="container" onClick={() => handleImgClick(index.toString())}  key={index}>
                  <img
                  src={item}
                  className="gallery-img"
                />
                <div className="overlay"><p className="text">{item.split('/').reverse()?.[0]}</p></div>
                </div>
              ))
            : video?.map((item, index) => (
                <div className="thumbnail-wrapper"  key={index}>
                  <img className="thumbnail-img" src={getYTVideoThumbnail(item)} />
                  <img
                    className="play-button"
                    src={details.play_button}
                    onClick={() => handleImgClick(index.toString())}
                  />
                </div>
              ))}
        </div>
      </>
    );
  };

  const renderSliderView = () => {
    return (
      <>
        <div className="heading-section">
          <span className="heading">
            {isImage ? t("images") : t("videos")}
          </span>
        </div>
        <SliderGallery
          list={isImage ? image : video}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          isImage={isImage}
        />
      </>
    );
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <header className="gallery-popup-header">
          {selectedItem && (
            <BsArrowLeft color="#6B6B6B" size={18} className="back" onClick={() => handleBack()} />
          )}
          <div className="margin">
            <span className="gallery">{t("gallery")}</span>{" "}
            {t("showcase")}
          </div>
          <img className="close" src={details.gallery_close} onClick={() => handleCloseClick()} />
        </header>
        {/* On click of item open slider view else open gallery */}
        {!selectedItem && !itemIndex ? renderGalleryView() : renderSliderView()}
      </Box>
    </Modal>
  );
}
