import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next';
import Card from './cardItem'

import { home } from '../../assets/images'

const Category = ({ categories }) => {
  const { t } = useTranslation()
  // useEffect(() => {
  //     window.scrollTo({ top: 0, behavior: 'smooth' })
  // });

  const PrevBtn = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        style={{ boxShadow: 'inset 3px 5px 10px 2px rgba(84, 80, 80, 0.45)', marginTop: -70 }}
        onClick={onClick}
      >
        <img src={home.left_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  const NextBtn = (props) => {
    const { className, onClick } = props
    return (
      <div
        className={className}
        style={{ boxShadow: 'inset 3px 5px 10px 2px rgba(84, 80, 80, 0.45)', marginTop: -70 }}
        onClick={onClick}
      >
        <img src={home.right_icon} style={{ width: '6px', height: '10px' }} />
      </div>
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    className: 'slick-list1',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 8,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 6.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },

      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
  }

  return (
    <div className="category-container">
      <div className='ml-30'>
        <h3>{t('features')}</h3>
      </div>

      <div
      className='category-class'
        style={{
          backgroundImage: `url(${encodeURI(home.category_bg).replace(/\(/g, '%28').replace(/\)/g, '%29')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          width: '100%',
        }}
      >
        <div className="slider-parent-div">
          <Slider {...settings}>
            {Array.isArray(categories) ? (
              categories.length !== 0 ? (
                categories.map((obj, index) => <Card key={index} obj={obj} index={index} />)
              ) : (
                <h1>{t("no_data_available")}</h1>
              )
            ) : (
              <h1>{t("no_data_available")}</h1>
            )}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Category
