import { useEffect, useRef, useState } from 'react'
import HeaderCompare from './header'
import ContentCompare from './contentCompare'
import ScrollIndicator from '../../components/ScrollIndicator'
import HeadList from '../../components/Headlist/index'
import { useSelector } from 'react-redux'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import { toast } from 'react-toastify'
import './compare.css'

export default function Index() {
  
  const { selectedIds, profileType, subProfileType } = useSelector((state) => state.compare, (a,b)=> JSON.stringify(a) === JSON.stringify(b))
  const [compareData, setCompareData] = useState([])
  let selectedIdsRef = useRef()
  const selectedIdsPrevious = selectedIdsRef.current ?? selectedIds
  selectedIdsRef.current= selectedIds

  useEffect(() => {
    console.log(selectedIds, selectedIdsPrevious)
      if ((selectedIds?.length < selectedIdsPrevious?.length || selectedIdsPrevious === undefined)) {
        let updatedData = compareData?.filter((x) => selectedIds?.includes(x.id))
        Array.isArray(updatedData) && setCompareData(updatedData)
      } else {
        api
          .post({ url: APIURL.compare, data: selectedIds })
          .then(res => setCompareData(res.data.result))
          .catch(err => toast.error(errorMsg(err)))
      }
  }, [selectedIds])
  
    return (
        <div>
            <HeadList/>
            <div className="listing-heading-title" style={{paddingLeft: '25px'}}>
                <span>Home / </span> <span> Compare</span> 
            </div>
            <div className="compare-main-div">
                <div className="compare-child-div">
                    <HeaderCompare compareData={compareData} profileType={profileType} subProfileType={subProfileType?.toLowerCase()} selectedIds={selectedIds} />
                    <ContentCompare compareData={compareData} profileType={profileType} selectedIds={selectedIds}/>
                </div>
            </div>
            <ScrollIndicator/>
        </div>
    )
}
