import { components } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import '../EnquiryPopup/enquiryPopup.css'
import './offer.css'

const Offer = (props) => {
    const { t } = useTranslation()
    return (
        <div className="enquiry-popup-container" onClick={() => props.showOffer()}>
            <div className='offer-div'>

                <div className='offer-img-content'>
                    <img src={components.offer}/>
                    <p>{t('offers')}</p>
                    <p>{t('disclaimer')}</p>
                </div>

                <div className='offer-btn offer-img-content' >
                    <p>{t('explore')}</p>
                </div>

            </div>
        </div>
    )
}

export default Offer