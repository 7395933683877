import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from '../../assets/images'
import api from './../../api/api'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { APIURL } from '../../api/url'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Fragment } from 'react'
import { profileTypefromId } from '../../utils/utils'

export default function MultiLogin(props) {
  // const multiLogin = JSON.parse(localStorage.getItem('multilogin'))
  const multiLogin = useSelector(state => state.auth.multiLogin)
  
  let [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loginData = JSON.parse(localStorage.getItem('login'));
  const [value, setValue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  useEffect(() => {}, [])

  const onSubmit = () => {
      setLoading(true)
      let data=JSON.parse(value)
      api
        .post({ url: APIURL.multiLogin, data})
        .then((res) => {
            let userData = res.data.result || {}
            if (res.data.validate) {
                const { token, up_id, p_type } = userData
                localStorage.removeItem('login')
                dispatch({ type: 'SET_USER_DETAILS', data: { userData, token, up_id, p_type } })
                toast.success('Login successful')
                setTimeout(() => {
                  props.setShowsignin(false)
                }, 300)
              }
        })
        .catch((err) => {
          toast.error('Some error occured while logging in.')
        })
        .finally(() => setLoading(false))
    }

    

  return (
    <div className="signin">
      <h2>Multiple Accounts</h2>
      <h4>We have found multiple accounts</h4>
      <h4 style={{ marginTop: '10px' }}>Please Select a account to login</h4>
      <div className="flex-row" style={{ margin: 10 }}>
        <FormControl>
          <FormLabel id="Account-Options">Accounts</FormLabel>
          <RadioGroup
            aria-labelledby="Account-Options"
            name="Selected Acount"
            value={value}
            onChange={handleChange}
          >
            {multiLogin.map((obj, index) => {
              const { uid, name, type } = obj
              if (type === 'STAFF') {
                const { inst_type, roles } = obj
                return (
                  <Fragment key={uid}>
                    <Typography>
                      {name} - {inst_type}
                    </Typography>
                    
                    {roles.map((obj, index) => {
                      const { id:role_id, name: roleName } = obj
                      return (
                        <FormControlLabel key={role_id} value={JSON.stringify({role_id, uid})} control={<Radio />} label={`${roleName}`} />
                      )
                    })}
                  </Fragment>
                )
              }
              return (
                <FormControlLabel key={uid} value={JSON.stringify({uid})} control={<Radio />} label={`${name} - ${type}`} />
              )
            })}

          </RadioGroup>
        </FormControl>
      </div>
      {loginData?.is_new_user === true && <h4 style={{ marginTop: '10px', color:'red', cursor:'pointer' }} onClick={()=>props.setSelected('Registration')}>We haven't found any account regarding your selection do you want to create a account for {profileTypefromId(loginData?.type)}. Do you wish to Create it?</h4>}

      {loading ? (
        <div className="loginProgress">
          <img src={components.loader2} />
        </div>
      ) : (
        <button onClick={onSubmit} className="flex-row">
          {t('continue')} <img src={components.signin_arrow} />
        </button>
      )}

      <h4 className="inst">
        <a onClick={() => props.setSelected('AdminSignin')}>
          {t('are_you_institute')}{' '}
          <span>{t('click_here')}</span>
        </a>
      </h4>
    </div>
  )
}
