import { details } from '../../../assets/images'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function Scholarship() {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=> state.details?.lMngtAlumniSchl)
  const mngtAlumniSchl = useSelector((state) =>  state.details?.mngtAlumniSchl) ?? {}
  const { t } = useTranslation()
   const { scholarships } = mngtAlumniSchl 
   /*  const scholarships= [
      {
          "id": "76aaf21f-76e5-4860-a8d3-2c58e976fcc6",
          "inst_prof": null,
          "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
          "name": "Scholarship name",
          "desc": "description fo rscholarship, a very long description here, maybe a paragraph, maybe a sentence. description fo rscholarship, a very long description here, maybe a paragraph, maybe a sentence.",
          "criteria": "below 18",
          "c_date": "2022-08-19 16:45:08.0",
          "u_date": "2022-08-19 16:45:08.0",
          "uid": null
      },
      {
        "id": "76aaf21f-76e5-4860-a8d3-2c58e976fcc6",
        "inst_prof": null,
        "i_p_id": "e9cbc567-2f0e-417b-9b96-721933b87922",
        "name": "Scholarship name",
        "desc": "description fo rscholarship, a very long description here, maybe a paragraph, maybe a sentence. description fo rscholarship, a very long description here, maybe a paragraph, maybe a sentence.",
        "criteria": "below 18",
        "c_date": "2022-08-19 16:45:08.0",
        "u_date": "2022-08-19 16:45:08.0",
        "uid": null
      } 
    ] */

    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsEdu()}
          </>
      )
    }
    
  return (
    <div className="detail-container">
      <h2>{t("scholarship")}</h2>
      {Array.isArray(scholarships) && scholarships.length !== 0 ? (
        <>
          <h4 className="blueblock">Merits and Scolarships</h4>
          <div className="details-scholar">
            {scholarships.map((obj, index) => {
              const { name, desc } = obj
              return (
                <div key={index} className="flex-row">
                  <img src={details.scholarship} />
                  <div>
                    <h4>{name}</h4>
                    <p>{desc?.replace(/<[^>]*>?/gm, '')}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>
      )}
    </div>
  )
}
