import {useState} from 'react'
import Slider from './slider'
import { CgCloseR } from "react-icons/cg";
import { ToastContainer } from 'react-toastify';
import './signin.css'
import Login from './login'
import ValidateOtp from './validateOtp'
import AdminSignin from './adminsignin'
import Registration from './registration';
import MultiLogin from './multiLogin';

export default function Index({setShowsignin}) {
    // useEffect(() => {
    //   return () => {
    //     setShowsignin(false)
    //   }
    // })
    
    const [selected, setSelected] = useState('Login')
    return (
        <div className="modal-container">
            <div className="signin-div flex-row">
            <div className="close">
                    <CgCloseR color='#6B6B6B' size={25} onClick={() =>  setShowsignin(false)} /></div>
                <div className="signin-left-div"><Slider /></div>
                <div className="signin-right-div">
                    
                    {selected === 'Login' && <div><Login setSelected={setSelected} /></div>}
                    {selected === 'ValidateOtp' && <div><ValidateOtp setSelected={setSelected} setShowsignin={setShowsignin} /></div>}
                    {selected === 'AdminSignin' && <div><AdminSignin setSelected={setSelected} setShowsignin={setShowsignin} /></div>}
                    {selected === 'Registration' && <div><Registration setSelected={setSelected} setShowsignin={setShowsignin} /></div>}
                    {selected === 'MultiLogin' && <div><MultiLogin setSelected={setSelected} setShowsignin={setShowsignin} /></div>}
                </div>
                 
            </div>
            <ToastContainer theme="colored" closeButton={false} />
        </div>
    )
}
