import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function FeeStruct() {
    const isLoading = useSelector(state=>state.details?.isLoading)
    const isLoadingContent = useSelector(state=>{return state.details?.lFeeTchrAdmn})
    const feeTchrAdmn = useSelector(state=> state.details?.feeTchrAdmn) ?? {}
    const { t } = useTranslation()
    const {fees} = feeTchrAdmn 
    // const fees= [
    //     {
    //         "entity_id": "0ad2d95a-3837-48dd-b236-0bac6ce73ee3",
    //         "created_date": "2022-07-13T09:24:05.667+0000",
    //         "updated_date": "2022-07-13T09:24:05.667+0000",
    //         "amount": 1000,
    //         "frequency": "Montly",
    //         "archived": false,
    //         "std_name": null,
    //         "course_name": null,
    //         "fees_type": null,
    //         "fees_type_id": null,
    //         "active": true
    //     }, {
    //         "entity_id": "0ad2d95a-3837-48dd-b236-0bac6ce73ee3",
    //         "created_date": "2022-07-13T09:24:05.667+0000",
    //         "updated_date": "2022-07-13T09:24:05.667+0000",
    //         "amount": 1000,
    //         "frequency": "Montly",
    //         "archived": false,
    //         "std_name": null,
    //         "course_name": null,
    //         "fees_type": null,
    //         "fees_type_id": null,
    //         "active": true
    //     }, {
    //         "entity_id": "0ad2d95a-3837-48dd-b236-0bac6ce73ee3",
    //         "created_date": "2022-07-13T09:24:05.667+0000",
    //         "updated_date": "2022-07-13T09:24:05.667+0000",
    //         "amount": 1000,
    //         "frequency": "Montly",
    //         "archived": false,
    //         "std_name": null,
    //         "course_name": null,
    //         "fees_type": null,
    //         "fees_type_id": null,
    //         "active": true
    //     }
    // ]

    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsEdu()}
          </>
      )
    }
    return (
      <div className="detail-container">
        <h2>{t('fee_struct')}</h2>
        <div className="fee-structure">
          {Array.isArray(fees) && fees.length !== 0 ? (
            <table>
              <thead>
                <tr>
                  <th>{t("type")}</th>
                  <th>{t("amount")}</th>
                  <th>{t("frequency")}</th>
                </tr>
              </thead>
              <tbody>
                {fees.map((obj, index) => {
                  const { frequency, amount, fees_type } = obj;
                  return (
                    <tr key={index}>
                      <td>{fees_type}</td>
                      <td>₹ {amount}</td>
                      <td>
                        <p
                          className={
                            index % 3 === 0
                              ? "warning"
                              : index % 3 === 1
                              ? "success"
                              : "danger"
                          }
                        >
                          {frequency}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
          )}
        </div>
      </div>
    );
}
