import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { skeletonListCards } from "../../components/Skeleton/skeleton";

export default function InfiniteScroll() {
  const cardsLoading = useSelector((states) => states.listing?.cardsLoading);
  const listDetails = useSelector((states) => states.listing?.listDetails);
  const pageOffset = useSelector((states) => states.listing?.pageOffset);
  const dispatch = useDispatch();
  const noOfPages = useMemo(() => listDetails?.total_elements ? Math.ceil(listDetails.total_elements / 20.0) : 1, [listDetails]) ;
  // const noOfPages = listDetails && Object.keys(listDetails).length > 0 && listDetails?.total_elements !== 0 ? Math.ceil(listDetails?.total_elements / 20.0) : 
  const [prevY, setPrevY] = useState(0);
  let listDetailsRef = useRef({});
  let loadingRef = useRef(null);
  let prevYRef = useRef({});
  let pageRef = useRef({});
  let noOfPagesRef = useRef(1)
  let cardsLoadingRef = useRef(false)
  listDetailsRef.current = listDetails;
  pageRef.current = pageOffset;
  prevYRef.current = prevY;
  noOfPagesRef.current = noOfPages
  cardsLoadingRef.current = cardsLoading

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleObserver = (entities, observer) => {
      const y = entities[0].boundingClientRect.y;
      if (prevYRef.current > y && !cardsLoadingRef.current && pageRef.current < noOfPagesRef.current ) {
       console.log("conditional is true");
        updateIndex();
      } else {
       console.log("conditional is false");
      }
      setPrevY(y);
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadingRef.current && !cardsLoading) {
      observer.observe(loadingRef.current);
    }
  }, []);

  const updateIndex = () => {
    dispatch({ type: "UPDATE_PAGE_OFFSET", pageOffset: pageRef.current + 1 });
  };

  return (
    <>
      {pageOffset < noOfPages ? (
        <div ref={loadingRef}>{skeletonListCards([1])}</div>
      ) : (
        <div ref={loadingRef} style={{ visibility: "hidden" }}>
          {skeletonListCards([1])}
        </div>
      )}
    </>
  );
}
