const initialState = {
  location_det: {
    c_nm: 'India',
    s_nm: 'Maharashtra',
    ct_nm: null,
    ar_nm: null,
    p_code: null,
    address: null,
    lat: 18.516726,
    long: 73.856255,
  },
  std_crs_type: '',
  mst_std_nm: '',
  showLocPopup: false,
}

function location(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOCATION': {
      return { ...state, location_det:{...action.location_det} }
    }

    case 'SET_STD_CRS': {
      const {crs_type, std_nm} = action.payload
      return { ...state, std_crs_type: crs_type, mst_std_nm: std_nm}
    }

    case 'TOGGLE_POPUP': {
      return {...state, showLocPopup: action.showLocPopup}
    }

    default: {
      return state
    }
  }
}

export default location
