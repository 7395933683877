import {useState} from 'react'
import ApplyHeader from './apply-header'
import Forms from './forms'

export default function ApplyForm(props) {
   
    return (
        <>
            <ApplyHeader formno={props.formno} setFormno={props.setFormno} fetchFormDetails={props.fetchFormDetails}  addId={props.addId} setLoading={props.setLoading}/>
            <Forms formno={props.formno} setFormno={props.setFormno} rdata={props.rdata} addId={props.addId} setAddid={props.setAddid}
             FormData={props.FormData} setLoading={props.setLoading} fetchFormDetails={props.fetchFormDetails} />
        </>
    )
}
