import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Collapse from '../../../components/Collapse/index'
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';


const Faq = () => {
    const isLoading = useSelector(state=>state.details?.isLoading)
    const isLoadingContent = useSelector(state=>{return state.details?.lEventAchvPlacFaq})
    const eventAchvPlacFaq = useSelector(state=>state.details?.eventAchvPlacFaq) ?? {}
    const { t } = useTranslation()
    const {faqs} = eventAchvPlacFaq
    // const faqs= [
    //     {
    //         "id": "3073c159-0873-4ca9-ab2f-07182c7f3544",
    //         "inst_prof": null,
    //         "faq_catg": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "catg_id": "41310f21-bb71-45b6-8c2b-e7ed5553f4cf",
    //         "question": "PARAGRAPh",
    //         "ans": "<P>",
    //         "c_date": "2022-05-24 17:51:45.0",
    //         "u_date": "2022-05-24 17:51:45.0",
    //         "uid": null
    //     },
    //     {
    //         "id": "8d315189-359d-449c-b40a-dc2ae0fa1d85",
    //         "inst_prof": null,
    //         "faq_catg": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "catg_id": "41310f21-bb71-45b6-8c2b-e7ed5553f4cf",
    //         "question": "DIV",
    //         "ans": "Div",
    //         "c_date": "2022-05-24 17:44:57.0",
    //         "u_date": "2022-05-24 17:44:57.0",
    //         "uid": null
    //     },
    //     {
    //         "id": "92e3de61-1082-44f1-9218-225e7f12be6e",
    //         "inst_prof": null,
    //         "faq_catg": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "catg_id": "7d2fe117-913d-46a2-b980-ef844036f136",
    //         "question": "Generator Function",
    //         "ans": "Generator Function",
    //         "c_date": "2022-05-24 12:42:45.0",
    //         "u_date": "2022-05-24 12:42:45.0",
    //         "uid": null
    //     },
    //     {
    //         "id": "941e9af5-8cb5-4c0a-823d-6ad027d33318",
    //         "inst_prof": null,
    //         "faq_catg": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "catg_id": "7d2fe117-913d-46a2-b980-ef844036f136",
    //         "question": "Catt",
    //         "ans": "Cat",
    //         "c_date": "2022-05-24 17:39:10.0",
    //         "u_date": "2022-05-24 17:48:31.0",
    //         "uid": null
    //     },
    //     {
    //         "id": "ae4f1902-1c13-4670-9b63-64e831581aae",
    //         "inst_prof": null,
    //         "faq_catg": null,
    //         "i_p_id": "d342dc5a-712f-4424-9115-56d92b58c410",
    //         "catg_id": "98c8979c-8619-4b28-8191-48cba992ab57",
    //         "question": "Javaa",
    //         "ans": "Java",
    //         "c_date": "2022-05-24 17:38:25.0",
    //         "u_date": "2022-05-24 17:38:25.0",
    //         "uid": null
    //     }
    // ]
    const [viewMore, setViewMore] = useState(false)
    const [descrIndex, setDescrIndex] = useState(0)

    const onTapViewMore = (index) => {
        setViewMore(!viewMore)
        setDescrIndex(index)
    }

    const viewMoreClass = "descr"
    const viewLessClass = "descrless"

    if (isLoading || isLoadingContent) {
        return (
            <>
            {skeletonDetailsEdu()}
            </>
        )
      }

    return (
        <div className="detail-container">
            <h2>{t("faq_heading")}</h2>

            {Array.isArray(faqs) && faqs.length!==0 ? faqs.map((item,index) => {
                const {question, ans} = item
                return (
                    <Collapse key={index} id={index} title={`${question}`} >
                        <div className="answer_text">{ans}</div> 
                    </Collapse>

                )
            }): <h5 style={{fontSize:'16px'}}>{t("no_data_available")}</h5>}

        </div>
    )
}

export default Faq