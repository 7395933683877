import {useRef, useState} from 'react'
import { components } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import  api, { errorMsg } from '../../api/api'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIURL } from '../../api/url';
import { useDispatch } from 'react-redux';

export default function ValidateOtp(props) {
    const loginData = JSON.parse(localStorage.getItem('login'));
    let refs = [useRef(null), useRef(null), useRef(null), useRef(null)]
    let [val, setVal] = useState(["", "", "", ""])
    let [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    let changeFocus = (value, ind) => {
        if(isNaN(value*1)){
            refs[ind].current.value = "";
        } else {
            if(value.length > 1){
                value = ( value % 10 )+"";
            }
            let newVal = [...val]
            newVal[ind] = value
            setVal(newVal);
            if(value.length===0){
                if(ind != 0){
                    refs[ind-1].current.focus();
                }
            } else {
                if(ind != 3){
                    refs[ind+1].current.focus();
                }
            }
        }
    }

    const onSubmit = () => {
        if(!val.every(data => data.length===1)){
            toast.error("Please provide valid OTP.");
        } else {
            setLoading(true);
            const { ent_id, otp_id, user_type, number, is_multi_login } = loginData
            api.post({
                url: APIURL.otpValidate,
                data: {
                  ent_id,
                  otp: val.join(''),
                  id: otp_id,
                  user_type,
                  phone: number,
                  is_multi_login
                },
              })
              .then((res) => {
                let userData = res.data.result
                
                if(is_multi_login && Array.isArray(res.data.result)){
                    localStorage.setItem('multilogin', JSON.stringify(res.data.result))
                    dispatch({ type: 'SET_USER_DETAILS', data: { multiLogin:res.data.result } })
                    props.setSelected('MultiLogin')

                }else{
                const { token, up_id, p_type } = userData
                if (res.data.validate) {
                  localStorage.removeItem('login')
                  dispatch({ type: 'SET_USER_DETAILS', data: { userData, token, up_id, p_type } })
                  toast.success('Login successful')
                  setTimeout(() => {
                    props.setShowsignin(false)
                  }, 300)
                }
                }
              })
              .catch((err) => {
                if(err.response.data.error[0].error_code===406){
                    toast.error('Invalid Otp!')
                    setLoading(false);
                }
                if(err.response.data.error[0].error_code===650 && is_multi_login===false){
                    props.setSelected('Registration')
                }
                // toast.error(errorMsg(err))
              })
        }
        
    }

    const onResend = () => {
        setLoading(true);
        api.post({url: APIURL.userLogin, data: { phone: loginData?.number,  user_type: loginData?.user_type }}).then((res) => {
            let adata = {...loginData, ...res.data.result}
            localStorage.setItem("login", JSON.stringify(adata))
            props.setSelected('ValidateOtp')
        }).catch((err) => {
            
            toast.error("Some error occured while logging in.");
        }).finally(()=>setLoading(false))
    }

    const changeNum = () => {
        localStorage.removeItem("login");
        props.setSelected('Login')
    }

    return (
        <div className="signin">
            <h2>{t('sign_in')}</h2>
            <h4>{t('to_your_acc')}</h4>
            <h4 style={{marginTop: '10px'}}>{t('enter_otp')}</h4>
            <h4 style={{marginBottom: '10px'}}>{t('mobile')} {loginData?.number}</h4>
            <div className="flex-row otpinput">
                <div><input type="text" ref={refs[0]} value={val[0]}  onChange={e => changeFocus(e.target.value, 0)}  /></div>
                <div><input type="text" ref={refs[1]} value={val[1]}  onChange={e => changeFocus(e.target.value, 1)} /></div>
                <div><input type="text" ref={refs[2]} value={val[2]}  onChange={e => changeFocus(e.target.value, 2)} /></div>
                <div><input type="text" ref={refs[3]} value={val[3]}  onChange={e => changeFocus(e.target.value, 3)} /></div>
            </div>
            <p className="resend" onClick={onResend}>{t('resend_otp')}</p>
            {
                loading ? 
                <div className="loginProgress"><img src={components.loader2} /></div>
                :
                <button className="flex-row" onClick={onSubmit} >{t('confirm')}<img src={components.signin_arrow} /></button>
            }
            <button className="flex-row" style={{background: '#F00'}} onClick={changeNum} >{t('chng_no')}</button>
            
        </div>
    )
}
