import { details } from "../../assets/images";

const Organisation = ({ organizationInfo, profileDetails }) => {
  const {
    area_nm,
    ct_nm,
    inst_name,
    ph_url,
    is_verified
  } = profileDetails;
  return (
    <div className="organization">
      <img className="org_thumbnail" src={ph_url || details.default_profile_inst} />
      {/* TO DO: Make school name and location dynamic */}
      <div className="title-section">
        <p className="title">{inst_name}</p>
        {is_verified && <img className="verified" src={details.badge} />}
      </div>
      <div className="flex-row">
        <img className="loc-icon" src={details.location2} />
        <p className="location">
          {area_nm}, {ct_nm}
        </p>
      </div>
      <div className="org-info">
        {organizationInfo.map((item) => {
          return (
            <div className="info-item">
              <img className="icon" src={item.icon} />
              <div className="text-section">
                <p className="category">{item.text}</p>
                <p className="value">{item.val}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Organisation;
