import React, { Fragment, memo } from 'react'
import { IoMdClose } from 'react-icons/io'
import { useTranslation } from 'react-i18next';

function SelectedFiltersView({ selectedFilters, filterOptions, onChecked }) {
  const { t } = useTranslation()

  let titles = {
    std_ids: t('standard'),
    crs_ids: t('courses'),
    edu_brd_ids: t('board'),
    moi_ids: t('medium_instruct'),
    mot_ids: t('mode_of_teaching'),
    fac_ids: t('facilities'),
    ext_cur_ids: t('extra_cirr'),
    own_type_ids: t('ownership'),
    edu_type_ids: t('edu_type'),
  }
  
  return (
    <div className="filter-selected">
      {selectedFilters &&
        Object.keys(selectedFilters).map((key, index) => {
          if (Array.isArray(selectedFilters[key])) {
            return (
            <Fragment key={index}>
            {selectedFilters[key].map((id, index2) => (
              <div className="filters" key={index2}>
                <div>
                  <p>
                    {titles[key]}:
                    {Object.values(filterOptions).map((arr) => arr && arr.map((obj) => obj.id === id && obj.name))}
                  </p>
                  <span onClick={() => onChecked(key, id)}>
                    <IoMdClose color="#000000" size={20} />
                  </span>
                </div>
              </div>
            ))}
            </Fragment>)
          }
        })}
    </div>
  )
}
export default memo(SelectedFiltersView)
