import { Component } from "react";
import { RiArrowUpSFill } from 'react-icons/ri'
import './collapse.css'


class Collapse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true
        };
    }

    handleClick = () => {
        var bodyContElem = document.getElementById(
            this.props.id + "_collapsible__body__hidden"
        );

        if (this.state.isCollapsed) {
            bodyContElem.style.maxHeight = bodyContElem.scrollHeight + "px";
            bodyContElem.style.opacity = 1;
            this.setState({ isCollapsed: false });
        } else {
            bodyContElem.style.maxHeight = 0;
            bodyContElem.style.opacity = 0;
            this.setState({ isCollapsed: true });
        }

    }

    render() {
        const { isCollapsed } = this.state
        return (
            <div className="collapsible__container" >
                <div className="collapsible__title-container" >
                    <div className={!isCollapsed ? "collapsible__title_text blue_text" : "collapsible__title_text"} >
                        {this.props.title}
                    </div>

                    <div className="collapsible__title-button__container">
                        <div className={ isCollapsed? "button_icon__open" :"button_icon" } onClick={this.handleClick}>
                            {  <RiArrowUpSFill size={26} color={"#686666"} />}
                            {/* {!isCollapsed && <RiArrowDownSFill size={26} color={"#686666"} />} */}
                        </div>

                    </div>
                </div>

                <div // PURPLE
                    id={this.props.id + "_collapsible__body__hidden"}
                    style={{

                        transition: "opacity 0.4s ease, max-height 0.4s ease",
                        maxHeight: "0px",
                        opacity: "0",
                        overflow: "hidden"
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Collapse;
