import { home } from '../../assets/images' 
import { IoIosArrowForward } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const Blog = ({latest_reads}) => {
    const { t } = useTranslation()
    
    return (
        <div className='m-v-30 blog-d'>
            <div className="carousel-header-row">
                <div>
                    <h3>{t('latest_reads')}</h3>
                    <div className="carousel-dash"></div>
                </div>

                <a href="#">{t('view_all')} <IoIosArrowForward color="#4274B0" /></a>
            </div>

            <div className="blog-content">

                <div className="blog-column-50">
                    <img src={home.blog1} className="column-img1" />

                    <div className="blog-overlay-content-50">
                        <p className="blog-views">2k Views</p>
                        <p className="blog-news">The writing section of the paper was much better and could catch up on lost time."</p>

                    </div>

                    <div className="blog-visibtn-content">
                        <div className="visit-btn"><a href="#"> {t('visit')} </a></div>
                    </div>
                </div>

                <div className="blog-column-60" >

                    <div className="blog-column-60-content" style={{ top:0 }}>

                        <div className="blog-column-60-child" >
                            <img src={home.blog2} className="blog-child-img" />

                            <div className="blog-child-overlay-content">
                                <p className="blog-child-views">2k Views</p>
                                <p className="blog-child-news">The writing section of the paper was much better and could catch up on lost time."</p>
                            </div>

                            <div className="blog-child-visibtn-content">
                                <div className="visit-btn"><a href="#"> {t('visit')} </a></div>
                            </div>
                        </div>

                        <div className="blog-column-60-child"   >
                            <img src={home.blog3} className="blog-child-img" />

                            <div className="blog-child-overlay-content">
                                <p className="blog-child-views">2k Views</p>
                                <p className="blog-child-news">The writing section of the paper was much better and could catch up on lost time."</p>
                            </div>

                            <div className="blog-child-visibtn-content">
                                <div className="visit-btn"><a href="#"> {t('visit')} </a></div>
                            </div>
                        </div>
                    </div>

                    <div className="blog-column-60-content" style={{ bottom:0 }}>

                        <div className="blog-column-60-child">
                            <img src={home.blog4} className="blog-child-img" />

                            <div className="blog-child-overlay-content">
                                <p className="blog-child-views">2k Views</p>
                                <p className="blog-child-news">The writing section of the paper was much better and could catch up on lost time."</p>
                            </div>

                            <div className="blog-child-visibtn-content">
                                <div className="visit-btn"><a href="#"> {t('visit')} </a></div>
                            </div>
                        </div>

                        <div className="blog-column-60-child" >
                            <img src={home.blog5} className="blog-child-img" />

                            <div className="blog-child-overlay-content">
                                <p className="blog-child-views">2k Views</p>
                                <p className="blog-child-news">The writing section of the paper was much better and could catch up on lost time."</p>
                            </div>

                            <div className="blog-child-visibtn-content">
                                <div className="visit-btn"><a href="#"> {t('visit')} </a></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Blog