import { useState, useEffect, useRef } from "react";
import { home } from "../../assets/images";
import Autocomplete from "react-google-autocomplete";
import { CgCloseR } from "react-icons/cg";
import { MdNavigation } from "react-icons/md";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { APIKEY } from "../../utils/utils";
import "./location.css";

const Location = (props) => {
  const { address } = useSelector(state=>state.location.location_det)
  const [eduIndex, seteduIndex] = useState(undefined);
  const [iscityValid, setIsCityValid] = useState(true);
  const [eduDataDisabled, setEduDataDisabled] = useState(false);
  const [schoolIndex, setSchoolIndex] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [prevLat, setPrevLat] = useState()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let locRef = useRef(null)
  let prevLatRef = useRef(null)
  prevLatRef.current = prevLat
  const schoolData = [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
  ];
  const eduData = [t("pre_school"), t("school"), t("grad"), t("post_grad"), t("others")];
  useEffect(() => {
    if (eduIndex === 1) {
      schoolIndex !== undefined ? setSubmitDisable(false) : setSubmitDisable(true)
    } else {
      eduIndex !== undefined && (location || address) ? setSubmitDisable(false) : setSubmitDisable(true)
    }
  }, [location,eduIndex,schoolIndex])

  const onPlaceSelected = (place) => {
   if (place) {
      let transformedLoc = transformLocObject(place);
      setLocation(transformedLoc);
      transformedLoc?.ct_nm === "Pune" ? setIsCityValid(true) : setIsCityValid(false);
      setEduDataDisabled(false);
    }
  };

  const transformLocObject = (place) => {
    const { lat, lng } = place.geometry.location;
    let ar_nm =
      place.address_components.find((item) => item.types[0] === "sublocality_level_1")?.long_name ??
      null;
    let ct_nm =
      place.address_components.find((item) => item.types[0] === "administrative_area_level_2" || item.types[0] === "administrative_area_level_3")
        ?.long_name ?? null;
    let s_nm =
      place.address_components.find((item) => item.types[0] === "administrative_area_level_1")
        ?.long_name ?? null;
    let c_nm =
      place.address_components.find((item) => item.types[0] === "country")?.long_name ?? null;
    let p_code =
      place.address_components.find((item) => item.types[0] === "postal_code")?.long_name ?? null;
    const transformedObj = {
      c_nm,
      s_nm,
      ct_nm,
      ar_nm,
      p_code,
      address: place.formatted_address,
      lat: lat,
      long: lng,
    };
    return transformedObj;
  };
 
  const getCurrentLoc = () => {
    setEduDataDisabled(false)
    navigator.geolocation.getCurrentPosition((pos) => {
      if (!prevLatRef.current || prevLatRef.current != pos.coords.latitude || !locRef.current.value) {
        setPrevLat(pos.coords.latitude)
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&key=${APIKEY.GEO_LOCATION}`
      )
        .then((response) => response.json())
        .then((res) => {
          setLocation(transformLocObject(res?.results?.[0]));
          locRef.current.value = res?.results?.[0].formatted_address
        });
      }
    });
  };

  const handleEduCategoryClick = (index) => {
    if (!location && !address) {
      setEduDataDisabled(true);
    } else {
      seteduIndex(index);
    }
  };

  const handleLocChange = (loc) => {
    if (!loc) {
      setEduDataDisabled(true);
      seteduIndex(undefined);
      setLocation(undefined)
    }
  };

  const handleSubmit = () => {
        dispatch({ type: `SET_LOCATION`, location_det: location });
        dispatch({ type: 'SET_STD_CRS', payload: {crs_type: eduData[eduIndex], std_nm: schoolData[schoolIndex]}})
        dispatch({ type: 'TOGGLE_POPUP', showLocPopup: false })
  };

  return (
    <div className="modal-container">
      <div className="signin-div location-div-parent">
        <div className="location-div">
          <div className="close-icon">
            {address && <CgCloseR color="#6B6B6B" size={25} onClick={() => dispatch({ type: 'TOGGLE_POPUP', showLocPopup: false })} />}
          </div>
          <div className="flex-row">
            <h2>{t("modal_title")}</h2>
          </div>
          <div className="separator"></div>
          <h4>{t("modal_search")}</h4>
          <div className="flex-row scomplete">
            <div className="flex-row search-parent">
              <div className="flex-row search-div">
                <img
                  src={home.location_icon}
                  style={{ height: "23px", width: "16px", margin: "0 16px" }}
                />
                <Autocomplete
                  apiKey={APIKEY.PLACE}
                  onPlaceSelected={(place) => onPlaceSelected(place)}
                  options={{
                    types: ["geocode", "establishment"],
                    componentRestrictions: { country: "in" },
                  }}
                  onChange={(e) => handleLocChange(e.target.value)}
                  defaultValue={address}
                  ref={locRef}
                />
              </div>
            </div>
            <div className="flex-row detect-location">
              {/* <img src={locblue} /> */}
              <MdNavigation
                style={{
                  transform: "rotateZ(50deg)",
                }}
                color={"#4274B0"}
                size={20}
              />
              <p className="blue" onClick={() => getCurrentLoc()}>
                {t("current_location")}
              </p>
            </div>
          </div>
          {!iscityValid && (
            <Alert
              classes={{ message: "padding-msg", icon: "padding-icon", root: "margin-top-15" }}
              severity="info"
              color="warning"
            >
              {t("modal_content")}
            </Alert>
          )}
          <h4>{t("edu_level")}</h4>
          <div className="edu-level">
            {eduData.map((item, index) => {
              return (
                <button
                  key={index}
                  className={index === eduIndex ? "active" : ""}
                  onClick={() => handleEduCategoryClick(index)}
                  //disabled={eduDataDisabled}
                >
                  {item}
                </button>
              );
            })}
          </div>
          {eduDataDisabled && (
            <Alert
              classes={{ message: "padding-msg", icon: "padding-icon" }}
              severity="info"
              color="warning"
            >
              {t("loc_first")}
            </Alert>
          )}
          {/* Show standards only when school is selected */}
          {eduIndex === 1 && (
            <div className="standard-level">
              {schoolData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={schoolIndex === index ? "active" : ""}
                    onClick={() => eduIndex != null && setSchoolIndex(index)}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          )}
          <div className="button-container">
            <button
              className="submit-button"
              onClick={() => handleSubmit()}
              disabled={submitDisable}
            >
              Start Exploring
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
