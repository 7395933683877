import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { skeletonDetailsEdu } from '../../../components/Skeleton/skeleton';

export default function Admission() {
  const isLoading = useSelector(state=>state.details?.isLoading)
  const isLoadingContent = useSelector(state=>state.details?.lFeeTchrAdmn) 
    const {admn_process} = useSelector(state=>state.details?.feeTchrAdmn)  ?? {}
    const { t } = useTranslation()
    // const admn_process= {
    //     "file_url_str": null,
    //     "id": "0bfb67ae-a120-4dcd-be1f-8a70840d18fa",
    //     "inst_prof": null,
    //     "i_p_id": "b611b682-d8dd-41d7-b431-15296c0517d7",
    //     "admsn_det": "<p>ndfnn</p>",
    //     "eligibility": [
    //         "Parent Interaction",
    //         "Student Interaction",
    //         "Entrance Test"
    //     ],
    //     "f_availability": [
    //         "Offline"
    //     ],
    //     "f_payment": [
    //         "Paid"
    //     ],
    //     "is_active": true,
    //     "c_date": "2022-07-14 15:57:11.0",
    //     "u_date": "2022-07-14 15:57:11.0",
    //     "files": null,
    //     "uid": null
    // }
    const {eligibility, f_availability, f_payment, is_active, admsn_det, file_url_str} = admn_process || {}
    
    if (isLoading || isLoadingContent) {
      return (
          <>
          {skeletonDetailsEdu()}
          </>
      )
    }

    return (
      <div className="detail-container">
        <h2>{t("admission")}</h2>
        {admn_process ? (
          <>
            {admsn_det ? <p className="adm-html" dangerouslySetInnerHTML={{__html: admsn_det}}></p> : <p>{"N/A"}</p>}
            <div className="details-admission">
              <a href="#">{t("download_pdf")}</a>
              <div className="flex-row">
                <div className="content">
                  <h4 className="blueblock">
                    {t("eligibility")}
                  </h4>
                  <div>
                    {Array.isArray(eligibility) &&
                      (eligibility.length === 0 ? (
                        <h3>{t("no_data")}</h3>
                      ) : (
                        eligibility.map((item, index) => (
                          <div key={index} className="flex-row">
                            <p className="dot"></p>
                            <p>{t("ent_test")}</p>
                          </div>
                        ))
                      ))}
                  </div>
                </div>
                <div className="separator"></div>
                <div className="content">
                  <h4 className="blueblock">{t("form_availability")}</h4>
                  <div>
                    {Array.isArray(f_availability) &&
                      (f_availability.length === 0 ? (
                        <h3>{t("no_data")}</h3>
                      ) : (
                        f_availability.map((item, index) => (
                          <div className="flex-row" key={index}>
                            <p className="dot"></p>
                            <p>{item}</p>
                          </div>
                        ))
                      ))}
                  </div>
                </div>
                <div className="separator"></div>
                <div className="content">
                  <h4 className="blueblock">{t("form_payment")}</h4>
                  <div>
                    {Array.isArray(f_payment) &&
                      (f_payment.length === 0 ? (
                        <h3>{t("no_data")}</h3>
                      ) : (
                        f_payment.map((item, index) => (
                          <div className="flex-row"  key={index}>
                            <p className="dot"></p>
                            <p>{item}</p>
                          </div>
                        ))
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
        )}
      </div>
    );
}
