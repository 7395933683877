import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { components } from '../../assets/images'
import api from '../../api/api'
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { APIURL } from '../../api/url'

export default function Registration(props) {
  const ldata = JSON.parse(localStorage.getItem('login'))
  const { user_type, number } = ldata || {}
  let [name, setName] = useState('')
  let [institiute, setInstitute] = useState('')
  let [email, setEmail] = useState('')
  let [terms, setTerms] = useState(false)
  let [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onSubmit = () => {
    if (name.length === 0) {
      toast.error(t('valid_name'))
    } else if (institiute.length === '') {
      toast.error(t('valid_inst_name'))
    } else if (email.length === '') {
      toast.error(t('valid_email'))
    } else if (terms === false) {
      toast.error(t('tc_check'))
    } else {
      setLoading(true)
      api
        .post({
          url: APIURL.adminRegister,
          data: {
            name: institiute,
            phone: number,
            email,
            user_type,
          },
        })
        .then((res) => {
          let userData = res.data.result
          localStorage.removeItem('login')
          const { token, up_id, p_type } = userData
          dispatch({ type: 'SET_USER_DETAILS', data: { userData, token, up_id, p_type } })
          toast.success('Registration successful')
          setTimeout(() => {
            props.setShowsignin(false)
          }, 300)
        })
        .catch((err) => {
          toast.error('Some error occured while logging in.')
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <div className="signin">
      <h2 style={{ fontSize: "24px" }}>
        {t("to_your_acc")}
      </h2>
      <div className="separator"></div>

      <input
        style={{ margin: '4% 0 0 0' }}
        type="text"
        placeholder={t("name_hint")}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <input
        style={{ margin: '4% 0 0 0' }}
        type="text"
        placeholder={`${t("inst_name")} *`}
        value={institiute}
        onChange={(e) => setInstitute(e.target.value)}
      />

      <input
        style={{ margin: '4% 0 0 0' }}
        type="text"
        placeholder={`${t('email_hint')} *`}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <div className="flex-row" style={{ margin: '4% 0' }}>
        <input checked={terms} onChange={(e) => setTerms(!terms)} type="checkbox" />{' '}
        <p style={{ marginLeft: '10px' }}>
          {t('agree_to')} <a href="#">{t('t_and_c')}</a>
        </p>
      </div>

      {loading ? (
        <div className="loginProgress">
          <img src={components.loader2} />
        </div>
      ) : (
        <button onClick={onSubmit} className="flex-row">
          {t('submit')} <img src={components.signin_arrow} />
        </button>
      )}
    </div>
  )
}
