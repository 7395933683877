import { useLocation } from 'react-router-dom'
import Header from '../Header'
import HeaderSearch from '../HeaderSearch'


function HeaderMain() {
  const location = useLocation()
  return location.pathname === '/' ? <Header /> :<HeaderSearch />
}

export default HeaderMain
