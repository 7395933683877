import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import './i18n'
import App from './App';
import { Provider } from "react-redux";
import {rootstore, persistor} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <Provider store={rootstore}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    , document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();