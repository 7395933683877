import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import { useState } from 'react'

const CompareButton = () => {
  const selectedIds = useSelector((state) => state.compare.selectedIds)
  const history = useHistory()
  const { t } = useTranslation()
  const [show,setShow]= useState(false)
  const dispatch = useDispatch()
  return (
    <div
    onMouseEnter={()=>setShow(true)}
    onMouseLeave={()=>setShow(false)}
      style={{
        display: selectedIds.length >= 1 ? 'flex' : 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // gap: 10,
        bottom: 10,
        right: 10,
        position: 'fixed',
        width: 'auto',
        height: 45,
        backgroundColor: 'rgba(28, 28, 53, 1)',
        borderRadius: 8,
        color: 'white',
        borderColor: '#095A8B',
      }}
    >
      {show &&
        <div 
        onClick={()=>{dispatch({type:'RESET_COMPARE'})}}
        style={{ paddingLeft: 7, paddingTop: 24, paddingBottom: 24, paddingRight: 3, cursor:'pointer' }}>
        <AiOutlineClose />
      </div>}
      <div
        onClick={() => history.push('/compare/')}
        style={{
          cursor:'pointer',
          padding: '0px 7px',
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
          backgroundColor: '#095A8B',
          borderRadius: 8,
          color: 'white',
          borderColor: '#095A8B',
        }}
      >
        {t('compare')}
        <span
          style={{
            border: 'solid blue 1px',
            backgroundColor: 'rgba(0,0,255,0.6)',
            borderRadius: '25%',
            fontSize: '15px',
            width: '22px',
            height: '22px',
            textAlign: 'center',
          }}
        >{` ${selectedIds.length} `}</span>
      </div>
    </div>
  )
}
export default CompareButton
