import { all } from 'redux-saga/effects';
import { beginFetchList, watchLogin } from './Login/loginSaga';

const rootSaga = function* rootSaga() {
  yield all([
    watchLogin(),
    beginFetchList()
  ]);
};

export default rootSaga;
