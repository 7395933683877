import { compare } from '../../assets/images'
import { useTranslation } from 'react-i18next';

export default function HeaderBlank({profileType}) {
    const { t } = useTranslation()
    return (
        <div className="compare-blank">
            <div className="imgdiv" style={{background: `#EBEBF2 url(${profileType==='coaching' ? compare.default_photo_coaching : compare.default_photo_inst}) no-repeat no-repeat center center` , backgroundSize: 'contain'}}></div>
            <div className="flex-row select-compare">
                <div className="flex-row select-first">
                    <img  style={{width: '10px', height: '13px', marginRight: '6px'}} src={compare.location} />
                    <select><option>{t("pune")}</option></select>
                </div>
                <input type="text" placeholder="Choose a college" />
            </div>
            <p className="or">OR</p>
            {/* <select className="sschool"><option>Shortlisted School</option></select> */}
            <select className="sschool"><option>Shortlisted School</option></select>
        </div>
    )
}
