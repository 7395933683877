import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { apply } from "../../../assets/images";
import ProgressBar from "@ramonak/react-progress-bar";
import api, { errorMsg } from '../../../api/api'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux'
import CompletePopup from "../completpopup";
import SubmitPopup from "../submitpopup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Form4 = (props) => {
    const { t } = useTranslation()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData
    const [progress, setProgress] = useState(30)
    const [profilePhoto, setProfilePhoto] = useState("")
    const [adhaaar, setAdhaar] = useState("")
    const [birth, setBirth] = useState("")
    const [address, setAddress] = useState("")
    const [Educationprofile, setEducationprofile] = useState("")
    const [getprofilePhoto, setGetProfilePhoto] = useState("")
    const [getadhaaar, setGetAdhaar] = useState("")
    const [getbirth, setGetBirth] = useState("")
    const [getaddress, setGetAddress] = useState("")
    const [complete, setComplete] = useState(false)
    const [submitPopup, setSubmitPopup] = useState(false)
    const [getEducationprofile, setGetEducationprofile] = useState("")
    const app_id = useSelector(state => state.auth.applicationId)



    useEffect(() => {
        setInterval(() => setProgress((progress + 10) % 100), 2000)
    }, [])


    useEffect(() => {
        if (props.FormData) {
            setProfilePhoto(props.FormData.doc?.profile ?? '')
            setAddress(props.FormData.doc?.address ?? '')
            setAdhaar(props.FormData.doc?.aadhar ?? '')
            setBirth(props.FormData.doc?.birth ?? '')
            setEducationprofile(props.FormData.doc?.education ?? '')
            setGetProfilePhoto(props.FormData.doc?.profile ?? '')
            setGetAddress(props.FormData.doc?.address ?? '')
            setGetAdhaar(props.FormData.doc?.aadhar ?? '')
            setGetBirth(props.FormData.doc?.birth ?? '')
            setGetEducationprofile(props.FormData.doc?.education ?? '')
        }
    }, [props.FormData])






    const savedetails = () => {
        if (profilePhoto == "") {
            toast.error("Please upload Profile Photo.")
        } else if (adhaaar == "") {
            toast.error("Please upload Adhhar.")
        } else {
            props.setLoading(true)
            var fd = new FormData();
            fd.append("birth_img", birth)
            fd.append("profile_img", profilePhoto)
            fd.append("aadhar_img", adhaaar)
            fd.append("address_img", address)
            fd.append("recent_edu_img", Educationprofile)
            let request = {
                url: `website/institute/form/${props.addId}/document/upload`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: fd
            }
            api.putAuth(request).then(data => {
                props.setLoading(false)
                toast.success("Doucumnets Uploaded successfully");
                // props.setFormno(4)
                setSubmitPopup(true)

            }).catch((err) => {
                props.setLoading(false)
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                }
            })
        }
    }


    const deleteImage = (id, check) => {
        props.setLoading(true)
        let request = {
            url: `website/institute/form/documnet/${id}/delete?doc_type=${check}&admission_form_id=${props.addId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.delete(request).then(data => {
            props.setLoading(false)
            toast.success("Image Deleted successfully")
            props.fetchFormDetails()

        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }

        })
    }


    const validateImage = (e) => {
        let img = e.size / 1024
        let ext = e.name.split(".")[1]
        console.log(ext, "cbfbb")
        if (img > 200) {
            toast.error("Please upload data of size 200kb only.")
            return false
        }
        else return true
    }


    const submit = () => {
        props.setLoading(true)
        let request = {
            url: `website/student/applications/${app_id}/submit`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },

        }
        api.postCustom(request).then(data => {
            props.setLoading(false)
            toast.success("Form submited successfully")
            setComplete(true)
            setSubmitPopup(false)
        }).catch((err) => {
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

// console.log(profilePhoto.name,"shjhjhfhhj")


    return (
        <>

            {
                complete == true &&
                <CompletePopup />
            }

            {
                submitPopup == true &&
                <SubmitPopup submit={submit} setSubmitPopup={setSubmitPopup} />
            }


            <div className="form-upload-group flex-row">
                <h4>Profile Photo</h4>
                <div className="flex-row" >
                    <label htmlFor="photo-upload-profile" style={{ cursor: 'pointer' }} >
                        <img style={{ marginRight: '12px', width: "13px", height: "13px" }} src={apply.upload} /> {t("choose_file")}
                        <input id="photo-upload-profile" type="file" accept="image/jpeg,image/jpg,image/img" style={{ display: "none", visibility: "hidden" }}
                            onChange={(e) => { if (validateImage(e.target.files[0])) setProfilePhoto(e.target.files[0]) }}
                        ></input>
                    </label>
                </div>
            </div>
            <div className="upload-allow">{t("max_size")}</div>

            {
                profilePhoto != "" &&
                <div className="upload-file-div flex-row">
                    <div className="file-type-img">
                    <img src={getprofilePhoto != "" ? getprofilePhoto.document_url : URL.createObjectURL(profilePhoto)} />
                    </div>

                    <div className="upload-content">
                        <div className="flex-row" style={{ marginBlock: '8px' }}><p className="filename">{getprofilePhoto != "" ? getprofilePhoto.document_url.split("/").pop() : profilePhoto.name}</p><img src={apply.close} style={{ width: "16px", height: "16px" }} onClick={() => getprofilePhoto != "" ? deleteImage(getprofilePhoto.document_id, "PROFILE") : setProfilePhoto("")} /></div>
                    </div>
                </div>
            }



            <div className="form-upload-group flex-row">
                <h4>{t("upload_aahaar")}</h4>
                <div className="flex-row" >
                    <label htmlFor="photo-upload-aadhaar" style={{ cursor: 'pointer' }} >
                        <img style={{ marginRight: '12px', width: "13px", height: "13px" }} src={apply.upload} />  {t("choose_file")}
                        <input id="photo-upload-aadhaar" type="file" accept="application/pdf,image/jpeg,image/jpg,image/img" style={{ display: "none", visibility: "hidden" }}
                            onChange={(e) => { if (validateImage(e.target.files[0])) setAdhaar(e.target.files[0]) }}
                        ></input>
                    </label>
                </div>
            </div>
            <div className="upload-allow">{t("max_size")}</div>

            {
                adhaaar != "" &&
                <div className="upload-file-div flex-row">
                    <div className="file-type-img">
                    {
                            adhaaar.name?.split(".")[1] == "pdf"  || getadhaaar.document_url?.split(".").pop()=="pdf" ?
                                <img src={apply.pdf} /> :
                                <img src={getadhaaar != "" ? getadhaaar.document_url : URL.createObjectURL(adhaaar)} />

                        }
                       </div>
                    <div className="upload-content">
                        <div className="flex-row" style={{ marginBlock: '8px' }}><p className="filename">{getadhaaar != "" ? getadhaaar.document_url.split("/").pop() : adhaaar.name}</p><img src={apply.close} style={{ width: "16px", height: "16px" }} onClick={() => getadhaaar != "" ? deleteImage(getadhaaar.document_id, "AADHAR") : setAdhaar("")} /></div>
                    </div>
                </div>

            }


            <div className="form-upload-group flex-row">
                <h4>{t("birth_cert")}</h4>
                <div className="flex-row" >
                    <label htmlFor="photo-upload-birth" style={{ cursor: 'pointer' }}>
                        <img style={{ marginRight: '12px', width: "13px", height: "13px" }} src={apply.upload} /> {t("choose_file")}
                        <input id="photo-upload-birth" type="file" accept="application/pdf,image/jpeg,image/jpg,image/img" onChange={(e) => { setBirth(e.target.files[0]) }} style={{ display: "none", visibility: "hidden" }}></input>
                    </label>
                </div>
            </div>
            <div className="upload-allow">{t("max_size")}</div>

            {
                birth != "" &&
                <div className="upload-file-div flex-row">
                    <div className="file-type-img">
                    {
                            birth.name?.split(".")[1] == "pdf"  || getbirth.document_url?.split(".").pop()=="pdf" ?
                                <img src={apply.pdf} /> :
                                <img src={getbirth != "" ? getbirth.document_url : URL.createObjectURL(birth)} />

                        }
                        </div>
                    <div className="upload-content">
                        <div className="flex-row" style={{ marginBlock: '8px' }}><p className="filename">{getbirth != "" ? getbirth.document_url.split("/").pop() : birth.name}</p><img src={apply.close} onClick={() => getbirth != "" ? deleteImage(getbirth.document_id, "BIRTH") : setBirth("")} style={{ width: "16px", height: "16px" }}
                            onChange={(e) => { if (validateImage(e.target.files[0])) setBirth(e.target.files[0]) }} /></div>
                    </div>
                </div>

            }

            <div className="form-upload-group flex-row">
                <h4>{t("add_proof")}</h4>
                <div className="flex-row" >
                    <label htmlFor="photo-upload-address" >
                        <img style={{ marginRight: '12px', width: "13px", height: "13px" }} src={apply.upload} /> {t("choose_file")}
                        <input id="photo-upload-address" type="file" accept="application/pdf,image/jpeg,image/jpg,image/img" style={{ display: "none", visibility: "hidden" }}
                            onChange={(e) => { if (validateImage(e.target.files[0])) setAddress(e.target.files[0]) }}></input>
                    </label>
                </div>
            </div>
            <div className="upload-allow">{t("max_size")}</div>
            {address != "" &&
                <div className="upload-file-div flex-row">
                    <div className="file-type-img">
                    {
                            address.name?.split(".")[1] == "pdf"  || getaddress.document_url?.split(".").pop()=="pdf" ?
                                <img src={apply.pdf} /> :
                                <img src={getaddress != "" ? getaddress.document_url : URL.createObjectURL(address)} />

                        }
                       </div>
                    <div className="upload-content">
                        <div className="flex-row" style={{ marginBlock: '8px' }}><p className="filename">{getaddress != "" ? getaddress.document_url.split("/").pop() : address.name}</p><img src={apply.close} style={{ width: "16px", height: "16px" }} onClick={() => getaddress != "" ? deleteImage(getaddress.document_id, "ADDRESS") : setAddress("")} /></div>
                    </div>
                </div>
            }

            <div className="form-upload-group flex-row">
                <h4>{t("recent_edu")}</h4>
                <div className="flex-row" >
                    <label htmlFor="photo-upload-education" style={{ cursor: 'pointer' }}>
                        <img style={{ marginRight: '12px', width: "13px", height: "13px" }} src={apply.upload} />{t("choose_file")}
                        <input id="photo-upload-education" type="file" accept="application/pdf,image/jpeg,image/jpg,image/img" style={{ display: "none", visibility: "hidden" }}
                            onChange={(e) => { if (validateImage(e.target.files[0])) setEducationprofile(e.target.files[0]) }}></input>
                    </label>
                </div>
            </div>
            <div className="upload-allow">{t("max_size")}</div>
            {
                Educationprofile != "" &&
                <div className="upload-file-div flex-row">
                    <div className="file-type-img">
                    {
                            Educationprofile.name?.split(".")[1] == "pdf"  || getEducationprofile.document_url?.split(".").pop()=="pdf" ?
                                <img src={apply.pdf} /> :
                                <img src={getEducationprofile != "" ? getEducationprofile.document_url : URL.createObjectURL(Educationprofile)} />

                        } 
                       </div>
                    <div className="upload-content">
                        <div className="flex-row" style={{ marginBlock: '8px' }}><p className="filename">{getEducationprofile != "" ? getEducationprofile.document_url.split("/").pop() : Educationprofile.name}</p><img src={apply.close} style={{ width: "16px", height: "16px" }} onClick={() => getEducationprofile != "" ? deleteImage(getEducationprofile.document_id, "EDUCATION") : setEducationprofile("")} /></div>
                    </div>
                </div>

            }
            <div className="submit-div">
                <p onClick={() => (props.fetchFormDetails(), props.setFormno(3))}> {t("back")}</p>
                <button onClick={() => savedetails()}>{t('save_next')}</button>
            </div>
        </>

    )
}

export default Form4;
