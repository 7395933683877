import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const generated_key = 'BJyAfG4CG8cJgwNylim2cGg70Z0jQtzvTmni6VVpwYKhJykLvVax2kDKAYDeLXrYdsvdbES3uKlgi_NoQasnK70';

export const firebaseConfig = {
    apiKey: "AIzaSyAUNA4F9HaBkgHJPoYnfMuornprBceVu28",
    authDomain: "discovery-fe665.firebaseapp.com",
    projectId: "discovery-fe665",
    storageBucket: "discovery-fe665.appspot.com",
    messagingSenderId: "306643135836",
    appId: "1:306643135836:web:d77729b0a6155840216599",
    measurementId: "G-6H439R1R44"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenFlag = () => {
    getToken(messaging, { vapidKey: generated_key })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('current token for client: ', currentToken);
                return true;
            } else {
                console.log('No registration token available. Request permission to generate one.');
                return false;
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            return false;
        });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});