import React, { useState, useEffect } from 'react'
import { student } from '../../../assets/images'
import { useHistory } from 'react-router-dom';
import './app_card.css'
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import OnlineForm from './form';
import PaymentForm from './paymentForm';


export default function ApplicationCard(props) {
    const dispatch = useDispatch()
    const [rdata, setRdata] = useState([])
    const [form, setForm] = useState(false)
    const history = useHistory()
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData
    const [paymnent,setPayment]= useState(false)




    useEffect(() => {
        getApplication()
    }, [props.filter, props.type, props.search])


    const getApplication = (active) => {
        props.setLoading(true)
        let request = {
            url: `website/student/applications/${c_id}/get?sort_order=${props.filter}&search_text=${props.search}&fromDate=2022-01-01&toDate=2022-12-30&status=${props.type == 0 ? "7" : props.type}&pageNumber&pageSize&isAll=true`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setRdata(data.data.result.response)
            props.setCount(data.data.result.response.length ?? 0)
        }).catch((err) => {
            console.log(err, "errrr")
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }

    return (
        <> {rdata.map((item, key) => {
            return (
                <div key={key} style={{ border: '1px solid #D6D6D6', marginTop: '10px', borderRadius: '5px', padding: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FFFFFF', justifyContent: 'space-between', padding: '10px' }}>
                        <div className="headingCard" style={{ width: '50%', display: 'flex' }}>{item.student_name}</div>
                        <div className="headingCard" style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                            {item.institute_name}
                            <div className="smallSchoolHeading">{item.institute_city}, {item.institute_state}</div>
                            <div className='ClassStan' style={{ background: '#F4F4F4', padding: '5px', width: '70%', display: 'flex', justifyContent: 'center' }}>{item.standard_Name} ({item.academic_year})</div>
                        </div>
                        <div className="headingCard" style={{ width: '30%', display: 'flex' }}>{item.is_form_fee_paid == true ? "Paid" : "UnPaid"}</div>
                        <div className="headingCard" style={{ width: '40%', display: 'flex' }}><img src={student.down} style={{ height: '30px', width: '30px', marginLeft: '10px', cursor: 'pointer' }} 
                        onClick={()=>setPayment(true)}
                        /></div>
                        <div className="headingCard" style={{ width: '50%', display: 'flex' }}>
                            {item.form_status == "Complete Now" &&
                                <button className='StatusButton' onClick={() => {
                                    history.push(`/apply/school/${item.inst_id}`, { inc: item.incomplete_section });
                                    dispatch({ type: 'ADD_FORM', form_id: item.admission_form_id, applicationId: item.application_id })
                                }}>Complete Now</button>}
                            {item.form_status == "Completed" &&
                                <button className='StatusButton' onClick={() => { setForm(true) }}>Completed</button>
                            }
                        </div>
                        <div className="headingCard" style={{ width: '40%' }}>
                            {item.application_status == "In Progress" && <button className='ProcessButton'>In- Process</button>}
                            {item.application_status == "Rejected" && <button className='rejectedButton'>Rejected</button>}
                            {item.application_status == "Accepted" && <><button className='acceptedButton'>Accepted</button><div style={{ marginTop: '45px', marginBottom: '5px' }} className="congrats"><img src={student.cup} style={{ marginRight: '5px' }} />Congrats!!</div><div><a href="#" style={{ marginTop: '55px' }}> View Details </a></div></>
                            }

                        </div>
                    </div>
                </div>
            )
        })
        }

            {form &&
                <OnlineForm setForm={setForm}/>
            }

            {paymnent &&
                <PaymentForm setPayment={setPayment}/>
            }
        </>
    )
}