import React, { useState, useRef, useEffect } from "react";
import { studyAbroad, home } from "../../assets/images";
import CarouselCard from "./carouselCard";
import { Services } from "./carData";
import Card from "./card";
import Headlist from "../../components/Headlist";
import { carouselData } from "./carouselData";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Stepper, Step, StepLabel, StepConnector, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import "./stud-abroad.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FaChevronCircleRight} from 'react-icons/fa'
import {indianStates} from "../../utils/indianStates";
import { useForm } from 'react-hook-form'

const StudyAbroad = () => {
  const { t } = useTranslation()
  const [active, setActive] = useState(0);
  const slider = useRef(null);


const {
  register,
  handleSubmit,
  watch,
  getValues,
  
  formState: { errors, touchedFields: touched, dirtyFields },
} = useForm({
  mode: 'onTouched',
  reValidateMode: 'onChange',
  defaultValues: {
    firstName: '',
    lastName: '',
    mob_no: '',
    eml_id: '',
    state: '',
  },
  criteriaMode: 'all',
})
const validationSchema = {
  firstName: {
    required: t('name_req'),
    minLength: {
      value: 3,
      message: t('min_three_letters'), // JS only: <p>error message</p> TS only support string
    },
    maxLength: {
      value: 10,
      message: t('max_twenty_letters'), // JS only: <p>error message</p> TS only support string
    },
    pattern: {
      value: /^[a-zA-Z ]*$/,
      message: t('special_chars'),
    },
  },
  lastName: {
    required: t('name_req'),
    minLength: {
      value: 3,
      message: t('min_three_letters'), // JS only: <p>error message</p> TS only support string
    },
    maxLength: {
      value: 10,
      message: t('max_twenty_letters'), // JS only: <p>error message</p> TS only support string
    },
    pattern: {
      value: /^[a-zA-Z ]*$/,
      message: t('special_chars'),
    },
  },
  mob_no: {
    required: t('mob_no_req'),
    minLength: { value: 10, message: 'Not a Valid Number' },
    pattern: { value: /^[0-9]+$/, message: t('no_allowed') },
  },

  eml_id: {
    pattern: {
      value: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
      message: t('invalid_email'),
    },
  },
  state: {
    required:'State is required',
  },
}
const watchAllFields = watch()
useEffect(() => {
  console.log(watch())
  console.log(errors)
}, [watchAllFields])


  const PrevBtn = () => {
    return (
      <div
        style={{ marginTop: "-10px" }}
        className="slick-arrow slick-prev"
        onClick={() => slidePrev()}
      >
        <img src={home.left_icon} style={{ width: "6px", height: "10px" }} />
      </div>
    );
  };
  const NextBtn = () => {
    return (
      <div
        style={{ marginTop: "-10px" }}
        className="slick-arrow slick-next"
        onClick={() => slideNext()}
      >
        <img src={home.right_icon} style={{ width: "6px", height: "10px" }} />
      </div>
    );
  };

  const slideNext = () => {
    if (active < carouselData?.length - 1) {
      slider?.current?.slickGoTo(active + 1);
      setActive((prev) => prev + 1);
    }
  };

  const slidePrev = () => {
    if (active > 0) {
      slider?.current?.slickGoTo(active - 1);
      setActive((prev) => prev - 1);
    }
  };

  const settings = {
    infinite: true,
    initialSlide: active,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevBtn />,
    nextArrow: <NextBtn />,
  };

  const steps = ["Canada", "US", "UK", "Australia"];

  return (
    <div className="backgrnd">
      <Headlist />
      <div
        className="top-section-back"
        style={{ backgroundImage: `url(${studyAbroad.bckgrnd_map})` }}
      >
        <div className="top-section">
          <div className="form-sec">
            <h1>
              Want to Study Abroad?
            </h1>
            <div className="abroad-desc">Get a personalized advice</div>
            <form onSubmit={handleSubmit((data)=>alert(JSON.stringify(data)))}>
              <div>
                <div style={{flexDirection:'column', width:'45%'}}>
                  <input type='text' placeholder='Enter First Name' {...register('firstName', validationSchema.firstName)}/>
                  {touched.firstName && errors.firstName?.message && (
                  <div className="abroad-err-msg">*{errors?.firstName?.message}</div>
                  )}
                </div>
                <div style={{flexDirection:'column', width:'45%'}}>
                   <input type='text' placeholder='Enter Last Name' {...register('lastName', validationSchema.lastName)}/>
                   {touched.lastName && errors.lastName?.message && (
                    <div className="abroad-err-msg">*{errors?.lastName?.message}</div>
                    )}
                </div>
              </div>
              <div style={{flexDirection:"column"}}>
                <input type='email' placeholder='Email' {...register('eml_id', validationSchema.eml_id)}/>
                {touched.eml_id && errors.eml_id?.message && (
                <div className="abroad-err-msg">*{errors?.eml_id?.message}</div>
                )}
              </div>
              <div style={{flexDirection:'column'}}>
                <input type='text' placeholder='Phone no.'  {...register('mob_no', validationSchema.mob_no)}/>
                {touched.mob_no && errors.mob_no?.message && (
                <div className="abroad-err-msg">*{errors?.mob_no?.message}</div>
                )}
              </div>
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label"> Select State </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="state"
                  style={{height:'3rem',}}
                  label='Select State'
                  {...register('state', validationSchema.state)}
                  error={Boolean(touched.state && errors.state?.message)}
                >
                  {indianStates.map((x,i)=> <MenuItem value={x.name} key={i}>{x.name}</MenuItem>)}
                </Select>
              </FormControl> 
              <button type="submit" className="abroad-button">
                Get Started &nbsp;&nbsp;<FaChevronCircleRight/>
              </button>
            </form>

          </div>
          <div className="carousel-sec">
            <div className="back-world">
              <img className="world-img" src={studyAbroad.world_map} />
            </div>
            <div className="country-carousel">
              <Stepper
                className="stepper-sec"
                alternativeLabel
                connector={<StepConnector classes={{ line: "connector-color" }} />}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      classes={{ label: `back-col-${index + 1} ctry-label` }}
                      StepIconComponent={() => {
                        return (
                          <div
                            className={`icon-comp back-col-${index + 1} ${
                              active === index ? "active-ctry" : ""
                            }`}
                          ></div>
                        );
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Slider ref={slider} {...settings}>
                {carouselData.map((item) => (
                  <CarouselCard cardItem={item} />
                ))}
              </Slider>
            </div>
          </div>
        </div>





      </div>
      <div className="mid-section">
        <p className="vision-text">
          {t('open_doors')}
        </p>
      </div>
      <div className="bottom-section">
        <h2 className="why-us-head">{t('why_chs_us')}</h2>
        <div className="feature-sec">
          {Services.map((item) => (
            <Card cardItem={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudyAbroad;
