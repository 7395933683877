import React from "react";

const CarouselCard = ({cardItem}) => {
const { id, country, descPoints } = cardItem
  return (
    <div className="card">
      <header>
        <h3 className={`heading-${id}`}>Study in {country}</h3>
      </header>
      <section className="desc-points">
        <ul>
          {descPoints.map((item) =>  <li><span>{item}</span></li>)}
        </ul>
      </section>
    </div>
  );
};

export default CarouselCard;
