import React from 'react'
import { useTranslation } from 'react-i18next';
import { contact } from '../../assets/images'

const GetInTouch = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="contact-mail-container">
        <div className='contact-mail-header'>
          <img src={contact.mail_call} />
          <div className="contact-mail-title-content">
            <h4>{t("get_in_touch")}</h4>
            <h5>{t("feel_free_to_reach_us_for_feedback_or_enquire")}</h5>
          </div>

        </div>
        <div className='contact-mail-content'>
          <h3>{t("for_school")}</h3>

          <div className='row-spaceBetween'>

            <div className='contact-mail-card'>
              <div>
                <h4>{t("phone")}</h4>
                <p>{"+91 9971 839 153"}</p>
              </div>

              <img src={contact.call} />
            </div>

            <div className='contact-mail-card'>
              <div>
                <h4>{t("email")}</h4>
                <p onClick={() => window.open(`mailto:info@proctur.com`)}>{"info@proctur.com"}</p>
              </div>
              <img src={contact.mail} />
            </div>
          </div>

        </div>
      </div>

      <div className='contact-city-content'>
        <p id="cityLabel">{t("below_cities")}</p>
        <div className='row-spaceBetween'>

          {/* <div className='city-card'>
            <div className='city-card-img-400px'>
              <img src={delhi} />
              <h4 id="delhi">New Delhi</h4>
            </div>

            <h5>Registered Office</h5>
            <p onClick={() => window.open`https://www.google.com/maps/place/Proctur+-+Pocket+Classroom/@28.056246,76.5260835,8.91z/data=!4m5!3m4!1s0x390d0493b09b821f:0x5ac21685d0f76b7!8m2!3d28.6414286!4d77.0833084`}>{`15D, Pocket A, Vikas Puri Extension, New Delhi-110018`}</p>
          </div> */}

          <div className='city-card'>
            <div className='city-card-img-400px'>
              <img src={contact.pune} />
              <h4 id="pune">{t("pune")}</h4>
            </div>

            <h5>{t("head_office")}</h5>
            <p onClick={() => window.open(`https://www.google.com/maps/place/Proctur+-+Your+Pocket+Classroom!/@18.5671552,73.7602223,14.69z/data=!4m5!3m4!1s0x3bc2bf2157310bf9:0x891fb258d3a78e0b!8m2!3d18.5747303!4d73.7750154`)}>{t("head_office_add")}</p>
          </div>

          <div className='city-card'>
            <div className='city-card-img-400px'>
              <img src={contact.noida} />
              <h4 id="noida">{t("noida")}</h4>
            </div>

            <h5>{t("branch_office")}</h5>
            <p onClick={() => window.open(`https://www.google.com/maps/place/Ground+floor,+H+146,+147,+Sector+63+Rd,+E+e,+A+Block,+Sector+63,+Noida,+Uttar+Pradesh+201307/@28.630831,77.3815023,19.93z/data=!4m5!3m4!1s0x390cef60825835bb:0x79fbdd7b75c22aa1!8m2!3d28.6310772!4d77.3821132`)}>{t("branch_office_add")}</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default GetInTouch
