import { Box, Button, Modal } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsPlusCircleFill } from 'react-icons/bs'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../../api/api'
import { APIURL } from '../../api/url'
import { details } from '../../assets/images'
import './claimPopup.css'
export default function ClaimPopup({ open, handleClose }) {
  const { t } = useTranslation()
  const i_p_id = open
  const [docNm, setDocNm] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [upload_fl, setUpload_fl] = useState([])
  const [docNmError, setDocNmError] = useState(false)

  const handleAddFile = ()=>{
    if(upload_fl?.length<3 && selectedFile){
      if(!docNm){
        setDocNmError(true)
      }
      else{
        const {name} = selectedFile
        const extension = name.substring(name.lastIndexOf('.'), name.length)
        let renamedFile = new File([selectedFile], docNm+extension, {type: selectedFile.type})
        selectedFile.size < 500000 ? setUpload_fl([...upload_fl, renamedFile]) : toast.error('File Size cannot be more than 500KB')
        setSelectedFile(null)
        setDocNm('')
        setDocNmError(false)
      }
    }else{
      toast.error('Maximum 3 files can be uploaded.')
    }
  }
  const handleRemoveFile = (name) =>{
    const updated = upload_fl.filter(x=>x.name!==name)
    setUpload_fl(updated)
  }
  const onSubmit = (data) => {
    const { name, mob_no, eml_id } = data
    let u_data= {
      name,
      mob_no,
      eml_id,
      i_p_id,
    }
    
    const fd = new FormData()
    fd.append('u_data', JSON.stringify(u_data))
    upload_fl.forEach((x)=>{
      fd.append('files', x)
    })

    api
      .postCustom({
        url: APIURL.claim,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: fd,
      })
      .then((res) => {
      })
      .catch((e) => errorMsg(e))
      .finally((e) => handleClose())
  }
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, touchedFields: touched, dirtyFields },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      doc_nm: '',
      name: '',
      mob_no: '',
      eml_id: '',
    },
    criteriaMode: 'all',
  })
  const validationSchema = {
    name: {
      required: t('name_req'),
      minLength: {
        value: 3,
        message: t('min_three_letters'), // JS only: <p>error message</p> TS only support string
      },
      maxLength: {
        value: 20,
        message: t('max_twenty_letters'), // JS only: <p>error message</p> TS only support string
      },
      pattern: {
        value: /^[a-zA-Z ]*$/,
        message: t('special_chars'),
      },
    },
    mob_no: {
      required: t('mob_no_req'),
      minLength: { value: 10, message: 'Not a Valid Number'},
      pattern: { value: /^[0-9]+$/, message: t('no_allowed') },
    },
    eml_id: {
      pattern: {
        value: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
        message: t('invalid_email'),
      },
    },
  }


  return (
    <Modal open={Boolean(open)} onClose={handleClose}>
      <Box className="claim-popup-container">
        <div className="claim-header">
          <div className="claim-header-div1">
            <div className="claim-header-icon-container">
              <img src={details.claimFlagFill} className="claim-header-icon" />
            </div>
          </div>
          <div className="claim-header-div2">
            <div className="claim-header-title">Claim Form</div>
            <div className="claim-header-content">
              Please Upload Document that Verifies Your Ownership Of Institute
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="claim-form">
            <div className="element">
              <label htmlFor="name" className="claim-form-field-label">
                {t('name')}
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="claim-input"
                type={'text'}
                placeholder="Enter Name"
                {...register('name', validationSchema.name)}
                id={'name'}
              />
              {touched.name && errors.name?.message ? (
                <div className="claim-err-msg">*{errors?.name?.message}</div>
              ) : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="element">
                <label htmlFor="mob_no" className="claim-form-field-label">
                  {t('mob_no')}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  className="claim-input wid-90"
                  type={'text'}
                  maxLength={10}
                  placeholder="99999 99999"
                  {...register('mob_no', validationSchema.mob_no)}
                  id={'mob_no'}
                />
                {touched.mob_no && errors.mob_no?.message ? (
                  <div className="claim-err-msg">*{errors?.mob_no?.message}</div>
                ) : null}
              </div>

              <div className="element">
                <label htmlFor="eml_id" className="claim-form-field-label">
                  {t('email_add')}
                  <span style={{ color: 'red' }}></span>
                </label>
                <input
                  className="claim-input"
                  type={'text'}
                  placeholder="username@domain.com"
                  {...register('eml_id', validationSchema.eml_id)}
                  id={'eml_id'}
                />
                {touched.eml_id && errors.eml_id?.message ? (
                  <div className="claim-err-msg">*{errors?.eml_id?.message}</div>
                ) : null}
              </div>
            </div>

            <div className="element">
              <label htmlFor="doc_nm" className="claim-form-field-label">
                Document Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="claim-input"
                type={'text'}
                placeholder="Enter"
                value={docNm}
                onChange={(e) => setDocNm(e.target.value)}
                id={'doc_nm'}
              />
              { (!docNmError || Boolean(docNm) ) ? null : <div className="claim-err-msg">*Enter Document Name</div> }
            </div>

            <div className="element">
              <label className="claim-form-field-label">
                Upload Document
              </label>
              <div htmlFor="upload_fl" className="claim-input" style={{ marginBottom:0 }}>
                <label htmlFor="upload_fl" style={{ width: '80%' }}>
                  {selectedFile?.name || 'No File Selected'}
                </label>
                <BsPlusCircleFill
                  onClick={()=> selectedFile ? handleAddFile() : toast.error('Select file before adding')}
                  fontSize={'1rem'}
                  color={'rgba(66, 116, 176, 1)'}
                  style={{ marginBottom: -3, marginRight: 5, height: 'auto', cursor: 'pointer' }}
                />
              </div>
              <div style={{fontSize:'0.562rem'}}>Format accepted: jpg, jpeg, img, pdf / Max size 500kb.</div>

              <input
                className={'claim-file-input'}
                type={'file'}
                placeholder="No File Selected"
                accept=".png, .jpg, .jpeg, .pdf"
                // {...register('upload_fl', validationSchema.upload_fl)}
                id={'upload_fl'}
                onChange={(e) => {
                  setSelectedFile(e.target.files[0])
                }}
                defaultValue={''}
                size={500}
              />
            </div>
            <div className='claim-doc-list'>
              {upload_fl.map((file, index) => 
                  <div key={index}>
                    <div>
                      <img src={file?.type.includes('pdf') ? details.pdfIcon : file?.type.includes('png') ? details.pngIcon : details.jpegIcon} style={{ height:'2rem', width:'2rem',}}/>
                      {file.name}
                    </div>
                    <IoIosCloseCircleOutline fontSize={'1.32rem'} color={'rgba(255, 7, 7, 1)'} style={{cursor:'pointer'}} onClick={()=>handleRemoveFile(file.name)}/>
                  </div>
              )}
            </div>
            <div className='claim-actions'>
              <Button variant="outlined" onClick={handleClose}>
                  Cancel
              </Button>
              <Button variant="contained" type='submit'>Submit</Button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  )
}