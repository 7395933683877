import axios from 'axios'
import config from '../config'
import URL from './url'

const api = {

    get: (url) => axios.get(URL[config.env].BASE_URL + url),
    getAuth: (request) => axios.get(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { "Authorization": request.token, "Content-Type": "application/json" }
        }
    ),
    getCustom: (request) => axios.get(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { ...request.headers},
            params: request.params
        },
    ),
    post: (request) => axios.post(
        URL[config.env].BASE_URL + request.url,
        request.data
    ),
    postAuth: (request) => axios.post(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { Authorization: request.token }
        }
    ),
    postCustom: (request) => axios.post(
        URL[config.env].BASE_URL + request.url, 
        request.data,
        {
            headers: { ...request.headers, },
            params: request.params,
            // timeout: 11000,
            // timeoutErrorMessage:'Network error',
        },
    ),
    put: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data
    ),
    putAuth: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { Authorization: request.token }
        }
    ),
    putOther: (request) => axios.put(
        URL[config.env].BASE_URL + request.url,
        request.data,
        {
            headers: { ...request.headers }
        }
    ),

    delete: (request) => axios.delete(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { ...request.headers}
        }
    ),

    deleteAuth: (request) => axios.delete(
        URL[config.env].BASE_URL + request.url,
        {
            headers: { Authorization: request.token },
            params: request.params,
        }
    ),
}



export const errorMsg = (err) => {
    if (err.response?.data?.error) {
        return `Error: ${err.response?.data?.error[0].error_message}`
    }
    else if(err.response?.data?.message)
        return `Error: ${err.response.data.message}`
    else if(err?.message)
        return `Error: ${err.message}`
}

export default api