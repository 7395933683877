
import Sidebar from './sidebar'
import Content from './content'
import '../details.css'

const index = ({profileDetails, profileType}) => {
    return (
        <div className="details-main-content">
            <Sidebar id={profileDetails.id} profileType={profileType}/>
            <Content id={profileDetails.id} profileDetails={profileDetails}/>
        </div>
    )
}

export default index


