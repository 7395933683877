import { combineReducers } from 'redux'

import auth from './auth'
import compare from './compare'
import details from './details'
import listing from './listing'
import location from './location'

const rootReducer = combineReducers({ auth, listing, details, compare, location })

export default rootReducer
