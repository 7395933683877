import React from "react";
const Card = ({cardItem}) => {
  const { cardHeading, cardText, theme, icon } = cardItem
  return (
    <div className="card-content">
      <div className="icon-container" style={{ background: theme}}>
        <img src={icon} />
      </div>
      <h3>{cardHeading}</h3>
      <p>{cardText}</p>
    </div>
  );
};

export default Card;
