import { studyAbroad } from "../../assets/images";

export const Services = [
    {
        id: '1',
        cardHeading: 'Counselling Free of Cost',
        cardText: 'We have a team of best experts who provides free of cost counseling to the students.',
        theme: '#FFD6DE',
        icon: studyAbroad.counsel
    },
    {
        id: '2',
        cardHeading: 'IELTS Coaching',
        cardText: 'We help you in getting the best IELTS coaching around you.',
        theme: '#E0FFDF',
        icon: studyAbroad.ielts
    },
    {
        id: '3',
        cardHeading: 'University selection',
        cardText: 'We help you in selecting the best university of the world according to your interest and preference.',
        theme: '#E4F6FF',
        icon: studyAbroad.uni_select
    },
    {
        id: '4',
        cardHeading: 'Personal statements',
        cardText: 'Based on the profiles of students, we list out the best universities for them',
        theme: '#FFF3E9',
        icon: studyAbroad.per_sta
    },
    {
        id: '5',
        cardHeading: 'Pre-departure Orientation',
        cardText: 'We help you in managing all the pre-departure formalities so that you can board your flight at your ease.',
        theme: '#ECFFEE',
        icon: studyAbroad.pre_depart
    },
    {
        id: '6',
        cardHeading: 'Visa advisor',
        cardText: '6th Safest country to live in according to Global Peace Index 2022',
        theme: '#F0E9FF',
        icon: studyAbroad.visa_adv
    },
    {
        id: '7',
        cardHeading: 'Application Assistance',
        cardText: 'We provide the statement of purpose, letter of recommendation, and various other forms.',
        theme: '#FFF9EA',
        icon: studyAbroad.app_assist
    },
    {
        id: '8',
        cardHeading: 'Review',
        cardText: 'We are blessed with good reviews from our students & this is what motivates us to get better.',
        theme: '#FFEEF9',
        icon: studyAbroad.ftr_review
    }
]