import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box } from "@mui/material";
import api, {errorMsg} from "../../../api/api";
import { APIURL } from "../../../api/url";
import { isphnValid } from "../../../utils/utils";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "40%",
  width: "100%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 8,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  fontFamily: "Poppins,Lato",
  overflow: "hidden",
};

const childObj = {
  id: "",
  name: "",
  mob_no: "",
  email_id: "",
}

const AddChild = ({ setAddChild, showAddChild, children, setChildren, activeChild, setActiveChild, userData, setLoading }) => {
  const { t } = useTranslation()
  const { c_id, up_id, token } = userData
  const [childInfo, setChildInfo] = useState(
    activeChild?.id
      ? activeChild
      : childObj
  );
  const [submitEnable, setSubmitEnable] = useState(false)

  useEffect(() => {
    const mobNoValid = isphnValid(childInfo?.mob_no)
    if (childInfo?.name && childInfo?.mob_no && mobNoValid) {
      setSubmitEnable(true)
    } else {
      setSubmitEnable(false)
    }
  }, [childInfo])

  const saveDetails = () => {
    setLoading(true)
    if (activeChild.id) {   // Edit existing child
      let prevChildren = [...children];
      const ind = prevChildren.findIndex((item) => item.id === activeChild.id);
      prevChildren[ind] = childInfo;
      const reqBody = {
        parent_id: c_id,
        name: childInfo.name,
        mob_no: childInfo.mob_no,
        email_id: childInfo.email_id,
        uid: up_id,
      };
      api.putAuth({ url: `${APIURL.child}/${childInfo.id}/update`, token: `Bearer ${token}`, data: reqBody }).then((data) => {  
        toast.success('Child updated successfully')
          setChildren(prevChildren);
      }).catch((err) => {
        if (err.error) {
          toast.error(err.error[0].error_message)
        } else {
          toast.error(errorMsg(err))
        }
      }).finally(()=>setLoading(false));
    } else { // Add new Child
      const prevChildren = children?.length ? [...children] : [];
      const reqBody = {
        parent_id: c_id,
        name: childInfo.name,
        mob_no: childInfo.mob_no,
        email_id: childInfo.email_id,
        uid: up_id,
      };
      api.postAuth({ url: `${APIURL.child}/add`, token: `Bearer ${token}`, data: reqBody }).then((data) => {
          toast.success('Child added successfully')
          setChildren([...prevChildren, childInfo]);
      }).catch((err) => {
        if (err.error) {
          toast.error(err.error[0].error_message)
        } else {
          toast.error(errorMsg(err))
        }
      }).finally(()=>setLoading(false));
    }
    setAddChild(false);
  };

  const onCancel = () => {
    setActiveChild(childObj)
    setAddChild(false)
  }

  return (
    <Modal open={showAddChild}>
      <Box sx={style}>
        <div className="mod-container">
          <header>
            <span className="add-child-heading">{t('add_child')}</span>
          </header>
          <div className="fields-container">
            <div className="inp-row">
              <input
                className="child-inp"
                value={childInfo.name}
                onChange={(e) => {
                  setChildInfo({ ...childInfo, name: e.target.value });
                }}
                placeholder="Child Full Name*"
              />
              <div className="mob-no-sec">
                <select>
                  <option value="ind">IND +91</option>
                </select>
                <input
                  maxLength={10}
                  className="child-inp width-70-per"
                  value={childInfo.mob_no}
                  onChange={(e) => {
                    setChildInfo({ ...childInfo, mob_no: e.target.value });
                  }}
                  placeholder="Mobile Number*"
                />
              </div>
            </div>
            <div className="inp-row">
              <input
                className="child-inp"
                value={childInfo.email_id}
                onChange={(e) => {
                  setChildInfo({ ...childInfo, email_id: e.target.value });
                }}
                placeholder="Child Email Id"
              />
            </div>
          </div>
          <div className="button-sec">
            <button className="removeButton" onClick={() => onCancel()}>
              {t('cancel')}
            </button>
            <button
              className="ViewButton"
              style={{ marginLeft: "20px" }}
              onClick={() => saveDetails()}
              disabled={!submitEnable}
            >
              {t('save')}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddChild;
