import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api, { errorMsg } from '../api/api'
import { APIURL } from '../api/url'

export const addWishList = ({ wishListEdu, wishListInst, userData }, profileType, dispatch, id) => {
  if (Object.keys(userData ?? {}).length) {
    api
      .postCustom({
        url: APIURL.wishlistAdd,
        // headers: {
        //   Authorization: `Bearer ${userData.token}`,
        // },
        data: {
          [profileType === 'educator' ? 'e_id' : 'i_p_id']: id,
          stu_id: userData.c_id,
        },
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          if (profileType === 'educator') {
            dispatch({
              type: 'UPDATE_WISHLIST',
              wishListEdu: [...wishListEdu, res.data.result],
            })
          } else {
            dispatch({
              type: 'UPDATE_WISHLIST',
              wishListInst: [...wishListInst, res.data.result],
            })
          }
        }
      })
      .catch((err) => {
        toast.error(errorMsg(err))
      })
  } else {
    dispatch({ type: 'SHOW_SIGN_IN', value: true })
  }
}

export const removeWishList = (props, profileType, dispatch, wish) => {
  const { wishListEdu, wishListInst, userData } = props
  if (Object.keys(userData ?? {}).length) {
    let wish_id
    if (profileType === 'educator') {
      wish_id = wishListEdu?.find((x) => x.entity_id === wish.entity_id)?.wish_id
    } else {
      wish_id = wishListInst?.find((x) => x.entity_id === wish.entity_id)?.wish_id
    }
    wish_id &&
      api
        .delete({
          url: `website/wishlist/${wish_id}/delete`,
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res.data.status_code === 200) {
            if (profileType === 'educator') {
              let updated = wishListEdu.filter((x) => x.entity_id !== wish.entity_id)
              dispatch({
                type: 'UPDATE_WISHLIST',
                wishListEdu: [...updated],
              })
            } else {
              let updated = wishListInst.filter((x) => x.entity_id !== wish.entity_id)
              dispatch({
                type: 'UPDATE_WISHLIST',
                wishListInst: [...updated],
              })
            }
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        })
  } else {
    dispatch({ type: 'SHOW_SIGN_IN', value: true })
  }
}
