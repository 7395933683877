import React, { useRef } from "react";
import { student } from '../../../assets/images'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";





export default function PaymentForm(props) {
    // const receiptTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4",);
        const data = await html2canvas(document.querySelector("#receiptTemplateRef"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("invoice.pdf");
    };

    return (
        <>
            <div className="modal-container" >
                <div style={{ background: ' #FFFFFF', borderRadius: '8px', marginBottom: "50px", marginTop: '80px' }}>
                    <div style={{ background: 'linear-gradient(90deg, #04498A 19.01%, #52C8E6 94.66%)', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <label className="formHeading">Invoice</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={student.download} style={{ width: '15px', height: "15px" }} onClick={() => handleGeneratePdf()}></img>
                            <img src={student.cr} style={{ width: '15px', height: "15px", marginLeft: '10px' }} onClick={() => props.setPayment(false)}></img>
                        </div>
                    </div>

                    <div id="receiptTemplateRef">
                        <div style={{ display: 'flex', flexDirection: 'row', background: ' #E7F2FF', justifyContent: 'space-between', padding: '10px' }}>
                            <img src={student.logo} style={{ width: '120px', height: '120px' }}></img>
                            <div>
                                <div className="StudeeHeading">Studde</div>
                                <div className="addressHeading">Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</div>
                                <div className="addressHeading">Email : info@yourschoolname.com</div>
                                <div className="addressHeading">Ph: +91 9123456780</div>
                            </div>
                            <div className="Reciept" style={{ display: 'inline-block', alignSelf: 'flex-end' }}>Invoice</div>

                        </div>


                        <div style={{ padding: '10px' }}>
                            <div className="Appliedto" style={{ marginBottom: '8px' }}><div style={{ background: '#4274B0', width: '3px', height: '20px', marginRight: '10px' }}></div>Details</div>
                            <div className="InstName" >Name<label className="SchoolName" style={{ marginLeft: '70px' }}>KCS School</label></div>
                            <div className="InstName">ID<label className="SchoolName" style={{ marginLeft: '95px' }}>AHGGHHGGHGHGHGHHG</label></div>
                            <div className="InstName">Contact No.<label className="SchoolName" style={{ marginLeft: '30px' }}>1234567899</label></div>
                        </div>


                        <div style={{ padding: '10px' }}>
                            <div style={{ border: "1px solid #C4C4C4", borderRadius: "5px", marginBottom: '10px' }}>
                                <div className="Details" style={{ background: ' #4274B0', padding: '5px', justifyContent: 'space-between', display: 'flex' }}>
                                    <div>School/ Institute Name</div>
                                    <div>Course/ Standard</div>
                                    <div>Amount (₹)</div>

                                </div>

                                <div style={{ borderBottom: "1px solid #C4C4C4", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: "10px" }}>
                                    <div className="InstName" style={{ width: '30%' }}>Margshree classes delhi (IIT-JEE PMT NEET MEDICAL)</div>
                                    <div className="SchoolName" >Neet</div>
                                    <div className="InstName" >₹ 1,00,000 </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px', marginBottom: '40px' }}>
                                <div>
                                    <div className="InstName" >Invoice No.<label className="SchoolName" style={{ marginLeft: '70px' }}>37AADCB2230M2ZR</label></div>
                                    <div className="InstName">Applied On<label className="SchoolName" style={{ marginLeft: '70px' }}>17-May-2022</label></div>

                                </div>
                                <div>

                                    <div className="InstName" >Sub Total <label className="SchoolName" style={{ marginLeft: '150px' }}>₹ 10,00,000.00</label></div>
                                    <div className="InstName">GST (9%)<label className="SchoolName" style={{ marginLeft: '155px' }}>₹ 90,000.00</label></div>
                                    <div style={{ border: '1px solid #2865B5', marginTop: '10px', marginBottom: '10px' }}></div>
                                    <div className="InstName" >Total Amount <label className="SchoolName" style={{ marginLeft: '120px'}}>₹ 10,00,000.00</label></div>
                                    <div className="InstName">Total Amount (in words)</div>
                                    <div className="SchoolName">Thirty two thousand five hundred and rupees only</div>
                                </div>
                            </div>

                        </div>






                        <div className="InstName" style={{ background: ' #E7F2FF', display: 'flex', justifyContent: 'center', padding: '5px', marginBottom: '10px' }}>Note:This is fee form admission application only. We do not assure you any guarantee of admission.</div>
                    </div>


                </div>
            </div>
        </>
    )
}