import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }
export function short(arr, n){
  return Array.isArray(arr) && arr.length!==0
    ? arr.length > n
      ? arr.slice(0, n).join(', ') + ` and ${arr.length - n} more`
      : arr.join(', ')
    : 'N/A'
}

export function YouTubeGetID(url){
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length===11)? match[7] : false;
}

export function  getYTVideoThumbnail(url){
  let id = YouTubeGetID(url)
  if(id){
    return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
  }
  return false
}

export const isphnValid = (val) => {
	let mobNoValid = false
    const regex = /^[0-9]{10}$/
    if (regex.test(val)) {
      mobNoValid = true
    } else {
      mobNoValid = false
    }
	return mobNoValid
}

export const getDistance = (lat1, lon1,lat2, lon2, unit)=> {
if([lat1, lat2, lon1, lon2].some((val)=>(val===null || val===undefined))){
	return '-'
}
	if (((lat1 === lat2) && (lon1 === lon2)) || ((lat1 === lat2) && (lon1 === lon2))) {
		return 0;
	}
	else {
		let radlat1 = Math.PI * lat1/180;
		let radlat2 = Math.PI * lat2/180;
		let theta = lon1-lon2;
		let radtheta = Math.PI * theta/180;
		let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist.toFixed(2);
	}
}
export const profileTypefromId = (id)=>{
	switch(id){
	  case '1': {return 'COACHING'}
	  case '2': {return 'SCHOOL'}
	  case '3': {return 'EDUCATOR'}
	  case '4': {return 'STUDENT'}
	  case '5': {return 'STAFF'}
	  case '6': {return 'SUPERADMIN'}
	  default: {return 'error'}
	} 
  }

export const APIKEY = {
	PLACE: 'AIzaSyBgjbTWg8b3WbMXvdykC5zn28RdmNKil6k',
	GEO_LOCATION: 'AIzaSyArgxwzg6Zl_RhGD1QzDoO4WpsvaVoDGu0'
}
