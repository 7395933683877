import { Skeleton } from '@mui/material'
import './skeleton.css'
export const skeletonSchoolCoach = (skeletonArray) => {
  return skeletonArray.map((obj, index) => (
    <div key={index} className="slider-container">
      <div className="carousel-img-container">
        <Skeleton variant="rectangular" 
        className="carousel-img-skel h-100p-imp" 
        // style={{width:'100%', height:'100%'}}
        />
      </div>
      <div className="carousel-school-content">
        <div className="carousel-school-text">
          <Skeleton variant="text" className='w-60p'/>
        </div>
        <div className="certificate-container">
          <div className="certificate-container-text">
            <Skeleton variant="text" className='w-30p' />
          </div>
        </div>
      </div>
    </div>
  ))
}

export const skeletonEdu = (skeletonArray) =>
  skeletonArray.map((item, index) => (
    <div key={index} className="slider-container ">
      <div className="carousel-img-container">
        <Skeleton variant="rectangular" className="carousel-img-skel h-100p-imp"/>
      </div>
      <div className="carousel-educator-content">
        <Skeleton variant="text" className="carousel-teacher-name w-70p h-2em-imp" />
        <Skeleton
          variant="text"
          className="carousel-experiance-text w-60p"
          height={'1.5rem'}
          sx={{ marginBottom: 1 }}
        />
        <Skeleton variant="text" /> 
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </div>
    </div>
  ))

export const skeletonProfileHome = (skeletonArray) =>
  skeletonArray.map((obj, index) => (
    <div key={index} className="header-menu">
      <Skeleton
        variant="rectangular"
        width={45}
        height={51}
        sx={{
          marginBottom: '10px',
        }}
      />
      <p>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
      </p>
    </div>
  ))

export const skeletonFilters=(heading)=>{
return(
  <div>
  <div className="listing-filter-div" id="listing-filter-div">
    <div className="header">
      <h3>{heading}</h3>
    </div>
  <span style={{display:'flex', justifyContent:'space-between', padding:15}}><Skeleton variant="text" width={'100%'} height={'2em'} /></span>
  <span style={{display:'flex', justifyContent:'space-between', padding:15}}><Skeleton variant="text" width={'100%'} height={'2em'} /></span>
  <span style={{display:'flex', justifyContent:'space-between', padding:15}}><Skeleton variant="text" width={'100%'} height={'2em'} /></span>
  <span style={{display:'flex', justifyContent:'space-between', padding:15}}><Skeleton variant="text" width={'100%'} height={'2em'} /></span>
  <span style={{display:'flex', justifyContent:'space-between', padding:15}}><Skeleton variant="text" width={'100%'} height={'2em'} /></span>
</div>
</div>
)
}

export const skeletonListCards=(skeletonArray)=>{
 return skeletonArray.map((item, index)=>
        <div  key ={index} style={{height:'35vh', display:'flex',flexDirection:'column', width:'100', boxSizing:'border-box', padding:0,paddingBottom:0, margin:20, justifyContent:'space-between', borderRadius:10, gap:5 }}>
        <div style={{display:'flex',height:'90%', borderRadius:'inherit'}}>
          <Skeleton variant='rectangle' style={{height:'100%',maxWidth:'20%', aspectRatio:'1', borderRadius:'inherit'}}/>
          <div style={{display:'flex', flexDirection:'column', padding:10, width:'100%', borderRadius:'inherit'}}>
            <Skeleton variant='text' style={{width:'80%', height:'2em', borderRadius:'inherit'}}/>
            <Skeleton variant='text' style={{width:'30%', height:'1em', borderRadius:'inherit'}}/>
            
            <Skeleton variant='text' style={{width:'50%', height:'1.5em', borderRadius:'inherit'}}/>
            <Skeleton variant='text' style={{width:'30%', height:'1.5em', borderRadius:'inherit'}}/>
            <div style={{width:'80%', display:'flex', gap:'5%', height:'100%', borderRadius:'inherit'}}>
              <Skeleton variant='rectangle' style={{flex:1, width:'100%', borderRadius:'inherit'}} height={'100%'} />
              <Skeleton variant='rectangle' style={{flex:1, width:'100%', borderRadius:'inherit'}} height={'100%'} />
              <Skeleton variant='rectangle' style={{flex:1, width:'100%', borderRadius:'inherit'}} height={'100%'} />
            </div>

          </div>
        </div>
        <Skeleton variant='rectangle' style={{width:'90%',justifySelf:'flex-end', borderRadius:'inherit'}} height={'20%'} />
        </div>)
}

export const skeletonDetailsEdu = () =>{
  return(
    <div className="detail-container">
    <Skeleton variant={'text'} style={{ width: '4em', fontSize: '2em', maxWidth: '50%' }} />
    <Skeleton variant={'text'} style={{ width: '90%', fontSize: '1.5em' }} />
    <Skeleton variant={'text'} style={{ width: '70%', fontSize: '1.0em' }} />
    </div>
  )
}

export const skeletonDetailsImages = () => {
  return (
    <div className="detail-container">
      <Skeleton variant={'text'} style={{ width: '4em', fontSize: '2em', maxWidth: '50%' }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap:30
        }}
      >
        <Skeleton variant="rectangular" style={{ width: '23%', height: '10em', borderRadius:6 }} />
        <Skeleton variant="rectangular" style={{ width: '23%', height: '10em', borderRadius:6 }} />
        <Skeleton variant="rectangular" style={{ width: '23%', height: '10em', borderRadius:6 }} />
        <Skeleton variant="rectangular" style={{ width: '23%', height: '10em', borderRadius:6 }} />
     </div>
    </div>
  )
}

export const skeletonDetailsEduSidebar = () =>{
  return(
    <div className="details-sidebar" style={{height:'40vh', gap:10}}>
        <Skeleton variant='rectangular' style={{margin:15, width:'80%', height:'30px', borderRadius:4}}/>
     
        <div style={{display:'flex', flexDirection:'row', margin:15,}}>
          <Skeleton variant='circular' style={{width:'4em', height:'4em', borderRadius:4}}/>
          <div style={{ width:'100%'}}>
          <Skeleton variant='rectangular' style={{display:'flex', marginBottom:10, width:'80%', height:'1em', borderRadius:4}}/>
          <Skeleton variant='rectangular' style={{display:'flex', marginBottom:10, width:'50%', height:'1em', borderRadius:4}}/>
          <Skeleton variant='rectangular' style={{display:'flex', marginBottom:10,marginLeft:10, width:'30%', height:'1em', borderRadius:7}}/>

          </div>
        </div>

    </div>
  )
}
export const skeletonDetailsInstSidebar = () =>{
  return(
    <div className="details-sidebar" style={{display:'flex',height:'40vh', gap:3, flexDirection:'column', alignItems:'center'}}>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
        <Skeleton variant='rectangular' style={{margin:15, width:'90%', height:'3em', borderRadius:4}}/>
    </div>
  )
}

export const skeletonRatingCard = ()=>{
  return(
    <div style={{display: 'flex', gap:10, width:'100%',  flexDirection:'row', boxSizing:'border-box', padding:10}}>
      <Skeleton variant='circular' style={{width:'4em', height:'4em'}}/>
      <div  style={{width: '100%', gap:10, display:'flex', flexDirection:'column'}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Skeleton variant='text' style={{width:'25%', height:'2.5em'}}/>
          <Skeleton variant='rectangular' style={{width:'2em', height:'2em'}}/>
        </div>
        <div >
          <span style={{flexDirection:'row', display:'flex', gap:5}}>
            <Skeleton variant='text' style={{width:'6em', height:'1.5em'}}/>
            <Skeleton variant='text' style={{width:'6em', height:'1.5em'}}/>
            <Skeleton variant='text' style={{width:'6em', height:'1.5em'}}/>
          </span>
        </div>
        <p>
        <Skeleton variant='text' style={{width:'70%', height:'1.5em'}}/>
        <Skeleton variant='text' style={{width:'50%', height:'1.5em'}}/>
        </p>
      </div>
    </div>
  )
}