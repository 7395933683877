import React, { useState, useEffect } from "react";
import '../nabBar.css'
import { student } from "../../../assets/images";
import { APIURL } from '../../../api/url'
import Moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'



function MyEnquirey(props) {
    const [rdata, setRdata] = useState([])
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData
  


    useEffect(() => {
        getEnquirey()

    }, [props.filter,props.search])


    const getEnquirey = (active) => {
        props.setLoading(true)
        let request = {
            url: `enquiry/all?stu_id=${c_id}&sortOrder=${props.filter}&search_text=${props.search}&pageOffset=1&pageSize=10&isAll=false`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        }
        api.getCustom(request).then(data => {
            props.setLoading(false)
            setRdata(data.data.result.response)
            props.setCount(data.data.result.response.length??0)
        }).catch((err) => {
            console.log(err, "errrr")
            props.setLoading(false)
            if (err.response.status == 401) {
                props.dispatch({ type: 'LOGOUT' })
            } else {
                toast.error(errorMsg(err))
            }
        })

    }


    return (
        <>
            {rdata.map((item, key) => {
                return (
                    <div key={key} className="maindiv">
                        <div style={{ width: '5px', background: '#F4F9FF', borderRadius: '8px' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", flexDirection: 'row', padding: '20px' }}>
                                    <img src={student.logo_inst} style={{ width: '40px', height: '40px' }} />
                                    <div style={{ marginLeft: '20px' }}>
                                        <div className="instiHeading">
                                            {item.inst_nm}
                                        </div>
                                        <div className="intiiiSmall">
                                            {item.loc ?? ' Rajouri Garden, Delhi'}

                                        </div>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="dis-bottomdiv"  >
                                        {Moment(item.u_date).format('MM-DD-YYYY')} | {Moment(item.u_date).format('HH:mm')}</div>
                                </div>
                            </div>
                            <div style={{ borderBottom: '0.5px solid #E7E7E7', marginLeft: '30px', marginRight: '30px' }}></div>

                            <div className="intiiiSmall" style={{ padding: '20px', marginLeft: '60px' }}>{item.msg}
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu arcu tortor neque, fermentum nisl ullamcorper enim. Lacus potenti
                        varius convallis lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu arcu tortor neque, fermentum nisl ullamcorper enim. Lacus potenti varius convallis lorem   Read more */}


                            </div>
                        </div>

                    </div>
                )
            })}

        </>
    )
}


const mapStateToProps = (state) => ({ auth: state.auth })
export default connect(mapStateToProps)(MyEnquirey)