import React, { useState, useEffect } from "react"
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Form2 = (props) => {
  const { t } = useTranslation()
  const userData = useSelector(state => state.auth.userData)
  const { token, c_id, up_id } = userData
  const [parenrDetails, setParentDetails] = useState(
    {
      parent_name: "",
      parent_mobile: "",
      parent_email: "",
      relationship: "",
      occupation: "",
      // parent_aadhar_no:""
    }
  )


  useEffect(() => {
    if (props.FormData) {
      setParentDetails({
        ...parenrDetails,

        parent_name: props.FormData?.parent_name??'',
        parent_mobile: props.FormData?.parent_mobile??'',
        parent_email: props.FormData?.parent_email??'',
        relationship: props.FormData?.relationship??'',
        occupation: props.FormData?.occupation??'',
      })
    }
  }, [props.FormData])


  const savedetails = () => {
    if (parenrDetails.parent_name == "") {
      toast.error("Please enter Parent Name.")
    } else if (parenrDetails.parent_mobile == "") {
      toast.error("Please enter Parent Mobile Number.")
    } else if (parenrDetails.parent_email == "") {
      toast.error("Please enter Parent Email.")
    } else {
      props.setLoading(true)
      let request = {
        url: `website/institute/form/${props.addId}/parents/add`,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: parenrDetails
      }
      api.postCustom(request).then(data => {
        props.setLoading(false)
        toast.success("Parent details Added successfully");
        props.setFormno(3)

      }).catch((err) => {
        props.setLoading(false)
        if (err.response.status == 401) {
          props.dispatch({ type: 'LOGOUT' })
        } else {
          toast.error(errorMsg(err))
        }
      })
    }
  }

  const onlyNumberKey = (evt) => {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which || evt.keyCode || 0
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
    return true;
  }

  const validateEmail = (email) => {
    var reg =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!reg.test(email))
      toast.error("Please enter a valid email address");

  }


  return (
    <>
      <div className="form-group"><input type="text" placeholder={`${t('parent_name')} *`} value={props.FormData.parent_name} onChange={(e) => setParentDetails({ ...parenrDetails, parent_name: e.target.value })} /></div>
      <div className="form-group">
        <input placeholder={`${t('mob_no')} *`}
          type="text"
          pattern="[0-9]*"
          value={parenrDetails.parent_mobile}
          onKeyPress={onlyNumberKey}
          maxLength={10}
          onChange={(e) => setParentDetails({ ...parenrDetails, parent_mobile: e.target.validity.valid ? e.target.value : parenrDetails.parent_mobile })} />

        <input type="text" placeholder={`${t('email_add')} *`} value={props.FormData.parent_email} onBlur={(e) => validateEmail(e.target.value)} onChange={(e) => setParentDetails({ ...parenrDetails, parent_email: e.target.value })} />
      </div>
      {/* <div className="form-group"><input type="text" placeholder="Adhhaar Number *" onChange={(e)=>setParentDetails({...parenrDetails,parent_aadhar_no:e.target.value})}/></div> */}
      <div className="form-group"><input type="text" placeholder={`${t('relation')} *`} value={props.FormData.relationship} onChange={(e) => setParentDetails({ ...parenrDetails, relationship: e.target.value })} /></div>
      <div className="form-group"><input type="text" placeholder={`${t('occupation')} *`} value={props.FormData.occupation} onChange={(e) => setParentDetails({ ...parenrDetails, occupation: e.target.value })} /></div>

      <div className="submit-div">
        <p onClick={() => (props.fetchFormDetails(),props.setFormno(1))}>{t('back')}</p>
        <button onClick={() => savedetails()}>{t('save_next')}</button>
      </div>
    </>
  )
}

export default Form2