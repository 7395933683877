import { CircularProgressbar } from 'react-circular-progressbar'
import { useSelector } from 'react-redux'
import { getDistance, short } from '../../utils/utils'
import { details } from '../../assets/images'

const CompareItem = ({ compareDataItem, isSchool }) => {
  const {lat: myLat, long: myLong} = useSelector(state=>state.location.location_det)
  
  const {
    id,
    average_fee,
    avg_rating,
    own_type,
    edu_board,
    s_to_f_ratio,
    n_o_fac,
    camp_size,
    n_of_stud,
    facilities,
    std_range,
    std_nms,
    inst_courses,
    m_of_instruct,
    view_cnt,
    lat,
    long,
  } = compareDataItem

  return (
    <div>
      <p>{average_fee}/month</p>
      <p className="star">
        <div>
          {avg_rating}&nbsp;&nbsp;
          <img src={details.star} style={{ width: '14px', height: '14px', marginTop: '-5px' }} />
        </div>
      </p>
      <p>{getDistance(myLat,myLong,lat,long,'K')} km</p>
      <p>{isSchool? (std_range || short(std_nms, 3)): (inst_courses || short(inst_courses, 3) )}</p>
      <p>{m_of_instruct || '-'}</p>
      <p>{view_cnt}</p>
      {isSchool && <p>{own_type || '-'}</p>}
      {isSchool && <p>{edu_board || '-'}</p>}
      <p>{s_to_f_ratio || '-'}</p>
      <p>{n_o_fac ?? '-'}</p>
      <p>{camp_size ?? '-'}</p>
      <p>{n_of_stud+'+' || '-'}</p>
      <p className="facility">
        <span data-tip data-for="facility_tooltip" style={{ width: '50px', height: '30px' }}>
          <CircularProgressbar value={100} text={facilities?.length ?? '-'} counterClockwise={true} />
        </span>
      </p>
    </div>
  )
}
export const CompareItemEmpty = ({isSchool}) => {
  return (
    <div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      {isSchool && (
        <>
          <p></p>
          <p></p>
        </>
      )}
    </div>
  )
}

export default CompareItem;
// export CompareItemEmpty;
