import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import api, { errorMsg } from '../../../api/api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { short } from '../../../utils/utils'
import { useSelector } from 'react-redux';
import { skeletonDetailsEduSidebar } from '../../../components/Skeleton/skeleton';

const Sidebar = ({ id }) => {
  const [recommendData, setRecommendData] = useState([])
  const { t } = useTranslation()
  const isLoading = useSelector(state=>state.details?.isLoading)
  const [contentLoading, setContentLoading] = useState(false)
  // const recommendData = [{
  //   "id": "03e202a1-2fb3-4361-bdcf-a453009781bb",
  //   "name": "Bikansha123",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "0f745e3f-de38-4385-ac8b-d578e0780779",
  //   "name": "Bikansha",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "1a7bb54b-e00b-4d06-b109-ccddb9955487",
  //   "name": "ngnfnnfnf",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/icons8-clock-24.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "12th"
  //     ],
  //     "courses": [
  //       "mst crs 3"
  //     ]
  //   }
  // },{
  //   "id": "03e202a1-2fb3-4361-bdcf-a453009781bb",
  //   "name": "Bikansha123",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "0f745e3f-de38-4385-ac8b-d578e0780779",
  //   "name": "Bikansha",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "1a7bb54b-e00b-4d06-b109-ccddb9955487",
  //   "name": "ngnfnnfnf",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/icons8-clock-24.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "12th"
  //     ],
  //     "courses": [
  //       "mst crs 3"
  //     ]
  //   }
  // },{
  //   "id": "03e202a1-2fb3-4361-bdcf-a453009781bb",
  //   "name": "Bikansha123",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "0f745e3f-de38-4385-ac8b-d578e0780779",
  //   "name": "Bikansha",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/default_test.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "10th"
  //     ],
  //     "courses": [
  //       "Civil Services"
  //     ]
  //   }
  // },
  // {
  //   "id": "1a7bb54b-e00b-4d06-b109-ccddb9955487",
  //   "name": "ngnfnnfnf",
  //   "ph_url": "https://proc-discovery.s3.ap-southeast-1.amazonaws.com/test/educator/profilePhoto/icons8-clock-24.png",
  //   "sub_mst_li": null,
  //   "oth_det_map": {
  //     "standards": [
  //       "12th"
  //     ],
  //     "courses": [
  //       "mst crs 3"
  //     ]
  //   }
  // }]
  const history = useHistory()

  useEffect(() => {
    const getRecommendedData = () => {
      setContentLoading(true)
      api
        .get(`website/view/edu/${id}/recommendations`)
        .then((response) => {
          if (response.status === 200) {
            setRecommendData(response.data.result)
          }
        })
        .catch((err) => {
          toast.error(errorMsg(err))
        }).finally(()=>setContentLoading(false))
    }
    id && getRecommendedData()
  }, [id])

  if(isLoading || contentLoading){
    return(
      <>{skeletonDetailsEduSidebar()}</>
    )
  }
  if (recommendData.length === 0) {
    return (
      <div className="details-sidebar scroll">
        <div>
          <div className="recommend-content">
            <p className="title">{t('recommended')}</p>
            <h3>{t('no_recommendations')}</h3>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="details-sidebar scroll">
      <div>
        <div className="recommend-content">
          <p className="title">{t('recommended')}</p>
          {/* <div className={!viewMore &&  3 ? "filter-section-baseheight" : viewMore ? "filter-section-maxheight-active" : 'filter-section-maxheight'}> */}
          <div className={'filter-section-baseheight'}>
            {recommendData.map((item, index) => {
              const { oth_det_map, name, ph_url, id } = item
              const { courses, standards } = oth_det_map
              return (
                <div key={index} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  <div className="flex-row" style={{ width: '100%' }}>
                    <div className="recommend-img-container">
                      <img src={ph_url} style={{ width: '35px', height: '35px', top: 0, borderRadius: '50%'}}/>
                    </div>
                    <div className="recommend-user-container">
                      <p className="recommend-user-name">{name}</p>
                      <p className="recommend-user-courses">{short(courses, 3)}</p>
                      {standards && <p className="recommend-user-courses">{short(standards, 3)}</p>}
                      <div
                        className="recommend-user-btn"
                        onClick={() =>
                          history.push(`/details/educator/${name && name.replace(/ /g, '-').replace(/\./g,'').toLowerCase()}?id=${id}`)
                        }
                      >
                        {t('view_profile')}
                      </div>
                    </div>
                  </div>
                  <div style={{ border: '0.5px solid rgba(0, 0, 0, 0.38)' }} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
