import { home } from "../../assets/images";
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';

export default function BannerImg() {
    const { t } = useTranslation()
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
    };

    return (
        <div className='home-banner-container' style={{ background: `url(${home.banner}) center center`, backgroundSize: 'cover', position: 'relative', }}>
            <div className='home-banner-img-overlay'>
                <Slider {...settings} >
                    <div className='home-banner-card'>
                        <p className='home-banner-header'>{t('tutor_title')}</p>

                        <p className='home-banner-text'>{t('tutor_para')}</p>

                        <div className='home-banner-btn'>
                            <p><a href='#'>{t('get_started')}</a></p>
                        </div>
                    </div>

                    <div className='home-banner-card'>
                        <p className='home-banner-header'>{t('tutor_title')}</p>

                        <p className='home-banner-text'>{t('tutor_para')}</p>

                        <div className='home-banner-btn'>
                            <p><a href='#'>{t('get_started')}</a></p>
                        </div>
                    </div>

                    <div className='home-banner-card'>
                        <p className='home-banner-header'>{t('tutor_title')}</p>

                        <p className='home-banner-text'>{t('tutor_para')}</p>

                        <div className='home-banner-btn'>
                            <p><a href='#'>{t('get_started')}</a></p>
                        </div>
                    </div>

                    <div className='home-banner-card'>
                        <p className='home-banner-header'>12{t('tutor_title')}</p>

                        <p className='home-banner-text'>{t('tutor_para')}</p>

                        <div className='home-banner-btn'>
                            <p><a href='#'>{t('get_started')}</a></p>
                        </div>
                    </div>

                </Slider>
            </div>

        </div>
    )
}
