import { memo, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { components } from '../../assets/images'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import api, { errorMsg } from '../../api/api';
import { APIURL } from '../../api/url';
import './footer.css';
import { staticText } from './footerText';

const Footer = (props) => {
    const allProfileTypes = useSelector(state=>state.auth.allProfileTypes)
    const footerCities = useSelector(state=>state.auth.footerCities)
    const { t } = useTranslation()
    
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    },[]);

    // useEffect(()=>{
    //     const getFooterCities=()=>{
    //         api.get(APIURL.footerCities)
    //           .then((response) => {
    //             if (response.status === 200) {
    //               dispatch({
    //                 type: 'SET_FOOTER_CITIES',
    //                 footerCities: response.data.result,
    //               })
    //             }
    //           })
    //           .catch((err) => {
    //             toast.error(errorMsg(err))
    //           })
    //       }
    //     getFooterCities()
    // },[])
        
    return (
        <div className="footer">
            <div className="sections">
                <div className="logo-div">
                    <img src={components.logo} />
                </div>
                <div className="connect-div">
                    <h4>{staticText.address}</h4>
                    <p>{staticText.address_part1}<br /> {staticText.address_part2}</p>
                </div>
                <div className="social-div">
                    <a href="#"><img src={components.footer_fb}  /></a>
                    <a href="#"><img src={components.footer_lin} /></a>
                    <a href="#"><img src={components.footer_tw} /></a>
                    <a href="#"><img src={components.footer_yt} /></a>
                </div>
                <div className="app-div">
                    <a href="#"><img src={components.footer_apps} /></a>
                    <a href="#"><img src={components.footer_play} /></a>
                </div>
            </div>
            <div className="sections">
                <div className="inner-sections">
                {/* {Array.isArray(allProfileTypes) && Array.isArray(footerCities) && allProfileTypes?.map((obj,index)=>{ 
                    return( <div key={index}>
                        <h3>{obj.name}</h3>
                        {footerCities?.map((obj,index)=><a key={index} href="#"><p>{obj.name}</p></a>)}
                    </div>)})} */}
                    {Array.isArray(allProfileTypes) && allProfileTypes?.map((obj,index)=>{ 
                    return( <div key={index}>
                        <h3>{obj.name}</h3>
                    </div>)})}
                    <div>
                        <h3>{staticText.company}</h3>
                        <a href="#"><p>{staticText.about_us}</p></a>
                        <a href="#"><p>{staticText.blogs}</p></a>
                        <a href="#"><p>{staticText.privacy}</p></a>
                        <a href="#"><p>{staticText.t_and_c}</p></a>
                    </div>
                    <div>
                        <h3>{staticText.help}</h3>
                        <Link to="/contact/"> <p>{staticText.contact_us}</p></Link>
                        <a href="#"><p>{staticText.site_map}</p></a>
                    </div>
                
                </div>
            </div>
        </div>
    )
}

export default memo(Footer)