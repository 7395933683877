import { FaRupeeSign } from 'react-icons/fa'
import { IoLocationSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { home } from '../../assets/images'

export default function EducatorCard(educator, visitText) {
  const { id, f_pr_hr, ph_url, ar_nm, ct_nm, e_name, p_headline, tot_expr_yr, tot_expr_mn, high_qual, qual_names, mot } = educator
  
  return (
    <div key={id} className="slider-container ">
      <div key={id} className="carousel-img-container">
        <div className="carousel-km-text-content">
          <FaRupeeSign style={{ color: '#FFFFFF', fontSize: 10 }} />
          <p className="carousel-km-text">{f_pr_hr}</p>
        </div>
        <img src={ph_url || home.default_photo_edu} className={'carousel-img'} />
        <p className="carousel-address-text flex-row">
          &nbsp;
          <span style={{ marginBottom: '0px' }}>
            <IoLocationSharp style={{ color: '#FFFFFF' }} size={13} />
          </span>
          &nbsp;{[ar_nm, ct_nm].filter((a) => a).join(', ')}&nbsp;
        </p>

        <div className="overlay-img" />
        <div className="visit-btn-container">
          <div className="visit-btn">
            <Link to={`/details/educator/${e_name.replace(/ /g, '-').replace(/\./g,'').toLowerCase()}?id=${id}`}>{visitText}</Link>
          </div>
        </div>
      </div>
      <div className="carousel-educator-content">
        <p className="carousel-teacher-name">&nbsp;{e_name}&nbsp;</p>
        {/* <p className="carousel-school-text">{obj.school}&nbsp;</p> */}
        {/* <p className="carousel-experiance-text">{obj.experiance}&nbsp;</p> */}
        <p className="carousel-experiance-text" title={p_headline}>{p_headline}</p>

        <div className="educator-qua-container">
          <div className="educator-all-content">
            <img src={home.briefcase} style={{ width: '13.51px', height: '13.43px' }} />

            <div className="educator-content">
              <p className="educator-qua-text">&nbsp;&nbsp;{'Experience'}&nbsp;</p>
              <p className="educator-qua-value-text">
                &nbsp;&nbsp;
                {tot_expr_yr !== 0 && `${tot_expr_yr} year`}
                {tot_expr_mn !== 0 && ` ${tot_expr_mn} months`}
                {tot_expr_mn === 0 && tot_expr_yr === 0 && '-'}&nbsp;
              </p>
            </div>
          </div>

          <div className="educator-all-content">
            <img src={home.educator_cert} style={{ width: '13px', height: '12.92px' }} />
            <div className="educator-content">
              <p className="educator-qua-text">&nbsp;&nbsp;{'Qualification'}&nbsp;</p>
              <p className="educator-qua-value-text">
                &nbsp;&nbsp;{(high_qual ? high_qual : qual_names?.join(', '))||'-'}&nbsp;
              </p>
            </div>
          </div>

          <div className="educator-all-content">
            <img src={home.teaching} style={{ width: '13px', height: '12.92px' }} />
            <div className="educator-content">
              <p className="educator-qua-text">&nbsp;&nbsp;{'Teaches'}&nbsp;</p>
              <p className="educator-qua-value-text">
                &nbsp;&nbsp;{mot?.join(', ') || '-'}&nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

