import { useState } from 'react';
import { Rating } from 'react-simple-star-rating'
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { details } from '../../assets/images';
import { Skeleton, Tooltip } from '@mui/material';
import api, { errorMsg } from '../../api/api';
import { APIURL } from '../../api/url';
import { addWishList, removeWishList } from '../wishlist';
import ToolTipChild from '../../components/ToolTipChild';
import ClaimPopup from '../../components/ClaimPopup';

const TitleSection = ({profileDetails, profileType, subProfileType}) => {

    const {inst_name,is_verified, area_nm, ct_nm, view_cnt, ph_url, avg_rating, id, review_cnt, lat,long} = profileDetails
    const dispatch = useDispatch()
    const selectedIds = useSelector(state=>state.compare.selectedIds)
    const compareSubProfileType = useSelector((state) => state.compare.subProfileType)
    const { t } = useTranslation()
    const isLoading = useSelector((state) => state.details?.isLoading);
    const {wishListEdu, wishListInst, userData} = useSelector(state=>state.auth)
    const wish = (wishListEdu?.find((x) => x.entity_id === id) || wishListInst?.find((x) => x.entity_id === id))
    const [claim,setClaim]= useState('')
    return (
      <div className="details-title-section flex-row">
        <div className="claim">
          <div className='a' onClick={()=>setClaim(id)}>{t('claim_institute')}</div>
        </div>
        <div className="title-main">
          <div className="img-div">
            {isLoading ? (
              <Skeleton variant="circular" style={{ width: '100%', height: '100%' }} />
            ) : (
              <img src={ph_url || details.default_profile_inst} />
            )}
          </div>
          <div className="flex-row" style={{ marginTop: "30px" }}>
          {isLoading ? (
          <Skeleton variant="text" style={{ width: "15em", height: "3em",maxWidth:'90vw' }} />
        ) : (
          <Tooltip title={inst_name} arrow>
            <ToolTipChild>
            <h2>{inst_name}</h2>
            </ToolTipChild>
          </Tooltip>
        )
            //
            }
            {is_verified && (
              <img
                src={details.badge}
                style={{
                  width: 24,
                  height: 30,
                  marginLeft: 20,
                  objectFit: 'contain',
                }}
              />
            )}
          </div>
          <div className="flex-row location">
            <img src={details.location2} style={{ width: 16, marginRight: 8 }} />
            {isLoading ? (
              <Skeleton variant={'text'} style={{ width: '8em', maxWidth: '35vw' }} />
            ) : (
              [area_nm, ct_nm].filter((a) => a).join(', ')
            )}
          </div>
          {/* <p className="map" onClick={()=>{}}>{t('show_on_map')}</p> */}
          {!isLoading &&
              <a className="map" href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`} target="_blank" rel="noopener noreferrer">{t('show_on_map')}</a>
            }
          
        </div>
        <div className="title-compare">
          <div className="flex-row view-rating">
            <div className="flex-row view">
              <img src={details.eye} style={{ width: 17.5, height: 12.5, marginRight: 10 }} />
              {view_cnt}
            </div>
            <div className="detail-rating flex-row">
              <Rating
                // onClick={handleRating}
                iconsCount={5}
                initialValue={avg_rating}
                ratingValue={avg_rating}
                size={20}
                readonly={true}
                style={{ marginTop: 3 }}
              />
              <div className="flex-row">
                &nbsp;<p style={{ color: '#707070' }}>|&nbsp;</p> {review_cnt ?? 0} {t('reviews')}
              </div>
            </div>
          </div>

          <div className="flex-row">
            <div className="compare">
              <input
                id={`compare-${id}`}
                type="checkbox"
                checked={selectedIds.includes(id)}
                onChange={() => {
                  if (
                    selectedIds.length < 3 ||
                    selectedIds.includes(id) ||
                    compareSubProfileType !== subProfileType
                  ) {
                    dispatch({
                      type: 'UPDATE_SELECTED_COMPARE',
                      data: { id, profileType, subProfileType },
                    })
                  } else {
                    toast.warning('Only 3 institutes can be compared at a time')
                  }
                }}
              />
              <label htmlFor={`compare-${id}`}>&nbsp;&nbsp;{t('compare')}</label>
            </div>
            <div
              className="shortlist-div flex-row"
              onClick={() => {
                wish
                  ? removeWishList(
                      { wishListEdu, wishListInst, userData },
                      profileType,
                      dispatch,
                      wish
                    )
                  : addWishList({ wishListEdu, wishListInst, userData }, profileType, dispatch, id)
              }}
            >
              <FaHeart size={15} color={wish ? '#4274B0' : '#C8C8C8'} />
            </div>
          </div>
        </div>
        {claim && <ClaimPopup open={claim} handleClose={()=>setClaim('')}/>}
      </div>
    )
}

export default TitleSection