import { useRef, useState } from 'react'
import { student } from '../../../assets/images'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import { useSelector, useDispatch } from 'react-redux'
import api, { errorMsg } from '../../../api/api'
import { connect } from 'react-redux'

export default function ChangePopup(props) {
    let refs = [useRef(null), useRef(null), useRef(null), useRef(null)]
    let [val, setVal] = useState(["", "", "", ""])
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const userData = useSelector(state => state.auth.userData)
    const { token, c_id, up_id } = userData
    const [phone,setPhone] =useState("")
    const [result,setresult] =useState({})

    let changeFocus = (value, ind) => {
        if (isNaN(value * 1)) {
            refs[ind].current.value = "";
        } else {
            if (value.length > 1) {
                value = (value % 10) + "";
            }
            let newVal = [...val]
            newVal[ind] = value
            setVal(newVal);
            if (value.length === 0) {
                if (ind != 0) {
                    refs[ind - 1].current.focus();
                }
            } else {
                if (ind != 3) {
                    refs[ind + 1].current.focus();
                }
            }
        }

    }

    const ChangeNumber = () => {
      if(phone.length!=10){
        toast.error("Please enter valid mobile number.")
      }else{
       setLoading(true)
        let request = {
          url: `otp/send-otp`,
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: {
            phone: phone,    //add if change phone no
            email_id: null,   //add if change email
            user_type:"STUDENT",
            is_check_dup:true,
            uid:  up_id
          }
        }
        api.postCustom(request).then(data => {
          setLoading(false)
          console.log(data.data.result,"mmfm")
          setresult(data.data.result)
            toast.success("OTP Sent to your Phone Number.")
        }).catch((err) => {
          setLoading(false)
            if(err.response.status == 401){
            props.dispatch({ type: 'LOGOUT' })
          }else{
            toast.error(errorMsg(err))
          } ;
        })}
      }


      const validateOTP =() =>{
        if(val.join("")==""){
            toast.error("Please enter OTP.")
        }else{
         setLoading(true)
          let request = {
            url: `otp/validate-otp`,
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            data: {
              id:result.entity_id,
              otp:val.join("")
            }
          }
          api.postCustom(request).then(data => {
            setLoading(false)
            toast.success(data.data.result)
            props.setChange(false)
          }).catch((err) => {
            setLoading(false)
              if(err.response.status == 401){
              props.dispatch({ type: 'LOGOUT' })
            }else{
              toast.error(errorMsg(err))
            } ;
          })
        }}

        const onResend = () => {
            setLoading(true);
            api.postAuth({ url: `otp/resend-otp?id=${result.entity_id}` }).then((data) => {
                setresult(data.data.result)
                toast.success("Otp send sucessfully");
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                if (err.response.status == 401) {
                    props.dispatch({ type: 'LOGOUT' })
                } else {
                    toast.error(errorMsg(err))
                };
            })
        }

        const onlyNumberKey = (evt) => {
            // Only ASCII character in that range allowed
            var ASCIICode = evt.which || evt.keyCode || 0 
            if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
                evt.preventDefault();
            }
            return true;
        }


    return (
        <>
         <Loader isLoading={loading} />
            <div className="modal-container">
                <div style={{ background: ' #FFFFFF', borderRadius: '8px',width:'400px' }}>
                    <div className="changediv">
                        <div>{t('change_no')}</div>
                        <img style={{ height: '15px', width: '15px' ,marginTop:'5px',cursor:'pointer'}} src={student.cross} onClick={()=>props.setChange(false)} />
                    </div>
                    <div style={{ padding: '20px' }}>
                        <div >
                            <div className='ContactHeading'>{t('contact_no')}<label style={{color:'red'}}>*</label></div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div class="flexv-p1 w-70" style={{margin: 0 ,width:'75%'}}>

                                    <div class="select-wrappers">
                                        <select class="expandale-input w-200 india">
                                            <option value="ind">+91</option>
                                            <option>+1</option>
                                        </select>
                                    </div>
                                    <button></button>
                                    <input class="expandale-input"  placeholder="Enter"
                                     type="text"
                                     pattern="[0-9]*"
                                     value={phone}
                                     onKeyPress={onlyNumberKey}
                                     maxLength={10}
                                    onChange={(e)=>setPhone(e.target.value)}
                                    />
                                    
                                </div>
                                <div><button className="SendButton" onClick={()=>ChangeNumber()}>{t('send_otp')}</button></div>
                            </div>

                        </div>

                        <div style={{display:'flex',flexDirection:'row',marginTop:'20px'}}>
                            <img src={student.rec} />
                            <div className="OtpHeading" style={{marginTop:'5px'}}>{t('otp_verify')}</div>
                        </div>
                        <div className='Otpqoute' style={{marginTop:'20px'}}>{t('otp_sent')}.</div>
                        <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'space-between',alignItems:'center' }}>
                            <div ><input className='inputOtp' type="text" ref={refs[0]} value={val[0]} onChange={e => changeFocus(e.target.value, 0)} /></div>
                            <div ><input className='inputOtp' type="text" ref={refs[1]} value={val[1]} onChange={e => changeFocus(e.target.value, 1)} /></div>
                            <div><input className='inputOtp' type="text" ref={refs[2]} value={val[2]} onChange={e => changeFocus(e.target.value, 2)} /></div>
                            <div ><input className='inputOtp' type="text" ref={refs[3]} value={val[3]} onChange={e => changeFocus(e.target.value, 3)} /></div>
                        </div>

                        <div className='Otpqoute' style={{marginTop:'20px' ,display:'flex',justifyContent:'center'}}>{t('receive_otp')}<a onClick={()=>onResend()}>{t('resend_otp')}</a></div>

                        <button className='verifyButton' onClick={()=>validateOTP()}>{t('verify_now')}</button>


                    </div>

                </div>
            </div>
        </>
    )
}