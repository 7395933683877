import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import mobile from '../../../assets/icon/mobile.png'
import review from '../../../assets/icon/review.png'
import enquiru from '../../../assets/icon/enguiry.png'
import wishlist from '../../../assets/icon/wishlist.png'
import profile from '../../../assets/icon/profile.png'
import pic from '../../../assets/img/pic.svg'
import '../nabBar.css'
import Loader from "../../../components/Loader";
import Application from "./application";
import MyEnquirey from "./my_Enquirey";
import MyReviews from "./my_reviews";
import Wishlist from './my_wishlist.js'
import Profile from "./profile";



export default function ApplicationSideBar(props) {
  const [filter, setFilter] = useState('desc')
  const [loading,setLoading]=useState(false) 
  const [type, setType] = useState(0)
  const [profilePhoto,setProfilePhoto]= useState("")
  const [name,setName] = useState("")
  const [image,setImage] = useState("")
  const [flag,setFlag] =useState(false)
  const [checked, setChecked] = useState({ school: false, educator: false, coaching: false })
  const { t } = useTranslation()
  const handleOnCheck = (value) => {
    setChecked({ ...checked, [value]: !checked[value] })
  }
  useEffect(() => {
    const { school, coaching, educator } = checked
    let value = (school ? 2 : 0) + (coaching ? 4 : 0) + (educator ? 1 : 0)
    setType(value)
  }, [checked])


  const onChange = (e) => {
    setProfilePhoto(e.target.files[0])
    setImage(URL.createObjectURL(e.target.files[0]))
  }
  


  return (
    <>
      <div className="flex-main">
        <div className="w-3-px">
          <div className="p-18">
            {props.modules === "profile" ? (
              <div>
                <label htmlFor="photo-upload">
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginRight: "25px",
                    }}
                  >
                    {/* <img for="photo-upload" class="pro-pic" src={pic} /> */}
                    {profilePhoto == "" ?
                                                <img for="photo-upload" class="pro-pic" src={pic} /> :
                                                <img for="photo-upload" class="pro-pic" src={image} />
                                            }
                  </div>
                  <input id="photo-upload" type="file" accept="image/*" onChange={onChange} style={{
                                                display: "none",
                                                visibility: "hidden"
                                            }} />
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    {name}
                  </div>
                </label>
              </div>
            ) : (
              <>
                <div>
                  <div
                    className="app"
                    style={{
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    {t("view")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <input
                        type="radio"
                        value="Latest"
                        style={{ marginRight: "30px" }}
                        checked={filter == "desc" ? true : false}
                        onChange={() => setFilter("desc")}
                      />
                      <span className="headingOption">{t("latest")} </span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="Oldest"
                        style={{ marginRight: "30px" }}
                        checked={filter == "acs" ? true : false}
                        onChange={() => setFilter("acs")}
                      />
                      <span className="headingOption">{t("oldest")} </span>
                    </div>
                  </div>
                </div>
                <div style={{ height: "90px" }}>
                  {props.modules == "applications" && (
                    <>
                      <div
                        className="app"
                        style={{
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        {t("status")}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div><input type='checkbox' value='educator' onChange={(e) => handleOnCheck(e.target.value)} checked={checked.educator} style={{ marginRight: '30px' }} /><span className="headingOption">Inprocess</span></div>
                        <div><input type='checkbox' value='school' onChange={(e) => handleOnCheck(e.target.value)} checked={checked.school} style={{ marginRight: '30px' }} /><span className="headingOption">Accepted</span></div>
                        <div><input type='checkbox' value='coaching' onChange={(e) => handleOnCheck(e.target.value)} checked={checked.coaching} style={{ marginRight: '30px' }} /><span className="headingOption">Rejected</span></div>
                      </div>
                    </>
                  )}
                  {props.modules == "wishlist" && (
                    <>
                      <div
                        className="app"
                        style={{
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        {t("status")}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            value="school"
                            onChange={(e) => handleOnCheck(e.target.value)}
                            checked={checked.school}
                            style={{ marginRight: "30px" }}
                          />
                          <span className="headingOption">{t("school")}</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            value="coaching"
                            onChange={(e) => handleOnCheck(e.target.value)}
                            checked={checked.coaching}
                            style={{ marginRight: "30px" }}
                          />
                          <span className="headingOption">
                            {t("coach_inst")}
                          </span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            value="educator"
                            onChange={(e) => handleOnCheck(e.target.value)}
                            checked={checked.educator}
                            style={{ marginRight: "30px" }}
                          />
                          <span className="headingOption">{t("educator")}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {
                    props.modules == "review" &&
                    <>
                      <div
                        className="app"
                        style={{
                          justifyContent: "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        {t("status")}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <input
                            type="radio"
                            value="school"
                            id='isEducator'
                            onClick={(e) => setFlag(false)}
                            checked={flag==false?true:false}
                            style={{ marginRight: "30px" }}
                          />
                          <span className="headingOption">{t("school")}</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            value="educator"
                            id="isEducator"
                            onClick={(e) => setFlag(true)}
                            checked={flag}
                            style={{ marginRight: "30px" }}
                          />
                          <span className="headingOption">{t("educator")}</span>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </>
            )}
          </div>
          <div style={{ height: "2px", background: "#F4F9FF" }}></div>
          <div style={{ padding: "18px" }}>
            {props.modules == "applications" && (
              <>
                <div className="app" style={{ marginBottom: "10px" }}>
                  {t("my_app")}
                </div>
                <div className="smallheading">
                  {t("track_app")}
                </div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <img src={mobile} />
                </div>
              </>
            )}
            {props.modules == "enquirey" && (
              <>
                <div className="app" style={{ marginBottom: "10px" }}>
                  {t("my_enq")}
                </div>
                <div className="smallheading">
                  {t("details_enq")}
                </div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <img src={enquiru} />
                </div>
              </>
            )}
            {props.modules == "review" && (
              <>
                <div className="app" style={{ marginBottom: "10px" }}>
                  {t("my_reviews")}
                </div>
                <div className="smallheading">
                  {t("keep_a_check")}
                </div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <img src={review} />
                </div>
              </>
            )}
            {props.modules == "wishlist" && (
              <>
                <div className="app" style={{ marginBottom: "10px" }}>
                  {t("your_wish")}
                </div>
                <div className="smallheading">
                  {t("add_profiles")}
                </div>
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <img src={wishlist} />
                </div>
              </>
            )}

            {props.modules == "profile" && (
              <div
                style={{
                  background: "#F4F9FF",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <div className="app" style={{ marginBottom: "10px" }}>
                  {t("exam_test")}
                </div>
                <div className="smallheading">
                  {t("take_test")}
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    margin: "10px",
                  }}
                >
                  <img src={profile} />
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    margin: "10px",
                  }}
                >
                  <button className="StartTest">{t("start_test")} </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            width: "75%",
            marginLeft: "10px",
            background: "#FFFFFF",
            padding: "15px",
          }}
        >
          {props.modules == "applications" && <Application filter={filter} type={type} setLoading={setLoading} setCount={props.setCount} search={props.search}/>}
          {props.modules == "enquirey" && <MyEnquirey  setLoading={setLoading} filter={filter}  setCount={props.setCount} search={props.search}/>}
          {props.modules == "review" && <MyReviews  setLoading={setLoading}  filter={filter}  setCount={props.setCount} search={props.search} flag={flag}/>}

          {props.modules == "wishlist" && (
            <Wishlist filter={filter} type={type} setLoading={setLoading} setCount={props.setCount} search={props.search}/>
          )}

          {props.modules == "profile" && <Profile setLoading={setLoading}  profilePhoto={profilePhoto} setProfilePhoto={setProfilePhoto}
          image={image} setImage={setImage}
          setName={setName} name={name}
          />}

          <Loader isLoading={loading}/>
        </div>
      </div>
    </>
  );

}