import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {skeletonDetailsEdu} from '../../../components/Skeleton/skeleton'
const About = ({ p_headline }) => {
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.details?.isLoading)
  // const isLoading = true
  if (isLoading) {
    return (
        <>
        {skeletonDetailsEdu()}
        </>
    )
  }
  return (
    <div className="detail-container">
      <h2>{t('about')}</h2>
      <p>{p_headline?.replace(/<[^>]*>?/gm, '') || 'N/A'}</p>
    </div>
  )
}

export default About
