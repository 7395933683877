import { useEffect, useState } from 'react'
import ScrollButton from '../../components/ScrollToTop'
import Slider from './slider'
import Main from './main'
import TitleSection from './titleSection'
import TitleBottomInfo from './titleBottomInfo'
import ProfileSection from './profileSection'
import ProfileBottomInfo from './profileBottomInfo'
import ScrollIndicator from '../../components/ScrollIndicator'
import MainEdu from './mainEdu'
import { useHistory, useParams } from 'react-router-dom'
import api, { errorMsg } from '../../api/api'
import { toast, ToastContainer } from 'react-toastify'
import { useQuery } from '../../utils/utils'
import { CODE, profileTypeTransform } from '../../utils/profileUtils'
import './details.css'
import CompareButton from '../../components/CompareButton'
import { useDispatch } from 'react-redux'
import ClaimPopup from '../../components/ClaimPopup'

const Index = () => {
  const search = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const { subProfileType, name } = useParams()
  const subProfileTypeTrans = subProfileType?.toLowerCase()
  const id = search.get('id')
  const [profileDetails, setProfileDetails] = useState({})
  const profileType = profileTypeTransform(subProfileTypeTrans)
  const [claim, setClaim] = useState({})
  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    const getProfileDetails = (profileType) => {
      dispatch({ type: 'SET_LOADING', value: true })
      let type = CODE[profileType]
      api
        .get(`website/view/${type}/${id}`)
        .then((response) => {
          if (response.status === 200) {
            let profileDetails = response.data.result
            setProfileDetails(profileDetails)
            let nameTrans = name.replace(/-/g, ' ').toLowerCase()
            // if (!(nameTrans === (profileDetails.e_name?.toLowerCase() || profileDetails.inst_name?.toLowerCase().replace(/-/g,' ')))) {
            //   toast.error('Wrong URL')
            //   setTimeout(() => {
            //     history.replace('/')
            //   }, 1000)
            // }
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(errorMsg(err))
          // setTimeout(() => {
          //   history.replace('/')
          // }, 5000)
        })
        .finally(() => dispatch({ type: 'SET_LOADING', value: false }))
    }
    name && getProfileDetails(profileType)
  }, [id, name, profileType])

  return (
    <div>
      {/* <MyRating /> */}
      <div className="listing-heading-title" style={{ paddingLeft: '25px' }}>
        <span onClick={() => history.push(`/`)}>Home / </span>{' '}
        <span
          style={{ textTransform: 'capitalize' }}
          onClick={() => {
            history.push(`/listing/${subProfileTypeTrans}`)
          }}
        >
          {subProfileTypeTrans.replace(/-/g, ' ')} /{' '}
        </span>
        <span>{profileDetails.inst_name || profileDetails.e_name}</span>
      </div>
      <Slider
        isEducator={profileType === 'educator'}
        profileDetails={profileDetails}
        id={id}
        profileType={profileType}
      />

      {profileType === 'educator' ? (
        <>
          <ProfileSection profileDetails={profileDetails} profileType={profileType} />
          <ProfileBottomInfo profileDetails={profileDetails} profileType={profileType} />
          <MainEdu profileDetails={profileDetails} profileType={profileType} />
        </>
      ) : (
        <>
          <TitleSection
            profileDetails={profileDetails}
            profileType={profileType}
            subProfileType={subProfileTypeTrans}
          />
          <TitleBottomInfo profileDetails={profileDetails} profileType={profileType} />
          <Main profileDetails={profileDetails} profileType={profileType} />
        </>
      )}
      <CompareButton />
      <ScrollButton />
      <ScrollIndicator />
      <ToastContainer theme="colored" closeButton={false} />
    </div>
  )
}
export default Index
