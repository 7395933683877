import HeaderBlank from "./headerBlank";
import HeaderSelected from "./headerSelected";
import { useTranslation } from 'react-i18next';

const Header = ({ compareData, profileType, selectedIds, subProfileType}) => {
  const { t } = useTranslation()
  return (
    <div className="flex-row compare-header">
      <div style={{ padding: "6px 28px 0 0px" }}>
        <h4>
          {t("compare")}{" "}
          {compareData[0]?.inst_name || "None"} vs{" "}
          {t("others")}
        </h4>
        <p>
          {compareData.length} {t("item")}
        </p>
      </div>
      <div>
        {compareData[0] ? (
          <HeaderSelected
            compareDataItem={compareData[0]}
            profileType={profileType}
            subProfileType={subProfileType}
            selectedIds={selectedIds}
          />
        ) : (
          <HeaderBlank profileType={profileType} />
        )}
      </div>
      <div>
        {compareData[1] ? (
          <HeaderSelected
            compareDataItem={compareData[1]}
            profileType={profileType}
            subProfileType={subProfileType}
            selectedIds={selectedIds}
          />
        ) : (
          <HeaderBlank profileType={profileType} />
        )}{" "}
      </div>
      <div>
        {compareData[2] ? (
          <HeaderSelected
            compareDataItem={compareData[2]}
            profileType={profileType}
            subProfileType={subProfileType}
            selectedIds={selectedIds}
          />
        ) : (
          <HeaderBlank profileType={profileType} />
        )}{" "}
      </div>
    </div>
  );
};

export default Header;
