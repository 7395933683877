import { useTranslation } from 'react-i18next';
import { GoSearch } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { home } from '../../assets/images';
import Location from '../Location';

export default function HomeSearch(ext='') {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mst_std_nm, location_det, showLocPopup } = useSelector(state => state.location)
  return (
    <div className={`${'home-search-div2' + ext}`} id="home-search-collapsible">
      <div style={{display: 'flex'}} onClick={() => dispatch({ type: 'TOGGLE_POPUP', showLocPopup: true })}>
        <div className="home-search-input-div2 search-bordered no-margin-right" style={{ flex: 2 }}>
          {mst_std_nm ? <div className="home-search-input" style={{color: '#283B4C'}}>{mst_std_nm}</div> : <div className="home-search-input placeholder-text">4th</div>}
        <img src={home.class_search} style={{ width: 16, height: 20, marginLeft: 20 }} />
        <div className='input-seperator'></div>
      </div>
      <div className="home-search-input-div2 search-bordered no-margin-left" style={{ flex: 6 }}>
        <input
        readOnly
          className="home-search-input"
          type="text"
          placeholder={t('place_hint')}
          style={{cursor: 'pointer'}}
          value={location_det?.address}
        />
        <img src={home.location_icon} style={{ width: 16, height: 20, marginLeft: 20 }} />
      </div>
      </div>
      <div className="home-search-input-div2 search-bordered" style={{ flex: 11 }}>
        <input
          className="home-search-input"
          type="text"
          placeholder={t('school_hint')}
        />
        <GoSearch size={20} color={'#283B4Cr'} style={{ marginLeft: 0 }} />
      </div>
      {showLocPopup && <Location />}
    </div>
  )
}
