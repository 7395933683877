import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Footer from '../components/Footer'
import HeaderMain from '../components/HeaderMain'
import { dashboardRoutes, websiteRoute } from './allRoutes'

function ParamsExample(props) {
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          true ? children : <Redirect to={{ pathname: '/', state: { from: location } }} />
        }
      />
    )
  }

  return (
    <Router>
      <HeaderMain />
      <div className="header-space">
        <Switch>
          {websiteRoute.map((routes, i) => {
            return (
              <Route
                key={i}
                path={routes.path}
                exact={routes.exact}
                strict={routes.strict}
                children={<routes.component />}
              />
            )
          })}
          <PrivateRoute path="/">
            {dashboardRoutes.map((routes, i) => {
              return (
                <Route
                  key={i}
                  path={routes.path}
                  exact={routes.exact}
                  strict={routes.strict}
                  children={<routes.component />}
                />
              )
            })}
          </PrivateRoute>
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}

export default ParamsExample
