import {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { components } from '../../assets/images'
import  api from './../../api/api'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIURL } from '../../api/url';

export default function AdminSignin(props) {

    let [loading, setLoading] = useState(false)
    let [type, setType] = useState(2)
    let [number, setNumber] = useState("")
    const { t } = useTranslation()

    const onSubmit = () => {
        if(number.length !== 10){
            toast.error(t('valid_no'));
        } else {
            setLoading(true);
            api.post({ url: APIURL.userLogin, data: { "phone": number, "user_type": type } }).then((res) => {
                let adata = res.data.result;
                adata.number = number
                adata.user_type = type
                localStorage.setItem("login", JSON.stringify(adata))
                props.setSelected('ValidateOtp')
            }).catch((err) => {
                toast.error("Some error occured while logging in.");
            }).finally(()=>setLoading(false))
        }
    }
    
    return (
        <div className="signin">
            <h2>{t('sign_in')}</h2>
            <h4>{t('to_your_acc')}</h4>
            <div className="flex-row signtype">
                <div onClick={() => setType(2)} className={type==2?"active":""}>School</div>
                <div onClick={() => setType(1)} className={type==1?"active":""}>Coaching</div>
                <div onClick={() => setType(3)} className={type==3?"active":""}>Educator<img title="If you are an individual teacher." src={components.signin_help} style={{marginLeft: '10px', width: '18px', height: '18px'}} /></div>
            </div>
            
            <div className="flex-row">
                <img className="country" src={components.signin_india} />
                <select className="ccode"><option>+91</option></select>
                <input type="text" placeholder={t('phone_hint')}  value={number} onChange={e => setNumber(e.target.value)} />
            </div>
            
            {
                loading ? 
                <div className="loginProgress"><img src={components.loader2} /></div>
                :
                <button  onClick={onSubmit} className="flex-row">{t('confirm')} <img src={components.signin_arrow} /></button>
            }
            
            <h4 className="inst" style={{margin: '10px 0 20px 0'}}><a onClick={() => props.setSelected('Login')}>{t('not_institue')}<span>{t('go_back')}</span></a></h4>
        </div>
    )
}
