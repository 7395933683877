import React, { useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { details, listing, student } from "../../../assets/images";
import ToolTipChild from "../../../components/ToolTipChild";
import { Tooltip } from "@mui/material";

const Achievements = ({ achievements, insideModal }) => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(true)
  console.log({flag})
  function handleFlip(e, index) {
    const front = document.getElementById(`${insideModal ? 'frontm' : 'front'}${index}`);
    const back = document.getElementById(`${insideModal ? 'backm' : 'back'}${index}`);
    front.classList.toggle("flipped");
    back.classList.toggle("flipped");
    setFlag(!flag)
  }

  const downloadImg = (fileItem, fileName) => {
    let element = document.createElement("a");
    let file = new Blob([fileItem], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    element.click();
  };
  return (
      <div className="details-events-sec">
        {Array.isArray(achievements) && achievements.length !== 0 ? (
          achievements.map((item, index) => {
            const {
              id,
              inst_prof,
              i_p_id,
              name,
              year,
              awarded_by,
              desc,
              c_date,
              u_date,
              uid,
              files,
            } = item;
            return (
              <div className="flip-card" key={index}>
                <div id={`${insideModal ? 'frontm' : 'front'}${index}`} className="cardFront">
                  <h3>{year && new Date(year).getFullYear()}</h3>
                  <div>
                    <Tooltip title={name} arrow>
                      <ToolTipChild>
                        <h4 className="ach-title">{name}</h4>
                      </ToolTipChild>
                    </Tooltip>
                    <h6>
                      {t("awarded_by")} : {awarded_by}
                    </h6>
                    <Tooltip title={desc} arrow>
                      <ToolTipChild>
                        <p className="ach-desc">{desc?.replace(/<[^>]*>?/gm, "") ?? "N/A"}</p>
                      </ToolTipChild>
                    </Tooltip>
                    <div className="click">
                      <a onClick={(e) => handleFlip(e, index)}>{t("click_here")}</a>{" "}
                      <img src={details.blue_arrow} />
                    </div>
                  </div>
                </div>
                <div id={`${insideModal ? 'backm' : 'back'}${index}`} className="cardBack">
                  <div key={index}>
                    <h3 onClick={(e) => handleFlip(e, index)} style={{ cursor: "pointer" }}>
                      <IoArrowBackSharp color="#4274B0" size={20} />
                    </h3>
                    <div className="file-section">
                      {item.files?.map((fileItem, index) => {
                        return (
                          <div className="file-row" key={index}>
                            <div className="img-content">
                              {fileItem.f_disp?.split(".").reverse()[0] === "pdf" ? (
                                <img className="pdf-thumbnail" src={listing.pdf} />
                              ) : (
                                <img className="file-thumbnail" src={fileItem.f_url} />
                              )}
                              <p className="file-text">{fileItem.f_disp}</p>
                            </div>
                            {fileItem.f_disp?.split(".").reverse()[0] === "pdf" ? (
                              <a href={fileItem.f_url}>
                                <img className="download-icon" src={student.download} />
                              </a>
                            ) : (
                              <img
                                className="download-icon"
                                src={student.download}
                                onClick={() => downloadImg(fileItem.f_url, fileItem.f_disp)}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h5 style={{ fontSize: "16px" }}>{t("no_data_available")}</h5>
        )}
      </div>
  );
};

export default Achievements;
