export const carouselData = [
  {
    id: "1",
    country: "Canada",
    descPoints: [
      "Scholastic excellence",
      "Affordable fees",
      "Focussed approach on skill development",
      "6th Safest country to live in according to Global Peace Index 2022",
      "Diversified culture",
      "ost-study career opportunities",
    ],
  },
  {
    id: "2",
    country: "US",
    descPoints: [
      "Globally accepted degrees",
      "Tensile education system",
      "Study and earn simultaneously",
      "Flexible scholarship programs",
      "Amazing campus life"
    ],
  },
  {
    id: "3",
    country: "UK",
    descPoints: [
      "Variety of courses",
      "Multiple scholarship programs",
      "Affordable fees",
      "World-class education",
      "Technologically Advanced courses"
    ],
  },
  {
    id: "4",
    country: "Australia",
    descPoints: [
      "Variety of courses",
      "Multiple scholarship programs",
      "Affordable fees",
      "World-class education",
      "Technologically Advanced courses"
    ],
  },
];
