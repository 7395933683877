import React from "react";
import './app_card.css'
import ApplicationCard from "./my_applications";
import { useTranslation } from 'react-i18next';

export default function Application(props) {
    const { t } = useTranslation()
    return (
        <>
            <div>
                <div style={{display:'flex',flexDirection:'row',backgroundColor:'#F4F9FF',justifyContent:'space-between',padding:'10px'}}>
                    <div className="headingCard" style={{width:'50%',display:'flex'}}>{t("stud_name")}</div>
                    <div className="headingCard" style={{width:'70%',display:'flex'}}>{t("applied_for")}</div>
                    <div className="headingCard" style={{width:'30%',display:'flex'}}>{t("fee")}</div>
                    <div className="headingCard" style={{width:'40%',display:'flex'}}>Fee Recipt</div>
                    <div className="headingCard" style={{width:'50%',display:'flex'}}>{t("form _status")}</div>
                    <div className="headingCard" style={{width:'40%',display:'flex'}}>{t("status")}</div>
                </div>
            </div>
            <ApplicationCard  type={props.type}  filter={props.filter} setLoading={props.setLoading}  setCount={props.setCount} search={props.search}/>
        </>
    )
}